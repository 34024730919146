import { BaseModel } from '../../_base/crud';

export class Descarga extends BaseModel {
    id: number;
    nombre_en: string;
    nombre_es: string;
    nombre_de: string;
    nombre_fr: string;
    enlace: string;
    codigo: string;
    imagenes: string;
    destacado: number;
    orden: number;

    clear(): void {
        this.id = undefined;
        this.nombre_es = '';
        this.nombre_en = '';
        this.nombre_de = '';
        this.nombre_fr = '';
        this.enlace = '';
        this.codigo = '';
        this.imagenes = '';
        this.destacado = 0;
        this.orden = 0;
    }
}
