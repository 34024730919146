import { BaseModel } from "../../_base/crud";

export class Referencia extends BaseModel {
  id: number;
  nombre: string;
  familia: number;
  subfamilia: number;
  codigo: string;
  tipo: string;
  largo: string;
  alto: string;
  profundo: string;
  relacionados: string;
  acabados: string;
  tamanoSvg: string;
  descripcion_en: string;
  descripcion_es: string;
  descripcion_de: string;
  descripcion_fr: string;
  descripcion_corta_en: string;
  descripcion_corta_es: string;
  descripcion_corta_de: string;
  descripcion_corta_fr: string;
  productoAcabado: number;
  subcategoria: number;
  id_disenador: number;
  relImagenesAcabados: string;
  imagenes: string;
  clear(): void {
    this.id = undefined;
    this.familia = 0;
    this.subfamilia = 0;
    this.codigo = '';
    this.nombre = '';
    this.tipo = '';
    this.largo = '';
    this.alto = '';
    this.acabados = '';
    this.profundo = '';
    this.descripcion_en = '';
    this.descripcion_es = '';
    this.descripcion_de = '';
    this.descripcion_fr = '';
    this.descripcion_corta_en = '';
    this.descripcion_corta_es = '';
    this.descripcion_corta_de = '';
    this.descripcion_corta_fr = '';
    this.tamanoSvg = '';
    this.productoAcabado = 0;
    this.subcategoria = 0;
    this.id_disenador = 0;
    this.relImagenesAcabados = '';
  }
}
