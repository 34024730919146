import { BaseModel } from '../../_base/crud';

export class Pedido extends BaseModel {
    id: number;
    fecha: Date;
    idCliente: string;
    productos: string;
    importeTotal: number;
    estado: number;
    version: number;

    clear(): void {
        this.id = undefined;
        this.idCliente = '';
        this.productos = '';
        this.importeTotal = 0;
        this.estado = 0;
        this.version = 0;

    }
}
