// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Proyecto } from '../../../core/auth/_models/proyecto.model';
import { CategoriaProyecto } from '../../../../../src/app/core/auth/_models/categoria-proyecto.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteProyectoComponent } from './proyecto/delete-proyecto/delete-proyecto.component';
import { TranslationService } from '../../../core/_base/layout';
@Component({
   selector: 'kt-proyectos',
   templateUrl: './proyectos.component.html',
   styleUrls: ['./proyectos.component.scss']
})
export class ProyectosComponent implements OnInit, OnDestroy {
   tipos_categoria_proyecto: CategoriaProyecto[] = [];
   tipo_cat_proy: string = "0";

   proyectos: Proyecto[] = [];
   proyectos_filtered: Proyecto[] = [];
   dataSource;
   contadorImagenes = [];
   imagenesProyecto = [];
   tmpProyectosForm = new FormGroup({
      selected: new FormControl()
   });

   displayedColumns: string[] = ['select', 'id', 'titulo', 'opciones'];

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;

   selection = new SelectionModel<Proyecto>(true, []);

   constructor(
      private api: ApiService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private subheaderService: SubheaderService,
      private matDialog: MatDialog,
      private header: HeaderService,
      private footer: FooterService,
      public ts: TranslationService) {
      this.header.hide();
      this.footer.hide();
   }

   ngOnInit() {
      this.subheaderService.setTitle('Sub Familias');
      this.api.readData('proyectos').subscribe((res: any) => {
         for (const o of res.data) {
            this.proyectos.push(o);
            this.proyectos_filtered.push(o);
            this.contadorImagenes[o.id] = '-';
         }
         if (this.tipo_cat_proy != '0'){
            this.proyectos_filtered = this.proyectos_filtered.filter(proy => proy.id_categoria.toString() == this.tipo_cat_proy);
         }
         this.dataSource = new MatTableDataSource(this.proyectos_filtered);
         this.dataSource.paginator = this.paginator;
         this.dataSource.sort = this.sort;
      });
      this.api.readData('categoriasproyectos').subscribe((res: any) => {
         for (const o of res.data) {
            this.tipos_categoria_proyecto.push(o);
         }
      });
   }

   applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
      }
   }

   filtrar() {
      this.proyectos_filtered = this.proyectos;
      if (this.tipo_cat_proy != "0")
         this.proyectos_filtered = this.proyectos_filtered.filter(proy => proy.id_categoria.toString() == this.tipo_cat_proy);
      this.dataSource = new MatTableDataSource(this.proyectos_filtered);
   }

   isAllSelected(): boolean {
      const numSelected = this.selection.selected.length;
      const numRows = this.proyectos.length;
      return numSelected === numRows;
   }

   masterToggle() {
      if (this.selection.selected.length === this.proyectos.length) {
         this.selection.clear();
      } else {
         this.proyectos.forEach(row => this.selection.select(row));
      }
   }

   fetchProyectos() {
      const messages = [];
      this.selection.selected.forEach(elem => {
         messages.push({
            text: `${elem.titulo_es}`,
            id: elem.id.toString(),
            status: elem.titulo_es
         });
      });
   }

   editProyecto(id: number) {
      this.router.navigate(['../proyectos/', id], { relativeTo: this.activatedRoute });
   }


   deleteProyecto(proyecto: Proyecto) {
      const dialogConfig: MatDialogConfig = {
         autoFocus: true,
         maxWidth: '400px',
         data: {
            id: proyecto.id,
            nombre: proyecto.titulo_es
         }
      };
      const dialogRef = this.matDialog.open(DeleteProyectoComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(res => {
         if (res) {
            this.api.deleteData('proyectos', res).subscribe(res2 => {
               if (res2) {
                  this.dataSource.data = this.dataSource.data.filter(obj => obj !== proyecto);
               }
            });
         }
      });
   }

   ngOnDestroy(): void {
      this.header.toggle();
   }
}
