import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Localizacion } from '../../../../core/auth/_models/localizacion.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from '../../../../core/_base/layout';
@Component({
  selector: 'kt-localizacion',
  templateUrl: './localizacion.component.html',
  styleUrls: ['./localizacion.component.scss']
})
export class LocalizacionComponent implements OnInit, OnDestroy {

  localizacion: Localizacion;
  localizacionId: Observable<number>;
  localizacionForm: FormGroup;
  localizaciones = [];
  localizacionesForm = new FormGroup({
    selected: new FormControl(),
  });
  sublocalizaciones = [];
  hasFormErrors = false;
  selectedTab = 0;
  loadingFile = false;
  subcatNombre = '';
  subcatNombre_es = '';

  idLocalizacion = 0;
  tipos = [
    { display: 'Continente', value: '1' },
    { display: 'País', value: '2' },
    { display: 'Provincia/estado', value: '3' },
    { display: 'Ciudad', value: '4' }];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private api: ApiService,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef,
    public ts: TranslationService) {
    this.header.hide();
    this.footer.hide();
  }



  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idLocalizacion = params.id;
      this.localizacion = new Localizacion();
      this.localizacion.clear();
      this.localizaciones.push({
        display: 'Ninguno',
        value: 0,
      });

      this.api.readData('localizaciones').subscribe((res: any) => {
          for (const o of res.data) {
            this.localizaciones.push({
              display: o.nombre_es,
              value: Number(o.id),
            });
          }
        });
      if (this.idLocalizacion && this.idLocalizacion > 0) {
        this.api.readData('localizaciones', '*', 'id = ' + this.idLocalizacion).subscribe((res: any) => {
          this.localizacion = Object.assign(this.localizacion, res.data[0]);
          if (
            this.localizacion.padre &&
            this.localizacion.padre !== ''
          ) {
              const jsonLocalizaciones = JSON.parse(this.localizacion.padre);
              this.localizacionesForm.setValue({
                selected: jsonLocalizaciones.selected,
              });
              this.cdRef.detectChanges();
          }

          this.initLocalizacion();
        });
      } else {
        this.initLocalizacion();
      }
    });
  }

  initLocalizacion() {
    this.createForm();
    if (!this.localizacion.id) {
      this.subheaderService.setTitle('Crear localizacion');
      this.subheaderService.setBreadcrumbs([
        { title: 'Localizaciones', page: `localizaciones` },
        { title: 'Crear localizacion', page: `localizaciones/new` }
      ]);
      return;
    }
    this.subheaderService.setTitle('Editar Familia');
    this.subheaderService.setBreadcrumbs([
      { title: 'Localizaciones', page: `localizaciones` },
      { title: 'Editar localizacion', page: `localizaciones`, queryParams: { id: this.localizacion.id } }
    ]);
    this.cdRef.detectChanges();
  }

  createForm() {
    this.localizacionForm = this.fb.group({
      nombre_en: [this.localizacion.nombre_en],
      nombre_es: [this.localizacion.nombre_es],
      nombre_de: [this.localizacion.nombre_de],
      nombre_fr: [this.localizacion.nombre_fr],
      padre: [this.localizacion.padre],
      tipo: [this.localizacion.tipo],
    });

    console.log(this.localizacionForm);
  }

  getComponentTitle() {
    let result = 'Crear localizacion';
    if (!this.localizacion || !this.localizacion.id) {
      return result;
    }

    result = `Editar localizacion - ${this.localizacion.nombre_es}`;
    return result;
  }

  saveLocalizacion() {
    const newLocalizacion = Object.assign(this.localizacion, this.localizacionForm.value);
    newLocalizacion.padre = JSON.stringify(this.localizacionesForm.value);

    if (!newLocalizacion.id) {
      this.api.createData('localizaciones', newLocalizacion).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/panel/localizaciones']);
        }

      });
    } else {
      this.api.updateData('localizaciones', newLocalizacion).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/panel/localizaciones']);
        }

      });
    }

  }

  ngOnDestroy(): void {
    this.header.toggle();
    this.footer.toggle();
  }

}
