// Angular
import {Component, OnInit, ChangeDetectorRef, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Client } from '../../core/auth/_models/client.model';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/auth/user.service';

import * as $ from 'jquery';
@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class LoginComponent implements OnInit {
  // Public params
  recordarPassword: boolean;
  idioma: string;
  loginForm: FormGroup;
  formRecordarPassword: FormGroup;
  registroFinalizado = false;
  textoError = '';
  errorLogin = false;
  clienteRecordar: Client;
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private cdRef: ChangeDetectorRef,
    private translate: TranslateService,
    public userService: UserService
  ) { this.idioma = translate.currentLang; this.recordarPassword = false; }

  ngOnInit(): void {
    this.initLogin();
  }
  initLogin() {
    this.createForm();
    this.createFormRecordarPassword();
    this.cdRef.detectChanges();
  }

  iniciarSesion() {
    if (this.loginForm.valid) {
      this.errorLogin = false;
      this.api
        .loginCliente(
          this.loginForm.controls.email.value,
          this.loginForm.controls.password.value
        )
        .subscribe((res: any) => {
          console.log(res);

          if (res.result.logged) {
            const usuario = res.data.userData;
            switch (usuario.tipo) {
              case '4':
                usuario.currency = 'CAD';
                break;
              case '6':
                usuario.currency = '$';
                break;
              default:
                usuario.currency = '€';
                break;
            }
            localStorage.setItem('userToken', res.data.accessToken);
            this.userService.cliente = usuario;
            this.userService.checkCart().then(() => {
              this.cdRef.detectChanges();
            });
            this.userService.cerrarLogin();

          } else {
            this.errorLogin = true;
            if (res.data.codError === '1') {
              this.textoError = this.translate.instant('SESION.ERRORUSER');
            } else if (res.data.codError === '2') {
              this.textoError = this.translate.instant('SESION.ERRORPASS');
            }
            this.cdRef.detectChanges();
          }
        });
    } else {
      this.errorLogin = true;
      this.textoError = this.translate.instant('SESION.ERRORDATOS');
      this.cdRef.detectChanges();
    }
  }


  cerrarSesion() {
    localStorage.removeItem('userToken');
    this.userService.cliente = null;
    this.userService.logged = false;
    this.userService.cerrarLogin();
    this.userService.checkCart().then(() => {
      this.cdRef.detectChanges();
    });
  }


  recordarContrasena() {
    if (this.formRecordarPassword.controls.email.value === '') {
      this.errorLogin = true;
      this.textoError = this.translate.instant('SESION.ERRORMAIL');
      this.cdRef.detectChanges();

    } else {
      this.api.readData('tiposreferencias').subscribe((res: any) => {
        if (res.data && res.data !== '') {

          this.api.sendMail('3', '', this.idioma, this.formRecordarPassword.controls.email.value).subscribe((resMail) => {
            this.errorLogin = true;
            this.textoError = this.translate.instant('SESION.SENTMAIL');
            this.cdRef.detectChanges();
          });

        } else {
          this.errorLogin = true;
          this.textoError = this.translate.instant('SESION.ERRORDATOS');

        }
      });

    }
  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  createFormRecordarPassword() {
    this.formRecordarPassword = this.fb.group({
      email: ['', [Validators.required]]
    });
  }

  toggleLoginForm() {
    this.recordarPassword ? this.recordarPassword = false : this.recordarPassword = true;
  }
}
