// Spain
export const locale = {
    lang: "de",
    data: {
	HOME: {
		NEWCATALOGUES: 'CONSULTA NUESTROS NUEVOS CATÁLOGOS ONLINE',
	},
	COOKIES: {
		THISTIME: 'Dieses Mal akzeptieren',
		ACCEPT: 'Akzeptieren',
		REJECT: 'Ablehnen',
		PRIVACY: 'Wir schätzen Ihre Privatsphäre',
		TEXT: 'Diese Website verwendet Cookies, um Ihre Benutzerfreundlichkeit zu verbessern. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
		LINK: 'Nuestra política de cookies',
    CONTENT: `<h3>Models, Finishes and Colours</h3>

    <p>Models: Here in PUNT we work constantly on the improvement of our products so we reserve the right to amend the measurements, materials, colours, etc. of our products with the aim of improving their quality, service, strength or price. If the products that you have ordered present some variation with respect to our current catalogue and prices, we will inform you previously to check that you are still interested.</p>

    <p>Finishes: All the finishes that we offer (natural, stained or lacquered wood, lacquered or anodised metal), even though they are protected, can gradually change their original colour over time due to sunlight exposure. Wood boards and wood pieces, because of their natural origin, present different and non-homogeneous patterns and tones. In PUNT we select each element of a piece of furniture to make these differences aesthetically pleasing.</p>

    <p>Colours: The actual appearance of the nice colours of our products may differ slightly from the ones you have seen on a screen or a catalogue.</p>

    <h3>Orders, Transportation and Delivery Times</h3>

    <p>Orders: To avoid confusion, you must formalise your orders in writing. Once we get a completely defined order, we will send you an email and start to prepare your delivery. Since this moment, any cancellation on your part might result in the reimbursement of the costs and expenses incurred by PUNT through the preparation of your delivery.</p> 

    <p>Transportation: In the confirmation of your order, we will indicate the accorded transportation service and if it generates some costs.</p>

    <p>Delivery Time: PUNT will indicate the estimated delivery times according to the purchased products and their shipping destination. We are working hard to improve these estimated times. We hope that you will excuse us if it is not possible owing to unforeseen causes. When your order is ready, we will deliver it in the shipping destination indicated on the order form, where you must sign the delivery receipt.</p>

    <h3>Warranty</h3>

    <p>If you are a final customer, the quality of the product is under warranty for 2 years, so you must keep the purchase receipt. If you have any incident, don’t send us back the product. Just send us a photo and an explanation so we can understand and solve the problem. Damages are not covered if they result from an inappropriate use of the product. In order to avoid it, we will deliver along with each product an installation manual and a guide for use and maintenance. We are at your disposal at info@puntmobles.com to answer any questions or requests that you might have.</p>

    <p>The shipping company is the only responsible for the damages caused during transportation, so please check carefully the product at the delivery and write down any suspected defect on the shipment document or the company won’t take the responsibility for it. Our packaging is very safe but it cannot protect the product from every mishandling. Make sure that you handle the product carefully before unpacking it and don’t unpack it until it is placed in its final location.</p>

    <h3>Furniture Cleaning and Maintenance</h3>

    <p>For a proper preservation, the piece of furniture must be kept in a dry space. You must remove any spillage or stain immediately so it doesn’t penetrate or damage the protective varnish layer. Every furniture material must be wiped with slightly damp non-abrasive cloths or cleaned with non-acid cleaning agents except paint thinners such as acetone. You mustn’t use cleaning agents containing alcohol on wood with a water-based coating. After cleaning, dry the surface with a dry cloth.</p>

    <h3>Web, contents and design</h3>

    <p>PUNT MOBLES XXI, S.L. (“PUNT”) is the owner of this domain and the content existing in it. If you want to reproduce it totally or partially, you must request permission. Likewise, PUNT has got the exclusive selling rights of the displayed products.</p>

    <h3>Data Protection</h3>

    <p>All the personal data sent through the website are stored in the servers of PUNT. We only register those data that are needed to send you the request information and news from PUNT and to process your orders. If you are a dealer, we communicate your data to your national commercial agent and the supplier of delivery services that will bring you the products. If you are a final customer, we send your data to a suitable distributor, as we are not selling online. All the agents involved are committed to maintain the privacy of your personal data and to use them solely for its designated purpose. We do not pass on your data in any case to commercial address brokers, so you are not going to receive unwanted advertising that is not related to PUNT. You can request at any moment information on the stored data and their use. You can also update, modify or remove them if you don’t want us to use them again. You only have to send an email to info@puntmobles.com.</p>

    <h3>Use of Cookies</h3>

    <p>A cookie is a small file generated and stored in the user’s computer when he/she visits our website. These files contain some information on the configuration of our site and information on your web browsing habits. The information obtained by the cookies is absolutely anonymous and cannot be linked to any specific and identified use in any case. This information allows us to know the usage patterns and statistics to improve the user browsing experience. The use of cookies can be disabled by selecting the appropriate settings on your browser.</p>`
	},
  NEWSLETTER: {
    TITLE: 'ERFAHREN SIE ES ALS ERSTER',
    DESCRIPTION1: 'Erhalten Sie als Erster neue Angebote, Produkte in limitierter Auflage, Veranstaltungen und Inspirationen direkt in Ihrem Mailbox',
    DESCRIPTION2: 'Introduciondo tu dirección de correo aceptas nuestra',
    PRIVACY: 'política de privacidad.'
  },
	NAVBAR: {
    PRODUCTSFINISHED: 'Fertige Produkte',
		PRODUCTS: 'Produkte',
		PROJECTS: 'Projekte',
		DESIGNERS: 'Designer',
		DOWNLOADS: 'Download',
		ABOUT: 'Über uns',
		CONTACT: 'Kontakt',
		LANGUAGE: 'Sprache',
		SIGNUP: 'Registrierung',
	},
      TRANSLATOR: {
        SELECT: "Wählen Sie Ihre Sprache",
        SPANISH: "Spanisch",
        ENGLISH: "Englisch",
        GERMAN: "Deutsch"
      },
      RESTORE: {
        RESTORE: "Stellen Sie Ihr Passwort wieder her",
        SUCCESS: "Ihr Passwort wurde aktualisiert.",
        UPDATE: "Aktualisieren",
        PASSMATCH: "Passwörter stimmen nicht überein",
        RESEND: "Das Passwort konnte nicht wiederhergestellt werden. Fordern Sie die Wiederherstellungs-E-Mail erneut an."
      },
      SESION: {
        EMAIL: "E-mail",
        PASSWORD: "Passwort",
        MYDATA: "Meine Daten",
        SIGNOFF: "Log out",
        LOGIN: "Log in",
        FORGOTPASS: "Haben Sie das Passwort vergessen? Klicken Sie hier.",
        ERRORDATOS: "Sie müssen alle Felder ausfüllen",
        ERRORMAIL: "Sie müssen das E-Mail-Feld ausfüllen",
        ERRORUSER: "Es gibt keinen Benutzer mit den eingegebenen Daten",
        SENTMAIL: "Wir haben Ihnen eine E-Mail gesendet, um Ihr Passwort wiederherzustellen. Überprüfen Sie Ihre Mailbox.",
        PROBLEMS: "Wenn Sie Probleme beim Zugriff haben, wenden Sie sich an"

      },
      DISTRIBUTORS: {
        NAME: 'Name',
        EMAIL: 'Email',
        PHONE: 'Phone number',
        LOCATION: 'LOCATION',
        PROVINCESTATE: 'State / province',
        COUNTRY: 'Country',
        TITLERESULTS: 'Distributors for location',
        NORESULTS: 'There are no results for this location',
        AGENT: 'Agent',
        AGENTMAIL: 'Email Agent',
        CONTACT: 'Kontaktieren Sie uns, finden Sie Ihren Händler'
  
      },
      CONFIGURADOR: {
        COLLECTIONS: "Kollektionen",
        BACK_BUTTON: "Vorherige",
        NEXT: "Folgende",
        COMPOSITION: "KOMPOSITION",
        FINISH: "Ausführung",
        SUMMARY: "Zusammenfassung",
        ADDCART: "In den Warenkorb legen",
        ADDMORE: "Weitere Artikel hinzufügen",
        GOCART: "Zum Warenkorb gehen",
        CONTINUEBUY: "Zurück zu Kollektionen",
        DOWNLOADS: "Downloads",
        REFERENCE: "Artikel",
        QTY: "Menge",
        PRIZE: "Preis ( ohne MWST.)",
        LOGINREQUIRED: "Um die Preise sehen zu können",
        LOGIN: "melden Sie an",
        PRIZEPENINSULA: 'Preis für das spanische Festland.',
        PRIZEADVICE: 'Bitte beachten Sie, dass es sich um einen Preis ohne MwSt. handelt, die MwSt. wird im Land der Einfuhr gezahlt. Andere Kosten wie Versand, Zoll und Deklarationskosten sind nicht enthalten.',
        REQUESTPRICE: 'Preis anfragen'
      },
      COLLECTIONS: {
        IMAGENDETALLE: 'DETAILBILDER',
        PRESENTATION: 'PRODUKTPRÄSENTATION',
        VIDEO: 'VIDEO',
        REVIT: "REVIT",
        SHEET: "TECHNISCHE INFORMATIONEN",
        THREEDFILES: "3D - ZEICHNUNG",
        TWODFILES: "2D - ZEICHNUNG",
        PHOTOSHI: "FOTOS IN HOHER AUFLÖSUNG",
        PHOTOSLO: "NIEDRIGE AUFLÖSUNG FOTOS",
        COLLECTIONS: "Kollektionen",
        GUARANTIES: 'ZERTIFIKAT DER QUALIÄT UND GARNTIE',
        DESIGNEDBY: 'entworfen von',
        DISCOVER: 'Entdecken Sie die gesamte Kollektion',
        BACKGROUNDWHITE: 'WEIßER HINTERGRUND',
        HOJAMONTAJE: 'MONTAGE',
        FILTER: "Filter",
        SEARCH: "Suche",
        TYPE: "Modell",
        ALL: "Alle",
        PRODUCTSBY: 'Produkte von',
        SEARCHINFO: "Mit unserer Suchmaschine finden Sie die benötigten Produktdateien"
      },

      DOWNLOADS: {
        DOWNLOADS: "Downloads",
        FILTER: "Auswählen",
        SEARCH: "Suchen...",
      },

      ORDER: {
        COLLECTIONS: "Kollektionen",
        ORDER: "Angebot",
        FILTER: "Filter",
        REFERENCE: "Artikel",
        QTY: "Menge",
        PRIZE: "Preis",
        PRIZEUNIT: "Stückpreis",
        REMOVE: "Löschen",
        PRIZEVALID: 'Preise gültig für 7 Tage',
        MKORDER: "Beenden",
        FINISHED: "Danke!",
        DOWNPDF: "PDF Download",
        TOTCOMPOSITION: "GESAMT ZUSAMMENSETZUNG",
        TOTALAMOUNT: "GESAMT ANGEBOT",
        IVANO: "Exkl. MwSt.",
        COMMENTS: "KOMMENTARE",
        MOREINFO: "DANKE, DASS SIE UNSEREN E-PREISLISTE BENUTZEN. DIESER PROZESS HAT KEINE BESTELLUNG AUTOMATISCH BEI PUNT ERZEUGT. WENN SIE UNS KONTAKTIEREN MÖCHTEN, SCHICKEN SIE EINE E-MAIL AN IHREN ANSPRECHPARTNER ",
        VALIDITY: 'Preise gültig bis ',
        VATNOTINCLUDE: 'Exkl. MwSt.',
        OBSERVATIONS: 'KOMENTARE: '
      },

      SIGNUP: {
        SIGNUP: "ANMELDUNG",
        BRANDNAME: "Handelsname",
        BUSSNAME: "Firmenname",
        CONTACTPERS: "Gesprächspartner",
        VATID: "Steuernummer",
        ADDRESS: "Adresse",
        POSTALCODE: "Postleitzahl",
        CITY: "Stadt",
        STATE: "Bundesland",
        PHONE: "Telefon",
        EMAIL: "E-Mail",
        WEBSITE: "Web",
        PASSWORD: "Passwort",
        CONFPASSWORD: "Passwort bestätigen",
        SIGNUPI: "Sich anmelden",
        SIGNUPMESSAGE: "Erfolgreiche Registrierung!",
        GOCOLLECTIONS: "Zu Kollektionen gehen",
        COUNTRY: "Land",
        USEREXISTS: "Es gibt bereits einen Benutzer mit dieser E-Mail. Bitte loggen Sie sich ein."
      },
      MENU: {
        NEW: "neu",
        ACTIONS: "Verhalten",
        CREATE_POST: "Neuen Beitrag erstellen",
        PAGES: "Seiten",
        FEATURES: "Eigenschaften",
        APPS: "Anwendungen",
        DASHBOARD: "Tafel",
        USERS: "Benutzer",
        CLIENTS: "Kunden",
        FAMILIES: "Familien",
        SUBFAMILIES: "Unterfamilien",
        COLECTION: "Kollektionen",
        FINISHES: "Ausführungen",
        PRICES: "Preise",
        REFERENCES: "Artikel",
        MODULES: "Module",
        PLINTHS: "Gestelle",
        COVERS: "Platten",
        ORDERS: "Angebotsliste",
        UNFINISHED: "Unvollendete Angebote",
        ABOUT: 'Uber uns',
        PROJECTS: 'Projekte',
        DESIGNERS: 'Designer',
        DISTRIBUTORS: 'Vertriebspartner'
      },
     PROJECTS: {
        PROJECTS: 'Projekte'
      },
	  FOOTER: {
		ABOUT: 'Über uns',
		PROFESSIONAL: 'Professionell',
		ABOUTUS: 'Über uns',
		DESIGNERS: 'Designer',
		VIRTUALSHOWROOM: 'Virtual showroom',
		LEGALMAINTENANCE: 'AGB & Pflege',
		SUSTAINABILITY: 'Nachhaltigkeit',
		DOWNLOADS: 'Download',
		DISTRIBUTIONS: 'Vertriebspartner',
		CONTACT: 'Kontakt',
		PRESSKIT: 'Presskit',
		NEWSLETTER: 'Newsletter',
		FOLLOWUS: 'Folgen Sie uns auf',
	  },
      AUTH: {
        GENERAL: {
          OR: "O",
          SUBMIT_BUTTON: "Senden",
          NO_ACCOUNT: "Haben Sie kein Konto?",
          SIGNUP_BUTTON: "Anmelden",
          FORGOT_BUTTON: "Sie haben Ihr Passwort vergessen",
          BACK_BUTTON: "Zurück",
          PRIVACY: "Datenschutz",
          LEGAL: "Legal",
          CONTACT: "Kontakt",
          TITLE: "Anmelden",
        },
        LOGIN: {
          TITLE: "Ein Konto erstellen",
          BUTTON: "Einloggen",
        },
        FORGOT: {
          TITLE: "Passwort vergessen?",
          DESC: "Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
          SUCCESS: "Ihr Konto wurde erfolgreich zurückgesetzt."
        },
        REGISTER: {
          TITLE: "Anmelden",
          DESC: "Geben Sie Ihre Daten ein, um Ihr Konto zu erstellen",
          SUCCESS: "Ihr Konto wurde erfolgreich registriert.",
          REGISTER: "ANMELDUNG",
        },
        INPUT: {
          EMAIL: "E-mail",
          FULLNAME: "Vollständiger Name",
          PASSWORD: "Passwort",
          CONFIRM_PASSWORD: "Passwort bestätigen",
          USERNAME: "Nutzername",
        },
        VALIDATION: {
          INVALID: "{{name}} ist ungültig",
          REQUIRED: "{{name}} ist erforderlich",
          MIN_LENGTH: "{{name}} Mindestlänge ist {{min}}",
          AGREEMENT_REQUIRED: "Das Akzeptieren der Allgemeinen Geschäftsbedingungen ist erforderlich",
          NOT_FOUND: "Der angeforderte {{name}} wurde nicht gefunden",
          INVALID_LOGIN: "Die Zugangsdaten sind falsch",
          REQUIRED_FIELD: "Pflichtfeld",
          MIN_LENGTH_FIELD: "Mindestfeldlänge:",
          MAX_LENGTH_FIELD: "Maximale Feldlänge:",
          INVALID_FIELD: "Feld ist ungültig",
        },
      },
      SUTAINABILITY: {
        T1 : 'Let’s Protect the Environment!',
        D11 : 'We are aware that the environment is everyone’s concern and we work with the highest level of quality and design to ensure a long-lasting use of all our products. We mostly work with recycled and/or recyclable materials and we rely on suppliers with certified processes of on-going improvement and respect for the environment.',
        D12 : 'All the electrical energy consumed in our factory is nourished by renewable energy sources.',
        T2 : 'Reducing the carbon footprint',
        D2 : 'Trees in their growth process, capture the CO2 released by burning fossil fuels. Producing wood that becomes furniture reduces atmospheric CO2 thus mitigating the greenhouse effect. At Punt we go further. All electricity consumed in the manufacturing process comes from renewable sources so that we reduce to 0% carbon emissions in our factory!',
        T3 : 'Materiales reciclados',
        D3 : 'We specialise in natural wood veneered board. By using this material, we offer you the highest quality finishes with 100% recycled cores.',
        T4 : 'Recyclable Materials',
        D4 : 'Our products are almost 100% exclusively made of wood, steel, aluminium and glass. These materials are identified on the product sheets that are delivered to our customers in each command and are 100% recyclable.',
        T5 : 'FSC',
        T6 : 'Let’s Respect our Forests',
        D61 : 'Protecting our forests and reducing the carbon dioxide in the atmosphere lies in our hands. By controlling the origin of the wood we can guarantee the reforestation of the forests used for obtaining our raw material. Growing trees capture the CO2 released by the combustion of fossil fuel, thus palliating the greenhouse effect.',
        D62 : 'FSC is a non-profit global organisation dedicated to promote responsible management of the forests all around the world. FSC certification guarantees that the products proceed from well managed forests that bring environmental, social and economic benefits. Concretely, the Chain of Custody certification applies to producers, auctioneers and distributors of FSC certified forest products. This type of certification checks that the products using FSC labels actually contain FSC certified materials and their source has been controlled through the chain of production. Controlled Wood has been defined to prevent companies or organisations from including in their materials wood from unacceptable sources. The FSC Controlled Wood can only be combined with FSC certified wood in products labelled as FSC Mixed Sources.',
        D63 : 'In Punt, we work with suppliers of FSC certified boards in Chain of Custody (FSC-STD-40-004) and in Controlled Wood (FCD-STD-40-005).',
        T7 : 'Warranty of Quality, respect for the environment and on-going improvement',
        D71 : 'Punt is certified in accordance with ISO 9001.',
        D72 : 'We select thoroughly our suppliers and we work together in order to guarantee the highest quality for our products. Therefore, our suppliers of boards, varnishes, foams, fabrics, metallic pieces and hardware hold ISO 9001:2008 certification, and our fabrics and varnishes suppliers hold ISO 14001:2004 certification too.',
      },
      CONTACT: {
        TGENERAL: "Kaufmännische Abteilung SPAIN / ESPAÑA",
        TCOMERCIAL: "Kaufmännische Abteilung OTHER COUNTRIES",
        TBUYS: "Einkaufsabteilung"
      },
      ECOMMERCE: {
        COMMON: {
          SELECTED_RECORDS_COUNT: "Ausgewählte Datensätze zählen: ",
          ALL: "Alle",
          SUSPENDED: "Ausgesetzt",
          ACTIVE: "Aktiv",
          FILTER: "Filter",
          BY_STATUS: "nach Status",
          BY_TYPE: "nach Typ",
          BUSINESS: "Geschäft",
          INDIVIDUAL: "Individuell",
          SEARCH: "Suchen",
          IN_ALL_FIELDS: "in alle Felder",
        },
        ECOMMERCE: "E-Commerce",
        CUSTOMERS: {
          CUSTOMERS: "Kunden",
          CUSTOMERS_LIST: "Kundenliste",
          NEW_CUSTOMER: "Neue Kunde",
          DELETE_CUSTOMER_SIMPLE: {
            TITLE: "Kunde löschen",
            DESCRIPTION: "Möchten Sie diesen Kunden wirklich dauerhaft löschen?",
            WAIT_DESCRIPTION: "Kunde wird gelöscht...",
            MESSAGE: "Kunde wurde gelöscht",
          },
          DELETE_CUSTOMER_MULTY: {
            TITLE: "Kunden löschen",
            DESCRIPTION: "Sind Sie sicher, dass Sie ausgewählte Kunden endgültig löschen?",
            WAIT_DESCRIPTION: "Kunden werden gelöscht...",
            MESSAGE: "Ausgewählte Kunden wurden gelöscht",
          },
          UPDATE_STATUS: {
            TITLE: "Status wurde für ausgewählte Kunden aktualisiert",
            MESSAGE: "Status für ausgewählte Kunden wurde erfolgreich aktualisiert",
          },
          EDIT: {
            UPDATE_MESSAGE: "Kunde wurde aktualisiert",
            ADD_MESSAGE: "Kunde wurde erstellt",
          }
        }
      }
    }
  };
  