import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Comercial } from '../../../../core/auth/_models/comercial.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'kt-comercial',
	templateUrl: './comercial.component.html',
	styleUrls: ['./comercial.component.scss']
})
export class ComercialComponent implements OnInit, OnDestroy {

	comercial: Comercial;
	comercialId: Observable<number>;
	comercialForm: FormGroup;
	paises = [];
   	paisesForm = new FormGroup({
    selected: new FormControl(),
  	});
	subcomerciales = [];
	hasFormErrors: boolean = false;
	selectedTab = 0;
	loadingFile: boolean = false;
	subcatNombre = '';
	subcatNombre_es = '';

	idComercial = 0;

	constructor(
		public _DomSanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private subheaderService: SubheaderService,
		private api: ApiService,
		private header: HeaderService,
		private footer: FooterService,
		private cdRef: ChangeDetectorRef) {
		this.header.hide();
		this.footer.hide();
	}



	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.idComercial = params.id;
			this.comercial = new Comercial();
			this.comercial.clear();

			this.api.readData("paises")
			.subscribe((res: any) => {
				for (const o of res.data) {
				this.paises.push({
					display: o.nombre,
					value: o.id,
				});
				}
			});

			if (this.idComercial && this.idComercial > 0) {
				this.api.readData('comerciales', '', 'id = ' + this.idComercial).subscribe((res: any) => {
					this.comercial = Object.assign(this.comercial, res.data[0]);
					this.initComercial();
					if (
						this.comercial.paises &&
						this.comercial.paises !== ''
					) {
						const jsonPaises = JSON.parse(this.comercial.paises);
						this.paisesForm.setValue({
						selected: jsonPaises.selected,
						});
					}
				})
			} else {
				this.initComercial();
			}
		});

		this.cdRef.detectChanges();

	}

	initComercial() {
		this.createForm();
		if (!this.comercial.id) {
			this.subheaderService.setTitle('Crear comercial');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Comerciales', page: `comerciales` },
				{ title: 'Crear comercial', page: `comerciales/new` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar Familia');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Comerciales', page: `comerciales` },
			{ title: 'Editar comercial', page: `comerciales`, queryParams: { id: this.comercial.id } }
		]);
	}

	createForm() {
		this.comercialForm = this.fb.group({
     	nombre: [this.comercial.nombre, Validators.required],
        apellido1: [this.comercial.apellido1],
        apellido2: [this.comercial.apellido2],
        email: [this.comercial.email, Validators.required],
        paises: [this.comercial.paises],
		});

		console.log(this.comercialForm);
	}

	getComponentTitle() {
		let result = 'Crear comercial';
		if (!this.comercial || !this.comercial.id) {
			return result;
		}

		result = `Editar comercial - ${this.comercial.nombre}`;
		return result;
	}

	saveComercial() {
		const newComercial = Object.assign(this.comercial, this.comercialForm.value);
		newComercial["paises"] = JSON.stringify(this.paisesForm.value);

		if (!newComercial.id) {
			this.api.createData('comerciales', newComercial).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/comerciales']);
				}
				
			});
		} else {
			this.api.updateData('comerciales', newComercial).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/comerciales']);
				}

			});
		}
		

	}

	quitarPais(id) {
    	const posicion: number = this.paisesForm.value.selected.indexOf(id);
		if (posicion !== -1) {
		this.paisesForm.value.selected.splice(posicion, 1);
		var selecActual = this.paisesForm.value.selected;
		this.paisesForm = new FormGroup({
			selected: new FormControl(selecActual),
		});
		}
	}
	devolverPais(id) {
		if (this.paises.find((element) => element.value == id)) {
		return this.paises.find((element) => element.value == id)[
			"display"
		];
		}
	}


	ngOnDestroy(): void {
		this.header.toggle();
		this.footer.toggle();
	}

}
