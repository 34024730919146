import { BaseModel } from '../../_base/crud';

export class Subcategoria extends BaseModel {
    id: number;
    categoria: number;
    nombre_en: string;
    nombre_es: string;
    nombre_de: string;
    nombre_fr: string;
    orden: number;
    

    clear(): void {
        this.id = undefined;
        this.categoria = 0;
        this.nombre_en = '';
        this.nombre_es = '';
        this.nombre_de = '';
        this.nombre_fr = '';
        this.orden = 0;

    }
}
