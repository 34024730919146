import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from '../../../../../../src/app/services/api/api.service';
import { FooterService } from '../../../../../../src/app/services/footer/footer.service';
import { HeaderService } from '../../../../../../src/app/services/header/header.service';

import { Proyecto } from '../../../../../../src/app/core/auth/_models/proyecto.model'
import { Pais } from '../../../../../../src/app/core/auth/_models/pais.model';
import { SubheaderService } from '../../../../../../src/app/core/_base/layout';
import { CategoriaProyecto } from '../../../../../../src/app/core/auth/_models/categoria-proyecto.model';
import { TranslationService } from "../../../../core/_base/layout";
@Component({
  selector: 'kt-proyecto',
  templateUrl: './proyecto.component.html',
  styleUrls: ['./proyecto.component.scss']
})
export class ProyectoComponent implements OnInit {
  proyectoForm: FormGroup;
  proyecto: Proyecto;
  paises: any = [];
  imagenesProyecto = [];
  categorias: CategoriaProyecto[] = [];
  selectPais = new FormGroup({ selected: new FormControl()});

  constructor(
    public domSanitizer: DomSanitizer,
    private header: HeaderService,
    private footer: FooterService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private api: ApiService,
    public ts: TranslationService) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const id = params.id;
      this.proyecto = new Proyecto();
      this.proyecto.clear();
      if (id && id > 0) {
        this.api.readData('proyectos', '', 'id = ' + id).subscribe((res: any) => {
          this.proyecto = Object.assign(this.proyecto, res.data[0]);

          if (res.data[0].pais !== '') {
            this.selectPais.patchValue({selected: res.data[0].pais});
          }
          if (res.data[0].imagenes !== ''){
            this.imagenesProyecto = JSON.parse(res.data[0].imagenes).map((element: any) => [element,`${this.api.getUrlBase()}/api/assets/images/proyectos/${id}/${element}`]);
          }
          this.initReferencia();
        });
      } else {
        this.initReferencia();
      }
    });
    this.api.readData('paises' ).subscribe((res: any) => {

      for (const o of res.data) {
        this.paises.push({
          display: o.nombre,
          value: o.id,
        });
      }
    });
    this.api.readData('categoriasproyectos').subscribe((res: any) => {
      for (const o of res.data) {
        this.categorias.push(o);
      }
    });
  }


  initReferencia() {
    this.createForm();
    if (!this.proyecto.id) {
      this.subheaderService.setTitle("Crear referencia");
      this.subheaderService.setBreadcrumbs([
        { title: 'referencias', page: `referencias` },
        { title: "Crear referencia", page: `referencias/new` },
      ]);
      return;
    }
    this.subheaderService.setTitle("Editar Referencia");
    this.subheaderService.setBreadcrumbs([
      { title: 'referencias', page: `referencias` },
      {
        title: "Editar referencia",
        page: `referencias`,
        queryParams: { id: this.proyecto.id },
      },
    ]);
  }

  createForm() {
    this.proyectoForm = this.fb.group({
      titulo_es: [this.proyecto.titulo_es],
      titulo_en: [this.proyecto.titulo_en],
      titulo_de: [this.proyecto.titulo_de],
      titulo_fr: [this.proyecto.titulo_fr],
      imagenes: [this.proyecto.imagenes],
      id_categoria: [this.proyecto.id_categoria],
    });
  }

  getComponentTitle() {
    let result = "Crear proyecto";
    if (!this.proyecto || !this.proyecto.id) {
      return result;
    }

    result = `Editar proyecto - ${this.proyecto.titulo_es}`;
    return result;
  }

  saveProyecto() {
    const newProyecto = Object.assign(
      this.proyecto,
      this.proyectoForm.value
    );
    newProyecto.pais = this.selectPais.value.selected;
    newProyecto.imagenes = this.imagenesProyecto;

    if (!newProyecto.id) {
      this.api.createData('proyectos', newProyecto).subscribe((res: any) => {
        if (res) {
          this.router.navigate(["/panel/proyectos"]);
        }
      });
    } else {
      this.api.updateData('proyectos', newProyecto).subscribe((res: any) => {
        if (res) {
          this.router.navigate(["/panel/proyectos"]);
        }
      });
    }
  }

  borrarImagen(imagen) {
    this.imagenesProyecto = this.imagenesProyecto.filter(
      (obj) => obj !== imagen
    );
  }

  preview(files: FileList) {
    if (files.length === 0) {
      return;
    }

    for (let index = 0; index < files.length; index++) {
      this.cargarImagenes(files[index])
    }
  }

  cargarImagenes(imagen: File) {
    var mimeType = imagen.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(imagen);
    myReader.onloadend = () => {
      var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
      this.imagenesProyecto.push([name, this.domSanitizer.bypassSecurityTrustUrl(myReader.result.toString())]);
      this.cdRef.detectChanges()
    };
  }

}
