// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Pedido } from '../../../core/auth/_models/pedido.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeletePedidoComponent } from './pedido/delete-pedido/delete-pedido.component';

@Component({
	selector: 'kt-pedidos',
	templateUrl: './pedidos.component.html',
	styleUrls: ['./pedidos.component.scss']
})
export class PedidosComponent implements OnInit, OnDestroy {

	pedidos: Pedido[] = [];
	dataSource;
	displayedColumns: string[] = ['select', 'id', 'fecha', 'idUsuario', 'importeTotal', 'opciones'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	selection = new SelectionModel<Pedido>(true, []);

	constructor(
		private api: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private subheaderService: SubheaderService,
		private matDialog: MatDialog,
		private header: HeaderService,
		private footer: FooterService) {
		this.header.hide();
		this.footer.hide();
	}

	ngOnInit() {

		this.subheaderService.setTitle('Pedidos');

		this.api.readData('pedidos').subscribe((res: any) => {
			for (const o of res.data) {
				this.pedidos.push(o);
			}
			this.dataSource = new MatTableDataSource(this.pedidos);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;

		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.pedidos.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.pedidos.length) {
			this.selection.clear();
		} else {
			this.pedidos.forEach(row => this.selection.select(row));
		}
	}

	fetchPedidos() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.idCliente}`,
				id: elem.id.toString(),
				status: elem.idCliente
			});
		});
		console.log(messages);

	}

	editPedido(id: number) {
		this.router.navigate(['../pedidos/', id], { relativeTo: this.activatedRoute });
	}

	deletePedido(id: number) {
		let pedido = this.pedidos.find(i => i.id === id);
		const dialogConfig: MatDialogConfig = {
			autoFocus: true,
			maxWidth: '400px',
			data: {
				id: pedido.id
			}
		}
		const dialogRef = this.matDialog.open(DeletePedidoComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {
				this.api.deleteData('pedidos', res).subscribe((res: any) => {
					if (res) {
						this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
					}
				});
			}
		});
	}


	ngOnDestroy(): void {
		this.header.toggle();
	}
}
