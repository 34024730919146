import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Disenador } from '../../../../core/auth/_models/disenador.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from "../../../../core/_base/layout";

@Component({
   selector: 'kt-disenadores',
   templateUrl: './disenador.component.html',
   styleUrls: ['./disenador.component.scss']
})
export class DisenadorComponent implements OnInit, OnDestroy {

   disenador: Disenador;
   disenadorId: Observable<number>;
   disenadorForm: FormGroup;
   hasFormErrors = false;
   selectedTab = 0;
   orden = 0;
   loadingFile = false;
   imagenesDisenador = [];

   constructor(
      public domSanitizer: DomSanitizer,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private subheaderService: SubheaderService,
      private api: ApiService,
      private header: HeaderService,
      private footer: FooterService,
      private cdRef: ChangeDetectorRef,
      public ts: TranslationService) {
      this.header.hide();
      this.footer.hide();
   }

   ngOnInit() {
      this.activatedRoute.params.subscribe(params => {
         const id = params.id;
         this.disenador = new Disenador();
         this.disenador.clear();
         if (id && id > 0) {
            this.api.readData('disenadores', '', 'id = ' + id).subscribe((res: any) => {
               this.disenador = Object.assign(this.disenador, res.data[0]);

               this.initDisenador();

               if (this.disenador.imagenes !== '') {
                  const parseImagenes = JSON.parse(this.disenador.imagenes);
                  parseImagenes.forEach((value, index) => {
                     this.imagenesDisenador.push
                        (
                           [value, this.api.getUrlBase() + '/api/assets/images/disenadores/' + this.disenador.id + '/' + value]
                        );
                  });
               }
            });
         } else {
            this.api.readData('disenadores', '').subscribe((res: any) => {
               this.orden = res.data.length + 1;
               this.initDisenador();
           });
            
         }
      });

   }

   initDisenador() {
      this.createForm();
      if (!this.disenador.id) {
         this.subheaderService.setTitle('Crear disenador');
         this.subheaderService.setBreadcrumbs([
            { title: 'Disenadores', page: `disenadores` },
            { title: 'Crear disenador', page: `disenadores/new` }
         ]);
         return;
      }
      this.subheaderService.setTitle('Editar Familia');
      this.subheaderService.setBreadcrumbs([
         { title: 'Disenadores', page: `disenadores` },
         { title: 'Editar disenador', page: `disenadores`, queryParams: { id: this.disenador.id } }
      ]);
   }

   createForm() {
      this.disenadorForm = this.fb.group({
         nombre: [this.disenador.nombre, Validators.required],
         descripcion_en: [this.disenador.descripcion_en],
         descripcion_es: [this.disenador.descripcion_es],
         descripcion_de: [this.disenador.descripcion_de],
         descripcion_fr: [this.disenador.descripcion_fr],
         imagenes: [this.disenador.imagenes]
      });
   }

   getComponentTitle() {
      let result = 'Crear disenador';
      if (!this.disenador || !this.disenador.id) {
         return result;
      }

      result = `Editar disenador - ${this.disenador.nombre}`;
      return result;
   }

   saveDisenador() {
      const newDisenador = Object.assign(this.disenador, this.disenadorForm.value);
      newDisenador.imagenes = this.imagenesDisenador;
      if (!newDisenador.id) {
         newDisenador.orden = this.orden;
         this.api.createData('disenadores', newDisenador).subscribe((res: any) => {

            if (res) {
               this.router.navigate(['/panel/disenadores']);
            }
         });
      } else {
         this.api.updateData('disenadores', newDisenador).subscribe((res: any) => {

            if (res) {
               this.router.navigate(['/panel/disenadores']);
            }
         });
      }

   }
   borrarImagen(imagen) {
      this.imagenesDisenador = this.imagenesDisenador.filter(obj => obj !== imagen);
      this.cdRef.detectChanges();
   }
   
   preview(files) {

      if (files.length === 0) {
         return;
      }
      // console.log(files);
      for (const file of files) {
         this.cargarImagenes(file);
      }

   }
   cargarImagenes(imagen) {
      const mimeType = imagen.type;
      if (mimeType.match(/image\/*/) == null) {
         return;
      }
      // console.log(imagen);
      const reader = new FileReader();
      // this.referenciaForm['pic'] = files;
      reader.readAsDataURL(imagen);
      reader.onload = (Event) => {
         const resultado = reader.result;
         var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
         this.imagenesDisenador.push([name, this.domSanitizer.bypassSecurityTrustUrl(resultado.toString())]);
         this.cdRef.detectChanges();
      };

   }

   updateUrl(event) {
      // event.target.src = '../../../../../assets/img/users/default.jpg';
   }

   ngOnDestroy(): void {
      this.header.toggle();
      this.footer.toggle();
   }

}
