import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Client } from '../../../../core/auth/_models/client.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'kt-cliente',
	templateUrl: './cliente.component.html',
	styleUrls: ['./cliente.component.scss']
})
export class ClienteComponent implements OnInit, OnDestroy {

	cliente: Client;
	clienteId: Observable<number>;
	clienteForm: FormGroup;
	hasFormErrors: boolean = false;
	selectedTab = 0;
	loadingFile: boolean = false;
	paises = [];
	idCliente = 0;

	constructor(
		public _DomSanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private subheaderService: SubheaderService,
		private api: ApiService,
		private header: HeaderService,
		private footer: FooterService,
		private cdRef: ChangeDetectorRef) {
		this.header.hide();
		this.footer.hide();
	}



	ngOnInit() {
		this.api.readData('paises', '*','', 'nombre ASC').subscribe((res: any) => {
			this.paises = res.data;
			this.paises.sort( (a, b) => {
				return a.nombre.localeCompare(b.nombre);
			});
		});
		this.activatedRoute.params.subscribe(params => {
			this.idCliente = params.id;
			this.cliente = new Client();
			this.cliente.clear();

			if (this.idCliente && this.idCliente > 0) {
				this.api.readData('clientes', '', 'id = ' + this.idCliente).subscribe((res: any) => {
					this.cliente = Object.assign(this.cliente, res.data[0]);
					this.initCliente();
				})
			} else {
				this.initCliente();
			}
		});

		this.cdRef.detectChanges();

	}

	initCliente() {
		this.createForm();
		if (!this.cliente.id) {
			this.subheaderService.setTitle('Crear cliente');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Clientees', page: `clientes` },
				{ title: 'Crear cliente', page: `clientes/new` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar Familia');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Clientees', page: `clientes` },
			{ title: 'Editar cliente', page: `clientes`, queryParams: { id: this.cliente.id } }
		]);
	}

	createForm() {
		console.log(this.cliente);
		this.clienteForm = this.fb.group({

     	nombre: [this.cliente.nombre, Validators.required],
        razonSocial: [this.cliente.razonSocial],
        NIF: [this.cliente.NIF],
        personaContacto: [this.cliente.personaContacto],
        direccion: [this.cliente.direccion],
        CP: [this.cliente.CP],
        municipio: [this.cliente.municipio],
        provincia: [this.cliente.provincia],
        telefono: [this.cliente.telefono],
        email: [this.cliente.email],
        web: [this.cliente.web],
        tipo: [this.cliente.tipo],
        password:[""],
		pais: [this.cliente.pais],
		permitirPrecios:[this.cliente.permitirPrecios == 1? true: false]

		});

	}

	getComponentTitle() {
		let result = 'Crear cliente';
		if (!this.cliente || !this.cliente.id) {
			return result;
		}

		result = `Editar cliente - ${this.cliente.nombre}`;
		return result;
	}

	saveCliente() {
		const newCliente = Object.assign(this.cliente, this.clienteForm.value);
		newCliente.permitirPrecios = newCliente.permitirPrecios ? 1 : 0;
		if (!newCliente.id) {
			this.api.createData('clientes', newCliente).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/clientes']);
				}
				
			});
		} else {
			this.api.updateData('clientes', newCliente).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/clientes']);
				}

			});
		}

	}

	ngOnDestroy(): void {
		this.header.toggle();
		this.footer.toggle();
	}

}

