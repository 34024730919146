// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Categoria } from '../../../core/auth/_models/categoria.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslationService } from '../../../core/_base/layout';
@Component({
	selector: 'kt-categorias',
	templateUrl: './categorias.component.html',
	styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit, OnDestroy {
	
	categorias: Categoria[] = [];
	dataSource;
	contadorImagenes = [];
	tmpCategoriasForm = new FormGroup({
		selected: new FormControl()
	});


	displayedColumns: string[] = ['select', 'id', 'nombre_es','opciones'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	selection = new SelectionModel<Categoria>(true, []);

	constructor(
		private api: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private subheaderService: SubheaderService,
		private matDialog: MatDialog,
		private header: HeaderService,
		private footer: FooterService,
		public ts:TranslationService) {
		this.header.hide();
		this.footer.hide();
	}

	ngOnInit() {

		this.subheaderService.setTitle('Sub Familias');

		this.api.readData('categorias').subscribe((res: any) => {
			for (const o of res.data) {
				this.categorias.push(o);
				
			}
			this.dataSource = new MatTableDataSource(this.categorias);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;

		});

	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.categorias.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.categorias.length) {
			this.selection.clear();
		} else {
			this.categorias.forEach(row => this.selection.select(row));
		}
	}

	fetchCategorias() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.nombre_en}`,
				id: elem.id.toString(),
				status: elem.nombre_en
			});
		});
		console.log(messages);

	}

	editCategoria(id: number) {
		this.router.navigate(['../categorias/', id], { relativeTo: this.activatedRoute });
	}

	deleteCategoria(id: number) {

	}


	ngOnDestroy(): void {
		this.header.toggle();
	}
}
