// Angular
import { Component, HostBinding, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
// RxJS
import { filter } from "rxjs/operators";
// Translate
import { TranslationService } from "../../../../../core/_base/layout";

interface LanguageFlag {
  lang: string;
  name: string;
  flag: string;
  active?: boolean;
}

@Component({
  selector: "kt-language-selector",
  templateUrl: "./language-selector.component.html",
})
export class LanguageSelectorComponent implements OnInit {
  // Public properties
  @HostBinding("class") classes = '';
  @Input() iconType: '' | "brand";
  @Input() tipo = 'texto';

  language: LanguageFlag;
  languages = [
    {
      lang: "en",
      displayName: "English",
      name: "EN",
      flag: "./assets/media/flags/260-united-kingdom.svg",
      active: false
    },
    {
      lang: 'es',
      displayName: "Español",
      name: "ES",
      flag: "./assets/media/flags/128-spain.svg",
      active: false
    },
    {
      lang: 'de',
      displayName: "Deutsch",
      name: "DE",
      flag: "./assets/media/flags/162-germany.svg",
      active: false
    },
    {
      lang: 'fr',
      displayName: "Français",
      name: "FR",
      flag: "./assets/media/flags/195-france.svg",
      active: false
    },
  ];

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.setSelectedLanguage();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        this.setSelectedLanguage();
      });
  }

  /**
   * Set language
   *
   * @param lang: any
   */
  setLanguage(lang) {

    this.languages.forEach((language: LanguageFlag) => {
      if (language.lang === lang) {
        language.active = true;
        this.language = language;
      } else {
        language.active = false;
      }
    });
    this.translationService.setLanguage(lang);
    
  }

  /**
   * Set selected language
   */
  setSelectedLanguage(): any {
    this.setLanguage(this.translationService.getSelectedLanguage());
    
  }
}
