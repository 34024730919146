import { Component, OnInit, Output, Injectable, AfterViewInit, EventEmitter, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../app/services/auth/user.service';
import { LoaderService } from '../../../../app/services/loader/loader.service';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'kt-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
  styles: []
})

export class NavBarComponent implements AfterViewInit  {
  mostrarDatosUsuario = false;
  isCollapsed: boolean;
  ruta: string;
  scrollActual: number  = 0;
  @Input() modo = 'extendido';
  constructor(
    private router: Router,
    public userService: UserService,
    private cdRef: ChangeDetectorRef,
    public loader: LoaderService) {
    this.isCollapsed = true;
    this.ruta = this.router.url;
  }

  ngAfterViewInit() {
    this.userService.checkLogin();
    this.userService.checkNewsletter();
    this.userService.checkCookies();
    this.userService.checkCart().then(() => {
      this.cdRef.detectChanges();
    });


    this.scrollChangeCallback = (event: any) => this.onContentScrolled(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
  }

  activo(rutaLink) {
   
    if (rutaLink === this.ruta ) {
      return 'enlace-activo';
    } else {
      return '';
    }
  }

  irA(pagina) {
    this.router.navigate([pagina]);
  }
  headerScroll(){
    let top = window.scrollY;
    
    if (top > 50){
      return true;
    }else{
      return false;
    }
  }
  showNav(){

    //let top = window.scrollY;
    //console.log(top);
    //console.log(this.scrollActual);
    //// console.log( 'ScrollY' + top);
    //// console.log('ScrollY' + this.scrollActual);
    //if (top <= this.scrollActual){
    //  this.scrollActual = top;
    //  return true;    
    //}

  }
  //Solucion javascript 
  // let prevScrollpos = window.pageYOffset;
  // window.onscroll = function() {
  // let currentScrollPos = window.pageYOffset;
  //   if (prevScrollpos > currentScrollPos) {
  //     document.getElementById("navbar").style.top = "0";
  //   } else {
  //     document.getElementById("navbar").style.top = "-50px";
  //   }
  //   prevScrollpos = currentScrollPos;
  // }

  //@HostListener("window:scroll", ['$event'])
  //doSomethingOnWindowsScroll($event:any){
  //  let scrollOffset = $event.srcElement.children[0].scrollTop;
  //  console.log("window scrollx: ", scrollOffset);
  //}

  private scrollChangeCallback: (ev: any) => void;
  currentPosition: any;

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
  }
  
  

  onContentScrolled(e) {

    const menu = document.getElementById('navbar');
    const scroll = window.pageYOffset;

    if (scroll > this.currentPosition) {
      menu.classList.add('scroll-down');
      menu.classList.remove('scroll-up');
    } else {
      menu.classList.add('scroll-up');
      menu.classList.remove('scroll-down');
    }

    this.currentPosition = scroll;
  }

  

}