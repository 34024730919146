import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { Disenador } from '../../../../../src/app/core/auth/_models/disenador.model';
import { ApiService } from '../../../../../src/app/services/api/api.service';
import { Subfamilia } from '../../../../../src/app/core/auth/_models/subfamilia.model';
import { removeAccents } from '../../../core/helpers/url.helpers';

@Component({
  selector: 'kt-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.scss']
})
export class DesignerComponent implements OnInit {

  id: number = 0;
  idioma: String;
  products: Subfamilia[] = [];
  designer: Disenador = new Disenador();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly api: ApiService,
    public readonly translate: TranslateService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly router: Router
  ) {
    this.designer.clear();
    this.idioma = translate.currentLang;
  }

  async ngOnInit() {
    this.route.params.subscribe(this._initialize);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
    });
  }

  private _initialize = async () => {
    const nombre: string = this.route.snapshot.params.name;

    // If contains uppercase letters, redirect to lowercase
    if (/[A-Z]/.test(nombre)) {
      this.router.navigate(['../', nombre.toLowerCase()], { relativeTo: this.route, replaceUrl: true });
      return;
    }
    // Else if contains some accent, redirect to without accent
    else if (/[áéíóúàèìòùÀÈÌÒÙÁÉÍÓÚ]/.test(nombre)) {
      this.router.navigate(['../', removeAccents(nombre)], { relativeTo: this.route, replaceUrl: true });
      return;
    }
    // Else if contains spaces, redirect to without spaces
    else if (/\s/.test(nombre)) {
      this.router.navigate(['../', nombre.replace(/\s/g, '-')], { relativeTo: this.route, replaceUrl: true });
      return;
    }

    const formatName = nombre.replace(/-/g, ' ');
    await this.api.readData('disenadores', '*', `nombre='${ formatName }'`).pipe(
      map((res: any) => {
        res.data[0].imagenes = this.api.URLBase + '/api/assets/images/disenadores/' + res.data[0].id + '/' + JSON.parse(res.data[0].imagenes)[0] || 'assets/img/mock/nuestros-arquitectos.png'
        this.designer = res.data[0]
      })
    ).toPromise()

    await this.api.readData('subfamilias', '*', `id_disenador=${this.designer.id}`).pipe(
      map((res: any) => res.data.map(prod => {
        prod.imagenes = prod.imagenes == '[]' ? 'assets/img/mock/nuestros-arquitectos.png' : `${this.api.getUrlBase()}/api/assets/images/subfamilias/${prod.codigo}/${JSON.parse(prod.imagenes)[0]}`
        this.products.push(prod)
      }))
    ).toPromise()
    this.cdRef.detectChanges()
  }

}
