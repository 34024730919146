import { BaseModel } from '../../_base/crud';

export class Distribuidor extends BaseModel {

    id: number;
    nombre_en: string;
    nombre_es: string;
    nombre_de: string;
    nombre_fr: string;
    localizacion: string;
    agente: string;
    email: string;
    agente_email: string;
    telefono: string;

    clear(): void {
        this.id = undefined;
        this.nombre_en = '';
        this.nombre_es = '';
        this.nombre_de = '';
        this.nombre_fr = '';
        this.localizacion = '';
        this.agente = '';
        this.agente_email = '';
        this.email = '';
        this.telefono = '';
    }
}
