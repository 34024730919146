import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../services/auth/user.service';
import { ApiService } from '../../../../../src/app/services/api/api.service';
import { DescargasService } from '../../../services/descargas/descargas.service';
import { Disenador } from '../../../../../src/app/core/auth/_models/disenador.model';
import { Subfamilia } from '../../../../../src/app/core/auth/_models/subfamilia.model';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'kt-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})

export class ProductComponent implements OnInit {

  subfamilia: Subfamilia = new Subfamilia();
  disenador: Disenador = new Disenador();
  idioma: string;
  codigo: string;
  id = '';
  imagenes = [];
  imagenesPrincipales = [];
  loaded = false;
  slideConfigSimpl = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  modo = 'extendido';
  imgProductFinished = '';
  productFinished :any;
  descargasLogin = {
    docRevit: true,
    doc3DFiles: true,
    doc2DFiles: true,
    docFicha: true,
    docFotosHI: true,
    docFotosLO: true,
    certificados: true,
    garantias: true,
    fondo_blanco: true,
    hoja_montaje: true
  };
  mostrarImagenDestacada: boolean;
  src_img_destacada: any;
  constructor(
    private router: Router,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef,
    public userService: UserService,
    public api: ApiService,
    public descargasService: DescargasService,
    private titleService: Title,
    private metaTagService: Meta,
    private activatedRoute: ActivatedRoute
    ) {
    this.subfamilia.clear();
    this.disenador.clear();
    this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
    });
    this.activatedRoute.data.subscribe(v => this.modo = v.modo);
    this.router.events
      .pipe(
        filter((event): event is ActivationEnd => event instanceof ActivationEnd),
        filter((event: ActivationEnd) => event.snapshot.firstChild === null),
        map((event: ActivationEnd) => event.snapshot.params)
      ).subscribe(params => 
        {
          console.log(params);
          console.log(this.modo);
          this.codigo = params.name;
          this.id = params.id;
        
        });
  }

  async ngOnInit() {
    
     
       if (this.modo === 'product-finished'){
        
          await this.api.readData('referencias', '*', `id=\"` + this.id + `\"`).pipe(
            map((res: any) => {
              this.productFinished = res.data[0];
            })
          ).toPromise();

          this.api.readData('disenadores', '*', `id=${this.productFinished.id_disenador}`).pipe(
            map((res2: any) => {
              res2.data[0].imagenes = JSON.parse(res2.data[0].imagenes)[0] || 'assets/img/mock/nuestros-arquitectos.png';
              this.disenador = res2.data[0];
            })
          ).toPromise();
          
       }else{
        await this.api.readData('subfamilias', '*', `codigo="${this.codigo}"`).pipe(
          map((res: any) => {
            res.data[0].descripcion_es = res.data[0].descripcion_es !== '' ?
            res.data[0].descripcion_es :
            'Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos de texto.' +
            ' Lorem Ipsum ha sido el texto de relleno estándar de las industrias        ' +
            ' desde el año 1500, cuando un impresor (N. del T. persona que se dedica a        ' +
            ' la imprenta) desconocido usó una galería de textos y los mezcló de tal ma -        ' +
            ' nera que logró hacer un libro de textos especimen.';
            res.data[0].descripcion_en = res.data[0].descripcion_en !== '' ?
            res.data[0].descripcion_en : 'Lorem Ipsum es simplemente el texto de relleno de las imprentas' +
            ' y archivos de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias        ' +
            ' desde el año 1500, cuando un impresor (N. del T. persona que se dedica a        la imprenta) ' +
            ' desconocido usó una galería de textos y los mezcló de tal ma -        nera que logró hacer un libro de textos especimen.';
            res.data[0].video_destacado = res.data[0].video_destacado !== '' ?
            `${this.api.URLBase}/api/assets/videos/subfamilias/${res.data[0].codigo}/${res.data[0].video_destacado}` :
            '';
            this.subfamilia = res.data[0];
            this.titleService.setTitle(this.subfamilia["nombre_" + this.idioma]);
            this.metaTagService.updateTag({name:'description', content:this.subfamilia["descripcion_" + this.idioma]});
            this.loaded = true;
            if (this.subfamilia.imagenes !== '') {
              const parseImagenes = JSON.parse(this.subfamilia.imagenes);

              for (const i in parseImagenes) {
                if (parseImagenes[i]) {
                  this.imagenes.push(this.api.getUrlBase() +
                  '/api/assets/images/subfamilias/' + this.subfamilia.codigo + '/' + parseImagenes[i]);

                }
              }
            }

            if (this.subfamilia.imagenesPrincipales !== '') {
              const parseImagenesPrinc = JSON.parse(this.subfamilia.imagenesPrincipales);

              for (const i in parseImagenesPrinc) {
                if (parseImagenesPrinc[i]) {
                  this.imagenesPrincipales.push(this.api.getUrlBase() +
                  '/api/assets/images/subfamilias/' + this.subfamilia.codigo + '/' + parseImagenesPrinc[i]);

                }
              }
            }

            if (this.subfamilia.descargasLogin && this.subfamilia.descargasLogin !== '') {
              this.descargasLogin = JSON.parse(this.subfamilia.descargasLogin);
            }

          })
        ).toPromise();

        await this.api.readData('disenadores', '*', `id=${this.subfamilia.id_disenador}`).pipe(
          map((res: any) => {
            res.data[0].imagenes = JSON.parse(res.data[0].imagenes)[0] || 'assets/img/mock/nuestros-arquitectos.png';
            this.disenador = res.data[0];
          })
        ).toPromise();
      }
    this.cdRef.detectChanges();
  }

  openLogin() {
    this.userService.abrirLogin();
  }

  imagenDestacada(event: any, codigo: string, src: string) {
    event.stopPropagation();
    event.preventDefault();
    this.src_img_destacada = this.api.URLBase + '/api/assets/images/subfamilias/' + codigo + '/' + src;
    this.toggleImagenDestacada();
  }

  toggleImagenDestacada() {
    this.mostrarImagenDestacada ? this.mostrarImagenDestacada = false : this.mostrarImagenDestacada = true;
    this.cdRef.detectChanges();
  }

  hasDownloads(){
    const tiposDescarga = this.descargasService.tiposDescargas;
    const subfam = this.subfamilia;
    let cont = 0;
    
    Object.keys(tiposDescarga).forEach(function(key) {
      if(subfam[key] != ''){
        cont++;
      };
    });
    return cont>0 ? true: false;
  
  }

}
