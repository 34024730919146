import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Categoria } from '../../../../core/auth/_models/categoria.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import {DomSanitizer} from '@angular/platform-browser';
import { Subcategoria } from '../../../../core/auth/_models/subcategoria.model';
import { TranslationService } from "../../../../core/_base/layout";
@Component({
	selector: 'kt-categoria',
	templateUrl: './categoria.component.html',
	styleUrls: ['./categoria.component.scss']
})
export class CategoriaComponent implements OnInit, OnDestroy {

	categoria: Categoria;
	categoriaId: Observable<number>;
	categoriaForm: FormGroup;
	subcategoriaForm: FormGroup;
	subcategoria: Subcategoria;
	subcategorias = [];
	hasFormErrors: boolean = false;
	selectedTab = 0;
	loadingFile: boolean = false;
	subcatNombre = '';
	subcatNombre_es = '';
	tmpSubcategoria: Subcategoria;
	idCategoria = 0;

	constructor(
		public _DomSanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private subheaderService: SubheaderService,
		private api: ApiService,
		private header: HeaderService,
		private footer: FooterService,
		private cdRef: ChangeDetectorRef,
		public ts:TranslationService) {
		this.header.hide();
		this.footer.hide();
	}



	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.idCategoria = params.id;
			this.categoria = new Categoria();
			this.categoria.clear();
			this.subcategoria = new Subcategoria();
			this.subcategoria.clear();

			this.api.readData('subcategorias', '*','categoria = ' + this.idCategoria).subscribe((res: any) => {
				for (const o of res.data) {
					this.subcategorias[o.id] = o;
				}
				console.log(this.subcategorias);
				this.cdRef.detectChanges();
			});

			if (this.idCategoria && this.idCategoria > 0) {
				this.api.readData('categorias', '', 'id = ' + this.idCategoria).subscribe((res: any) => {
					this.categoria = Object.assign(this.categoria, res.data[0]);
					this.initCategoria();

				})
			} else {
				this.initCategoria();
			}
			this.initSubcategoria();
		});

		this.cdRef.detectChanges();

	}

	initCategoria() {
		this.createForm();
		if (!this.categoria.id) {
			this.subheaderService.setTitle('Crear categoria');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Categorias', page: `categorias` },
				{ title: 'Crear categoria', page: `categorias/new` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar Familia');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Categorias', page: `categorias` },
			{ title: 'Editar categoria', page: `categorias`, queryParams: { id: this.categoria.id } }
		]);
	}

	initSubcategoria(){
		this.subcategoriaForm = this.fb.group({
			id: [0],
      		nombre_en: [''],
      		nombre_es: [''],
			nombre_de: [''],
			nombre_fr: [''],
			categoria: [0]
		});
		this.cdRef.detectChanges();
	}

	createForm() {
		this.categoriaForm = this.fb.group({
     		nombre_en: [this.categoria.nombre_en, Validators.required],
      		nombre_es: [this.categoria.nombre_es],
			nombre_de: [this.categoria.nombre_de],
			nombre_fr: [this.categoria.nombre_fr]
		});

		console.log(this.categoriaForm);
	}

	getComponentTitle() {
		let result = 'Crear categoria';
		if (!this.categoria || !this.categoria.id) {
			return result;
		}

		result = `Editar categoria - ${this.categoria.nombre_es}`;
		return result;
	}

	saveCategoria() {
		const newCategoria = Object.assign(this.categoria, this.categoriaForm.value);
		if (!newCategoria.id) {
			this.api.createData('categorias', newCategoria).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/categorias']);
				}
				
			});
		} else {
			this.api.updateData('categorias', newCategoria).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/categorias']);
				}

			});
		}
		

	}

	saveSubCategoria(){

		const newSubcategoria = Object.assign(this.subcategoria, this.subcategoriaForm.value);
		newSubcategoria["categoria"] =  this.idCategoria;
		
		if (!newSubcategoria.id) {
			this.api.createData('subcategorias', newSubcategoria).subscribe((res: any) => {	
				this.subcategorias[res.data[0].id] = res.data[0];
				this.initSubcategoria();
			});
		} else {
			this.api.updateData('subcategorias', newSubcategoria).subscribe((res: any) => {
				this.subcategorias[newSubcategoria.id] = newSubcategoria;
				this.initSubcategoria();
			});
		}
	}

	quitarSubcategoria(){

		

	}

	editarSubcategoria(idSubcategoria){

		this.subcategoriaForm = this.fb.group({
			id: [this.subcategorias[idSubcategoria].id],
      		nombre_en: [this.subcategorias[idSubcategoria].nombre_en],
      		nombre_es: [this.subcategorias[idSubcategoria].nombre_es],
			nombre_de: [this.subcategorias[idSubcategoria].nombre_de],
			nombre_fr: [this.subcategorias[idSubcategoria].nombre_fr],
		});
	}

	ngOnDestroy(): void {
		this.header.toggle();
		this.footer.toggle();
	}

}
