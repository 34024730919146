export const locale = {
	lang: "fr",
	data: {
	  HOME: {
	  	NEWCATALOGUES: 'CONSULTEZ NOS NOUVEAUX CATALOGUES EN LIGNE',
	    },
	    COOKIES: {
	  	THISTIME: 'Accepter cette fois',
	  	ACCEPT: 'Accepter',
	  	REJECT: 'Rejeter',
	  	PRIVACY: 'Nous respectons votre vie privée',
	  	TEXT: 'Ce site utilise des cookies pour améliorer votre expérience utilisateur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
	  	LINK: 'Notre politique en matière de cookies',
		CONTENT: `<h3>Models, Finishes and Colours</h3>

		  <p>Models: Here in PUNT we work constantly on the improvement of our products so we reserve the right to amend the measurements, materials, colours, etc. of our products with the aim of improving their quality, service, strength or price. If the products that you have ordered present some variation with respect to our current catalogue and prices, we will inform you previously to check that you are still interested.</p>
	
		  <p>Finishes: All the finishes that we offer (natural, stained or lacquered wood, lacquered or anodised metal), even though they are protected, can gradually change their original colour over time due to sunlight exposure. Wood boards and wood pieces, because of their natural origin, present different and non-homogeneous patterns and tones. In PUNT we select each element of a piece of furniture to make these differences aesthetically pleasing.</p>
	
		  <p>Colours: The actual appearance of the nice colours of our products may differ slightly from the ones you have seen on a screen or a catalogue.</p>
	
		  <h3>Orders, Transportation and Delivery Times</h3>
	
		  <p>Orders: To avoid confusion, you must formalise your orders in writing. Once we get a completely defined order, we will send you an email and start to prepare your delivery. Since this moment, any cancellation on your part might result in the reimbursement of the costs and expenses incurred by PUNT through the preparation of your delivery.</p> 
	
		  <p>Transportation: In the confirmation of your order, we will indicate the accorded transportation service and if it generates some costs.</p>
	
		  <p>Delivery Time: PUNT will indicate the estimated delivery times according to the purchased products and their shipping destination. We are working hard to improve these estimated times. We hope that you will excuse us if it is not possible owing to unforeseen causes. When your order is ready, we will deliver it in the shipping destination indicated on the order form, where you must sign the delivery receipt.</p>
	
		  <h3>Warranty</h3>
	
		  <p>If you are a final customer, the quality of the product is under warranty for 2 years, so you must keep the purchase receipt. If you have any incident, don’t send us back the product. Just send us a photo and an explanation so we can understand and solve the problem. Damages are not covered if they result from an inappropriate use of the product. In order to avoid it, we will deliver along with each product an installation manual and a guide for use and maintenance. We are at your disposal at info@puntmobles.com to answer any questions or requests that you might have.</p>
	
		  <p>The shipping company is the only responsible for the damages caused during transportation, so please check carefully the product at the delivery and write down any suspected defect on the shipment document or the company won’t take the responsibility for it. Our packaging is very safe but it cannot protect the product from every mishandling. Make sure that you handle the product carefully before unpacking it and don’t unpack it until it is placed in its final location.</p>
	
		  <h3>Furniture Cleaning and Maintenance</h3>
	
		  <p>For a proper preservation, the piece of furniture must be kept in a dry space. You must remove any spillage or stain immediately so it doesn’t penetrate or damage the protective varnish layer. Every furniture material must be wiped with slightly damp non-abrasive cloths or cleaned with non-acid cleaning agents except paint thinners such as acetone. You mustn’t use cleaning agents containing alcohol on wood with a water-based coating. After cleaning, dry the surface with a dry cloth.</p>
	
		  <h3>Web, contents and design</h3>
	
		  <p>PUNT MOBLES XXI, S.L. (“PUNT”) is the owner of this domain and the content existing in it. If you want to reproduce it totally or partially, you must request permission. Likewise, PUNT has got the exclusive selling rights of the displayed products.</p>
	
		  <h3>Data Protection</h3>
	
		  <p>All the personal data sent through the website are stored in the servers of PUNT. We only register those data that are needed to send you the request information and news from PUNT and to process your orders. If you are a dealer, we communicate your data to your national commercial agent and the supplier of delivery services that will bring you the products. If you are a final customer, we send your data to a suitable distributor, as we are not selling online. All the agents involved are committed to maintain the privacy of your personal data and to use them solely for its designated purpose. We do not pass on your data in any case to commercial address brokers, so you are not going to receive unwanted advertising that is not related to PUNT. You can request at any moment information on the stored data and their use. You can also update, modify or remove them if you don’t want us to use them again. You only have to send an email to info@puntmobles.com.</p>
	
		  <h3>Use of Cookies</h3>
	
		  <p>A cookie is a small file generated and stored in the user’s computer when he/she visits our website. These files contain some information on the configuration of our site and information on your web browsing habits. The information obtained by the cookies is absolutely anonymous and cannot be linked to any specific and identified use in any case. This information allows us to know the usage patterns and statistics to improve the user browsing experience. The use of cookies can be disabled by selecting the appropriate settings on your browser.</p>`
	    },
	    NAVBAR: {
		PRODUCTSFINISHED: 'Produits finis',
	  	PRODUCTS: 'Produits',
	  	PROJECTS: 'Projets',
	  	DESIGNERS: 'Designers',
	  	DOWNLOADS: 'Téléchargements',
	  	ABOUT: 'Nous',
	  	CONTACT: 'Contact',
	  	LANGUAGE: 'Langue',
	  	SIGNUP: 'Registre',
	    },
	  TRANSLATOR: {
		SELECT: "Choisir la langue",
		SPANISH: "Espagnol",
		ENGLISH: "Anglais",
	  },
	  RESTORE: {
		RESTORE: "RECUPEREZ VOTRE MOT DE PASSE",
		SUCCESS: "Votre mot de passe a été mis à jour.",
		UPDATE: "Mis à jour",
		PASSMATCH: "les mots de passe ne coïncident pas",
		RESEND: "Impossible de récupérer votre mot de passe. Redemandez l’ email de recuperation."
	  },
	  PROJECTS: 'Projets',
	  SESION: {
		EMAIL: "email",
		PASSWORD: "Mot de passe",
		MYDATA: "Mes données",
		SIGNOFF: "Log out",
		LOGIN: "Log in",
		FORGOTPASS: "¿Vous avez oublié votre mot de passe? Cliquez ici.",
		ERRORDATOS: "Vous devez remplir tous les champs",
		ERRORMAIL: "Vous devez remplir le champs email",
		ERRORUSER: "Il n’existe pas d’utilisateur avec les données saisies",
		SENTMAIL: "Nous vous avons envoyé un email pour récupérer le mot de passe. Vérifiez votre boîte aux lettres"
	  },
	  DISTRIBUTORS: {
		NAME: 'Nom',
		EMAIL: 'Email',
		PHONE: 'Téléphone',
		LOCATION: 'LOCALISATION',
		PROVINCESTATE: 'État / province',
		COUNTRY: 'Pays',
		TITLERESULTS: 'Distributeurs pour la localisation',
		NORESULTS: "Il n'y a pas de résultats pour ce lieu",
		AGENT: 'Agent',
		AGENTMAIL: 'Email Agent',
		CONTACT: 'Contactez-nous, trouvez votre distributeur'
	  },
	  CONFIGURADOR: {
  
		COLLECTIONS: "Collections",
		BACK_BUTTON: "Précédent",
		NEXT: "Suivant",
		COMPOSITION: "COMPOSITION",
		FINISH: "Finition",
		SUMMARY: "Resumé",
		ADDCART: "Ajouter au caddie",
		ADDMORE: "Ajouter plus d’éléments",
		GOCART: "Aller au caddie",
		CONTINUEBUY: "Retourner aux collections",
		DOWNLOADS: "Téléchargments",
		REFERENCE: "Reference",
		QTY: "Quantité",
		PRIZE: "Prix (hors TVA)",
		LOGINREQUIRED: "Pour pouvoir voir les prix",
		LOGIN: "Ouvre la cession",
		PRIZEPENINSULA: "Prix pour l'Espagne continentale.",
		PRIZEADVICE: "Veuillez noter qu'il s'agit d'un prix hors TVA, la TVA sera payée dans le pays d'importation. Les autres frais, y compris les frais d'expédition, les droits de douane et les frais de déclaration, ne sont pas inclus.",
		REQUESTPRICE: 'Demande de prix'
	  },
	  COLLECTIONS: {
		IMAGENDETALLE: 'IMAGES DE DÉTAIL',
		VIDEO: 'VIDÉO',
		PRESENTATION: 'PRÉSENTATION DU PRODUIT',
		REVIT: "REVIT",
		SHEET: "INFORMATIONS TECHNIQUES",
		THREEDFILES: "ARCHIVES 3D",
		TWODFILES: "ARCHIVES 2D",
		PHOTOSHI: "PHOTOS HI",
		PHOTOSLO: "PHOTOS LO",
		COLLECTIONS: "Collections",
		DESIGNEDBY: 'conçu par',
		DISCOVER: 'DECOUVREZ TOUTE LA COLLECTION',
		GUARANTIES: 'CERTIFICAT DE QUALITÉ ET GARANTIE',
		FILTER: "Filtre",
		SEARCH: "Chercher",
		TYPE: "Type",
		ALL: "Tout",
		BACKGROUNDWHITE: 'fond blanc',
      	HOJAMONTAJE: 'montage',
      	MEDIDASINTERIORES: 'dimensions intérieures',
		SEARCHINFO: "Vous pouvez chercher  les archives des produits dont vous avez besoin grace a notre moteur de recherche"
	  },
	  DOWNLOADS: {
		DOWNLOADS: "Téléchargements",
		FILTER: "Filtrer",
		SEARCH: "Chercher...",
	  },
	  ORDER: {
		COLLECTIONS: "Collections",
		ORDER: "Devis",
		FILTER: "Filtre",
		REFERENCE: "Reference",
		QTY: "Quantité",
		PRIZE: "Prix",
		PRIZEVALID: 'Prix valables pour 7 jours',
		PRIZEUNIT: "Prix unitaire",
		REMOVE: "Supprimer",
		MKORDER: "Finaliser",
		FINISHED: "Merci!",
		DOWNPDF: "Télécharger PDF",
		TOTCOMPOSITION: "TOTAL COMPOSITION",
		TOTALAMOUNT: "TOTAL DEVIS",
		IVANO: "TVA NON INLUS",
		COMMENTS: "OBSERVATIONS",
		MOREINFO: "MERCI D’AVOIR UTILISÉ NOTRE TARIF EN LIGNE. CE PROCESSUS N’A PAS GENERE DE COMMANDE CHEZ PUNT DE FACON AUTOMATIQUE. SI VOUS SOUHAITEZ ENTRER EN CONTACT AVEC NOUS ENVOYEZ UN MAIL A VOTRE CONTACT",
		VALIDITY: "prix valables jusqu'à",
        VATNOTINCLUDE: 'TVA NON INLUS',
        OBSERVATIONS: 'OBSERVATIONS '
	  },
	  SIGNUP: {
		SIGNUP: "REGISTRE",
		BRANDNAME: "Nom Commercial",
		BUSSNAME: "Raison Sociale",
		CONTACTPERS: "Personne de Contact",
		VATID: "NIF / CIF",
		ADDRESS: "Adresse",
		POSTALCODE: "Codo Postal",
		CITY: "Ville",
		STATE: "Province",
		PHONE: "Teléphone",
		EMAIL: "Email",
		WEBSITE: "Site Web",
		PASSWORD: "Mot de passe",
		CONFPASSWORD: "Confirmer le mot de passe",
		SIGNUPI: "Enregistrer",
		SIGNUPMESSAGE: "¡Enregistrement reussi!",
		GOCOLLECTIONS: "Aller aux collections",
		COUNTRY: "Pays",
		USEREXISTS: "Il existe déjà un utilisateur avec cet email. Veuillez vous connecter"
	  },
	  MENU: {
		NEW: "nouveau",
		ACTIONS: "Actions",
		CREATE_POST: "Créer une nouvelle publication",
		PAGES: "Pages",
		FEATURES: "Caracteristiques",
		APPS: "Applications",
		DASHBOARD: "Tableau de bord",
		USERS: "Utilisateurs",
		CLIENTS: "Clients",
		FAMILIES: "Familles",
		SUBFAMILIES: "Sous Famílles",
		COLECTION: "Collections",
		FINISHES: "Finitions",
		PRICES: "Prix",
		REFERENCES: "References",
		MODULES: "Modules",
		PLINTHS: "Pieds",
		COVERS: "Couverture",
		ORDERS: "Liste des devis",
		UNFINISHED: "Devis inachevés",
		DESIGNERS: 'Designers',
		DOWNLOADS: 'Téléchargements',
		DISTRIBUTORS: 'Distributeurs',
		ABOUT: 'Nous',
		CONTACT: 'Contact',
	  },
	  FOOTER: {
		ABOUT: 'A propose de',
		PROFESSIONAL: 'Professionnel',
		ABOUTUS: 'Nous',
		DESIGNERS: 'Designers',
		VIRTUALSHOWROOM: 'Showroom virtuel',
		LEGALMAINTENANCE: 'legal & entretien',
		SUSTAINABILITY: 'Durabilité',
		DOWNLOADS: 'Téléchargements',
		DISTRIBUTIONS: 'Distributeurs',
		CONTACT: 'Contact',
		PRESSKIT: 'Kit de presse',
		NEWSLETTER: 'Newsletter',
		FOLLOWUS: 'Suivez-nous sur',
	  },
	  AUTH: {
		GENERAL: {
		  OR: "Ou",
		  SUBMIT_BUTTON: "Envoyer",
		  NO_ACCOUNT: "Vous n’avez pas de compte?",
		  SIGNUP_BUTTON: "S’enregistrer",
		  FORGOT_BUTTON: "Vous avez oublié votre mot de passe",
		  BACK_BUTTON: "Retour",
		  PRIVACY: "Vie privé",
		  LEGAL: "Legal",
		  CONTACT: "Contact",
		  TITLE: "Démarrer cession",
		},
		LOGIN: {
		  TITLE: "Créez un compte",
		  BUTTON: "Démarrer cession",
		},
		FORGOT: {
		  TITLE: "Mot de passe oublié?",
		  DESC: "Entrer votre email pour réinitialiser votre mot de passe",
		  SUCCESS: "Votre compte a été réinitialisé avec succès.",
		},
		REGISTER: {
		  TITLE: "S’inscrire",
		  DESC: "Entrer vos informations pour créer votre compte",
		  SUCCESS: "Votre compte a été créé avec succès.",
		  REGISTER: "REGISTEO",
		},
		INPUT: {
		  EMAIL: "Email",
		  FULLNAME: "Nom complet",
		  PASSWORD: "Mot de passe",
		  CONFIRM_PASSWORD: "Confirner mot de passe",
		  USERNAME: "Utilisateur",
		},
		VALIDATION: {
		  INVALID: "{{nom}} n’est pas valide",
		  REQUIRED: "{{nom}} est requis",
		  MIN_LENGTH: "{{nom}} longueur minimum est {{min}}",
		  AGREEMENT_REQUIRED: "Acceptation des termes et conditions requise",
		  NOT_FOUND: "Le {{nom}} requis n’est par trouvé",
		  INVALID_LOGIN: "L’identifiant est incorrecte",
		  REQUIRED_FIELD: "Champs requis",
		  MIN_LENGTH_FIELD: "Longueur minimale du champs:",
		  MAX_LENGTH_FIELD: "Longueur maximale du champs:",
		  INVALID_FIELD: "Champs is non valide",
		},
	  },
	  SUTAINABILITY: {
		T1 : 'Let’s Protect the Environment!',
		D11 : 'We are aware that the environment is everyone’s concern and we work with the highest level of quality and design to ensure a long-lasting use of all our products. We mostly work with recycled and/or recyclable materials and we rely on suppliers with certified processes of on-going improvement and respect for the environment.',
		D12 : 'All the electrical energy consumed in our factory is nourished by renewable energy sources.',
		T2 : 'Reducing the carbon footprint',
		D2 : 'Trees in their growth process, capture the CO2 released by burning fossil fuels. Producing wood that becomes furniture reduces atmospheric CO2 thus mitigating the greenhouse effect. At Punt we go further. All electricity consumed in the manufacturing process comes from renewable sources so that we reduce to 0% carbon emissions in our factory!',
		T3 : 'Materiales reciclados',
		D3 : 'We specialise in natural wood veneered board. By using this material, we offer you the highest quality finishes with 100% recycled cores.',
		T4 : 'Recyclable Materials',
		D4 : 'Our products are almost 100% exclusively made of wood, steel, aluminium and glass. These materials are identified on the product sheets that are delivered to our customers in each command and are 100% recyclable.',
		T5 : 'FSC',
		T6 : 'Let’s Respect our Forests',
		D61 : 'Protecting our forests and reducing the carbon dioxide in the atmosphere lies in our hands. By controlling the origin of the wood we can guarantee the reforestation of the forests used for obtaining our raw material. Growing trees capture the CO2 released by the combustion of fossil fuel, thus palliating the greenhouse effect.',
		D62 : 'FSC is a non-profit global organisation dedicated to promote responsible management of the forests all around the world. FSC certification guarantees that the products proceed from well managed forests that bring environmental, social and economic benefits. Concretely, the Chain of Custody certification applies to producers, auctioneers and distributors of FSC certified forest products. This type of certification checks that the products using FSC labels actually contain FSC certified materials and their source has been controlled through the chain of production. Controlled Wood has been defined to prevent companies or organisations from including in their materials wood from unacceptable sources. The FSC Controlled Wood can only be combined with FSC certified wood in products labelled as FSC Mixed Sources.',
		D63 : 'In Punt, we work with suppliers of FSC certified boards in Chain of Custody (FSC-STD-40-004) and in Controlled Wood (FCD-STD-40-005).',
		T7 : 'Warranty of Quality, respect for the environment and on-going improvement',
		D71 : 'Punt is certified in accordance with ISO 9001.',
		D72 : 'We select thoroughly our suppliers and we work together in order to guarantee the highest quality for our products. Therefore, our suppliers of boards, varnishes, foams, fabrics, metallic pieces and hardware hold ISO 9001:2008 certification, and our fabrics and varnishes suppliers hold ISO 14001:2004 certification too.',
	  },
	  CONTACT: {
		TGENERAL: "Département commercial SPAIN / ESPAÑA",
		TCOMERCIAL: "Département commercial OTHER COUNTRIES",
		TBUYS: "Département des achats"
	  },
	  ECOMMERCE: {
		COMMON: {
		  SELECTED_RECORDS_COUNT: "Nombre d’enregistrements selectionnés: ",
		  ALL: "Tous",
		  SUSPENDED: "Suspendus",
		  ACTIVE: "Actif",
		  FILTER: "Filtre",
		  BY_STATUS: "par Status",
		  BY_TYPE: "par Type",
		  BUSINESS: "Business",
		  INDIVIDUAL: "Individuel",
		  SEARCH: "Chercherr",
		  IN_ALL_FIELDS: "dans tous les champs",
		},
		ECOMMERCE: "eCommerce",
		CUSTOMERS: {
		  CUSTOMERS: "Clients",
		  CUSTOMERS_LIST: "liste des clients",
		  NEW_CUSTOMER: "Nouveau Client",
		  DELETE_CUSTOMER_SIMPLE: {
			TITLE: "Supprimer client",
			DESCRIPTION: "Êtes-vous sûr de vouloir supprimer ce client définitivement?",
			WAIT_DESCRIPTION: "Client supprimé...",
			MESSAGE: "le client a été supprimé",
		  },
		  DELETE_CUSTOMER_MULTY: {
			TITLE: "Clients supprimés",
			DESCRIPTION: "Êtes-vous sûr de vouloir supprimer les clients séñectionnés définitivement?",
			WAIT_DESCRIPTION: "Clients supprimés...",
			MESSAGE: "Clients sélectionnés supprimés",
		  },
		  UPDATE_STATUS: {
			TITLE: "Status mis à jour pour les clients sélectionnés",
			MESSAGE: " status des clients sélectionnés a été mis à jour",
		  },
		  EDIT: {
			UPDATE_MESSAGE: "Le client a été mis à jour",
			ADD_MESSAGE: "le client a été créé",
		  }
		},
	  },
	},
  };
  