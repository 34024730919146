import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../app/services/auth/user.service';
@Component({
  selector: 'kt-modal-cookies',
  templateUrl: './modal-cookies.component.html',
  styleUrls: ['./modal-cookies.component.scss']
})
export class ModalCookiesComponent implements OnInit {

  constructor(public userService: UserService) { }

  ngOnInit() {
  }

}
