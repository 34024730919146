import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy,} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl,} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from "../../../../services/api/api.service";
import { HeaderService } from "../../../../services/header/header.service";
import { FooterService } from "../../../../services/footer/footer.service";
// Models
import { Referencia } from "../../../../core/auth/_models/referencia.model";
import { Observable } from "rxjs";
import { SubheaderService } from "../../../../core/_base/layout";
import { TipoReferencia } from "../../../../core/auth/_models/tipo-referencia.model";
import { Familia } from "../../../../core/auth/_models/familia.model";
import { Subfamilia } from "../../../../core/auth/_models/subfamilia.model";
import { Input } from '@angular/core';
import { SelectAutocompleteComponent } from "mat-select-autocomplete";
import { DomSanitizer } from "@angular/platform-browser";

import { TranslationService } from "../../../../core/_base/layout";

@Component({
  selector: "kt-referencia",
  templateUrl: "./referencia.component.html",
  styleUrls: ["./referencia.component.scss"],
})
export class ReferenciaComponent implements OnInit, OnDestroy {
  @ViewChild(SelectAutocompleteComponent, { static: false })
  multiSelect: SelectAutocompleteComponent;

  acabadosForm = new FormGroup({
    selected: new FormControl(),
  });
  relacionadosForm = new FormGroup({
    selected: new FormControl(),
  });
  selectedOptionsAcabados = [];
  referencia: Referencia;
  referenciaId: Observable<number>;
  familias: Familia[] = [];
  familiasArray = [];
  subfamilias: Subfamilia[] = [];
  tiposReferencias: TipoReferencia[] = [];
  relacionados = [];
  acabados = [];
  referenciaForm: FormGroup;
  hasFormErrors: boolean = false;
  selectedTab = 0;
  loadingFile: boolean = false;
  imagenesReferencia = [];
  
  relImagenesAcabados = {};
  disenadores = [];
  @Input() placeholderLabel = "Buscar";
  subcategorias = [];
  //dropdownTipos = [];
  //selectedTiposItems = [];
  //dropdownSettings: IDropdownSettings = {};

  constructor(
    public _DomSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private api: ApiService,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef,
    public ts: TranslationService
  ) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      const id = params.id;
      this.referencia = new Referencia();
      this.referencia.clear();
      if (id && id > 0) {
        this.api.readData('referencias', '', 'id = ' + id).subscribe((res: any) => {
          this.referencia = Object.assign(this.referencia, res.data[0]);
          this.initReferencia();
          if (this.referencia.imagenes !== '') {
            const parseImagenes = JSON.parse(this.referencia.imagenes);
            for (const i in parseImagenes) {
              // console.log(parseImagenes[i]);
              this.imagenesReferencia.push([
                parseImagenes[i],
                this.api.getUrlBase() +
                  "/api/assets/images/referencias/" +
                  this.referencia.codigo +
                  '/' +
                  parseImagenes[i],
              ]);
            }
          }
          if (this.referencia.relImagenesAcabados !== '') {
            const parseImagenesAc = JSON.parse(this.referencia.relImagenesAcabados);
            this.relImagenesAcabados = parseImagenesAc;
          }
          if (this.referencia.acabados && this.referencia.acabados !== '') {
            const jsonAcabados = JSON.parse(this.referencia.acabados);
            this.acabadosForm.setValue({
              selected: jsonAcabados.selected,
            });
          }

          if (
            this.referencia.relacionados &&
            this.referencia.relacionados !== ''
          ) {
            const jsonRelacionados = JSON.parse(this.referencia.relacionados);
            this.relacionadosForm.setValue({
              selected: jsonRelacionados.selected,
            });
          }
        });
      } else {
        this.initReferencia();
      }
      // console.log(imagenes);
    });

    this.api.readData('tiposreferencias').subscribe((res: any) => {
      for (const o of res.data) {
        this.tiposReferencias.push(o);
      }
    });

    
    this.api.readData('subcategorias').subscribe((res: any) => {
      for (const o of res.data) {
        this.subcategorias.push(o);
      }
    });

    this.api.readData('disenadores').subscribe((res: any) => {
      for (const o of res.data) {
        this.disenadores.push(o);
      }
    });

    this.api.readData("familias").subscribe((res: any) => {
      for (const o of res.data) {
        this.familias.push(o);
        this.familiasArray[o.id] = o.nombre;
      }
    });
    this.api.readData('subfamilias').subscribe((res: any) => {
      for (const o of res.data) {
        this.subfamilias.push(o);
      }
    });

    this.api.readData('acabados', "*", "1").subscribe((res: any) => {
      for (const o of res.data) {
        this.acabados.push({
          display: o.grupo + " - " + o.descripcion_es,
          value: o.id,
        });
      }
    });

    this.api
      .readData('referencias', "id,familia,nombre,codigo", "tipo<>1")
      .subscribe((res: any) => {
        for (const o of res.data) {
          this.relacionados.push({
            display: o.codigo + " - " + o.nombre,
            value: o.id,
          });
        }
      });

    this.cdRef.detectChanges();
  }

  onToggleDropdown() {
    this.multiSelect.toggleDropdown();
  }

  devolverAcabado(id) {
    if (this.acabados.find((element) => element.value == id)) {
      return this.acabados.find((element) => element.value == id)["display"];
    }
  }
  devolverRelacionado(id) {
    if (this.relacionados.find((element) => element.value == id)) {
      return this.relacionados.find((element) => element.value == id)[
        "display"
      ];
    }
  }

  quitarItemAcabado(id) {
    const posicion: number = this.acabadosForm.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.acabadosForm.value.selected.splice(posicion, 1);
      var selecActual = this.acabadosForm.value.selected;
      this.acabadosForm = new FormGroup({
        selected: new FormControl(selecActual),
      });
    }
  }
  quitarItemRelacionado(id) {
    const posicion: number = this.relacionadosForm.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.relacionadosForm.value.selected.splice(posicion, 1);
      var selecActual = this.relacionadosForm.value.selected;
      this.relacionadosForm = new FormGroup({
        selected: new FormControl(selecActual),
      });
    }
  }

  initReferencia() {
    this.createForm();
    if (!this.referencia.id) {
      this.subheaderService.setTitle("Crear referencia");
      this.subheaderService.setBreadcrumbs([
        { title: 'referencias', page: `referencias` },
        { title: "Crear referencia", page: `referencias/new` },
      ]);
      return;
    }
    this.subheaderService.setTitle("Editar Referencia");
    this.subheaderService.setBreadcrumbs([
      { title: 'referencias', page: `referencias` },
      {
        title: "Editar referencia",
        page: `referencias`,
        queryParams: { id: this.referencia.id },
      },
    ]);
  }

  createForm() {
    this.referenciaForm = this.fb.group({
      nombre: [this.referencia.nombre],
      codigo: [this.referencia.codigo],
      tipo: [this.referencia.tipo],
      largo: [this.referencia.largo],
      alto: [this.referencia.alto],
      profundo: [this.referencia.profundo],
      familia: [this.referencia.familia],
      tamanoSvg: [this.referencia.tamanoSvg],
      descripcion_en: [this.referencia.descripcion_en],
      descripcion_es: [this.referencia.descripcion_es],
      descripcion_de: [this.referencia.descripcion_de],
      descripcion_fr: [this.referencia.descripcion_fr],
      descripcion_corta_en: [this.referencia.descripcion_corta_en],
      descripcion_corta_es: [this.referencia.descripcion_corta_es],
      descripcion_corta_de: [this.referencia.descripcion_corta_de],
      descripcion_corta_fr: [this.referencia.descripcion_corta_fr],
      imagenes: [this.referencia.imagenes],
      acabados: [this.referencia.acabados],
      relacionados: [this.referencia.acabados],
      subfamilia: [this.referencia.subfamilia],
      productoAcabado: [this.referencia.productoAcabado == 1 ? true : false],
      subcategoria: [this.referencia.subcategoria],
      relImagenesAcabados: [this.referencia.relImagenesAcabados],
      id_disenador: [this.referencia.id_disenador] });

    // console.log(this.referenciaForm);
  }

  getComponentTitle() {
    let result = "Crear referencia";
    if (!this.referencia || !this.referencia.id) {
      return result;
    }

    result = `Editar referencia - ${this.referencia.nombre} ${this.referencia.codigo}`;
    return result;
  }

  saveReferencia() {
    const newReferencia = Object.assign(
      this.referencia,
      this.referenciaForm.value
    );
    newReferencia["subcategoria"] = this.referenciaForm.value.subcategoria == '' ? null : this.referenciaForm.value.subcategoria;
    newReferencia["imagenes"] = this.imagenesReferencia;
    newReferencia['acabados'] = JSON.stringify(this.acabadosForm.value);
    newReferencia["relacionados"] = JSON.stringify(this.relacionadosForm.value);
    newReferencia["productoAcabado"] = newReferencia.productoAcabado === true ? 1 : 0;
    newReferencia["relImagenesAcabados"] = JSON.stringify(this.relImagenesAcabados);
    
    console.log(newReferencia);
    if (!newReferencia.id) {
      this.api.createData('referencias', newReferencia).subscribe((res: any) => {
        // console.log(res);
        if (res) {
          this.router.navigate(["/panel/referencias"]);
        }
      });
    } else {
      this.api.updateData('referencias', newReferencia).subscribe((res: any) => {
        // console.log(res);
        if (res) {
          this.router.navigate(["/panel/referencias"]);
        }
      });
    }
  }

  borrarImagen(imagen) {
    this.imagenesReferencia = this.imagenesReferencia.filter(
      (obj) => obj !== imagen
    );

    delete this.relImagenesAcabados[imagen[0]];
    this.cdRef.detectChanges();
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }
    // console.log(files);

    for (var _i = 0; _i < files.length; _i++) {
      this.cargarImagenes(files[_i]);
    }
  }

  updateUrl(event) {
    //event.target.src = '../../../../../assets/img/users/default.jpg';
  }

  ngOnDestroy(): void {
    this.header.toggle();
    this.footer.toggle();
  }

  actualizaImgSelect(event, imagen) {
    this.relImagenesAcabados[imagen] = event.value;
  }

  cargarImagenes(imagen) {
    var mimeType = imagen.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // console.log(imagen);
    var reader = new FileReader();
    //this.referenciaForm['pic'] = files;
    reader.readAsDataURL(imagen);
    reader.onload = (_event) => {
      var resultado = reader.result;
      var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
      this.imagenesReferencia.push([
        name,
        this._DomSanitizer.bypassSecurityTrustUrl(resultado.toString()),
      ]);
      this.cdRef.detectChanges();
    };
  }
}
