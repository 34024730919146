import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Acabado } from '../../../../core/auth/_models/acabado.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from "../../../../core/_base/layout";
@Component({
    selector: 'kt-acabado',
    templateUrl: './acabado.component.html',
    styleUrls: ['./acabado.component.scss']
})
export class AcabadoComponent implements OnInit, OnDestroy {

    acabado: Acabado;
    acabadoId: Observable<number>;
    acabadoForm: FormGroup;
    hasFormErrors: boolean = false;
    selectedTab = 0;
    loadingFile: boolean = false;
    imagenesAcabado = [];

    constructor(
        public _DomSanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private subheaderService: SubheaderService,
        private api: ApiService,
        private header: HeaderService,
        private footer: FooterService,
        private cdRef: ChangeDetectorRef,
        public ts: TranslationService) {
        this.header.hide();
        this.footer.hide();
    }



    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            this.acabado = new Acabado();
            this.acabado.clear();
            if (id && id > 0) {
                this.api.readData('acabados', '', 'id = ' + id).subscribe(res => {
                    this.acabado = Object.assign(this.acabado, res['data'][0]);
                    console.log(this.acabado);

                    this.initAcabado();

                    if (this.acabado.imagenes != "" && this.acabado.imagenes != "NULL") {
                        var parseImagenes = JSON.parse(this.acabado.imagenes);
                        for (var i in parseImagenes) {
                            //console.log(parseImagenes[i]);
                            this.imagenesAcabado.push([parseImagenes[i], this.api.getUrlBase() + "/api/assets/images/acabados/" + this.acabado.grupo + this.acabado.codigoColor + "/" + parseImagenes[i]]);
                        }
                    }
                })
            } else {
                this.initAcabado();
            }
        });

    }

    initAcabado() {
        this.createForm();
        if (!this.acabado.id) {
            this.subheaderService.setTitle('Crear acabado');
            this.subheaderService.setBreadcrumbs([
                { title: 'Acabados', page: `acabados` },
                { title: 'Crear acabado', page: `acabados/new` }
            ]);
            return;
        }
        this.subheaderService.setTitle('Editar Familia');
        this.subheaderService.setBreadcrumbs([
            { title: 'Acabados', page: `acabados` },
            { title: 'Editar acabado', page: `acabados`, queryParams: { id: this.acabado.id } }
        ]);
    }

    createForm() {
        this.acabadoForm = this.fb.group({
            grupo: [this.acabado.grupo, Validators.required],
            codigoColor: [this.acabado.codigoColor],
            descripcion_es: [this.acabado.descripcion_es],
            descripcion_en: [this.acabado.descripcion_en],
            descripcion_de: [this.acabado.descripcion_de],
            descripcion_fr: [this.acabado.descripcion_fr],
            orden: [this.acabado.orden],
            imagenes: [this.acabado.imagenes],
            activo: this.acabado.activo == 1 ? true : false,
        });

        console.log(this.acabadoForm);
    }

    getComponentTitle() {
        let result = 'Crear acabado';
        if (!this.acabado || !this.acabado.id) {
            return result;
        }

        result = `Editar acabado - ${this.acabado.descripcion_es}`;
        return result;
    }

    saveAcabado() {
        const newAcabado = Object.assign(this.acabado, this.acabadoForm.value);
        newAcabado["imagenes"] = this.imagenesAcabado;
        newAcabado.activo = this.acabadoForm.controls.activo.value === true ? 1 : 0;
        if (!newAcabado.id) {
            this.api.createData('acabados', newAcabado).subscribe(res => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/acabados']);
                }
            });
        } else {
            this.api.updateData('acabados', newAcabado).subscribe(res => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/acabados']);
                }
            });
        }
        /*
            
        */
    }
    borrarImagen(imagen) {
        this.imagenesAcabado = this.imagenesAcabado.filter(obj => obj !== imagen);
        this.cdRef.detectChanges();
    }
    preview(files) {

        if (files.length === 0) {
            return;
        }
        //console.log(files);

        for (var _i = 0; _i < files.length; _i++) {
            this.cargarImagenes(files[_i]);
        }
    }
    cargarImagenes(imagen) {
        var mimeType = imagen.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        //console.log(imagen);
        var reader = new FileReader();
        //this.referenciaForm['pic'] = files;
        reader.readAsDataURL(imagen);
        reader.onload = (_event) => {
            var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
            var resultado = reader.result;
            this.imagenesAcabado.push([name, this._DomSanitizer.bypassSecurityTrustUrl(resultado.toString())]);
            this.cdRef.detectChanges();
        }

    }

    updateUrl(event) {
        //event.target.src = '../../../../../assets/img/users/default.jpg';
    }

    ngOnDestroy(): void {
        this.header.toggle();
        this.footer.toggle();
    }

}
