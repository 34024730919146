import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Distribuidor } from '../../../../core/auth/_models/distribuidor.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from "../../../../core/_base/layout";
@Component({
  selector: 'kt-distribuidor',
  templateUrl: './distribuidor.component.html',
  styleUrls: ['./distribuidor.component.scss']
})
export class DistribuidorComponent implements OnInit, OnDestroy {

  distribuidor: Distribuidor;
  distribuidorId: Observable<number>;
  distribuidorForm: FormGroup;
  localizaciones = [];
  localizacionesSelect = [];
  localizacionesForm = new FormGroup({
    selected: new FormControl(),
  });

  hasFormErrors = false;
  selectedTab = 0;
  loadingFile = false;
  subcatNombre = '';
  subcatNombre_es = '';

  idDistribuidor = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private api: ApiService,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef,
    private ts: TranslationService) {
    this.header.hide();
    this.footer.hide();
  }



  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.idDistribuidor = params.id;
      this.distribuidor = new Distribuidor();
      this.distribuidor.clear();
      this.localizaciones.push({
        display: 'Ninguna',
        value: 0,
      });

      this.api.readData('localizaciones').subscribe((res: any) => {
        for (const o of res.data) {
          this.localizaciones[o.id] = o;
        }
        for (const localizacion of this.localizaciones) {
         if (localizacion !== undefined){
            let textoDisplay = '';
            if ( localizacion.padre && localizacion.padre !== '') {
              const jsonLocalizaciones = JSON.parse(localizacion.padre);
              for (const padre of jsonLocalizaciones.selected){
                textoDisplay += this.localizaciones[padre].nombre_es;
              }
            }

            this.localizacionesSelect.push({
              display: textoDisplay + ' - ' + localizacion.nombre_es,
              value: Number(localizacion.id),
            });
         }
        }
      });
      if (this.idDistribuidor && this.idDistribuidor > 0) {
        this.api.readData('distribuidores', '*', 'id = ' + this.idDistribuidor).subscribe((res: any) => {
          this.distribuidor = Object.assign(this.distribuidor, res.data[0]);
          if (
            this.distribuidor.localizacion &&
            this.distribuidor.localizacion !== ''
          ) {
            const jsonDistribuidores = JSON.parse(this.distribuidor.localizacion);
            this.localizacionesForm.setValue({
              selected: jsonDistribuidores.selected,
            });
          }
          this.initDistribuidor();
        });
      } else {
        this.initDistribuidor();
      }
    });
  }

  initDistribuidor() {
    this.createForm();
    if (!this.distribuidor.id) {
      this.subheaderService.setTitle('Crear distribuidor');
      this.subheaderService.setBreadcrumbs([
        { title: 'Distribuidores', page: `distribuidores` },
        { title: 'Crear distribuidor', page: `distribuidores/new` }
      ]);
      return;
    }
    this.subheaderService.setTitle('Editar Familia');
    this.subheaderService.setBreadcrumbs([
      { title: 'Distribuidores', page: `distribuidores` },
      { title: 'Editar distribuidor', page: `distribuidores`, queryParams: { id: this.distribuidor.id } }
    ]);
    this.cdRef.detectChanges();
  }

  createForm() {
    this.distribuidorForm = this.fb.group({
      nombre_en: [this.distribuidor.nombre_en],
      nombre_es: [this.distribuidor.nombre_es],
      nombre_de: [this.distribuidor.nombre_de],
      nombre_fr: [this.distribuidor.nombre_fr],
      localizacion: [this.distribuidor.localizacion],
      email: [this.distribuidor.email],
      telefono: [this.distribuidor.telefono],
    });

    console.log(this.distribuidorForm);
  }

  getComponentTitle() {
    let result = 'Crear distribuidor';
    if (!this.distribuidor || !this.distribuidor.id) {
      return result;
    }

    result = `Editar distribuidor - ${this.distribuidor.nombre_es}`;
    return result;
  }

  saveDistribuidor() {
    const newDistribuidor = Object.assign(this.distribuidor, this.distribuidorForm.value);
    newDistribuidor.localizacion = JSON.stringify(this.localizacionesForm.value);

    if (!newDistribuidor.id) {
      this.api.createData('distribuidores', newDistribuidor).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/panel/distribuidores']);
        }

      });
    } else {
      this.api.updateData('distribuidores', newDistribuidor).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/panel/distribuidores']);
        }

      });
    }

  }

  ngOnDestroy(): void {
    this.header.toggle();
    this.footer.toggle();
  }

}
