import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DescargasService {

  tiposDescargas = {
    docRevit: 'COLLECTIONS.REVIT',
    doc3DFiles: 'COLLECTIONS.THREEDFILES',
    doc2DFiles: 'COLLECTIONS.TWODFILES',
    docFicha: 'COLLECTIONS.SHEET',
    docFotosHI: 'COLLECTIONS.PHOTOSHI',
    docFotosLO: 'COLLECTIONS.PHOTOSLO',
    certificados: 'COLLECTIONS.CERTIFICATES',
    garantias: 'COLLECTIONS.GUARANTIES',
    fondo_blanco: 'COLLECTIONS.BACKGROUNDWHITE',
    hoja_montaje: 'COLLECTIONS.HOJAMONTAJE',
    medidas_interiores: 'COLLECTIONS.MEDIDASINTERIORES',
    video: 'COLLECTIONS.VIDEO',
    presentacion: 'COLLECTIONS.PRESENTATION',
    imagen_detalle: 'COLLECTIONS.IMAGENDETALLE',
  };

  descargasLoginDefault = {
    docRevit: true,
    doc3DFiles: true,
    doc2DFiles: true,
    docFicha: true,
    docFotosHI: true,
    docFotosLO: true,
    certificados: true,
    garantias: true,
    fondo_blanco: true,
    hoja_montaje: true,
    medidas_interiores: true,
    video: true,
    presentacion: true,
    imagen_detalle: true
  };

  constructor() { }
}
