import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { TipoReferencia } from '../../../../core/auth/_models/tipo-referencia.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from "../../../../core/_base/layout";
@Component({
  selector: 'kt-tipoReferencia',
  templateUrl: './tipo-referencia.component.html',
  styleUrls: ['./tipo-referencia.component.scss']
})
export class TipoReferenciaComponent implements OnInit, OnDestroy {

  tipoReferencia: TipoReferencia;
  tipoReferenciaId: Observable<number>;
  tipoReferenciaForm: FormGroup;
  hasFormErrors: boolean = false;
  selectedTab = 0;
  loadingFile: boolean = false;
  imagenesTipoReferencia = [];

  constructor(
    public _DomSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private api: ApiService,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef,
    public ts: TranslationService) {
    this.header.hide();
    this.footer.hide();
  }



  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      this.tipoReferencia = new TipoReferencia();
      this.tipoReferencia.clear();
      if (id && id > 0) {
        this.api.readData('tiposreferencias', '', 'id = ' + id).subscribe((res: any) => {
          this.tipoReferencia = Object.assign(this.tipoReferencia, res.data[0]);
          console.log(this.tipoReferencia);

          this.initTipoReferencia();
        })
      } else {
        this.initTipoReferencia();
      }
    });

  }

  initTipoReferencia() {
    this.createForm();
    if (!this.tipoReferencia.id) {
      this.subheaderService.setTitle('Crear tipoReferencia');
      this.subheaderService.setBreadcrumbs([
        { title: 'Tipo Referencia', page: `tipoReferencias` },
        { title: 'Crear tipo Referencia', page: `tiposReferencias/new` }
      ]);
      return;
    }
    this.subheaderService.setTitle('Editar Familia');
    this.subheaderService.setBreadcrumbs([
      { title: 'TipoReferencias', page: `tiposReferencias` },
      { title: 'Editar tipoReferencia', page: `tiposReferencias`, queryParams: { id: this.tipoReferencia.id } }
    ]);
  }

  createForm() {
    this.tipoReferenciaForm = this.fb.group({
      nombre_es: [this.tipoReferencia.nombre_es, Validators.required],
      nombre_en: [this.tipoReferencia.nombre_en],
      nombre_de: [this.tipoReferencia.nombre_de],
      nombre_fr: [this.tipoReferencia.nombre_fr],
      orden: [this.tipoReferencia.orden]
    });

    console.log(this.tipoReferenciaForm);
  }

  getComponentTitle() {
    let result = 'Crear tipoReferencia';
    if (!this.tipoReferencia || !this.tipoReferencia.id) {
      return result;
    }

    result = `Editar tipoReferencia - ${this.tipoReferencia.nombre_es}`;
    return result;
  }

  saveTipoReferencia() {
    const newTipoReferencia = Object.assign(this.tipoReferencia, this.tipoReferenciaForm.value);

    if (!newTipoReferencia.id) {
      this.api.createData('tiposreferencias', newTipoReferencia).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/panel/tiposReferencias']);
        }
      });
    } else {
      this.api.updateData('tiposreferencias', newTipoReferencia).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/panel/tiposReferencias']);
        }
      });
    }
    /*
    	
    */
  }

  updateUrl(event) {
    //event.target.src = '../../../../../assets/img/users/default.jpg';
  }

  ngOnDestroy(): void {
    this.header.toggle();
    this.footer.toggle();
  }

}
