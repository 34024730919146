// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Referencia } from '../../../core/auth/_models/referencia.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteReferenciaComponent } from './referencia/delete-referencia/delete-referencia.component';
import { TipoReferencia } from '../../../core/auth/_models/tipo-referencia.model';
import { Subfamilia } from '../../../core/auth/_models/subfamilia.model';
@Component({
	selector: 'kt-referencias',
	templateUrl: './referencias.component.html',
	styleUrls: ['./referencias.component.scss']
})
export class ReferenciasComponent implements OnInit, OnDestroy {

	referencias: Referencia[] = [];
	subfamilias: Subfamilia[] = [];
	tiposReferencias: TipoReferencia[] = [];
	contadorImagenes = [];
	imagenes = [];
	contador = [];
	dataSource;
	displayedColumns: string[] = ['select', 'id', 'subfamilia', 'referencia', 'nombre', 'acabados', 'tipo','producto-acabado', 'imagen', 'opciones'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	selection = new SelectionModel<Referencia>(true, []);

	constructor(
		private api: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private subheaderService: SubheaderService,
		private matDialog: MatDialog,
		private header: HeaderService,
		private footer: FooterService) {
		this.header.hide();
		this.footer.hide();
	}

	ngOnInit() {
		this.contador['acabados'] = [];
		this.subheaderService.setTitle('Referencias');

		this.api.readData('referencias').subscribe((res: any) => {
			for (const o of res.data) {

				o["nombreSubfamilia"] = this.subfamilias[o.subfamilia];
				this.referencias.push(o);
				this.contadorImagenes[o.id] = "-";

				if (o.imagenes !== '') {
					const parseImagenes = JSON.parse(o.imagenes);
					for (const i in parseImagenes) {
						// console.log(parseImagenes[i]);
						this.imagenes[o.id] = this.api.getUrlBase() + "/api/assets/images/referencias/" + o.codigo + '/' + parseImagenes[i];
					}
				}

				if (o.acabados && o.acabados !== '') {
					const jsonAcabados = JSON.parse(o.acabados);

					if (jsonAcabados.selected.length != 0) {
						this.contador['acabados'][o.id] = jsonAcabados.selected.length;
					}
				}

			}
			this.dataSource = new MatTableDataSource(this.referencias);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;

		});

		this.api.readData('tiposreferencias').subscribe((res: any) => {
			for (const o of res.data) {
				this.tiposReferencias[o.id] = o.nombre;
			}
		});
		this.api.readData('subfamilias').subscribe((res: any) => {
			for (const o of res.data) {
				this.subfamilias[o.id] = o.nombre;
			}
		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.referencias.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.referencias.length) {
			this.selection.clear();
		} else {
			this.referencias.forEach(row => this.selection.select(row));
		}
	}

	fetchReferencias() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.nombre}`,
				id: elem.id.toString(),
				status: elem.nombre
			});
		});
		console.log(messages);

	}

	editReferencia(id: number) {
		this.router.navigate(['../referencias/', id], { relativeTo: this.activatedRoute });
		//this.router.navigate([]).then(result => {  window.open('panel/referencias/'+ id, '_blank'); });
	}

	deleteReferencia(id: number) {
		var coleccionesConRef = '';
		this.api.readData('subfamilias').subscribe((res: any) => {
			for (let coleccion of res.data) {
				if (coleccion.referencias !== '') {
					var parseReferencias = JSON.parse(coleccion.referencias);
					if (parseReferencias.selected != null){
						var posicionRef: number = parseReferencias.selected.indexOf(id);
					}else{
						var posicionRef: number = -1;
					}
				}
				if (coleccion.referenciasRel !== '') {
					var parseReferenciasRel = JSON.parse(coleccion.referenciasRel);
					if (parseReferencias.selected != null){
						var posicionRefRel: number = parseReferenciasRel.selected.indexOf(id);
					}else{
						var posicionRefRel: number = -1;
					}
				}

				if (posicionRef != -1 || posicionRefRel != -1) {
					coleccionesConRef += "- " + coleccion.nombre + '\n';
				}
			}

			if (coleccionesConRef !== '') {
				alert("La referencia seleccionada está en estas colecciones:\n" + coleccionesConRef);

			} else {
				let referencia = this.referencias.find(i => i.id === id);
				const dialogConfig: MatDialogConfig = {
					autoFocus: true,
					maxWidth: '400px',
					data: {
						id: referencia.id
					}
				}
				const dialogRef = this.matDialog.open(DeleteReferenciaComponent, dialogConfig);
				dialogRef.afterClosed().subscribe((res: any) => {
					if (res) {
						this.api.deleteData('referencias', res).subscribe((res: any) => {
							if (res) {
								this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
							}
						});
					}
				});
			}
		});

	}


	ngOnDestroy(): void {
		this.header.toggle();
	}
}
