// USA
export const locale = {
  lang: 'en',
  data: {
    HOME: {
      NEWCATALOGUES: 'Check out our new online catalogues',
    },
    COOKIES: {
      THISTIME: 'Accept this sesion',
      ACCEPT: 'Accept',
      REJECT: 'Reject',
      PRIVACY: 'We value your privacy',
      TEXT: 'This site uses cookies to improve your user experience. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      LINK: 'Our cookie policy',
      CONTENT: `<h3>Models, Finishes and Colours</h3>

      <p>Models: Here in PUNT we work constantly on the improvement of our products so we reserve the right to amend the measurements, materials, colours, etc. of our products with the aim of improving their quality, service, strength or price. If the products that you have ordered present some variation with respect to our current catalogue and prices, we will inform you previously to check that you are still interested.</p>

      <p>Finishes: All the finishes that we offer (natural, stained or lacquered wood, lacquered or anodised metal), even though they are protected, can gradually change their original colour over time due to sunlight exposure. Wood boards and wood pieces, because of their natural origin, present different and non-homogeneous patterns and tones. In PUNT we select each element of a piece of furniture to make these differences aesthetically pleasing.</p>

      <p>Colours: The actual appearance of the nice colours of our products may differ slightly from the ones you have seen on a screen or a catalogue.</p>

      <h3>Orders, Transportation and Delivery Times</h3>

      <p>Orders: To avoid confusion, you must formalise your orders in writing. Once we get a completely defined order, we will send you an email and start to prepare your delivery. Since this moment, any cancellation on your part might result in the reimbursement of the costs and expenses incurred by PUNT through the preparation of your delivery.</p> 

      <p>Transportation: In the confirmation of your order, we will indicate the accorded transportation service and if it generates some costs.</p>

      <p>Delivery Time: PUNT will indicate the estimated delivery times according to the purchased products and their shipping destination. We are working hard to improve these estimated times. We hope that you will excuse us if it is not possible owing to unforeseen causes. When your order is ready, we will deliver it in the shipping destination indicated on the order form, where you must sign the delivery receipt.</p>

      <h3>Warranty</h3>

      <p>If you are a final customer, the quality of the product is under warranty for 2 years, so you must keep the purchase receipt. If you have any incident, don’t send us back the product. Just send us a photo and an explanation so we can understand and solve the problem. Damages are not covered if they result from an inappropriate use of the product. In order to avoid it, we will deliver along with each product an installation manual and a guide for use and maintenance. We are at your disposal at info@puntmobles.com to answer any questions or requests that you might have.</p>

      <p>The shipping company is the only responsible for the damages caused during transportation, so please check carefully the product at the delivery and write down any suspected defect on the shipment document or the company won’t take the responsibility for it. Our packaging is very safe but it cannot protect the product from every mishandling. Make sure that you handle the product carefully before unpacking it and don’t unpack it until it is placed in its final location.</p>

      <h3>Furniture Cleaning and Maintenance</h3>

      <p>For a proper preservation, the piece of furniture must be kept in a dry space. You must remove any spillage or stain immediately so it doesn’t penetrate or damage the protective varnish layer. Every furniture material must be wiped with slightly damp non-abrasive cloths or cleaned with non-acid cleaning agents except paint thinners such as acetone. You mustn’t use cleaning agents containing alcohol on wood with a water-based coating. After cleaning, dry the surface with a dry cloth.</p>

      <h3>Web, contents and design</h3>

      <p>PUNT MOBLES XXI, S.L. (“PUNT”) is the owner of this domain and the content existing in it. If you want to reproduce it totally or partially, you must request permission. Likewise, PUNT has got the exclusive selling rights of the displayed products.</p>

      <h3>Data Protection</h3>

      <p>All the personal data sent through the website are stored in the servers of PUNT. We only register those data that are needed to send you the request information and news from PUNT and to process your orders. If you are a dealer, we communicate your data to your national commercial agent and the supplier of delivery services that will bring you the products. If you are a final customer, we send your data to a suitable distributor, as we are not selling online. All the agents involved are committed to maintain the privacy of your personal data and to use them solely for its designated purpose. We do not pass on your data in any case to commercial address brokers, so you are not going to receive unwanted advertising that is not related to PUNT. You can request at any moment information on the stored data and their use. You can also update, modify or remove them if you don’t want us to use them again. You only have to send an email to info@puntmobles.com.</p>

      <h3>Use of Cookies</h3>

      <p>A cookie is a small file generated and stored in the user’s computer when he/she visits our website. These files contain some information on the configuration of our site and information on your web browsing habits. The information obtained by the cookies is absolutely anonymous and cannot be linked to any specific and identified use in any case. This information allows us to know the usage patterns and statistics to improve the user browsing experience. The use of cookies can be disabled by selecting the appropriate settings on your browser.</p>`
    },
    NAVBAR: {
      PRODUCTSFINISHED: 'Products finished',
      PRODUCTS: 'Products',
      PROJECTS: 'Projects',
      DESIGNERS: 'Designers',
      DOWNLOADS: 'Downloads',
      ABOUT: 'About',
      CONTACT: 'Contact',
      LANGUAGE: 'Language',
      SIGNUP: 'Register',
    },
    TRANSLATOR: {
      SELECT: 'Select your language',
      SPANISH: 'Español',
      ENGLISH: 'English',
    },
    RESTORE: {
      RESTORE: 'RESTORE YOUR PASSWORD',
      SUCCESS: 'Your pasword has been restored.',
      UPDATE: 'Restore password',
      PASSMATCH: 'Passwords do not match',
      RESEND: 'The password could not be recovered. Request the recovery email again.'
    },
    SESION: {
      EMAIL: 'email',
      PASSWORD: 'Password',
      MYDATA: 'My data  ',
      SIGNOFF: 'Log out',
      LOGIN: 'Log In',
      FORGOTPASS: 'Have you forgotten your password? Click here.',
      ERRORDATOS: 'You have to fill in all the fields',
      ERRORMAIL: 'Please fill in the email field',
      ERRORUSER: 'There is no user with the data entered',
      SENTMAIL: 'We have sent you an email to recover your password. Check your mailbox.',
      PROBLEMS: 'If you have problems accessing contact',
      PROBLEMS2: 'Do you have a password? Click here',
      REMEMBERPASS: 'Remember password',
      ERRORPASS: 'Wrong password. Please try again',
    },
    NEWSLETTER: {
      TITLE: 'BE THE FIRST TO KNOW',
      DESCRIPTION1: 'Be the first to receive new offers, limited edition products, events and inspiration directly in your inbox.',
      DESCRIPTION2: '>By submitting your email address, you consent to our',
      PRIVACY: 'privacy policy.'
    },
    DISTRIBUTORS: {
      NAME: 'Name',
      EMAIL: 'Email',
      PHONE: 'Phone number',
      LOCATION: 'LOCATION',
      PROVINCESTATE: 'State / province',
      COUNTRY: 'Country',
      TITLERESULTS: 'Distributors for location',
      NORESULTS: 'There are no results for this location',
      AGENT: 'Agent',
      AGENTMAIL: 'Email Agent',
      CONTACT: 'Check with us to find your local area representative/distributor:'

    },
    CONFIGURADOR: {
      COLLECTIONS: 'Collections',
      BACK_BUTTON: 'Back',
      NEXT: 'Next',
      COMPOSITION: 'COMPOSITION',
      FINISH: 'Finishes',
      SUMMARY: 'Summary',
      ADDCART: 'Add to cart',
      GOCART: 'Go to cart',
      ADDMORE: 'Add more elements',
      CONTINUEBUY: 'Return to colletions',
      DOWNLOADS: 'Downloads',
      REFERENCE: 'Reference',
      QTY: 'QTY',
      PRIZE: 'Price ( EX. VAT)',
      LOGINREQUIRED: 'In order to see the prices',
      LOGIN: 'log in',
      PRIZEPENINSULA: 'Price for mainland Spain.',
      PRIZEADVICE: 'Please note it is an excl. VAT price, VAT will be paid in the country of import. Other charges including Shipping, Duty and declaration charges are not included.',
      REQUESTPRICE: 'Request price'
    },
    COLLECTIONS: {
      IMAGENDETALLE: 'DETAIL IMAGES',
      PRESENTATION: 'PRODUCT PRESENTATION',
      REVIT: 'REVIT',
      SHEET: 'TECHNICAL INFORMATION',
      THREEDFILES: '3D FILES',
      TWODFILES: '2D FILES',
      PHOTOSHI: 'PHOTOS HI',
      PHOTOSLO: 'PHOTOS LO',
      COLLECTIONS: 'Collections',
      FILTER: 'Filter',
      SEARCH: 'Search...',
      TYPE: 'Type',
      ALL: 'All',
      DESIGNEDBY: 'designed by',
      DISCOVER: 'Discover all collection',
      PRODUCTSBY: 'PRODUCTS BY',
      CERTIFICATES: 'CERTIFICATES',
      GUARANTIES: 'QUALITY AND WARRANTY CERTIFICATE',
      BACKGROUNDWHITE: 'WHITE BACKGROUND',
      HOJAMONTAJE: 'INSTRUCTIONS',
      MEDIDASINTERIORES: 'INTERNAL MEASUREMENTS',
      NOTFOUND: 'No products were found with the selected filters.',
      SEARCHINFO: "You can find the product files you need by using our search engine",
      VIDEO: 'VIDEO'
    },
    PROJECTS: {
      PROJECTS: 'PROJECTS'
    },
    DOWNLOADS: {
      DOWNLOADS: 'Downloads',
      FILTER: 'Filter',
      SEARCH: 'Search...',
      TECHNICALINFO: 'TECHNICAL INFORMATION',
      CERTIFICATES: 'CERTIFICATES',
      GUARANTY: 'GUARANTY',
      INSTRUCTIONS: 'GUIDES',
      PHOTOHIGHTRESOLUTION: 'PHOTOS HIGHT RESOLUTION',
      PHOTOLOWRESOLUTION: 'PHOTOS LOW RESOLUTION',
      WHITEBACKGROUNDPHOTOS: 'WHITE BACKGROUND PHOTOS',
      VIDEO: 'VIDEO'
    },
    ORDER: {
      COLLECTIONS: 'Collections',
      ORDER: 'Budget',
      FILTER: 'Flter',
      REFERENCE: 'Reference',
      QTY: 'Qty',
      PRIZE: 'Price',
      PRIZEUNIT: 'Unit price',
      PRIZEVALID: 'Prices valid for 7 days',
      REMOVE: 'Remove',
      MKORDER: 'Finish',
      FINISHED: 'Thank you!',
      DOWNPDF: 'Download as PDF',
      TOTCOMPOSITION: 'TOTAL COMPOSITION',
      TOTALAMOUNT: 'TOTAL',
      IVANO: 'EX.vat',
      COMMENTS: 'OBSERVATIONS',
      MOREINFO: 'Thank you for using our online rate. This process has not generated' +
      'any order automatically. If you would like to contact us, please email your contact person.',
      VALIDITY: 'VALIDITY OF THE BUDGET ',
      VATNOTINCLUDE: 'EX.vat',
      OBSERVATIONS: 'OBSERVATIONS: '
    },
    SIGNUP: {
      SIGNUP: 'REGISTER',
      BRANDNAME: 'Brand',
      BUSSNAME: 'Business Name',
      CONTACTPERS: 'Contact Person',
      VATID: 'VAT ID',
      ADDRESS: 'Address',
      POSTALCODE: 'Postal Code',
      CITY: 'City',
      STATE: 'State',
      PHONE: 'Phone',
      EMAIL: 'Email',
      WEBSITE: 'Website',
      PASSWORD: 'Password',
      CONFPASSWORD: 'Confirm Password',
      SIGNUPI: 'Sign UP',
      SIGNUPMESSAGE: 'Successful registration',
      GOCOLLECTIONS: 'Go to collections',
      COUNTRY: 'Country',
      USEREXISTS: 'There is already a user with that email. Please log in.'
    },
    MENU: {
      NEW: 'new',
      FINISHES: 'finishes',
      ACTIONS: 'Actions',
      CREATE_POST: 'Create New Post',
      PAGES: 'Pages',
      FEATURES: 'Features',
      APPS: 'Apps',
      DASHBOARD: 'Dashboard',
      DESIGNERS: 'DESIGNERS',
      DOWNLOADS: 'Downloads',
      DISTRIBUTORS: 'Distributors',
      ABOUT: 'About',
      CONTACT: 'Contact',
    },
    FOOTER: {
      ABOUT: 'About',
      PROFESSIONAL: 'Professional',
      ABOUTUS: 'About',
      DESIGNERS: 'Designers',
      VIRTUALSHOWROOM: 'Virtual showroom',
      LEGALMAINTENANCE: 'Legal & maintenance',
      SUSTAINABILITY: 'Sustainability',
      DOWNLOADS: 'Downloads',
      DISTRIBUTIONS: 'Distributors',
      CONTACT: 'Contact',
      PRESSKIT: 'Press kit',
      NEWSLETTER: 'Newsletter',
      FOLLOWUS: 'Follow us',
    },
    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don\'t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact',
        TITLE: 'Login'
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In',
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        REGISTER: 'SIGN UP',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
    },
    SUTAINABILITY: {
      T1 : 'Let’s Protect the Environment!',
      D11 : 'We are aware that the environment is everyone’s concern and we work with the highest level of quality and design to ensure a long-lasting use of all our products. We mostly work with recycled and/or recyclable materials and we rely on suppliers with certified processes of on-going improvement and respect for the environment.',
      D12 : 'All the electrical energy consumed in our factory is nourished by renewable energy sources.',
      T2 : 'Reducing the carbon footprint',
      D2 : 'Trees in their growth process, capture the CO2 released by burning fossil fuels. Producing wood that becomes furniture reduces atmospheric CO2 thus mitigating the greenhouse effect. At Punt we go further. All electricity consumed in the manufacturing process comes from renewable sources so that we reduce to 0% carbon emissions in our factory!',
      T3 : 'Materiales reciclados',
      D3 : 'We specialise in natural wood veneered board. By using this material, we offer you the highest quality finishes with 100% recycled cores.',
      T4 : 'Recyclable Materials',
      D4 : 'Our products are almost 100% exclusively made of wood, steel, aluminium and glass. These materials are identified on the product sheets that are delivered to our customers in each command and are 100% recyclable.',
      T5 : 'FSC',
      T6 : 'Let’s Respect our Forests',
      D61 : 'Protecting our forests and reducing the carbon dioxide in the atmosphere lies in our hands. By controlling the origin of the wood we can guarantee the reforestation of the forests used for obtaining our raw material. Growing trees capture the CO2 released by the combustion of fossil fuel, thus palliating the greenhouse effect.',
      D62 : 'FSC is a non-profit global organisation dedicated to promote responsible management of the forests all around the world. FSC certification guarantees that the products proceed from well managed forests that bring environmental, social and economic benefits. Concretely, the Chain of Custody certification applies to producers, auctioneers and distributors of FSC certified forest products. This type of certification checks that the products using FSC labels actually contain FSC certified materials and their source has been controlled through the chain of production. Controlled Wood has been defined to prevent companies or organisations from including in their materials wood from unacceptable sources. The FSC Controlled Wood can only be combined with FSC certified wood in products labelled as FSC Mixed Sources.',
      D63 : 'In Punt, we work with suppliers of FSC certified boards in Chain of Custody (FSC-STD-40-004) and in Controlled Wood (FCD-STD-40-005).',
      T7 : 'Warranty of Quality, respect for the environment and on-going improvement',
      D71 : 'Punt is certified in accordance with ISO 9001.',
      D72 : 'We select thoroughly our suppliers and we work together in order to guarantee the highest quality for our products. Therefore, our suppliers of boards, varnishes, foams, fabrics, metallic pieces and hardware hold ISO 9001:2008 certification, and our fabrics and varnishes suppliers hold ISO 14001:2004 certification too.',
    },
    CONTACT: {
      TGENERAL: "Commercial Department SPAIN / ESPAÑA",
      TCOMERCIAL: "Commercial Department OTHER COUNTRIES",
      TBUYS: "Purchasing Departament"
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        }
        
      },
    },
  }
};

