import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'kt-delete-cliente',
  templateUrl: './delete-cliente.component.html',
  styleUrls: ['./delete-cliente.component.scss']
})
export class DeleteClienteComponent implements OnInit {

  id: number;
  cliente: string;

  constructor(public dialogRef: MatDialogRef<DeleteClienteComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.cliente = data.name;
   }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }
}