// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns string from Array
 */
@Pipe({
	name: 'lineBreak'
})
export class lineBreakPipe implements PipeTransform {
	/**
	 * Transform
	 *
	 * @param value: string
	 */
	transform(value: string): string {
		return (value || '').replace(/\n/g, "<br />");
	}
}
