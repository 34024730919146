import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Home } from '../../../core/auth/_models/home.model';
import { SubheaderService } from '../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from "../../../core/_base/layout";
import { PartialsModule } from '../../partials/partials.module';
@Component({
  selector: 'kt-home-panel',
  templateUrl: './home-panel.component.html',
  styleUrls: ['./home-panel.component.scss']
})
export class HomePanelComponent implements OnInit, OnDestroy {

  home: Home;
  homeForm: FormGroup;
  hasFormErrors = false;
  selectedTab = 0;
  loadingFile = false;
  imagenesHome = [];
  valueImagenes = [];
  guardado = false;

  constructor(
    public _DomSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private api: ApiService,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef,
    public ts: TranslationService) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {

      this.home = new Home();
      this.home.clear();
      this.api.readData('home', '', 'id = 1').subscribe((res: any) => {
        this.home = Object.assign(this.home, res.data[0]);
        console.log(this.home);

        if (this.home.imagenes !== '' && this.home.imagenes !== null) {
          const parseImagenes = JSON.parse(this.home.imagenes);
          let parseImgPrincipal =  (this.home.imgPrincipal !== '' && this.home.imgPrincipal !== null)? JSON.parse(this.home.imgPrincipal) : [];
          let parseImgDetalle1 =  (this.home.imgDetalle1 !== '' && this.home.imgDetalle1 !== null)? JSON.parse(this.home.imgDetalle1) : [];
          let parseImgDetalle2 =  (this.home.imgDetalle2 !== '' && this.home.imgDetalle2 !== null)? JSON.parse(this.home.imgDetalle2) : [];
          
          for (const image of parseImagenes) {

            const extension = image.split('.').pop();
            this.imagenesHome.push([image, this.api.getUrlBase() +
                '/api/assets/images/home/1/' + image, extension]);
            

            if (parseImgPrincipal.includes(image)) {
              this.valueImagenes[image] = '1';
            } else if (parseImgDetalle1.includes(image)) {
              this.valueImagenes[image] = '2';
            } else if (parseImgDetalle2.includes(image)) {
              this.valueImagenes[image] = '3';
            }

            this.cdRef.detectChanges();
          }
        }
        this.createForm();
      });
    });

  }

  createForm() {
    this.homeForm = this.fb.group({
      id: [this.home.id],
      tituloPrincipal_en: [this.home.tituloPrincipal_en],
    tituloPrincipal_es: [this.home.tituloPrincipal_es],
    tituloPrincipal_de: [this.home.tituloPrincipal_de],
    tituloPrincipal_fr: [this.home.tituloPrincipal_fr],
    descripcionPrincipal_en: [this.home.descripcionPrincipal_en],
    descripcionPrincipal_es: [this.home.descripcionPrincipal_es],
    descripcionPrincipal_de: [this.home.descripcionPrincipal_de],
    descripcionPrincipal_fr: [this.home.descripcionPrincipal_fr],
    linkVideoPrincipal: [this.home.linkVideoPrincipal],
    imgPrincipal: [this.home.imgPrincipal],
    tituloDetalle1_en: [this.home.tituloDetalle1_en],
    tituloDetalle1_es: [this.home.tituloDetalle1_es],
    tituloDetalle1_de: [this.home.tituloDetalle1_de],
    tituloDetalle1_fr: [this.home.tituloDetalle1_fr],
    descripcionDetalle1_en: [this.home.descripcionDetalle1_en],
    descripcionDetalle1_es: [this.home.descripcionDetalle1_es],
    descripcionDetalle1_de: [this.home.descripcionDetalle1_de],
    descripcionDetalle1_fr: [this.home.descripcionDetalle1_fr],
    linkVideoDetalle1: [this.home.linkVideoDetalle1],
    imgDetalle1: [this.home.imgDetalle1],
    tituloDetalle2_en: [this.home.tituloDetalle2_en],
    tituloDetalle2_es: [this.home.tituloDetalle2_es],
    tituloDetalle2_de: [this.home.tituloDetalle2_de],
    tituloDetalle2_fr: [this.home.tituloDetalle2_fr],
    descripcionDetalle2_en: [this.home.descripcionDetalle2_en],
    descripcionDetalle2_es: [this.home.descripcionDetalle2_es],
    descripcionDetalle2_de: [this.home.descripcionDetalle2_de],
    descripcionDetalle2_fr: [this.home.descripcionDetalle2_fr],
    validezPrecios_en: [this.home.validezPrecios_en],
    validezPrecios_es: [this.home.validezPrecios_es],
    validezPrecios_de: [this.home.validezPrecios_de],
    validezPrecios_fr: [this.home.validezPrecios_fr],
    linkVideoDetalle2: [this.home.linkVideoDetalle2],
    imgDetalle2: [this.home.imgDetalle2],
    imagenes: [this.home.imagenes]
    });


 
    
    this.cdRef.detectChanges();

    this.homeForm.valueChanges.subscribe(x => {
      console.log('entra en cambio');
      this.guardado = false;
    });
  }

  getComponentTitle() {
    
      const result = `Editar variable generales`;
      return result;
    
    
  }

  saveHome() {
    const newHome = Object.assign(this.home, this.homeForm.value);
    newHome.imagenes = this.imagenesHome;
    newHome.imgPrincipal = [];
    newHome.imgDetalle1 = [];
    newHome.imgDetalle2 = [];
    for (const key of Object.keys(this.valueImagenes)) {
      if (this.valueImagenes[key] === '1') {
        newHome.imgPrincipal.push(key);
      }
      if (this.valueImagenes[key] === '2') {
        newHome.imgDetalle1.push(key);
      }
      if (this.valueImagenes[key] === '3') {
        newHome.imgDetalle2.push(key);
      }
    }
    newHome.imgPrincipal = JSON.stringify(newHome.imgPrincipal);
    newHome.imgDetalle1 = JSON.stringify(newHome.imgDetalle1);
    newHome.imgDetalle2 = JSON.stringify(newHome.imgDetalle2);

    this.api.updateData('home', newHome).subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.guardado = true;
        this.cdRef.detectChanges();

        setTimeout(() => {
          this.guardado = false;
          this.cdRef.detectChanges();
        }, 3000);
      }
    });

  }

  actualizaImgSelect(event, imagen) {
    this.guardado = true;

    this.valueImagenes[imagen] = event.value;
    this.cdRef.detectChanges();
  }

  borrarImagen(imagen) {
    this.imagenesHome = this.imagenesHome.filter(obj => obj !== imagen);
    this.cdRef.detectChanges();
  }
  preview(files) {

    if (files.length === 0) {
      return;
    }
    // console.log(files);

    for (var _i = 0; _i < files.length; _i++) {
      this.cargarImagenes(files[_i]);
    }
  }

  cargarImagenes(imagen) {

    const extension = imagen.name.split('.').pop();
    const reader = new FileReader();
    // this.referenciaForm['pic'] = files;
    reader.readAsDataURL(imagen);
    reader.onload = (_event) => {
      const resultado = reader.result;
      var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
      this.imagenesHome.push([name, this._DomSanitizer.bypassSecurityTrustUrl(resultado.toString()), extension]);
      this.cdRef.detectChanges();
    };

  }

  updateUrl(event) {
    // event.target.src = '../../../../../assets/img/users/default.jpg';
  }

  ngOnDestroy(): void {
    this.header.toggle();
    this.footer.toggle();
  }

}
