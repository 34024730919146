import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Home } from '../../core/auth/_models/home.model';




@Component({
  selector: 'kt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit{
  descargasPrincipales = [];
  imagenesDescargas = [];
  idioma: string;
  home: Home;
  homeCharged = false;
  slideConfigSimpl = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    pauseOnFocus: false
  };
  imagenesHome = { imgPrincipal: [], imgDetalle1: [], imgDetalle2: []};
  constructor(
    private api: ApiService,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
    }); }

  ngOnInit() {

    this.api.readData('descargas', '*', 'destacado = 1').subscribe((resRel: any) => {

      for (const oRel of resRel.data) {
        this.descargasPrincipales.push(oRel);
        if (oRel.imagenes !== '' && oRel.imagenes !== '[]') {
          this.imagenesDescargas[oRel.id] = [];
          const parseImagenes = JSON.parse(oRel.imagenes);
          for (const i in parseImagenes) {
            if (parseImagenes[i]) {
              this.imagenesDescargas[oRel.id] = this.api.getUrlBase() +
              '/api/assets/images/descargas/' + oRel.codigo + '/' + parseImagenes[i];
              this.cdRef.detectChanges();
            }
          }
        }
      }

    });

    this.api.readData('home', '*', 'id = 1').subscribe((resHome: any) => {
      this.home = resHome.data[0];
      const carpetaImgHome = this.api.getUrlBase() + '/api/assets/images/home/' + this.home.id + '/';

      if (this.home.imgPrincipal !== '' && this.home.imgPrincipal !== 'null') {
        let parseImagenes = JSON.parse(this.home.imgPrincipal);
        parseImagenes.forEach(element => {
          this.imagenesHome.imgPrincipal.push(carpetaImgHome + element);
        });
      }
      if (this.home.imgDetalle1 !== '') {
        let parseImagenes = JSON.parse(this.home.imgDetalle1);
        parseImagenes.forEach(element => {
          this.imagenesHome.imgDetalle1.push(carpetaImgHome + element);
        });

      }
      if (this.home.imgDetalle2 !== '') {
        let parseImagenes = JSON.parse(this.home.imgDetalle2);
        parseImagenes.forEach(element => {
          this.imagenesHome.imgDetalle2.push(carpetaImgHome + element);
        });

      }

      this.homeCharged = true;
      this.cdRef.detectChanges();
    });
  }

  imgFileType(img) {
    if (img == ''){
      return false;
    }

      if (img.includes('.mp4') || img.includes('.avi') || img.includes('.ogg') || img.includes('.webm')  ) {
        return 'video';
      } else {
        return 'image';
      }
  }
}
