// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { PrecioReferencia } from '../../../core/auth/_models/precios.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeletePrecioComponent } from './precio/delete-precio/delete-precio.component';

@Component({
	selector: 'kt-precios',
	templateUrl: './precios-referencias.component.html',
	styleUrls: ['./precios-referencias.component.scss']
})
export class PreciosReferenciasComponent implements OnInit, OnDestroy {

	precios: PrecioReferencia[] = [];
	dataSource;
	displayedColumns: string[] = ['id','CODART','TARIFA','IDTARIFAV','FECMIN','PRECIO','opciones'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	selection = new SelectionModel<PrecioReferencia>(true, []);

	constructor(
		private api: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private subheaderService: SubheaderService,
		private matDialog: MatDialog,
		private header: HeaderService,
		private footer: FooterService) {
		this.header.hide();
		this.footer.hide();
	}

	ngOnInit() {

		this.subheaderService.setTitle('PreciosReferencias');
	}
	getPrecios(){
		this.api.readData('preciosReferencias').subscribe((res: any) => {
			for (const o of res.data) {
				this.precios.push(o);
			}
			this.dataSource = new MatTableDataSource(this.precios);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});
	}

	applyFilter(event:any) {

		const filterValue = event.target.value;
		if (filterValue.length > 3){
			this.api.readData('preciosReferencias','*',"CODART LIKE '%" + filterValue + "%'").subscribe((res: any) => {
				this.precios = [];
				for (const o of res.data) {
					this.precios.push(o);
				}
				this.dataSource = new MatTableDataSource(this.precios);
				this.dataSource.paginator = this.paginator;
				this.dataSource.sort = this.sort;

			});
		}
	}
	/*
	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}*/

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.precios.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.precios.length) {
			this.selection.clear();
		} else {
			this.precios.forEach(row => this.selection.select(row));
		}
	}

	deletePrecio(id: number) {
		let precio = this.precios.find(i => i.id === id);
		const dialogConfig: MatDialogConfig = {
			autoFocus: true,
			maxWidth: '400px',
			data: {
				id: precio.id,
				name: precio.CODART
			}
		}
		const dialogRef = this.matDialog.open(DeletePrecioComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {
				this.api.deleteData('preciosReferencias', res).subscribe((res: any) => {
					if (res) {
						this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
					}
				});
			}
		});
	}



  
	ngOnDestroy(): void {
		this.header.toggle();
	}
}
