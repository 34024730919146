// Angular
import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
// Material
import { MatDatepickerModule } from '@angular/material';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Pedido } from '../../../core/auth/_models/pedido.model';
import { Client } from '../../../core/auth/_models/client.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
/* Export to CSV */
import { ExportToCsv } from 'export-to-csv';
/* moment */
import * as moment from 'moment';
@Component({
  selector: 'kt-estadisticas',
  templateUrl: './estadisticas.component.html',
  styleUrls: ['./estadisticas.component.scss']
})

export class EstadisticasComponent implements OnInit {

  pedidos: Pedido[] = [];
  clientes: Client[] = [];

  estados = [];
  clientesSelect = [];
  postsControl: FormControl = new FormControl();
  filtroForm = new FormGroup({
    estado: new FormControl(),
    fechaDesde: new FormControl(),
    fechaHasta: new FormControl(),
    clientes: new FormControl(),
  });

  dataSource;
  displayedColumns: string[] = ['select', 'id', 'fecha', 'nombreCliente', 'importeTotal', 'estado', 'opciones'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Pedido>(true, []);
  pedidosFiltrado: Pedido[];
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private matDialog: MatDialog,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef) {
    this.header.hide();
    this.footer.hide();
    this.pedidosFiltrado = [];
  }

  ngOnInit() {
    this.estados.push({
      display: 'Todos',
      value: '0',
    });
    this.estados.push({
      display: 'No Finalizado',
      value: '1',
    });
    this.estados.push({
      display: 'Finalizado',
      value: '2',
    });

    this.clientesSelect.push({
      display: '-- TODOS --',
      value: '0',
    });

    this.filtroForm.controls.estado.setValue(0);

    this.subheaderService.setTitle('Pedidos');

    this.api.readData('clientes', '', '', 'nombre ASC').subscribe((res: any) => {
      this.clientes = res.data;
      for (const o of res.data) {
        this.clientesSelect.push({
          display: o.nombre.toUpperCase() + ' ( ' + o.email.toUpperCase() + ' ) ',
          value: o.id,
        });
      }
    });

    this.api.readData('pedidos').subscribe((res: any) => {
      for (const o of res.data) {
        o.nombreCliente = this.getNombreCliente(o.idCliente);
        this.pedidos.push(o);
      }
      this.dataSource = new MatTableDataSource(this.pedidos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });

    this.pedidosFiltrado = this.pedidos;
  }

  getNombreCliente(id) {

    const cliente = this.clientes.find(o => o.id === id);
    if (cliente !== undefined) {
      return cliente.nombre.toUpperCase();
    } else {
      return '';
    }
  }

  getImporteTotal(productos) {

    productos = JSON.parse(productos);
    let total = 0;
    for (const producto of productos) {

      total += producto.importe;
    }
    return total;
  }

  getImporteTotalProductos(productosPedido) {
    const productos = JSON.parse(productosPedido);
    let total = 0;
    productos.forEach((producto) => {
      total += producto.importe;
    });
    return total;

  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.pedidos.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.pedidos.length) {
      this.selection.clear();
    } else {
      this.pedidos.forEach(row => this.selection.select(row));
    }
  }

  fetchPedidos() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.idCliente}`,
        id: elem.id.toString(),
        status: elem.idCliente
      });
    });
    console.log(messages);

  }


  filtrar() {
    const estado = this.filtroForm.get('estado').value;
    const fDesde = this.filtroForm.get('fechaDesde').value;
    const fHasta = this.filtroForm.get('fechaHasta').value;
    const cliente = this.filtroForm.get('clientes').value;
    if (fHasta != null) {
      fHasta.setHours(23, 59, 59);
    }
    if (estado !== 0 || fDesde != null || fHasta != null || cliente !== 0) {
      this.pedidosFiltrado = this.pedidos.filter(
        o =>
          (estado !== 0 && estado.length > 0 ? o.estado === estado : true) &&
          (fDesde != null ? new Date(o.fecha) >= new Date(fDesde) : true) &&
          (fHasta != null ? new Date(o.fecha) <= new Date(fHasta) : true) &&
          (cliente !== 0 ? o.idCliente === cliente : o.id !== 0)
      );
      this.dataSource = new MatTableDataSource(this.pedidosFiltrado);

    } else {
      this.dataSource = new MatTableDataSource(this.pedidos);
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdRef.detectChanges();
  }

  exportarCSV() {
    let nombre = 'estadisticas';
    if (this.filtroForm.get('fechaDesde').value !== null || this.filtroForm.get('fechaHasta').value !== null) {
      nombre += ' ' + moment(this.filtroForm.get('fechaDesde').value).format('DD/MM/YYYY')
      + ' - ' + moment(this.filtroForm.get('fechaHasta').value).format('DD/MM/YYYY');
    }
    const options = {
      filename: nombre,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Estadísticas [' + moment(this.filtroForm.get('fechaDesde').value).format('DD/MM/YYYY')
      + ' - ' + moment(this.filtroForm.get('fechaHasta').value).format('DD/MM/YYYY') + ']',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    const data: any[] = [];
    this.pedidosFiltrado.forEach(element => {

      data.push({
        ID: element.idCliente,
        Fecha: element.fecha,
        Usuario: this.getNombreCliente(element.idCliente),
        'Importe Total': this.getImporteTotal(element.productos),
        Estado: element.estado === 1 ? 'Finalizado' : 'No finalizado'
      });
    });
    csvExporter.generateCsv(data);
  }

}
