// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { CategoriaProyecto } from '../../../core/auth/_models/categoria-proyecto.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DeleteCategoriaProyectoComponent } from './categoria-proyecto/delete-categoria-proyecto/delete-categoria-proyecto.component';
import { TranslationService } from '../../../core/_base/layout';
@Component({
    selector: 'kt-categorias-proyectos',
    templateUrl: './categorias-proyectos.component.html',
    styleUrls: ['./categorias-proyectos.component.scss']
})
export class CategoriasProyectosComponent implements OnInit, OnDestroy {

    categoriasProyectos: CategoriaProyecto[] = [];
    dataSource;
    contadorImagenes = [];
    tmpCategoriasProyectosForm = new FormGroup({
        selected: new FormControl()
    });


    displayedColumns: string[] = ['select', 'id', 'nombre', 'opciones'];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    selection = new SelectionModel<CategoriaProyecto>(true, []);

    constructor(
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private subheaderService: SubheaderService,
        private matDialog: MatDialog,
        private header: HeaderService,
        private footer: FooterService,
        public ts: TranslationService) {
        this.header.hide();
        this.footer.hide();
    }

    ngOnInit() {

        this.subheaderService.setTitle('Sub Familias');

        this.api.readData('categoriasproyectos').subscribe((res: any) => {
            for (const o of res.data) {
                this.categoriasProyectos.push(o);
            }
            this.dataSource = new MatTableDataSource(this.categoriasProyectos);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

        });

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.categoriasProyectos.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.selection.selected.length === this.categoriasProyectos.length) {
            this.selection.clear();
        } else {
            this.categoriasProyectos.forEach(row => this.selection.select(row));
        }
    }

    fetchCategoriasProyectos() {
        const messages = [];
        this.selection.selected.forEach(elem => {
            messages.push({
                text: `${elem.nombre_es}`,
                id: elem.id.toString(),
                status: elem.nombre_es
            });
        });

    }

    editCategoriaProyecto(id: number) {
        this.router.navigate(['../catProyectos/', id], { relativeTo: this.activatedRoute });
    }

    deleteCategoriaProyecto(id: number) {
        const categoriaProyecto = this.categoriasProyectos.find(i => i.id === id);
        const dialogConfig: MatDialogConfig = {
            autoFocus: true,
            maxWidth: '400px',
            data: {
                id: categoriaProyecto.id,
                nombre_es: categoriaProyecto.nombre_es
            }
        };
        const dialogRef = this.matDialog.open(DeleteCategoriaProyectoComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
                this.api.deleteData('categoriasproyectos', res).subscribe((res2: any) => {
                    if (res2) {
                        this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
                    }
                });
            }
        });
    }


    ngOnDestroy(): void {
        this.header.toggle();
    }
}
