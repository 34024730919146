import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: "root",
})
export class ApiService {

  URLBase: string = location.protocol + '//' + (location.hostname === 'localhost' || location.hostname.includes('192') ? 'puntmobles.test' : location.hostname);
  //URLBase: string = 'https://puntmobles.com';
  
  URL: string = this.URLBase + '/api/datatables/';

  httpHeaders = new HttpHeaders({
    Authorization: localStorage.getItem('userToken'),
  });

  constructor(protected http: HttpClient) {
    console.log(this.URLBase);
   }

  readData(
    table,
    fields: string = '',
    filter: string = '',
    order: string = ''
  ) {

    return this.http.post(
      this.URL + `listado.php`,
      { table: table, fields: fields, filter: filter, order: order },
      { headers: this.httpHeaders }
    ).pipe(
      map( (response: any) => {

        // TODO: Apaño provisional códigos para ser deshabilitados
        if ( table === 'referencias' && true ) {
          const data = response.data.filter( item => !['ACC233','ACC034','ACC095','ACC073','ACC074','ACC075'].includes(item.codigo) );
          response.data = data;

          return response;
        } else {
          return response;
        }

      })
    );
  }

  deleteData(table: string, data: string) {

    return this.http.post(
      this.URL + `borrar.php`,
      { table: table, data: data },
      { headers: this.httpHeaders }
    );
  }

  createData(table: string, data: string) {
    
    return this.http.post(
      this.URL + `anadir.php`,
      { table: table, data: data },
      { headers: this.httpHeaders }
    );
  }

  createClient(table: string, data: string) {
    return this.http.post(
      this.URL + `anadirCliente.php`,
      { table: table, data: data },
      { headers: this.httpHeaders }
    );
  }

  updateData(table: string, values: string) {
    return this.http.post(
      this.URL + `actualizar.php`,
      { table: table, data: values },
      { headers: this.httpHeaders }
    );
  }

  updateCampos(table: string, values: string) {

    return this.http.post(
      this.URL + `actualizarCampos.php`,
      { table: table, data: values },
      { headers: this.httpHeaders }
    );
  }

  saveDownload(idCliente: number, idColeccion : number, subtipo: string ){
    return this.http.post(
      this.URL + `actualizarDescarga.php`,
      { idCliente, idColeccion, subtipo },
      { headers: this.httpHeaders }
    );
  }

  async readSVG(url) {
    let respuesta = await this.http
      .post(
        this.URL + `images-svg.php`,
        { url: url },
        { headers: new HttpHeaders({}) }
      )
      .toPromise();
    //console.log(respuesta["data"]);
    return respuesta["data"];
  }

  readImg(url) {
    
    return this.http.post(this.URL + `images-base64.php`, {
      url: url
    });

  }

  getUrlBase() {
    return this.URLBase;
  }

  loginCliente(email: string = '', password: string = '') {
    return this.http.post(this.URL + `users/loginCliente.php`, {
      email: email,
      password: password,
    },{ headers: this.httpHeaders });
  }

  restorePass(token: string, pass: string) {
    return this.http.post(this.URL + `recuperaContrasena.php`, {
      token: token,
      pass: pass
    },{ headers: this.httpHeaders });
  }


  sendMail(plantilla: string, contenido: string, idioma = "en", to = '') {
    return this.http.post(this.URL + `envioMails.php`, {
      plantilla: plantilla,
      contenido: contenido,
      idioma: idioma,
      to: to,
    });
  }

  upload(data: any): Observable<any> {
    return this.http.post(`${this.URL}tests/upload.php`, {
      data
    });
  }

}
