import { Component, OnInit, ChangeDetectorRef, ViewChild, HostListener, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { Familia } from '../../core/auth/_models/familia.model';
import { Subfamilia } from '../../core/auth/_models/subfamilia.model';
import { Grupo } from '../../core/auth/_models/grupo.model';
import { Categoria } from '../../core/auth/_models/categoria.model';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { UserService } from '../../services/auth/user.service';
import { DescargasService } from '../../services/descargas/descargas.service';
import { animate, state, style, transition, trigger} from '@angular/animations';


import { faFilter, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subcategoria } from '../../core/auth/_models/subcategoria.model';
import { LoaderService } from '../../services/loader/loader.service';

declare var $: any;

@Component({
  selector: 'kt-colecciones',
  templateUrl: './colecciones.component.html',
  styleUrls: ['./colecciones.component.scss'],
  animations: [
    trigger('toggleRight', [
      state(
        'true',
        style({
          left: '0px',
        })
      ),
      state(
        'false',
        style({
          left: '-500px',
        })
      ),
      transition('true <=> false', [animate('200ms ease-in')]),
    ]),
    trigger('toggleBack', [
      state(
        'true',
        style({
          display: 'block',
        })
      ),
      state(
        'false',
        style({
          display: 'none',
        })
      ),
      transition('true <=> false', [animate('200ms ease-in')]),
    ]),
  ],
})
export class ColeccionesComponent implements OnInit {
  @Input() link:string;
  filtrocoleccion = 0;
  subfamilias: Subfamilia[] = [];
  public nombreFamilia: string = null;
  codigoFamilia: string;
  referenciasArr = [];
  grupos = [];
  imagenes = [];
  inputSearch = new FormControl('');
  selectCollection = new FormControl(0);
  selectSubcategory = new FormControl(0);
  imagenesColecc = [];
  faFilter = faFilter;
  filterOpen = false;
  almacenaje = true;
  mesas = false;
  asientos = false;
  otras = false;
  submenu = 0;
  encontrados = 1;
  categorias = [];
  relCatSubCat = [];
  subcategorias = [];
  subcategoriasIds = [];
  subcategoriaSelect = 0;
  collections = [];
  collectionsShow = [];
  filtroSubcategoria = 0;
  dataSource;
  idioma: string;
  cesta = [];
  subcategoriaFiltro = 0;
  descargas = false;
  descargasPrincipales = [];
  imagenesDescargas = [];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };
  descargasLogin = [];
  disenadores = [];
  coleccionesForm = new FormGroup({
    selected: new FormControl(),
    });
  acabados = [];
  acabadosImg = [];

  logged = false;
  mostrarImagenDestacada: boolean;
  src_img_destacada: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    private sanitizer: DomSanitizer,
    public cdRef: ChangeDetectorRef,
    public translate: TranslateService,
    public userService: UserService,
    public descargasService: DescargasService,
    private loaderService: LoaderService
  ) {
    this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
      this.reordenar();
    });
    this.mostrarImagenDestacada = false;
    this.src_img_destacada = '../../../assets/img/product-default.png';

  }

  async ngOnInit() {
    this.descargas = this.router.url === '/downloads-punt';
    this.api.readData('referencias').subscribe((resRel: any) => {

      for (const oRel of resRel.data) {
        this.referenciasArr[oRel.id] = oRel;
      }
    }, error => console.log(error));

    this.api.readData('descargas').subscribe((resRel: any) => {

      for (const oRel of resRel.data) {
        this.descargasPrincipales.push(oRel);
        if (oRel.imagenes !== '' && oRel.imagenes != '[]') {
          this.imagenesDescargas[oRel.id] = [];
          const parseImagenes = JSON.parse(oRel.imagenes);
          for (const i in parseImagenes) {
            this.imagenesDescargas[oRel.id] = this.api.getUrlBase() +
            '/api/assets/images/descargas/' + oRel.codigo + '/' + parseImagenes[i];
            this.cdRef.detectChanges();
          }
        }
      }
      this.descargasPrincipales = this.descargasPrincipales.sort((a, b) => a.orden - b.orden);

    });

    this.api.readData('disenadores').subscribe((res: any) => {
      for (const o of res.data) {
         this.disenadores[o.id] = o;
      }

   });

    this.api.readData('acabados')
    .subscribe((res: any) => {
      for (const o of res.data) {
        this.acabados[o.id] = o;
        if (o.imagenes !== '') {
          const parseImagenes = JSON.parse(o.imagenes);
          for (const i in parseImagenes) {
            if (parseImagenes[i]) {
              this.acabadosImg[o.id] = [];
              this.acabadosImg[o.id].push(this.api.getUrlBase()
              + '/api/assets/images/acabados/' + o.grupo + o.codigoColor + '/' + parseImagenes[i]);
            }
          }
        }
      }
    });

    this.api.readData('grupos')
    .subscribe((res: any) => {
        this.grupos = res.data;
    });
    
    this.api.readData('categorias').subscribe((res: any) => {
      
      if (res.data.length > 0) {
        res.data.sort( (a, b) => a.orden - b.orden);
        for (const o of res.data) {
          
          this.categorias[o.id] = o;
          this.categorias[o.id]['subcategorias'] = [];
        }
        
        this.api.readData('subcategorias').subscribe((res2: any) => {
          if (res2.data.length > 0) {
            for (const o of res2.data) {
              this.collectionsShow[o.id] = [];
              this.subcategorias.push(o);
              this.subcategoriasIds[o.id] = o;
              this.categorias[o.categoria]['subcategorias'].push(o);
            }

            this.cdRef.detectChanges();
            let filtro = '';
            if (this.link === 'products-finished'){
              filtro = 'productoAcabado = 1';
            }
            this.api.readData(this.link === 'products-finished' ? 'referencias' : 'subfamilias','*',filtro).subscribe((res: any) => {
              if (res.data.length > 0) {
                
                if (!this.descargas){
                  this.collections = res.data;
                }else{
                
                  for (const subFam of res.data) {
                    let cont = 0;
                    const tiposDescarga = this.descargasService.tiposDescargas;
                    Object.keys(tiposDescarga).forEach(function(key) {
                      if(subFam[key] != ''){
                        cont++;
                      };
                    });
                    if(cont > 0) {
                      this.collections.push(subFam);
                    }
                  }
                }
                
                this.reordenar();

                // console.log(this.collections);
                this.cdRef.detectChanges();

                for (const subFam of this.collections) {

                  this.descargasLogin[subFam.id] = this.descargasService.descargasLoginDefault;
                  if (subFam.descargasLogin && subFam.descargasLogin !== '') {

                    Object.entries(JSON.parse(subFam.descargasLogin)).forEach(
                      ([key, value]) => {
                        this.descargasLogin[subFam.id][key] = value;
                      }
                    );

                  }
                  if (this.link !== 'products-finished') {
                    let stringReferencias = subFam['nombre_' + this.idioma ];

                    if (subFam.referencias !== '') {
                      let parseReferencias;
                      try {
                        parseReferencias = JSON.parse(subFam.referencias);
                      }
                      catch (error) {
                        console.warn(error); console.log(subFam.referencias);
                      }

                      if (parseReferencias.selected !== null){
                        for (const ref of parseReferencias.selected) {
                          if (this.referenciasArr[ref] != undefined) {
                            stringReferencias += this.referenciasArr[ref].codigo;
                          }

                        }
                      }
                    }

                    this.collections.find(element => element.id == subFam.id).referenciasString = stringReferencias;
                  }

                  if (subFam.subcategoria != 0) {
                    this.collectionsShow[subFam.subcategoria].push(subFam);
                    this.collectionsShow[subFam.subcategoria].sort((a, b) => a.orden - b.orden);
                  }

                  this.subfamilias.push(subFam);
                  
                  if (subFam.imagenes != '[]') {
                    this.imagenes[subFam.id] = [];
                    this.imagenesColecc[subFam.id] = [];
                    var parseImagenes;
                    try {
                      parseImagenes = JSON.parse(subFam.imagenes);
                      for (var i in parseImagenes) {
                        this.imagenesColecc[subFam.id] = [parseImagenes[i]];

                        this.imagenes[subFam.id].push(
                          this.api.getUrlBase() +
                          "/api/assets/images/"+ (this.link === 'products-finished'? 'referencias/':'subfamilias/') +
                          subFam.codigo +
                          "/" +
                          parseImagenes[i]
                        );
                        this.cdRef.detectChanges();
                      }
                    } catch (error) {
                      console.warn(error);
                      console.warn(subFam.id);
                      console.warn(subFam.imagenes);
                    }
                    
                  }
                }

                // console.log(this.imagenes);
              }
              this.dataSource = new MatTableDataSource(this.subfamilias);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              // console.log(this.subfamilias);
            }, error => console.log(error));

          }
        });
      }
    });
  }

  applyFilter() {

    let inputSearch = this.inputSearch.value;
    let selectCollection = this.selectCollection.value;
    let selectSubcategoria = this.selectSubcategory.value;

    for (const subCat of this.subcategorias) {
      this.collectionsShow[subCat.id] = this.collections.filter( (element) => {
        if (element.subcategoria == subCat.id && (selectSubcategoria == 0 || element.subcategoria == selectSubcategoria)){
          if (selectCollection == 0 || selectCollection == element.grupo){
            if ( inputSearch == '' || element.referenciasString.toLowerCase().indexOf(inputSearch.toLowerCase()) !== -1){
              return true;
            }else{
              return false;
            }
          }else{
            return false;
          }
        }else{
          return false;
        }
      
      });
    }

    let encontrados = 0;
    this.collectionsShow.forEach((subcategory:any) => {
      encontrados += subcategory.length; 
      subcategory.sort((a, b) => a.orden - b.orden);
    });
    this.encontrados = encontrados;
    this.cdRef.detectChanges();
  }

  async getImage(url) {
    return await this.api.readSVG(url);
  }

  filtraSubCategoria(idSubcategoria) {
    this.filtroSubcategoria = idSubcategoria;
    // this.filterOpen = !this.filterOpen;
    this.cdRef.detectChanges();
  }

  filtrarTipo(idSubcategoria) {
    this.subcategoriaFiltro = idSubcategoria;
    // this.filterOpen = !this.filterOpen;
    this.cdRef.detectChanges();
  }

  quitarFiltro() {
    this.filtroSubcategoria = 0;
    this.subcategoriaFiltro = 0;
  }

  mostarSubMenu(categoria) {
    if (this.submenu == categoria) {
      this.submenu = 0;
    } else {
      this.submenu = categoria;
    }
  }

  irA(pagina) {
    this.router.navigate([pagina]);
  }

  descargarDoc(datos, tipo) {
    const linkSource = datos;
    const downloadLink = document.createElement('a');
    const fileName = tipo + '.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  descargaRedirect(ruta, nombre) {

    if (this.userService.logged) {
      this.grabarDescarga(0, nombre);
      if (ruta != ''){
        window.open(ruta, '_blank');
      }else{
        return false;
      }
      
    } else {
      window.scroll(0, 0);
      this.userService.abrirLogin();

    }

  }

  grabarDescarga(idColeccion, tipo){
    if (this.userService.logged) {
      this.api.saveDownload(this.userService.cliente.id ,idColeccion, tipo).subscribe((res: any) => {});
    }
  }

  imagenDestacada(event: any, codigo: string, src: string) {
    event.stopPropagation();
    event.preventDefault();
    console.log('/api/assets/images/subfamilias/' + codigo
    + '/' + src);
    this.src_img_destacada = this.api.URLBase + '/api/assets/images/subfamilias/' + codigo
    + '/' + src;
    this.toggleImagenDestacada();
  }
  
  toggleImagenDestacada() {
    this.mostrarImagenDestacada ? this.mostrarImagenDestacada = false : this.mostrarImagenDestacada = true;
    this.cdRef.detectChanges();
  }
  clearElement(element:any){
    element.value = '';
    this.applyFilter();
    
  }
  
  reordenar(){
    this.categorias.sort( (a, b) => a.orden - b.orden);
    this.grupos.sort((a, b) => a['nombre_' + this.idioma].toLowerCase().localeCompare(b['nombre_' + this.idioma].toLowerCase()));
    this.categorias.forEach((cat)=>{
      cat['subcategorias'].sort((a, b) => a['nombre_' + this.idioma].toLowerCase().localeCompare(b['nombre_' + this.idioma].toLowerCase()));
    });
    //this.collections.sort((a, b) => a['nombre_' + this.idioma].toLowerCase().localeCompare(b['nombre_' + this.idioma].toLowerCase()));
    this.subcategorias.sort( (a, b) => a.orden - b.orden);
    this.cdRef.detectChanges();
  }

  getImgColeccion(coleccionId:number){
    if (this.link === 'products-finished'){
      let collection = this.collections.find((cl:any) => cl.id === coleccionId);
      if (collection){
        let relImgAcabados = JSON.parse(collection.relImagenesAcabados);
        if (relImgAcabados){
          for (let img of this.imagenesColecc[coleccionId]) {
            if (relImgAcabados[img] !== 'svg'){
              return this.api.getUrlBase() + '/api/assets/images/referencias/' + collection.codigo + "/" +img;
            }
          }
        }
        return this.imagenes[coleccionId];
      }else{
        return this.imagenes[coleccionId][0];
      }

    }else{
      return this.imagenes[coleccionId][0];
    }
  }
  
  acabadosProductoAcabado(coleccionId:number){
    let collection = this.collections.find((cl:any) => cl.id === coleccionId);
    let arrayAcabados = [];
    let resultado = '';
    if (collection){
      let relImgAcabados = JSON.parse(collection.relImagenesAcabados);
      if (relImgAcabados){
        for (let key in relImgAcabados){
          if (relImgAcabados[key] !== 'svg'){
            arrayAcabados.push(relImgAcabados[key]);
          }
       }
      }
    }
    if (arrayAcabados.length > 0){
      for(let i = 0; i < arrayAcabados.length; i++){
        resultado += this.acabados[arrayAcabados[i]]['descripcion_' + this.idioma] + (i + 1 < arrayAcabados.length ? ', ' : '');
      }
    }

    return resultado;
  }

}
