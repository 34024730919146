import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ApiService } from "../../services/api/api.service";
import { Familia } from "../../core/auth/_models/familia.model";
import { Subfamilia } from "../../core/auth/_models/subfamilia.model";
import { Referencia } from "../../core/auth/_models/referencia.model";
import { Categoria } from "../../core/auth/_models/categoria.model";
import { Subcategoria } from "../../core/auth/_models/subcategoria.model";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatSort } from "@angular/material";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: "kt-descargas",
  templateUrl: "./descargas.component.html",
  styleUrls: ["./descargas.component.scss"],
  animations: [
    trigger("toggleRight", [
      state(
        "true",
        style({
          left: "0px",
        })
      ),
      state(
        "false",
        style({
          left: "-500px",
        })
      ),
      transition("true <=> false", [animate("200ms ease-in")]),
    ]),
    trigger("toggleBack", [
      state(
        "true",
        style({
          display: "block",
        })
      ),
      state(
        "false",
        style({
          display: "none",
        })
      ),
      transition("true <=> false", [animate("200ms ease-in")]),
    ]),
  ],
})
export class DescargasComponent implements OnInit {
  familia: Familia;
  subfamilia: Subfamilia;
  subfamilias: Subfamilia[] = [];
  codigoFamilia: string;
  referenciasFamiliaIds = [];
  imagenes = [];
  faFilter = faFilter;
  filterOpen: boolean = false;
  almacenaje: boolean = true;
  mesas: boolean = false;
  asientos: boolean = false;
  otras: boolean = false;
  submenu = 0;

  categorias: Categoria[] = [];
  subcategorias = [];
  subcategoriasIds = [];
  subcategoriaSelect = 0;
  collections = [];
  collectionsShow = [];
  filtroSubcategoria = 0;
  dataSource;
  idioma: string;
  mostrarModal = false;
  coleccionSelect:Subfamilia;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
    });
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.codigoFamilia = params.familia;
    });

    this.subfamilia = new Subfamilia();
    this.subfamilia.clear();
    this.familia = new Familia();
    this.familia.clear();
    this.api.readData("categorias").subscribe((res: any) => {
      if (res.data.length > 0) {
        this.categorias = res.data;
        for (const oCat of res.data) {
          this.subcategorias[oCat.id] = [];
        }

        this.api.readData("subcategorias").subscribe((res: any) => {
          if (res.data.length > 0) {
            for (const o of res.data) {
              this.subcategorias[o.categoria].push(o);
              this.subcategoriasIds[o.id] = o;
            }
          }
          console.log(this.subcategorias);
        });

      }
    });
    

    this.api.readData("subfamilias", '', "", "").subscribe((res: any) => {
      if (res.data.length > 0) {
        res.data.sort(function (a, b) {
          return a.nombre - b.nombre;
        });

        this.collections = res.data;
        this.collectionsShow = res.data;
        // console.log(this.collections);
        this.cdRef.detectChanges();

        for (let numero of res.data) {
          this.subfamilias.push(numero);
          if (numero.imagenes != "[]") {
            this.imagenes[numero.id] = [];
            const parseImagenes = JSON.parse(numero.imagenes);
            for (const i in parseImagenes) {
              this.imagenes[numero.id].push(
                this.api.getUrlBase() +
                  '/api/assets/images/subfamilias/' +
                  numero.codigo +
                  '/' +
                  parseImagenes[i]
              );
              this.cdRef.detectChanges();
            }
          }
        }

        // console.log(this.imagenes);
      }
      this.dataSource = new MatTableDataSource(this.subfamilias);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // console.log(this.subfamilias);
    });
  }
  applyFilter(event: Event) {
    this.collectionsShow = [];
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log(this.dataSource.filter);
    /*
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
    }*/

    this.collectionsShow = this.collections.filter(
      (element) =>
        element["nombre_" + this.idioma ].toLowerCase().indexOf(this.dataSource.filter) !== -1
    );
    
  }
  async getImage(url) {
    return await this.api.readSVG(url);
  }

  filtraSubCategoria(idSubcategoria) {
    this.filtroSubcategoria = idSubcategoria;
    //this.filterOpen = !this.filterOpen;
    this.cdRef.detectChanges();
  }
  quitarFiltro() {
    this.filtroSubcategoria = 0;
  }

  mostarSubMenu(categoria) {
    if (this.submenu == categoria) {
      this.submenu = 0;
    } else {
      this.submenu = categoria;
    }
  }

  descargarDoc(datos, tipo) {
    const linkSource = datos;
    const downloadLink = document.createElement("a");
    const fileName = tipo + ".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  cargarModal(coleccion) {
    this.mostrarModal = true;
    this.coleccionSelect = coleccion;
    console.log(this.coleccionSelect);
    this.cdRef.detectChanges();
  }

  cerrarModal(){
    this.mostrarModal = false;
  }
  

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };
}
