import { BaseModel } from '../../_base/crud';

export class CategoriaProyecto extends BaseModel {
    id: number;
    nombre_en: string;
    nombre_es: string;
    nombre_de: string;
    nombre_fr: string;

    clear(): void {
        this.id = undefined;
        this.nombre_en = '';
        this.nombre_es = '';
        this.nombre_de = '';
        this.nombre_fr = '';
        

    }
}
