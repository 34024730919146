import { BaseModel } from '../../_base/crud';

export class User extends BaseModel {
    id: number;
    username: string;
    password: string;
    email: string;
    accessToken: string;
    role: number;
    pic: string;
    data: any;

    clear(): void {
        this.id = undefined;
        this.username = '';
        this.password = '';
        this.email = '';
        this.accessToken = 'access-token-' + Math.random();
        this.role = 2;
        this.pic = '';
    }
}
