import { Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import { ApiService } from '../../services/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { Client } from '../../core/auth/_models/client.model';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationService } from '../../core/_base/layout';
import { SelectAutocompleteComponent } from 'mat-select-autocomplete';
@Component({
  selector: 'kt-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.scss'],
})
export class RegistroComponent implements OnInit {
   @ViewChild(SelectAutocompleteComponent, { static: false })
  cliente: Client;
  registerForm: FormGroup;
  hasFormErrors = false;
  erroresEnvio = false;
  registroFinalizado = false;
  textoError = '';
  tipo: number;
  idioma: string;
  paises = [];

  /*
  paisesForm = new FormGroup({
  selected: new FormControl(),
  });
  */

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private rutaActiva: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    public ts: TranslateService
  ) {}

  ngOnInit() {
    this.cliente = new Client();
    this.cliente.clear();

    this.api.readData('paises','*','', this.idioma == 'es'? 'nombre ASC':'name ASC')
      .subscribe((res: any) => {
        this.paises = res.data;

        if ( this.ts.currentLang == "es"){
          this.paises.sort( (a, b) => {
              return a.nombre.localeCompare(b.nombre);
          });
        }else{
          this.paises.sort( (a, b) => {
            return a.name.localeCompare(b.name);
        });
        }
      });

    // console.log(this.tipo);
    this.initRegistro();

    this.cdRef.detectChanges();
  }

  initRegistro() {
    this.createForm();
  }

  createForm() {
    this.registerForm = this.fb.group({
      id: [0],
      nombre: [''],
      razonSocial: [''],
      NIF: [''],
      personaContacto: ['', [Validators.required]],
      direccion: [''],
      CP: [''],
      municipio: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordConf: ['', [Validators.required]],
      accessToken: [''],
      provincia: ['', [Validators.required]],
      pais: ['', [Validators.required]],
      telefono: [''],
      permitirPrecios: [0],
      tipo: [0],

      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z.]{2,15}$'),
        ],
      ],
      web: [''],
    });
  }

  guardarRegistro() {
    if (this.registerForm.valid) {
      this.erroresEnvio = false;
      this.textoError = '';
      let existe = false;

      let newCliente = this.registerForm.value;
      //newCliente.pais = Number(this.paisesForm.value.selected);
      console.log(newCliente.pais);
      switch (newCliente.pais){
        case '64':
          newCliente.tipo = 1;
          break;
        case '65':
          newCliente.tipo = 3;
          break;
        case '39':
          newCliente.tipo = 4;
          break;
        default:
          newCliente.tipo = 2;
      }

      const paisCliente = this.paises.find((pais:any)=>{ 
        if (pais.id == newCliente.pais){return pais}
      });
      if (newCliente.password === newCliente.passwordConf) {
        this.api
          .readData('clientes', '', 'email = "' + newCliente.email + '"')
          .subscribe((res: any) => {
            for (const o of res.data) {
              existe = true;
            }
            if (existe) {
              this.erroresEnvio = true;
              this.textoError = this.ts.instant('SIGNUP.USEREXISTS');
              this.cdRef.detectChanges();
            } else {
              this.api.createData('clientes', newCliente).subscribe((res2: any) => {
                const usuario = res2.data[0];
                localStorage.setItem('user', JSON.stringify(usuario));
                
                let contenido = '<p>Nuevo registro en la página web</p>';
                contenido += '<p><ul><li style="height:20px">Email: '+newCliente.email+'</li>';
                contenido += '<li style="height:20px">Nombre: '+newCliente.nombre+'</li>';
                contenido += '<li style="height:20px">Razón social: '+newCliente.razonSocial+'</li>';
                contenido += '<li style="height:20px">Nif: '+newCliente.NIF+'</li>';
                contenido += '<li style="height:20px">País: '+ ( paisCliente.nombre)+'</li>';
                contenido += '</ul></p>';

                this.api.sendMail('4', contenido, 'es','online@puntmobles.com').subscribe((resMail) => {
                //  this.api.sendMail('4', contenido, 'es','icamps@circulorojo.es').subscribe((resMail) => {
                  if (resMail) {
                    // console.logg(resMail);
                  }
                  this.registroFinalizado = true;
                });

                this.cdRef.detectChanges();
              });
            }
          });
      } else {
        this.erroresEnvio = true;
        this.textoError = this.ts.instant('RESTORE.PASSMATCH');
        this.cdRef.detectChanges();
      }

      return false;
    } else {
      return false;
    }
  }
}
