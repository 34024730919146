import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-delete-grupo',
  templateUrl: './delete-grupo.component.html',
  styleUrls: ['./delete-grupo.component.scss']
})
export class DeleteGrupoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
