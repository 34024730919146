import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-referencia',
  templateUrl: './delete-referencia.component.html',
  styleUrls: ['./delete-referencia.component.scss']
})
export class DeleteReferenciaComponent implements OnInit {

  id: number;
  referencia: string;

  constructor(public dialogRef: MatDialogRef<DeleteReferenciaComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.referencia = data.referencia;
   }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }

}
