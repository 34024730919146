import { BaseModel } from "../../_base/crud";

export class Client extends BaseModel {
  id: number;
  nombre: string;
  razonSocial: string;
  NIF: string;
  personaContacto: string;
  password: string;
  passwordConf: string;
  direccion: string;
  CP: string;
  municipio: string;
  provincia: string;
  pais:number;
  telefono: string;
  email: string;
  web: string;
  tipo: number;
  accessToken: string;
  permitirPrecios: number;

  clear(): void {
    this.id = undefined;
    this.nombre = '';
    this.razonSocial = '';
    this.NIF = '';
    this.personaContacto = '';
    this.password = '';
    this.direccion = '';
    this.CP = '';
    this.municipio = '';
    this.provincia = '';
    this.pais = 0;
    this.telefono = '';
    this.email = '';
    this.web = '';
    this.tipo = 0;
    this.passwordConf = '';
    this.accessToken = '';
    this.permitirPrecios = 0;
  }
}
