import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/auth/user.service';
@Component({
  selector: 'kt-footer-dn',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public userService: UserService) { }

  ngOnInit() {
  }

}
