import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Descarga } from '../../../../core/auth/_models/descarga.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from "../../../../core/_base/layout";
@Component({
    selector: 'kt-descarga',
    templateUrl: './descarga.component.html',
    styleUrls: ['./descarga.component.scss']
})
export class DescargaComponent implements OnInit, OnDestroy {

    descarga: Descarga;
    descargaId: Observable<number>;
    descargaForm: FormGroup;
    hasFormErrors = false;
    selectedTab = 0;
    loadingFile = false;
    imagenesDescarga = [];
    orden = 0;
    constructor(
        public _DomSanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private subheaderService: SubheaderService,
        private api: ApiService,
        private header: HeaderService,
        private footer: FooterService,
        private cdRef: ChangeDetectorRef,
        public ts: TranslationService) {
        this.header.hide();
        this.footer.hide();
    }



    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            this.descarga = new Descarga();
            this.descarga.clear();
            if (id && id > 0) {
                this.api.readData('descargas', '', 'id = ' + id).subscribe((res: any) => {
                    this.descarga = Object.assign(this.descarga, res.data[0]);
                    console.log(this.descarga);

                    this.initDescarga();

                    if (this.descarga.imagenes !== '') {
                        const parseImagenes = JSON.parse(this.descarga.imagenes);
                        for (const i in parseImagenes) {
                            // console.log(parseImagenes[i]);
                            this.imagenesDescarga.push([parseImagenes[i], this.api.getUrlBase() + "/api/assets/images/descargas/" + this.descarga.codigo + '/' + parseImagenes[i]]);
                        }
                    }
                })
            } else {
                this.api.readData('descargas', '').subscribe((res: any) => {
                    this.orden = res.data.length;
                    this.initDescarga();
                });
                
            }
        });

    }

    initDescarga() {
        this.createForm();
        if (!this.descarga.id) {
            this.subheaderService.setTitle('Crear descarga');
            this.subheaderService.setBreadcrumbs([
                { title: 'Descargas', page: `descargas` },
                { title: 'Crear descarga', page: `descargas/new` }
            ]);
            return;
        }
        this.subheaderService.setTitle('Editar Familia');
        this.subheaderService.setBreadcrumbs([
            { title: 'Descargas', page: `descargas` },
            { title: 'Editar descarga', page: `descargas`, queryParams: { id: this.descarga.id } }
        ]);
    }

    createForm() {
        this.descargaForm = this.fb.group({

            nombre_en: [this.descarga.nombre_en],
            nombre_es: [this.descarga.nombre_es],
            nombre_de: [this.descarga.nombre_de],
            nombre_fr: [this.descarga.nombre_fr],
            codigo: [this.descarga.codigo, Validators.required],
            enlace: [this.descarga.enlace, Validators.required],
            imagenes: [this.descarga.imagenes],
            destacado: [this.descarga.destacado == 1 ? true : false]
        });

    }

    getComponentTitle() {
        let result = 'Crear descarga';
        if (!this.descarga || !this.descarga.id) {
            return result;
        }

        result = `Editar descarga - ${this.descarga.nombre_es}`;
        return result;
    }

    saveDescarga() {
        const newDescarga = Object.assign(this.descarga, this.descargaForm.value);
        newDescarga.destacado = this.descargaForm.controls.destacado.value === true ? 1 : 0;
        newDescarga.imagenes = this.imagenesDescarga;
        
        if (!newDescarga.id) {
            newDescarga.orden = this.orden;
            this.api.createData('descargas', newDescarga).subscribe((res: any) => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/descargasPanel']);
                }
            });
        } else {
            this.api.updateData('descargas', newDescarga).subscribe((res: any) => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/descargasPanel']);
                }
            });
        }

    }
    borrarImagen(imagen) {
        this.imagenesDescarga = this.imagenesDescarga.filter(obj => obj !== imagen);
        this.cdRef.detectChanges();
    }
    preview(files) {

        if (files.length === 0) {
            return;
        }
        // console.log(files);

        for (var _i = 0; _i < files.length; _i++) {
            this.cargarImagenes(files[_i]);
        }
    }
    cargarImagenes(imagen) {
        const mimeType = imagen.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        // console.log(imagen);
        const reader = new FileReader();
        // this.referenciaForm['pic'] = files;
        reader.readAsDataURL(imagen);
        reader.onload = (_event) => {
            const resultado = reader.result;
            var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
            this.imagenesDescarga.push([name, this._DomSanitizer.bypassSecurityTrustUrl(resultado.toString())]);
            this.cdRef.detectChanges();
        }

    }

    updateUrl(event) {
        // event.target.src = '../../../../../assets/img/users/default.jpg';
    }



    ngOnDestroy(): void {
        this.header.toggle();
        this.footer.toggle();
    }

}
