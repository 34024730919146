export class Address {
    id: number;
    country: string;
    province: string;
    town: string;
    postal_code: string;
    street: string;
    extra: string;

    clear() {
        this.id = undefined;
        this.country = '';
        this.province = '';
        this.town = '';
        this.postal_code = '';
        this.street = '';
        this.extra = '';
    }
}
