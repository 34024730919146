import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-precio',
  templateUrl: './delete-precio.component.html',
  styleUrls: ['./delete-precio.component.scss']
})
export class DeletePrecioComponent implements OnInit {

  id: number;
  nombre: string;

  constructor(public dialogRef: MatDialogRef<DeletePrecioComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.nombre = data.nombre;
  }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }

}
