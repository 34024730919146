import { BaseModel } from '../../_base/crud';

export class Disenador extends BaseModel {
      id: number;
      nombre: string;
      descripcion_en: string;
      descripcion_es: string;
      descripcion_de: string;
      descripcion_fr: string;
      orden: number;
      imagenes: string;

      clear(): void {
            this.id = undefined;
            this.nombre = '';
            this.descripcion_en = '';
            this.descripcion_es = '';
            this.descripcion_de = '';
            this.descripcion_fr = '';
            this.orden = 0;
            this.imagenes = '';
      }
}
