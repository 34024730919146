// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Acabado } from '../../../core/auth/_models/acabado.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DeleteAcabadoComponent } from './acabado/delete-acabado/delete-acabado.component';
import { TranslationService } from '../../../core/_base/layout';
@Component({
	selector: 'kt-acabados',
	templateUrl: './acabados.component.html',
	styleUrls: ['./acabados.component.scss']
})
export class AcabadosComponent implements OnInit, OnDestroy {

	acabados: Acabado[] = [];
	dataSource;
	contadorImagenes = [];
	imagenesAcabado = [];
	tmpAcabadosForm = new FormGroup({
		selected: new FormControl()
	});


	displayedColumns: string[] = ['select', 'id', 'grupo', 'codigoColor', 'descripcion_es', 'orden', 'imagen','estado', 'opciones'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	selection = new SelectionModel<Acabado>(true, []);

	constructor(
		private api: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private subheaderService: SubheaderService,
		private matDialog: MatDialog,
		private header: HeaderService,
		private footer: FooterService,
		public ts:TranslationService) {
		this.header.hide();
		this.footer.hide();
	}

	ngOnInit() {

		this.subheaderService.setTitle('Sub Familias');

		this.api.readData('acabados').subscribe((res: any) => {
			for (const o of res.data) {
				this.acabados.push(o);
				this.contadorImagenes[o.id] = "-";
				if (o.imagenes !== '' && o.imagenes !== 'NULL') {
					const parseImagenes = JSON.parse(o.imagenes);
					if (parseImagenes && parseImagenes.length) {
						this.contadorImagenes[o.id] = parseImagenes.length;
					}
					this.imagenesAcabado[o.id] = [];
					for (const i in parseImagenes) {
						// console.log(parseImagenes[i]);
						this.imagenesAcabado[o.id].push([this.api.getUrlBase() + "/api/assets/images/acabados/" + o.grupo + o.codigoColor + '/' + parseImagenes[i]]);
					}

				}



			}
			this.dataSource = new MatTableDataSource(this.acabados);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;

		});

	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.acabados.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.acabados.length) {
			this.selection.clear();
		} else {
			this.acabados.forEach(row => this.selection.select(row));
		}
	}

	fetchAcabados() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.descripcion_es}, ${elem.codigoColor}`,
				id: elem.id.toString(),
				status: elem.descripcion_es
			});
		});
		console.log(messages);

	}

	editAcabado(id: number) {
		this.router.navigate(['../acabados/', id], { relativeTo: this.activatedRoute });
	}


	deleteAcabado(id: number) {

		let acabado = this.acabados.find(i => i.id === id);
		const dialogConfig: MatDialogConfig = {
			autoFocus: true,
			maxWidth: '400px',
			data: {
				id: acabado.id,
				descripcion_es: acabado.descripcion_es
			}
		}
		const dialogRef = this.matDialog.open(DeleteAcabadoComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {

				this.api.readData('subfamilias').subscribe((res: any) => {

					for (const o of res.data) {
						var existe = false;

						if (o.acabados && o.acabados !== '') {
							const jsonAcabados = JSON.parse(o.acabados);
							if (jsonAcabados.selected.length != 0) {
								if (jsonAcabados.selected.includes(id)) {
									existe = true;
									const index = jsonAcabados.selected.indexOf(id);
									if (index > -1) {
										jsonAcabados.selected.splice(index, 1);
									}
									o.acabados = JSON.stringify(jsonAcabados);
								}
							}
						}
						if (o.acabados2 && o.acabados2 !== '') {
							const jsonAcabados2 = JSON.parse(o.acabados2);
							if (jsonAcabados2.selected.length != 0) {
								if (jsonAcabados2.selected.includes(id)) {
									existe = true;
									const index = jsonAcabados2.selected.indexOf(id);
									if (index > -1) {
										jsonAcabados2.selected.splice(index, 1);
									}
									o.acabados2 = JSON.stringify(jsonAcabados2);
								}
							}
						}
						if (o.acabados3 && o.acabados3 !== '') {
							const jsonAcabados3 = JSON.parse(o.acabados3);
							if (jsonAcabados3.selected.length != 0) {
								if (jsonAcabados3.selected.includes(id)) {
									existe = true;
									const index = jsonAcabados3.selected.indexOf(id);
									if (index > -1) {
										jsonAcabados3.selected.splice(index, 1);
									}
									o.acabados3 = JSON.stringify(jsonAcabados3);
								}
							}
						}

						if (existe) {
							this.api.updateData("subfamilias", o).subscribe((res: any) => {
							});
						}
					}
				});

				this.api.readData('referencias').subscribe((res: any) => {

					for (const o of res.data) {
						var existe = false;

						if (o.acabados && o.acabados !== '') {
							const jsonAcabados = JSON.parse(o.acabados);
							if (jsonAcabados.selected.length != 0) {
								if (jsonAcabados.selected.includes(id)) {
									existe = true;
									const index = jsonAcabados.selected.indexOf(id);
									if (index > -1) {
										jsonAcabados.selected.splice(index, 1);
									}
									o.acabados = JSON.stringify(jsonAcabados);
								}
							}
						}

						if (existe) {
							this.api.updateData('referencias', o).subscribe((res: any) => {
							});
						}
					}
				});

				this.api.deleteData('acabados', res).subscribe((res: any) => {
					if (res) {
						this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
					}
				});


			}
		});



	}


	ngOnDestroy(): void {
		this.header.toggle();
	}
}
