export class MenuConfig {
      public defaults: any = {
            aside: {
                  self: {},
                  items: [
                        {
                              title: 'Variables globales',
                              root: true,
                              icon: 'flaticon2-architecture-and-city',
                              page: 'variables-globales',
                              bullet: 'dot',
                        },
                        {
                              title: 'Nosotros',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'nosotros',
                              bullet: 'dot',
                        },
                        {
                              title: 'Usuarios',
                              root: true,
                              icon: 'flaticon-users-1',
                              page: 'usuarios',
                              bullet: 'dot',
                        },
                        {
                              title: 'Clientes',
                              root: true,
                              icon: 'flaticon-customer',
                              page: 'clientes',
                              bullet: 'dot',
                        },
                        { section: 'Colecciones' },
                        /*
                        {
                              title: 'Familias',
                              root: true,
                              icon: 'flaticon2-architecture-and-city',
                              page: 'familias',
                              translate: 'MENU.FAMILIES',
                              bullet: 'dot',
                        },
                        */
                        {
                              title: 'Colecciones',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'subfamilias',
                              bullet: 'dot',
                        },
                        {
                              title: 'Diseñadores',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'disenadores',
                              bullet: 'dot',
                        },
                        {
                              title: 'Referencias',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'referencias',
                              bullet: 'dot',
                        },
                        {
                              title: 'Grupos',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'grupos',
                              bullet: 'dot',
                        },
                        {
                              title: 'Acabados',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'acabados',
                              bullet: 'dot',
                        },
                        {
                              title: 'Precios',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'precios',
                              bullet: 'dot',
                        },
                        {
                              title: 'Categorías',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'categorias',
                              bullet: 'dot',
                        },
                        {
                              title: 'Tipos Referencias',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'tiposReferencias',
                              bullet: 'dot',
                        },
                        {
                              title: 'Comerciales',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'comerciales',
                              bullet: 'dot',
                        },
                        {
                              title: 'Descargas Generales',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'descargasPanel',
                              bullet: 'dot',
                        },
                        { section: 'Proyectos' },
                        {
                              title: 'Proyectos',
                              root: true,
                              icon: 'flaticon2-architecture-and-city',
                              page: 'proyectos',
                              bullet: 'dot',
                        },
                        {
                              title: 'Categorias proyectos',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'catProyectos',
                              bullet: 'dot',
                        },
                        { section: 'Estadísticas' },
                        /*
                        {
                              title: 'Listado pedidos',
                              root: true,
                              icon: 'flaticon2-delivery-truck',
                              page: 'pedidos',
                              translate: 'MENU.ORDERS',
                              bullet: 'dot',
                        }
                        ,*/ {
                              title: 'Pedidos',
                              root: true,
                              icon: 'flaticon2-delivery-truck',
                              page: 'estadisticas',
                              bullet: 'dot',
                        },
                        {
                              title: 'Descargas',
                              root: true,
                              icon: 'flaticon2-delivery-truck',
                              page: 'estadisticasDescargas',
                              bullet: 'dot',
                        },
                        { section: 'Distribuidores' },
                        {
                              title: 'Distribuidores',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'distribuidores',
                              bullet: 'dot',
                        }
                        , {
                              title: 'Localizaciones',
                              root: true,
                              icon: 'flaticon2-layers',
                              page: 'localizaciones',
                              bullet: 'dot',
                        },
                  ]
            },
      };

      public get configs(): any {
            return this.defaults;
      }
}
