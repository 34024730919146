// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { ClientesComponent } from './clientes/clientes.component';
import { PedidosComponent } from './pedidos/pedidos.component';

import { UsuarioComponent } from './usuarios/usuario/usuario.component';
import { ClienteComponent } from './clientes/cliente/cliente.component';
import { ReferenciaComponent } from './referencias/referencia/referencia.component';
import { ReferenciasComponent } from './referencias/referencias.component';
import { PedidoComponent } from './pedidos/pedido/pedido.component';

import { DeleteUserComponent } from './usuarios/usuario/delete-user/delete-user.component';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MAT_DATE_LOCALE } from '@angular/material';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DeleteClienteComponent } from './clientes/cliente/delete-cliente/delete-cliente.component';
import { FamiliasComponent } from './familias/familias.component';
import { FamiliaComponent } from './familias/familia/familia.component';
import { DeleteFamiliaComponent } from './familias/familia/delete-familia/delete-familia.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeleteReferenciaComponent } from './referencias/referencia/delete-referencia/delete-referencia.component';
import { AcabadosComponent } from './acabados/acabados.component';
import { AcabadoComponent } from './acabados/acabado/acabado.component';
import { DeleteAcabadoComponent } from './acabados/acabado/delete-acabado/delete-acabado.component';
import { DeletePedidoComponent } from './pedidos/pedido/delete-pedido/delete-pedido.component';
import { SelectAutocompleteModule } from 'mat-select-autocomplete';
import { SubfamiliasComponent } from './subfamilias/subfamilias.component';
import { SubfamiliaComponent } from './subfamilias/subfamilia/subfamilia.component';
import { DeleteSubfamiliaComponent } from './subfamilias/subfamilia/delete-subfamilia/delete-subfamilia.component';
import { PreciosReferenciasComponent } from './precios-referencias/precios-referencias.component';
import { CategoriasComponent } from './categorias/categorias.component';
import { CategoriaComponent } from './categorias/categoria/categoria.component';
import { GruposComponent } from './grupos/grupos.component';
import { GrupoComponent } from './grupos/grupo/grupo.component';
import { DeleteCategoriaComponent } from './categorias/categoria/delete-categoria/delete-categoria.component';
import { DescargaComponent } from './descargas-panel/descarga/descarga.component';
import { DescargasPanelComponent } from './descargas-panel/descargas-panel.component';
import { ComercialesComponent } from './comerciales/comerciales.component';
import { ComercialComponent } from './comerciales/comercial/comercial.component';
import { DeleteComercialComponent } from './comerciales/comercial/delete-comercial/delete-comercial.component';
import { EstadisticasComponent } from './estadisticas/estadisticas.component';
import { TiposReferenciasComponent } from './tipos-referencias/tipos-referencias.component';
import { TipoReferenciaComponent } from './tipos-referencias/tipo-referencia/tipo-referencia.component';
import { DeleteTipoReferenciaComponent } from './tipos-referencias/tipo-referencia/delete-tipo-referencia/delete-tipo-referencia.component';
import { DeletePrecioComponent } from './precios-referencias/precio/delete-precio/delete-precio.component';
import { DisenadoresComponent } from './disenadores/disenadores.component';
import { DisenadorComponent } from './disenadores/disenador/disenador.component';
import { DeleteDisenadorComponent } from './disenadores/disenador/delete-disenador/delete-disenador.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { ProyectoComponent } from './proyectos/proyecto/proyecto.component';
import { DeleteProyectoComponent } from './proyectos/proyecto/delete-proyecto/delete-proyecto.component';
import { CategoriasProyectosComponent } from './categorias-proyectos/categorias-proyectos.component';
import { CategoriaProyectoComponent } from './categorias-proyectos/categoria-proyecto/categoria-proyecto.component';
import { DeleteCategoriaProyectoComponent } from './categorias-proyectos/categoria-proyecto/delete-categoria-proyecto/delete-categoria-proyecto.component';
import { HomePanelComponent } from './home-panel/home-panel.component';
import { DistribuidoresComponent } from './distribuidores/distribuidores.component';
import { LocalizacionesComponent } from './localizaciones/localizaciones.component';
import { DistribuidorComponent } from './distribuidores/distribuidor/distribuidor.component';
import { LocalizacionComponent } from './localizaciones/localizacion/localizacion.component';
import { DeleteLocalizacionComponent } from './localizaciones/localizacion/delete-localizacion/delete-localizacion.component';
import { DeleteDistribuidorComponent } from './distribuidores/distribuidor/delete-distribuidor/delete-distribuidor.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { DeleteDescargasComponent } from './descargas-panel/descarga/delete-descargas/delete-descargas.component';
import { EstadisticasDescargasComponent } from './estadisticas-descargas/estadisticas-descargas.component';
import { DeleteGrupoComponent } from './grupos/grupo/delete-grupo/delete-grupo.component';
@NgModule({
   declarations: [
      UsuariosComponent,
      DeleteGrupoComponent,
      ClientesComponent,
      ReferenciasComponent,
      PedidosComponent,
      UsuarioComponent,
      ClienteComponent,
      ReferenciaComponent,
      PedidoComponent,
      DeleteUserComponent,
      DeleteClienteComponent,
      FamiliasComponent,
      FamiliaComponent,
      DeleteFamiliaComponent,
      DeleteReferenciaComponent,
      AcabadosComponent,
      AcabadoComponent,
      DeleteAcabadoComponent,
      DeletePedidoComponent,
      SubfamiliasComponent,
      SubfamiliaComponent,
      DeleteSubfamiliaComponent,
      PreciosReferenciasComponent,
      CategoriasComponent,
      CategoriaComponent,
      DeleteCategoriaComponent,
      DescargaComponent,
      DescargasPanelComponent,
      ComercialesComponent,
      ComercialComponent,
      DeleteComercialComponent,
      EstadisticasComponent,
      TiposReferenciasComponent,
      TipoReferenciaComponent,
      DeleteTipoReferenciaComponent,
      DeletePrecioComponent,
      DisenadoresComponent,
      DisenadorComponent,
      DeleteDisenadorComponent,
      ProyectosComponent,
      ProyectoComponent,
      DeleteProyectoComponent,
      CategoriasProyectosComponent,
      CategoriaProyectoComponent,
      DeleteCategoriaProyectoComponent,
      HomePanelComponent,
      DistribuidoresComponent,
      LocalizacionesComponent,
      DistribuidorComponent,
      LocalizacionComponent,
      DeleteLocalizacionComponent,
      DeleteDistribuidorComponent,
      AboutUsComponent,
      DeleteDescargasComponent,
      EstadisticasDescargasComponent,
      GruposComponent,
      GrupoComponent
   ],
   exports: [],
   imports: [
      CommonModule,
      HttpClientModule,
      FormsModule,
      CoreModule,
      PartialsModule,
      MatTableModule,
      MatFormFieldModule,
      MatInputModule,
      RouterModule,
      MatIconModule,
      MatButtonModule,
      MatPaginatorModule,
      MatSortModule,
      MatCheckboxModule,
      MatMenuModule,
      MatTabsModule,
      MatProgressBarModule,
      MatDialogModule,
      MatDatepickerModule,
      MatRadioModule,
      MatProgressSpinnerModule,
      ReactiveFormsModule,
      MatSelectModule,
      SelectAutocompleteModule
   ],
   providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
   entryComponents: [DeleteUserComponent,
      DeleteClienteComponent,
      DeleteFamiliaComponent,
      DeleteReferenciaComponent,
      DeleteSubfamiliaComponent,
      DeleteComercialComponent,
      DeleteAcabadoComponent,
      DeleteTipoReferenciaComponent,
      DeletePrecioComponent,
      DeleteDisenadorComponent,
      DeleteCategoriaProyectoComponent,
      DeleteProyectoComponent,
      DeleteLocalizacionComponent,
      DeleteDistribuidorComponent,
      DeleteDescargasComponent
   ]

})
export class PagesModule {
}
