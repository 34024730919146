import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../../services/api/api.service';
import { Subfamilia } from '../../core/auth/_models/subfamilia.model';
import { Referencia } from '../../core/auth/_models/referencia.model';
import { Pedido } from '../../core/auth/_models/pedido.model';
import { UserService } from '../../services/auth/user.service';
import _ from 'lodash';

@Component({
   selector: 'kt-configurador',
   templateUrl: './configurador.component.html',
   styleUrls: ['./configurador.component.scss'],
})
export class ConfiguradorComponent implements OnInit {
   @Input() modo;
   @Output() emmiterImgProductFinished = new EventEmitter();
   subfamilia: Subfamilia;
   @Input() id = '';
   productFinished: any;
   productFinishedAcabados = [];
   productFinishedImgAcabado = [];
   productFinishedAcabadoSelected = 0;
   productFinishedSvg:any;
   pedidoForm: FormGroup;
   codigoFamilia: string;
   referenciasFamiliaIds = [];
   acabados = [];
   acabadosImg = [];
   referenciasFamiliaImgSvg = [];
   referenciasFamiliaImg = [];
   referenciasRelacionadas = [];
   referenciasAcabados = [];
   referenciasAcabados2 = [];
   referenciasAcabados3 = [];
   referenciasFamilia = [];
   cestaPrev = [];
   acabadosFamilia;
   acabados2Familia;
   acabados3Familia;
   referenciasRElFamilia;
   referenciasSelect = [];
   acabadosSelect = [];
   acabados2Select = [];
   acabados3Select = [];
   acabadosRelSelect = [];
   acabadosRelMultSelect = [];
   refRelSelect = [];
   tiposReferencias = [];
   contador = [];
   contadorRel = [];
   contadorRelAcabado = [];
   importeTotal = 0;
   importeTotalPrint = '';
   masElementos5 = true;
   AsistenteActualPrincipal = 1;
   AsistenteSituacion5 = [];
   referencias = [];
   referenciasArr = [];
   mostrarPasoAsistente = [];
   resumen = [];
   preciosEspeciales = [];
   preciosPorRef = [];
   acabado1Precio = 0;
   acabado2Precio = 0;
   acabado3Precio = 0;
   currency = '';
   mostrarSiguiente = false;
   mostrarModal = false;
   tituloModal = '';
   imagenModal = '';
   contenidoModal = '';
   tipoModal = 1;
   indConRefRel = false;
   disenadores = [];
   precioPrincipal = 0;
   precioRelacionados = [];
   relacionRef = [];
   importeTotalDesglose = '';
   anadidoCarrito = false;
   idioma: string;
   pedido: Pedido;
   anadirMas = false;
   idPedido = null;
   refSecundariasOpcionales = [];
   refSecundariasPrecio = [];
   cargandoPrecio = false;
   paises = [];
   mostrarImagenDestacada: boolean;
   srcImgDestacada: string;
   hideTopConfigurationInfo: boolean;
   codigoReferencia = '';
   constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private api: ApiService,
      private cdRef: ChangeDetectorRef,
      private sanitizer: DomSanitizer,
      private fb: FormBuilder,
      public translate: TranslateService,
      public userService: UserService
   ) {
      this.idioma = translate.currentLang;
      translate.onLangChange.subscribe((event: LangChangeEvent) => {
         this.idioma = event.lang;
      });
      this.mostrarImagenDestacada = false;
      this.srcImgDestacada = '../../../assets/img/product-default.png';
   }

   async ngOnInit() {

      this.userService.loggedChange.subscribe(value => {
         if (value === true && !this.cargandoPrecio) {
            this.totalizar();
         }
      });

      this.pedido = new Pedido();
      this.activatedRoute.params.subscribe((params) => {
         if (this.modo === 'product-finished'){
            this.codigoReferencia = params.name;
         }else{
            this.codigoFamilia = params.familia || params.name;
            this.hideTopConfigurationInfo = params.familia ? false : true;
         }
         
      });

      this.inicializarAsistente();
      this.api.readData('disenadores').subscribe((res: any) => {
         for (const o of res.data) {
            this.disenadores[o.id] = o;
         }
   
      });

      this.api.readData('acabados').subscribe((res: any) => {
         for (const o of res.data) {
            this.acabados[o.id] = o;
            if (o.imagenes !== '') {
               const parseImagenes = JSON.parse(o.imagenes);
               for (const i in parseImagenes) {
                  if (parseImagenes[i]) {
                     this.acabadosImg[o.id] = [];
                     this.acabadosImg[o.id].push(this.api.getUrlBase() +
                     '/api/assets/images/acabados/' + o.grupo + o.codigoColor + '/' + parseImagenes[i]);
                  }
               }
            }
         }

         if (this.productFinishedAcabadoSelected !== 0 && this.acabados.length > 0 && this.importeTotal === 0 && this.modo === 'product-finished'){
            this.totalizarProductFinished();
         }
      });
   }

   async getImage(url) {
      return await this.api.readSVG(url);
   }
   

   async cargarDatosRefRelArray(referenciasRel, idReferencia) {
         for (const i in referenciasRel.selected) {
            if (referenciasRel.selected[i]) {
               await this.cargarDatosRefRel(referenciasRel.selected[i], idReferencia);
            }
         }
   }

   cargarDatosRefRel(idRefRel, idReferencia) {

      this.api.readData('referencias', '', 'id = ' + idRefRel)
         .subscribe((resRel: any) => {
            // console.log(resRel['data']);
            for (const oRel of resRel.data) {
               // console.log(oRel);
               this.contadorRel[oRel.tipo][oRel.id] = 0;
               this.contadorRelAcabado[oRel.tipo][oRel.id] = [];

               if (this.relacionRef !== undefined &&
                  this.relacionRef[idReferencia] != null && this.relacionRef[idReferencia].hasOwnProperty(oRel.id)) {
                  if (!Array.isArray(this.referenciasRelacionadas[oRel.tipo][idReferencia])) {
                     this.referenciasRelacionadas[oRel.tipo][idReferencia] = [];
                  }

                  this.referenciasRelacionadas[oRel.tipo][idReferencia].push(oRel);
               }
               // console.log(oRel.imagenes, "oRel.imagenes");
               if (oRel.imagenes !== '') {
                  const parseImagenes = JSON.parse(oRel.imagenes);

                  for (const i in parseImagenes) {
                     if (parseImagenes[i]) {
                        const ext = parseImagenes[i]
                           .split('.')
                           .pop();
                        if (ext === 'svg') {
                           this.getImage('/api/assets/images/referencias/' + oRel.codigo + '/' + parseImagenes[i]
                           ).then((imagen) => {
                              this.referenciasFamiliaImgSvg[oRel.id] = this.sanitizer.bypassSecurityTrustHtml(
                                 imagen.replace('<svg', '<svg style="width:' + oRel.tamanoSvg + '; height: auto;"')
                              );
                              this.cdRef.detectChanges();
                           });
                        } else {
                           this.referenciasFamiliaImg[oRel.id] =
                              this.api.getUrlBase() + '/api/assets/images/referencias/' + oRel.codigo + '/' + parseImagenes[i];
                        }
                     }
                  }
               }

               if (oRel.acabados !== '') {
                  const parseAcabados = JSON.parse(oRel.acabados);
                  this.referenciasAcabados[oRel.id] = parseAcabados;
               } else {
                  this.referenciasAcabados[oRel.id] = { selected: [] };
               }

            }
         });
   }

   cerrarModal() {
      this.mostrarModal = false;
   }

   cargarModal(tipo: number, dato1 = '', dato2, dato3 = '') {

      this.mostrarModal = true;
      this.tituloModal = dato1;
      this.imagenModal = dato2;
      this.contenidoModal = dato3;
      this.tipoModal = tipo;

      this.cdRef.detectChanges();
   }

   incrDecrSelecc(id, tipo: number, subtipo: number, numero: number) {
      this.importeTotal = 0;
      if (this.userService.logged && this.userService.paises[this.userService.cliente.pais].precios == 1 || this.userService.logged && this.userService.cliente.permitirPrecios == 1 ){
         this.importeTotalPrint = this.userService.formatCurrency(0);
      }else{
         this.importeTotalPrint = this.translate.instant('CONFIGURADOR.REQUESTPRICE');
      }

      if (tipo == 1) {
         this.indConRefRel = false;
         for (var key in this.contador[tipo]) {
            this.acabadosSelect = [];
            this.acabados2Select = [];
            this.acabados3Select = [];
            if (key == id) {
               this.contador[tipo][key] = this.contador[tipo][key] + numero;
               if (this.contador[tipo][key] <= 0) {
                  this.contador[tipo][key] = 0;
                  this.mostrarSiguiente = false;
               }
               if (this.contador[tipo][key] > 0) {
                  this.mostrarSiguiente = true;
               }
            } else {
               this.contador[tipo][key] = 0;

            }
         }

         if (typeof this.referenciasAcabados[id] !== "undefined" && this.referenciasAcabados[id].selected.length > 0) {
            let orderAcabado1 = [];
            for (let acabado of this.referenciasAcabados[id].selected) {
               orderAcabado1.push(this.acabados[acabado]);
            }
            orderAcabado1.sort(function (a, b) {
               return a.orden - b.orden;
            });

            for (let acabado of orderAcabado1) {
               this.acabadosSelect.push(acabado.id);
            }
         }

         if (typeof this.referenciasAcabados2[id] !== "undefined" && this.referenciasAcabados2[id].selected.length > 0) {
            let orderAcabado2 = [];
            for (let acabado of this.referenciasAcabados2[id].selected) {
               orderAcabado2.push(this.acabados[acabado]);
            }

            orderAcabado2.sort(function (a, b) {
               return a.orden - b.orden;
            });

            for (let acabado of orderAcabado2) {
               this.acabados2Select.push(acabado.id);
            }
         }

         if (typeof this.referenciasAcabados3[id] !== "undefined" && this.referenciasAcabados3[id].selected.length > 0) {
            let orderAcabado3 = [];
            for (let acabado of this.referenciasAcabados3[id].selected) {
               orderAcabado3.push(this.acabados[acabado]);
            }

            orderAcabado3.sort(function (a, b) {
               return a.orden - b.orden;
            });

            for (let acabado of orderAcabado3) {
               this.acabados3Select.push(acabado.id);
            }
         }

         for (let tipoReferencia of this.tiposReferencias) {
            if (
               this.referenciasRelacionadas[tipoReferencia.id][id] != undefined
            ) {
               this.refRelSelect[tipoReferencia.id] = this.referenciasRelacionadas[tipoReferencia.id][id];
               this.indConRefRel = true;
            } else {
               this.refRelSelect[tipoReferencia.id] = [];
            }
         }
      } else {
         this.contador[tipo] = id;
         this.mostrarSiguiente = true;
      }

      this.totalizar();
      this.cdRef.detectChanges();

   }


   obtenerYSumarImporte(idReferencia: number, idAcabado: number, idAcabado2: number, idAcabado3: number, cantidad: number, tipo: number) {

      let codReferencia: string;
      let codRefAcabado: string;
      let codRefAcabado2: string;
      let codRefAcabado3: string;
      let referencia: Referencia;
      let referenciaPrecioEsp: number;
      if (this.modo === 'product-finished'){
         referencia = this.productFinished;
         codReferencia = this.productFinished.codigo;
      }else{
         referencia = this.referencias.find((el) => el.id == idReferencia);
         codReferencia = referencia.codigo;
      }

      if (idAcabado && this.acabado1Precio == 1) {
         codRefAcabado = this.acabados[idAcabado].grupo;
         if (idAcabado2 > 0 && codRefAcabado.length == 2) {
            if (this.acabado2Precio == 1) {
               codRefAcabado = codRefAcabado.charAt(0);
            } else {
               codRefAcabado = codRefAcabado;
            }
         }
      } else {
         if (referencia.codigo.length < 8) {
            codRefAcabado = "0";
         } else {
            codRefAcabado = "";
         }
      }

      if (idAcabado2 > 0 && this.acabado2Precio == 1) {
         codRefAcabado2 = this.acabados[idAcabado2].grupo;
      } else {
         if (idAcabado && this.acabado1Precio == 1) {
            if (codRefAcabado.length == 1) {
               codRefAcabado = codRefAcabado + 0;
               codRefAcabado2 = "";
            } else {
               codRefAcabado2 = "";
            }
         } else {
            if (referencia.codigo.length < 8) {
               codRefAcabado2 = "0";
            } else {
               codRefAcabado2 = "";
            }
         }
      }

      if (idAcabado3 > 0 && this.acabado3Precio == 1) {
         codRefAcabado3 = this.acabados[idAcabado3].grupo;
      } else {
         codRefAcabado3 = "";
      }
      if (this.contador.length > 0){
         this.contador[1].forEach((value, index) => {
            if (value > 0) {
               referenciaPrecioEsp = index;
            }
         });
      }
      //// console.logg("Referencia precio esp:" + referenciaPrecioEsp);
      //// console.logg("Acabado1 Precio especial:" + idAcabado);
      //// console.logg("Acabado2 Precio especial:" + idAcabado2);
      //// console.logg("Acabado3 Precio especial:" + idAcabado3);

      var precioEsp;
      var importePrecioEsp = 0;
      if (this.userService.cliente) {
         if (this.preciosEspeciales.length > 0){
            if (idAcabado > 0) {
               precioEsp = this.preciosEspeciales.find((el) => el.referencia == referenciaPrecioEsp && el.acabado == idAcabado);
               if (precioEsp) {
                  importePrecioEsp += this.userService.cliente.tipo == "1" ? parseInt(precioEsp.importeNac) : parseInt(precioEsp.importeExp);
               }
            }

            if (idAcabado2 > 0) {
               precioEsp = this.preciosEspeciales.find((el) => el.referencia == referenciaPrecioEsp && el.acabado == idAcabado2);
               if (precioEsp) {
                  importePrecioEsp += this.userService.cliente.tipo == "1" ? parseInt(precioEsp.importeNac) : parseInt(precioEsp.importeExp);
               }
            }

            if (idAcabado3 > 0) {
               precioEsp = this.preciosEspeciales.find((el) => el.referencia == referenciaPrecioEsp && el.acabado == idAcabado3);
               if (precioEsp) {
                  importePrecioEsp += this.userService.cliente.tipo == "1" ? parseInt(precioEsp.importeNac) : parseInt(precioEsp.importeExp);
               }
            }
         }

         console.log(this.preciosEspeciales);
         console.log("Búsqueda:");
         console.log("codReferencia = " + codReferencia);
         console.log("codRefAcabado = " + codRefAcabado);
         console.log("codRefAcabado2 = " + codRefAcabado2);
         console.log("A buscar = " + codReferencia + codRefAcabado + codRefAcabado2);
         console.log("TARIFA = " + this.userService.cliente.tipo + ' and CODART = "' + codReferencia + codRefAcabado + codRefAcabado2 + codRefAcabado3 + '"');
         this.cargandoPrecio = true;
         this.cdRef.detectChanges();
         try {
            this.api
               .readData("preciosReferencias", "*", "TARIFA = " + this.userService.cliente.tipo + ' and CODART = "' + codReferencia + codRefAcabado + codRefAcabado2 + codRefAcabado3 + '"', "FECMIN DESC")
               .subscribe((res) => {
                  let contador = 0;
                  for (let o of res["data"]) {
                     if (contador == 0) {
                        let precio = parseInt(o.PRECIO);
                        if (this.userService.logged && this.userService.paises[this.userService.cliente.pais].precios == 1 || this.userService.logged && this.userService.cliente.permitirPrecios == 1 ){
                           this.importeTotalDesglose = this.importeTotalDesglose + codReferencia + codRefAcabado + codRefAcabado2 + ' : ' + this.userService.formatCurrency(precio) + "<br>";
                        }else{
                           this.importeTotalDesglose = this.importeTotalDesglose + codReferencia + codRefAcabado + codRefAcabado2 + '<br>' ;
                        }

                        if (importePrecioEsp > 0) {
                           if (this.userService.logged && this.userService.paises[this.userService.cliente.pais].precios == 1 || this.userService.logged && this.userService.cliente.permitirPrecios == 1 ){
                              this.importeTotalDesglose = this.importeTotalDesglose + 'Extra : ' + this.userService.formatCurrency(importePrecioEsp) + "<br>";
                              document.getElementById('importeTotalPrint').innerHTML = this.importeTotalDesglose;
                           }
                           
                           precio += importePrecioEsp;
                        }

                        this.preciosPorRef[codReferencia] = { referencia: codReferencia + codRefAcabado + codRefAcabado2, importe: precio };
                        if (cantidad > 0) {
                           this.importeTotal += cantidad * precio;
                           if (this.userService.logged && this.userService.paises[this.userService.cliente.pais].precios == 1 || this.userService.logged && this.userService.cliente.permitirPrecios == 1 ){
                              this.importeTotalPrint = this.userService.formatCurrency(this.importeTotal);
                           }else{
                              this.importeTotalPrint = this.translate.instant('CONFIGURADOR.REQUESTPRICE');
                           }
                           this.cdRef.detectChanges();
                        }
                     }
                     contador++;
                  }
                  this.cargandoPrecio = false;
                  this.cdRef.detectChanges();
               });
         } catch (error) {
            this.cargandoPrecio = false;
            this.cdRef.detectChanges();
         }

      }
   }

   importeFormMoneda(importe) {
      return importe.toLocaleString('de-DE', {
         maximumFractionDigits: 2,
         minimumFractionDigits: 2,
      });
   }

   totalizar() {
      // console.clear();
      // console.log("-- ENTRA EN TOTALIZAR --");
      this.importeTotalDesglose = '';
      this.importeTotal = 0;
      let refPrincipal = '';
      let refPrincipalCant = '';
      let refPrincipalAc = '';
      let refPrincipalAc2 = '';
      let refPrincipalAc3 = '';

      for (const key in this.contador[1]) {
         if (this.contador[1][key]) {
            const value = this.contador[1][key];
            if (value > 0) {
               refPrincipal = key;
               refPrincipalCant = value;
            }
         }
      }
      if (refPrincipal !== '') {
         if (this.contador[2] > 0) {
            refPrincipalAc = this.contador[2];
         }
         // console.log(this.contador[3]);
         if (this.contador[3] > 0) {
            refPrincipalAc2 = this.contador[3];
         } else {
            refPrincipalAc2 = '0';
         }
         if (this.contador[4] > 0) {
            refPrincipalAc3 = this.contador[4];
         } else {
            refPrincipalAc3 = '0';
         }

         this.obtenerYSumarImporte(Number(refPrincipal), Number(refPrincipalAc),
         Number(refPrincipalAc2), Number(refPrincipalAc3), Number(refPrincipalCant), 1);

         // console.log(this.contadorRel);
         for (const tipoReferencia of this.tiposReferencias) {
            for (const key2 in this.contadorRel[tipoReferencia.id]) {
               if (this.contadorRel[tipoReferencia.id][key2] > 0) {
                  const referenciaAcabado2 = '';
                  let referenciaAcabado = '';

                  if (this.contadorRelAcabado[tipoReferencia.id][key2] > 0) {
                     referenciaAcabado = this.contadorRelAcabado[tipoReferencia.id][key2];
                  }
                  const referenciaTemp = key2;
                  if (!this.refSecundariasPrecio.includes(key2)) {
                     this.obtenerYSumarImporte(Number(referenciaTemp),
                     Number(referenciaAcabado), 0, 0, Number(this.contadorRel[tipoReferencia.id][key2]), 2);
                  }
               }
            }
         }
      }
   }

   selectSubTipo(id, tipo, numero) {

      this.acabadosRelSelect[tipo] = [];

      const orderAcabado1 = [];
      for (const acabado of this.referenciasAcabados[id].selected) {
         orderAcabado1.push(this.acabados[acabado]);
      }
      orderAcabado1.sort((a, b) => {
         return a.orden - b.orden;
      });

      for (const acabado of orderAcabado1) {
         this.acabadosRelSelect[tipo].push(acabado.id);
      }

      for (const key in this.contadorRel[tipo]) {
         if (key === id) {
            if (this.contadorRel[tipo][key] > 0 || numero === 1) {
               this.contadorRel[tipo][key] = this.contadorRel[tipo][key] + numero;
            }
            if (this.acabadosRelSelect[tipo].length) { this.AsistenteActualPrincipal = 4; }
         } else {
            if (this.subfamilia.multiSelectRel === 0) { this.contadorRel[tipo][key] = 0; }
         }
      }

      this.totalizar();
   }

   selectAcabadoSubTipo(tipo, idAcabado) {
      for (const key in this.contadorRel[tipo]) {
         if (this.contadorRel[tipo][key] > 0) {
            this.contadorRelAcabado[tipo][key] = idAcabado;
         }
      }

      this.totalizar();
   }

   situacionAsistente(accion: number) {
      const referenciasConValores = [];

      let actualPrincipal = this.AsistenteActualPrincipal;
      this.masElementos5 = true;

      let contador = 0;
      for (const tipoReferencia of this.tiposReferencias) {
         if (this.refRelSelect[tipoReferencia.id].length > 0) {

            contador++;
            referenciasConValores[contador] = tipoReferencia.id;
         }
      }

      let conRefRel = false;
      for (const tipoReferencia of this.tiposReferencias) {
         if (this.refRelSelect[tipoReferencia.id] !== undefined && this.refRelSelect[tipoReferencia.id].length > 0) {
            conRefRel = true;
         }
      }

      // console.log('this.AsistenteActualPrincipal : ' + this.AsistenteActualPrincipal);
      // console.log('this.AsistenteSituacion5[0] : ' + this.AsistenteSituacion5[0]);
      // console.log('this.AsistenteSituacion5[1] : ' + this.AsistenteSituacion5[1]);

      if (accion === 1) {
         if (this.AsistenteActualPrincipal < 5) {
            if (this.AsistenteActualPrincipal === 1 && this.acabadosSelect.length == 0) {
                  actualPrincipal = 3;
                  this.AsistenteActualPrincipal = 5;
            } else if (this.AsistenteActualPrincipal === 2) {
               if (this.acabados2Select.length > 0) {
                  this.AsistenteActualPrincipal++;
               } else {
                  if (this.acabados3Select.length > 0) {
                     this.AsistenteActualPrincipal++;
                  } else {
                     actualPrincipal = 3;
                     this.AsistenteActualPrincipal = 5;
                  }
               }
            } else if (this.AsistenteActualPrincipal === 3) {
               if (this.acabados3Select.length > 0) {
                  this.AsistenteActualPrincipal++;
               } else {
                  actualPrincipal = 3;
                  this.AsistenteActualPrincipal = 5;
               }
            } else {
               this.AsistenteActualPrincipal++;
            }
         }
         if (this.AsistenteActualPrincipal === 5) {
            this.mostrarPasoAsistente[1] = false;
            this.mostrarPasoAsistente[2] = false;
            this.mostrarPasoAsistente[3] = false;
            this.mostrarPasoAsistente[4] = false;
            if (conRefRel) {
               if (this.AsistenteSituacion5[0] === 0 && this.AsistenteSituacion5[1] === 0) {
                  this.mostrarPasoAsistente[5][referenciasConValores[1]][0] = true;
                  this.AsistenteSituacion5[0] = 1;
               } else if (
                  this.AsistenteSituacion5[0] > 0 &&
                  this.AsistenteSituacion5[1] === 0 &&
                  this.acabadosRelSelect[referenciasConValores[this.AsistenteSituacion5[0]]].length > 0
               ) {

                  this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][0] = false;
                  this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][1] = true;
                  this.AsistenteSituacion5[1] = 1;
                  // console.log("entra en else 1");
               } else if (
                  (this.AsistenteSituacion5[0] > 0 &&
                     this.AsistenteSituacion5[1] === 1) ||
                  this.acabadosRelSelect[referenciasConValores[this.AsistenteSituacion5[0]]].length === 0
               ) {
                  this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][0] = false;
                  this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][1] = false;

                  this.AsistenteSituacion5[1] = 0;
                  this.AsistenteSituacion5[0]++;

                  if (
                     referenciasConValores[this.AsistenteSituacion5[0]] !== undefined
                  ) {
                     while (this.masElementos5 && referenciasConValores[this.AsistenteSituacion5[0]].length === 0) {
                        this.AsistenteSituacion5[0]++;
                        // console.log(this.acabadosRelSelect);
                        if (typeof this.acabadosRelSelect[this.AsistenteSituacion5[0]] === 'undefined') { this.masElementos5 = false; }
                     }
                  } else {
                     this.masElementos5 = false;
                  }
                  if (this.masElementos5) {
                     this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][0] = true;
                     this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][1] = false;
                  } else {
                     this.AsistenteActualPrincipal++;
                     this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = true;
                     this.mostrarSiguiente = false;
                  }
               }
            } else {
               this.AsistenteActualPrincipal++;
               this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = true;
            }
         } else {
            // console.log("entra en 5");
            this.mostrarPasoAsistente[actualPrincipal] = false;
            this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = true;
            this.mostrarSiguiente = false;
         }
         if (this.mostrarPasoAsistente[6]) {
            const seleccion = {
               contador: _.clone(this.contador),
               contadorRel: _.clone(this.contadorRel),
               contadorRelAcabado: _.clone(this.contadorRelAcabado),
               subtotal: _.clone(this.importeTotal),
            };

            this.cestaPrev.push(seleccion);
            // console.log(this.cestaPrev);
         }



      } else {

         this.cestaPrev.pop();
         this.mostrarSiguiente = true;

         if (this.AsistenteActualPrincipal === 5) {

            this.mostrarPasoAsistente[2] = false;
            this.mostrarPasoAsistente[3] = false;

            if (this.AsistenteSituacion5[0] === 1 && this.AsistenteSituacion5[1] === 0) {

               this.AsistenteActualPrincipal--;
               this.mostrarPasoAsistente[5][referenciasConValores[1]][0] = false;

               if (this.acabados3Select.length == 0) {
                  this.AsistenteActualPrincipal--;
               }

               if (this.acabados2Select.length == 0) {
                  this.AsistenteActualPrincipal--;
               }
               if (this.acabadosSelect.length == 0) {
                  this.AsistenteActualPrincipal--;
               }

               this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = true;

               this.AsistenteSituacion5[0] = 0;
            } else if (this.AsistenteSituacion5[0] === 0 && this.AsistenteSituacion5[1] === 1) {

               this.mostrarPasoAsistente[5][referenciasConValores[1]][0] = true;
               this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = false;
               this.AsistenteSituacion5[1] = 0;

            } else if (this.AsistenteSituacion5[0] > 0 && this.AsistenteSituacion5[1] === 0) {

               this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][0] = false;
               this.AsistenteSituacion5[0]--;
               this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][0] = false;
               this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][1] = true;
               this.AsistenteSituacion5[1] = 1;

            } else if (this.AsistenteSituacion5[0] > 0 && this.AsistenteSituacion5[1] === 1) {

               this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][0] = true;
               this.mostrarPasoAsistente[5][referenciasConValores[this.AsistenteSituacion5[0]]][1] = false;
               this.AsistenteSituacion5[1] = 0;
            }
         } else if (this.AsistenteActualPrincipal === 6) {

            this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = false;
            if (conRefRel) {

               this.AsistenteActualPrincipal--;
               this.AsistenteSituacion5[0] = referenciasConValores.length - 1;

               if (this.acabadosRelSelect[referenciasConValores[this.AsistenteSituacion5[0]]].length > 0) {

                  this.mostrarPasoAsistente[5][
                     referenciasConValores[referenciasConValores.length - 1]][0] = false;
                  this.mostrarPasoAsistente[5][referenciasConValores[referenciasConValores.length - 1]][1] = true;
                  this.AsistenteSituacion5[1] = 1;
               } else {

                  this.mostrarPasoAsistente[5][referenciasConValores[referenciasConValores.length - 1]][0] = true;
                  this.mostrarPasoAsistente[5][referenciasConValores[referenciasConValores.length - 1]][1] = false;
                  this.AsistenteSituacion5[1] = 0;
               }
            } else {

               this.AsistenteActualPrincipal = 2;
               this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = true;
            }
         } else {

            this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = false;
            this.AsistenteActualPrincipal--;
            this.mostrarPasoAsistente[this.AsistenteActualPrincipal] = true;
         }
      }

   }

   cargarResumen() { }

   aPulgadas(cm: string) {
      let textoPulgadas = '';
      textoPulgadas = (Number(cm) / 2.55).toFixed(2);
      return textoPulgadas + '\'\'';
   }

   crearItemProducto(itemCesta) {

      const contador = itemCesta.contador;
      const contadorRel = itemCesta.contadorRel;
      const contadorRelAcabado = itemCesta.contadorRelAcabado;
      if (this.modo == 'product-finished'){
         let acabados = [null,[1],this.productFinishedAcabadoSelected];
         const producto = {
            version: 2,
            coleccion: 0,
            referenciaPrincipal: this.productFinished.id,
            cantidad: 1,
            acabados: acabados,
            relacionadas: [],
            importeUnitario: this.importeTotal,
            importeRefPrincipal: this.importeTotal,
            importe: this.importeTotal,
         };
         return producto;
      }else{
         const producto = {
            version: 2,
            coleccion: this.subfamilia.id,
            referenciaPrincipal: '',
            cantidad: 0,
            acabados: [],
            relacionadas: [],
            importeUnitario: 0,
            importeRefPrincipal: 0,
            importe: this.importeTotal,
         };
         if (contador[1]) {
            for (const idReferencia in contador[1]) {
               if (
                  contador[1][idReferencia] !== undefined &&
                  contador[1][idReferencia] > 0
               ) {
                  producto.referenciaPrincipal = idReferencia;
                  producto.cantidad = contador[1][idReferencia];
                  producto.importeUnitario = this.preciosPorRef[this.referenciasArr[idReferencia].codigo].importe;
                  producto.importeRefPrincipal =
                  contador[1][idReferencia] * this.preciosPorRef[this.referenciasArr[idReferencia].codigo].importe;
               }
            }
         }

         const acabados = [];
         if (contador[1]) { acabados[1] = contador[1]; }
         if (contador[2]) { acabados[2] = contador[2]; }
         if (contador[3]) { acabados[3] = this.contador[3]; }
         if (contador[4]) { acabados[4] = this.contador[4]; }

         producto.acabados = acabados;

         const refRelacionadas = [];

         for (const tipoReferencia of this.tiposReferencias) {
            if (contadorRel[tipoReferencia.id].length > 0) {
               for (const ref in contadorRel[tipoReferencia.id]) {
                  if (contadorRel[tipoReferencia.id][ref] !== undefined && contadorRel[tipoReferencia.id][ref] > 0) {
                     const refRel = {
                        idReferencia: ref,
                        cantidad: contadorRel[tipoReferencia.id][ref],
                        precioUnitario: this.preciosPorRef[this.referenciasArr[ref].codigo].importe,
                        precio: this.preciosPorRef[this.referenciasArr[ref].codigo] ?
                        contadorRel[tipoReferencia.id][ref] * this.preciosPorRef[this.referenciasArr[ref].codigo]
                           .importe : 0,
                        acabado: 0,
                     };

                     if (
                        contadorRelAcabado[tipoReferencia.id][ref] !==
                        undefined &&
                        contadorRelAcabado[tipoReferencia.id][ref].length > 0
                     ) {
                        refRel.acabado = contadorRelAcabado[tipoReferencia.id][
                           ref
                        ];
                     }

                     refRelacionadas.push(refRel);
                  }
               }
            }
         }
         producto.relacionadas = refRelacionadas;

         return producto;
      }
   }

   anadirPedido(tipo) {
      if (tipo === 'carrito') {

         if (!this.userService.logged) {
            this.userService.abrirLogin();

         } else {
            if (!this.anadidoCarrito) {
               if (this.modo == 'product-finished'){
                  const seleccion = {
                     contador: 1,
                     contadorRel: 0,
                     contadorRelAcabado: 0,
                     subtotal: _.clone(this.importeTotal),
                  };
      
                  this.cestaPrev.push(seleccion);
               }

               for (const itemCesta of this.cestaPrev) {
                  this.userService.cesta.push(this.crearItemProducto(itemCesta));
               }
               this.userService.cesta.sort((a, b) => {
                  return a.coleccion - b.coleccion;
               });
               // console.log(this.idPedido);
               if (this.userService.idPedido !== null && this.userService.idPedido !== '') {
                  this.pedidoForm = this.fb.group({
                     version: 2,
                     id: this.userService.idPedido,
                     idCliente: [this.userService.cliente.id],
                     productos: [JSON.stringify(this.userService.cesta)],
                     importeTotal: 0,
                     estado: 1,
                  });
                  const newPedido = Object.assign(this.pedido, this.pedidoForm.value);

                  this.api.updateData('pedidos', newPedido).subscribe((res: any) => {
                     if (res) {
                        // console.log("pedido actualizado");
                     }
                  });
               } else {
                  this.pedidoForm = this.fb.group({
                     version: 2,
                     idCliente: [this.userService.cliente.id],
                     productos: [JSON.stringify(this.userService.cesta)],
                     importeTotal: 0,
                     estado: 1,
                  });
                  const newPedido = Object.assign(this.pedido, this.pedidoForm.value);
                  this.api.createData('pedidos', newPedido).subscribe((res: any) => {
                     if (res) {
                        this.userService.checkCart();
                        // console.log('pedido guardado');
                     }
                  });
               }

               this.anadidoCarrito = true;
               this.anadirMas = false;
            }
         }

      } else if (tipo === 'masElementos') {

         // console.log(this.cestaPrev);
         this.inicializarAsistente();
      }
   }

   inicializarAsistente() {
      if (this.modo === 'product-finished'){
            
            this.api
               .readData('referencias', '*', 'id = \"' + this.id + '\"')
               .subscribe((res: any) => {
                  if (res.data.length > 0) {
                     this.productFinished = res.data[0];
                     this.referencias.push(this.productFinished);
                     this.acabado1Precio = 1
                     let relImgAcabados = JSON.parse(this.productFinished.relImagenesAcabados);
                     this.AsistenteActualPrincipal = 6;
                     let counter = 0;
                     if (relImgAcabados){
                        for (let key in relImgAcabados){
                           
                           if (relImgAcabados.hasOwnProperty(key) && relImgAcabados[key] !== 'svg') {
                             counter++;
                             this.productFinishedAcabados.push(relImgAcabados[key]);
                             this.productFinishedImgAcabado[relImgAcabados[key]] = this.api.getUrlBase() + '/api/assets/images/referencias/' + this.productFinished.codigo + '/' + key;
                             if(counter === 1){
                              this.emmiterImgProductFinished.emit(this.productFinishedImgAcabado[relImgAcabados[key]]);
                              this.productFinishedAcabadoSelected = relImgAcabados[key];
                             }
                           }else{
                              this.getImage('/api/assets/images/referencias/' + this.productFinished.codigo + '/' + key
                              ).then((imagen) => {
                                 this.productFinishedSvg = this.sanitizer.bypassSecurityTrustHtml(
                                    imagen.replace('<svg', '<svg style="width:' + this.productFinished.tamanoSvg + '; height: auto;"')
                                 );
                                 this.cdRef.detectChanges();
                              });
                              
                           }
                        }
                        if (this.productFinishedAcabadoSelected !== 0 && this.acabados.length > 0 && this.importeTotal === 0){
                           this.totalizarProductFinished();
                        }
                     }

                  }
               });
      }else{
         this.AsistenteActualPrincipal = 1;
         this.mostrarPasoAsistente[1] = true;
         this.mostrarPasoAsistente[2] = false;
         this.mostrarPasoAsistente[3] = false;
         this.mostrarPasoAsistente[4] = false;
         this.mostrarPasoAsistente[5] = [];
         this.mostrarPasoAsistente[6] = false;

         this.AsistenteSituacion5[0] = 0;
         this.AsistenteSituacion5[1] = 0;

         this.subfamilia = new Subfamilia();
         this.subfamilia.clear();

         this.contador[1] = [];
         this.contador[2] = 0;
         this.contador[3] = 0;
         this.contador[4] = 0;
         this.refRelSelect = [];
         this.referenciasRelacionadas = [];
         this.tiposReferencias = [];

         this.mostrarSiguiente = false;

         this.api.readData('referencias').subscribe((resRel: any) => {

            for (const oRel of resRel.data) {
               this.referencias.push(oRel);
               this.referenciasArr[oRel.id] = oRel;
            }
         });

         this.api.readData('tiposreferencias').subscribe((res: any) => {
            for (const o of res.data) {
               this.referenciasRelacionadas[o.id] = [];
               this.acabadosRelSelect[o.id] = [];
               this.tiposReferencias.push(o);
               this.refRelSelect[o.id] = [];
               this.precioRelacionados[o.id] = 0;

               this.contadorRel[o.id] = [];
               this.contadorRelAcabado[o.id] = [];
               this.mostrarPasoAsistente[5][o.id] = [];
               this.mostrarPasoAsistente[5][o.id][0] = false;
               this.mostrarPasoAsistente[5][o.id][1] = false;
            }

            this.tiposReferencias.sort((a, b) => {
               return a.orden - b.orden;
            });
         });

         if (this.codigoFamilia && this.codigoFamilia !== '') {
            if (this.codigoFamilia && this.codigoFamilia !== '') {
               this.api
                  .readData('subfamilias', '', 'codigo = \"' + this.codigoFamilia + '\"')
                  .subscribe((res: any) => {
                     if (res.data.length > 0) {
                        this.subfamilia = Object.assign(this.subfamilia, res.data[0]);

                        this.acabado1Precio = this.subfamilia.acabado1Precio;
                        this.acabado2Precio = this.subfamilia.acabado2Precio;
                        this.acabado3Precio = this.subfamilia.acabado3Precio;
                        this.anadirMas = this.subfamilia.multiSelectPrin === 1 ? true : false;

                        this.srcImgDestacada = this.api.getUrlBase() +
                        '/api/assets/images/subfamilias/' + this.subfamilia.codigo + '/' + this.subfamilia.img_destacada;

                        if (this.subfamilia.acabados !== '') {
                           const parseAcabados = JSON.parse(this.subfamilia.acabados);
                           this.acabadosFamilia = parseAcabados;
                        }

                        if (this.subfamilia.acabados2 !== '') {
                           const parseAcabados = JSON.parse(this.subfamilia.acabados2);
                           this.acabados2Familia = parseAcabados;
                        }

                        if (this.subfamilia.acabados3 !== '') {
                           const parseAcabados = JSON.parse(this.subfamilia.acabados3);
                           this.acabados3Familia = parseAcabados;
                        }

                        if (this.subfamilia.referenciasRel !== '') {
                           const parseReferenciasRel = JSON.parse(this.subfamilia.referenciasRel);
                           this.referenciasRElFamilia = parseReferenciasRel;
                        }

                        if (this.subfamilia.refRelOpcionales && this.subfamilia.refRelOpcionales !== '') {
                           const jsonRefRelOpcionales = JSON.parse(this.subfamilia.refRelOpcionales);
                           this.refSecundariasOpcionales = jsonRefRelOpcionales;
                        }

                        if (this.subfamilia.refRelPrecio && this.subfamilia.refRelPrecio !== '') {
                           const jsonRefRelPrecio = JSON.parse(this.subfamilia.refRelPrecio);
                           this.refSecundariasPrecio = jsonRefRelPrecio;
                        }

                        if (this.subfamilia.relacionEntreRef && this.subfamilia.relacionEntreRef !== '') {
                           const jsonRelacionRef = JSON.parse(this.subfamilia.relacionEntreRef);
                           this.relacionRef = jsonRelacionRef;
                        }

                        if (this.subfamilia.preciosEspeciales && this.subfamilia.preciosEspeciales !== '') {
                           this.preciosEspeciales = JSON.parse(this.subfamilia.preciosEspeciales);
                        }

                        if (this.subfamilia.referencias !== '') {
                           const parseReferencias = JSON.parse(this.subfamilia.referencias);

                           for (const idRef of parseReferencias.selected) {
                              this.api
                                 .readData('referencias', '', 'id = "' + idRef + '"')
                                 .subscribe((res2: any) => {
                                    for (const o of res2.data) {

                                       const posicion: number = parseReferencias.selected.indexOf(o.id);
                                       this.referenciasFamilia[posicion] = o;

                                       this.referenciasFamiliaIds[o.id] = o;
                                       this.contador[1][o.id] = [];
                                       this.contador[1][o.id] = 0;
                                       if (o.imagenes !== '') {
                                          const parseImagenes = JSON.parse(o.imagenes);
                                          for (const i in parseImagenes) {
                                             if (parseImagenes[i]) {
                                                const ext = parseImagenes[i].split('.').pop();
                                                if (ext === 'svg') {
                                                   this.getImage('/api/assets/images/referencias/' + o.codigo
                                                   + '/' + parseImagenes[i]).then((imagen) => {
                                                      this.referenciasFamiliaImgSvg[o.id] = this.sanitizer.bypassSecurityTrustHtml(
                                                         imagen.replace('<svg', '<svg style="width:' + o.tamanoSvg + '; height: auto;"')
                                                      );

                                                      this.cdRef.detectChanges();
                                                   });
                                                } else {
                                                   this.referenciasFamiliaImg[o.id] = this.api.getUrlBase()
                                                   + '/api/assets/images/referencias/' + o.codigo + '/' + parseImagenes[i];
                                                }
                                             }
                                          }
                                       }

                                       if (this.subfamilia.referenciasRel !== '') {
                                          const parseReferenciasRel = JSON.parse(this.subfamilia.referenciasRel);
                                          this.cargarDatosRefRelArray(parseReferenciasRel, o.id);
                                       }

                                       this.referenciasAcabados[o.id] = this.acabadosFamilia;
                                       this.referenciasAcabados2[o.id] = this.acabados2Familia;
                                       this.referenciasAcabados3[o.id] = this.acabados3Familia;
                                    }

                                    this.cdRef.detectChanges();
                                 });
                           }
                        }
                     }

                  });
            }
         }
      }

   }

   prueba(event: string) {
      // console.log(event);
   }

   totalizarProductFinished() {
      this.importeTotalDesglose = '';
      this.importeTotal = 0;
      this.obtenerYSumarImporte(this.productFinished.id,this.productFinishedAcabadoSelected,0,0,1,1);
   }

   irA(pagina) {
      this.router.navigate([pagina]);
   }

   imagenDestacada() {
      this.mostrarImagenDestacada ? this.mostrarImagenDestacada = false : this.mostrarImagenDestacada = true;
   }

   changeFinish(idAcabado) {
      this.productFinishedAcabadoSelected = idAcabado;
      this.totalizarProductFinished();
      this.emmiterImgProductFinished.emit(this.productFinishedImgAcabado[idAcabado]);
   }

}
