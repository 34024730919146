import { Pipe, PipeTransform } from '@angular/core';
import { removeAccents } from '../../../../core/helpers/url.helpers';

@Pipe({
  name: 'removeAccents'
})
export class RemoveAccentsPipe implements PipeTransform {

  transform(value: string): string {
    return removeAccents(value);
  }

}
