import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-localizacion',
  templateUrl: './delete-localizacion.component.html',
  styleUrls: ['./delete-localizacion.component.scss']
})
export class DeleteLocalizacionComponent implements OnInit {

  id: number;
  nombre_es: string;

  constructor(public dialogRef: MatDialogRef<DeleteLocalizacionComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.nombre_es = data.nombre_es;
  }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }
}
