import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-proyecto',
  templateUrl: './delete-proyecto.component.html',
  styleUrls: ['./delete-proyecto.component.scss']
})
export class DeleteProyectoComponent implements OnInit {

  id: number;
  nombre: string;

  constructor(public dialogRef: MatDialogRef<DeleteProyectoComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.nombre = data.nombre;
   }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }
}
