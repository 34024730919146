import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Pedido } from '../../core/auth/_models/pedido.model';
import { ApiService } from '../../services/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Home } from '../../core/auth/_models/home.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;



import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { UserService } from '../../services/auth/user.service';

@Component({
  selector: 'kt-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  acabados = [];
  referencias = [];
  subfamilias = [];
  currency = '';
  cestaAdminCliente = {
    nombre: '',
    email: '',
  };
  paises = [];
  cestaAdmin = false;
  pedido: Pedido;
  pedidoForm: FormGroup;
  pedidoFinalizado = false;
  idioma: string;
  print = false;
  comentarios = '';
  logo = '';
  idPedido;
  acabadosImg = [];
  referenciasFamiliaImgSvg = [];
  referenciasFamiliaImg = [];
  tiposReferencias = [];
  total = 0;
  referenciasEnCesta = [];
  home: Home;
  
  constructor(
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.userService.checkCart();
    this.idioma = this.translate.currentLang;
    this.pedido = new Pedido();
    this.pedido.clear();
    this.activatedRoute.params.subscribe((params) => {
      this.api.readData('home', '*', 'id = 1').subscribe((resHome: any) => {
        this.home = resHome.data[0];
      });

      this.api.readImg('assets/img/punt-logo.png').subscribe((resImg: any) => { this.logo = 'data:image/png;base64,' + resImg.data; });
      if (!this.userService.logged && params.idpedido === undefined) {
        this.router.navigate(['./home']);
      } else {

        let filtro = '';
        if (params.idpedido !== undefined) {
          filtro = 'id= ' + params.idpedido ;
        } else {
          filtro = 'estado = 1 and version = 2';
        }

        this.api.readData('pedidos', '*', '', 'fecha DESC').subscribe((resPedido: any) => {
          if (resPedido) {
            this.userService.cesta = JSON.parse(resPedido.data[0].productos);
            this.userService.idPedido = resPedido.data[0].id;

            if (params.idpedido !== undefined && localStorage.getItem('currentUser') == null) {
              this.router.navigate(['./home']);
            }

            this.api.readData('tiposreferencias').subscribe((res: any) => {
              for (const o of res.data) {
                this.tiposReferencias[o.id] = o;
              }
            });

            if (params.idpedido !== undefined) {

              this.api.readData('clientes', 'nombre,razonSocial,email', 'id= '
              + resPedido.data[0].idCliente).subscribe((resCliente: any) => {
                this.cestaAdminCliente = resCliente.data[0];
                this.cestaAdmin = true;
                this.cdRef.detectChanges();
              });
            }

            this.api.readData('subfamilias').subscribe((res2: any) => {
              for (const o2 of res2.data) {
                this.subfamilias[o2.id] = o2;
              }
            });

            for (const producto of this.userService.cesta) {
              this.referenciasEnCesta.push(producto.referenciaPrincipal);
              for (const rel of producto.relacionadas) {
                this.referenciasEnCesta.push(rel.idReferencia);
              }

            }

            for (const ref of this.referenciasEnCesta) {
              this.api.readData('referencias', '', 'id = "' + ref + '"').subscribe((resRel: any) => {

                for (const o of resRel.data) {
                  this.referencias[o.id] = o;
                  if (o.imagenes !== '') {
                    const parseImagenes = JSON.parse(o.imagenes);
                    for (const i in parseImagenes) {
                      if (parseImagenes[i]) {
                        const ext = parseImagenes[i].split('.').pop();
                        if (ext === 'svg') {
                          this.getImage('/api/assets/images/referencias/' + o.codigo + '/' + parseImagenes[i])
                            .then((imagen) => {
                              this.referenciasFamiliaImgSvg[o.id] = this.sanitizer.bypassSecurityTrustHtml(imagen);
                            });
                        } else {
                          this.referenciasFamiliaImg[o.id] = this.api.getUrlBase()
                          + '/api/assets/images/referencias/' + o.codigo + '/' + parseImagenes[i];
                        }
                      }
                    }
                  }
                }
                this.cdRef.detectChanges();
              });
            }
          } else {
            this.router.navigate(['./products']);
          }
        });
      }

      this.api.readData('acabados')
        .subscribe((res: any) => {
          for (const o of res.data) {
            this.acabados[o.id] = o;
            if (o.imagenes !== '') {
              const parseImagenes = JSON.parse(o.imagenes);
              for (const i in parseImagenes) {
                if (parseImagenes[i]) {
                  this.acabadosImg[o.id] = [];
                  this.acabadosImg[o.id].push(this.api.getUrlBase()
                  + '/api/assets/images/acabados/' + o.grupo + o.codigoColor + '/' + parseImagenes[i]);
                }
              }
            }
          }
        });
    });


  }

  async getImage(url) {
    return await this.api.readSVG(url);
  }

  async getImage64(url) {
    return await this.api.readSVG(url);
  }

  borrarArticulo(index) {
    this.userService.cesta.splice(index, 1);

    this.pedidoForm = this.fb.group({
      version : 2,
      id: this.userService.idPedido,
      idCliente: [this.userService.cliente.id],
      productos: [JSON.stringify(this.userService.cesta)],
      importeTotal: 0,
      estado: 1,
    });
    const newPedido = Object.assign(this.pedido, this.pedidoForm.value);

    if (this.userService.cesta.length === 0) {

      this.api.deleteData('pedidos', this.userService.idPedido).subscribe((res: any) => {
          if (res) {
            this.router.navigate(['./products']);
          }
        });

    } else {
      this.api.updateData('pedidos', newPedido).subscribe((res: any) => {
        if (res) {
            // console.log('cesta actualizada')
        }
      });
    }
  }

  finalizarPedido() {
    this.pedidoFinalizado = true;

    this.pedidoForm = this.fb.group({
      version: 2,
      id: this.userService.idPedido,
      idCliente: [this.userService.cliente.id],
      productos: [JSON.stringify(this.userService.cesta)],
      importeTotal: [this.total],
      estado: 2,
    });

    const newPedido = Object.assign(this.pedido, this.pedidoForm.value);

    this.api.updateData('pedidos', newPedido).subscribe((res: any) => {
      if (res) {
        this.pedidoFinalizado = true;
        this.cdRef.detectChanges();
        this.userService.checkCart();
      }
    });

    let contenido = '';
    contenido +=
      '<table id="datosCliente" style="width: 100%;text-align: center;line-height: 1.4em;border-collapse: collapse;">';
    contenido +=
      '<tr><th colspan="4" style="height: 36px; text-transform:uppercase;letter-spacing: 2px;">Datos cliente</th></tr><tbody>';
    contenido += '<tr style="margin-top:30px;">';
    contenido += '<td>';
    contenido += this.userService.cliente.NIF;
    contenido += '</td>';
    contenido += '</tr>';
    contenido += '<tr>';
    contenido += '<td>';
    contenido += this.userService.cliente.razonSocial;
    contenido += '</td>';
    contenido += '</tr>';
    contenido += '<tr>';
    contenido += '<td>';
    contenido += this.userService.cliente.nombre;
    contenido += '</td>';
    contenido += '</tr>';
    contenido += '<tr>';
    contenido += '<td>';
    contenido += this.userService.cliente.email;
    contenido += '</td>';
    contenido += '</tr>';
    contenido += '</tbody></table><hr><br><br>';

    contenido += '<table id="datosPedido" style="width:100%;"><tbody>';
    contenido +=
      '<tr><th colspan="4" style="height: 36px;text-transform:uppercase;letter-spacing: 2px;">Datos Pedido</th></tr><tbody>';
    for (const articulo of this.userService.cesta) {
      contenido += '<tr style="height: 36px;background-color: transparent;">';
      contenido += '<td></td>';
      contenido +=
        '<td style="text-transform:uppercase;letter-spacing: 2px;font-weight:bold;text-align:center;">Resumen</td>';
      contenido += '<td></td></tr>';
      contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';

      contenido += '<td style="padding-left: 10px;padding-right: 10px;">';
      contenido += this.subfamilias[articulo.coleccion]['nombre_' + this.idioma];
      contenido += '</td>';
      contenido += '<td style="padding-left: 10px;padding-right: 10px;"></td>';
      contenido += '<td style="padding-left: 10px;padding-right: 10px;">';
      contenido += this.userService.formatCurrency(articulo.importe);
    
      contenido += '</tr>';



      contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';
      contenido +=
        '<td style="font-weight:bold;padding-left: 10px;padding-right: 10px;">Composición</td>';
      contenido += '<td style="padding-left: 10px;padding-right: 10px;">';
      contenido +=
        articulo.cantidad +
        ' ✕ ' +
        this.referencias[articulo.referenciaPrincipal].codigo;
      contenido += '</td>';
      contenido += '<td style="padding-left: 10px;padding-right: 10px;"></td>';
      contenido += '</tr>';

      for (const numAcabado of [2, 3, 4]) {
        if (articulo.acabados[numAcabado]) {
          let textoVar = '';

          switch (numAcabado) {
            case 2:
              textoVar = '';
              break;
            case 3:
              textoVar = '2';
              break;

            case 4:
              textoVar = '3';
              break;
          }
          const nombreTexto = 'nombreAcabado' + textoVar + '_es';
          contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';
          contenido +=
            '<td style="font-weight:bold;padding-left: 10px;padding-right: 10px;">';
          contenido += this.subfamilias[articulo.coleccion][nombreTexto];
          contenido += '</td>';
          contenido +=
            '<td style="text-transform: lowercase;padding-left: 10px;padding-right: 10px;">';
          contenido += this.acabados[articulo.acabados[numAcabado]]['descripcion_' + this.idioma] 
          contenido += '</td>';
          contenido +=
            '<td style="padding-left: 10px;padding-right: 10px;"></td></tr>';
        }
      }
      if (articulo.relacionadas && articulo.relacionadas.length > 0) {
        for (const refRel of articulo.relacionadas) {
          contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';
          contenido +=
            '<td style="font-weight:bold;padding-left: 10px;padding-right: 10px;">Ref. Secundaria</td>';
          contenido += '<td style="padding-left: 10px;padding-right: 10px;">';
          contenido +=
            '(' + this.referencias[refRel.idReferencia].codigo + ') ';
          contenido += '</td>';
          contenido +=
            '<td style="text-transform: lowercase;padding-left: 10px;padding-right: 10px;">';
          contenido +=
            refRel.acabado !== 0
              ?  this.acabados[refRel.acabado]['descripcion_' + this.idioma]
              : '';
          contenido += '</td>';
          contenido += '</tr>';
        }
      }
    }
    contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';
    contenido +=
      '<td style="font-weight:bold;padding-left: 10px;padding-right: 10px;">TOTAL:</td>';
    contenido +=
      '<td colspan="2" style="text-transform: lowercase;padding-left: 10px;padding-right: 10px;">';
    contenido += this.userService.formatCurrency(this.total);
    if (this.idioma === 'en') {
      contenido += 'EX.vat';
    } else {
      contenido += 'IVA no incluido';
    }
    contenido += '</td>';

    contenido += '</tr>';
    contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';
    contenido +=
      '<td colspan = "3" style="font-weight:bold;padding-left: 10px;padding-right: 10px;">Comentarios:</td>';
    contenido += '</tr>';
    contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';
    contenido +=
      '<td colspan = "3" style="font-weight:bold;padding-left: 10px;padding-right: 10px;">' +
      this.comentarios +
      '</td>';
    contenido += '</tr>';

    if (this.userService.paises[this.userService.cliente.pais].precios == 0 ){
      contenido += '<tr style="height: 36px;background-color: #f3f3f3;">';
      contenido +=
        '<td colspan = "6" style="font-weight:bold;padding-left: 10px;padding-right: 10px;">* CLIENTE SIN PAÍS CON PRECIO AUTOMÁTICO</td>';
      contenido += '</tr>';
    }

    contenido += '</tbdody></table>';

    this.api.readData('comerciales').subscribe((res2: any) => {
      if (res2.data !== '') {
        let encontrado = false;
        for (const o of res2.data) {

          if (o.paises !== '') {
            const parsePaises = JSON.parse(o.paises);
            for (const i in parsePaises.selected) {
              // console.logg(paisCliente);
              // console.logg(parsePaises.selected[i]);
              if (this.userService.cliente.pais === parsePaises.selected[i]) {
                encontrado = true;
                this.api.sendMail('2', contenido, '', o.email).subscribe((resMail) => {
                  if (resMail) {
                    // console.logg(resMail);
                  }
                });
              }
            }
          }

        }
        if (!encontrado) {
          this.api.sendMail('2', contenido).subscribe((resMail) => {
            if (resMail) {
              // console.logg(resMail);
            }
          });
        }

      } else {
        this.api.sendMail('2', contenido).subscribe((resMail) => {
          if (resMail) {
            // console.logg(resMail);
          }
        });
      }
    });
  }

  valorComents(event) {
    this.comentarios = event.target.value;
  }

  totalCesta() {
    this.total = 0;

    for (const articulo of this.userService.cesta) {
      this.total += articulo.importe;
    }
    if (this.userService.logged && this.userService.paises[this.userService.cliente.pais] && (this.userService.paises[this.userService.cliente.pais].precios == 1 || this.userService.cliente.permitirPrecios == 1)){
      return this. userService.formatCurrency(this.total);
    }else{
        return this.translate.instant('CONFIGURADOR.REQUESTPRICE');
    }

    
  }

  toDataURL(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  aPulgadas(cm: string) {
    let textoPulgadas = '';
    textoPulgadas = (Number(cm) / 2.55).toFixed(2);
    return textoPulgadas + "''";
  }

  imprimir() {
    this.print = true;

    this.cdRef.detectChanges();

    setTimeout(() => {
      window.print();
      this.print = false;
      this.cdRef.detectChanges();
    }, 1000);


    // console.logg("Entra en print");

  }

  generatePdf() {
    const contenido = [];

    for (const articulo of this.userService.cesta) {
      contenido.push([
        {
          text: this.subfamilias[articulo.coleccion]['nombre_' + this.idioma],
          margin: [0, 30, 0, 0],
          bold: true,
        },
        { text: '' },
        {
          text: '',
          margin: [0, 30, 0, 0],
          alignment: 'center',
          bold: true,
          fontSize: 18,
        },
      ]);
      contenido.push([
        {
          text: this.subfamilias[articulo.coleccion].disenador,
          fontSize: 6,
          margin: [0, 0, 0, 0],
        },
        { text: '' },
        { text: '' },
      ]);

      contenido.push([
        {
          colSpan: 3,
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 0,
              x2: 500,
              y2: 0,
              lineWidth: 0.5,
              lineColor: '#C7C7C7',
            },
          ],
        },
      ]);

      // contenido.push([{canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595-2*40, y2: 5, lineWidth: 0.5 }]},]);
      contenido.push([
        { text: 'Summary', margin: [0, 10, 0, 0], bold: true, fontSize: 12 },
        { text: '' },
        { text: '' },
      ]);
      contenido.push([
        { text: 'Composición', margin: [0, 5, 0, 0], fontSize: 8 },
        {
          text:
            articulo.cantidad +
            ' X ' +
            this.referencias[articulo.referenciaPrincipal].codigo,
          fontSize: 8,
        },
        { text: '', margin: [0, 5, 0, 0] }, // imagen svg
      ]);

      for (const numAcabado of [2, 3, 4]) {
        if (articulo.acabados[numAcabado]) {
          let textoVar = '';
          let idiomaVar = '';
          if (this.idioma === 'es') {
            idiomaVar = 'Esp';
          } else {
            idiomaVar = '';
          }

          switch (numAcabado) {
            case 2:
              textoVar = '';
              break;
            case 3:
              textoVar = '2';
              break;

            case 4:
              textoVar = '3';
              break;
          }
          contenido.push([
            {
              text: this.subfamilias[articulo.coleccion][
                'nombreAcabado' + textoVar + idiomaVar
              ],
              margin: [10, 5, 0, 0],
              fontSize: 8,
            },
            {
              text: this.acabados[articulo.acabados[numAcabado]]['descripcion_' + this.idioma],
              margin: [0, 5, 0, 0],
              fontSize: 8,
            },
            { text: '', margin: [0, 5, 0, 0] }, // imagen acabado
          ]);
        }
      }
      if (articulo.relacionadas && articulo.relacionadas.length > 0) {
        for (const refRel of articulo.relacionadas) {
          contenido.push([
            { text: '', margin: [0, 5, 0, 0] },
            {
              text: '(' + this.referencias[refRel.idReferencia].codigo + ')  ',
              fontSize: 8,
              margin: [0, 5, 0, 0],
            }, // imagen SVG
            {
              text:
                refRel.acabado !== '0'
                  ? this.acabados[refRel.acabado]['descripcion_' + this.idioma]
                  : '',
              margin: [0, 5, 0, 0],
              fontSize: 8,
            },
          ]);

        }
      }
      contenido.push([
        { text: '', margin: [0, 30, 0, 0], bold: true },
        { text: '' },
        {
          text: this.userService.formatCurrency(articulo.importe),
          margin: [0, 30, 0, 0],
          alignment: 'right',
          bold: true,
          fontSize: 10,
        },
      ]);
    }

    contenido.push([
      { text: '', margin: [0, 20, 0, 0] },
      { text: 'TOTAL : ', margin: [0, 20, 0, 0] },
      {
        text: this.userService.formatCurrency(this.total),
        margin: [0, 20, 0, 0],
        bold: true,
        fontSize: 15,
        alignment: 'right',
      },
    ]);

    contenido.push([
      { text: '', margin: [0, 20, 0, 0] },
      { text: '', margin: [0, 20, 0, 0] },
      {
        text: this.translate.instant('ORDER.VATNOTINCLUDE'),
        margin: [0, 20, 0, 0],
        bold: true,
        fontSize: 10,
        alignment: 'right',
      },
    ]);

    contenido.push([
      {
        text: this.home? this.home['validezPrecios_' + this.idioma] : '',
        margin: [0, 20, 0, 0],
        bold: true,
        fontSize: 10,
        alignment: 'left',
      },
      {
        text: '',
        margin: [0, 20, 0, 0],
        bold: true,
        fontSize: 10,
        alignment: 'left',
      },
      { text: '', margin: [0, 20, 0, 0] },
    ]);

    contenido.push([
      {
        colSpan: 3,
        text: this.translate.instant('ORDER.OBSERVATIONS'),
        margin: [0, 20, 0, 0],
        bold: true,
        fontSize: 10,
        alignment: 'left',
      },
    ]);
    contenido.push([
      {
        colSpan: 3,
        text: this.comentarios,
        margin: [0, 20, 0, 0],
        bold: true,
        fontSize: 10,
        alignment: 'left',
      },
    ]);

    const content = [
      {
        style: 'tablaEncabezado',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text:
                  this.userService.cliente.NIF +
                  '\n' +
                  this.userService.cliente.razonSocial +
                  '\n' +
                  this.userService.cliente.nombre +
                  '\n' +
                  this.userService.cliente.email +
                  '\n',
                fontSize: 12,
              },
              {
                image: this.logo,
                width: 60,
                alignment: 'right',
                margin: [0, 0, 0, 20],
              },
            ],
            [{ text: 'BUDGET', margin: [0, 30, 0, 0] }, { text: '' }],
          ],
        },
        layout: 'noBorders',
      },
      {
        style: 'tablaEncabezado',
        table: {
          widths: ['*', '*', '*'],
          body: contenido,
        },
        layout: 'noBorders',
      },
    ];

    const documentDefinition = {
      header: '',
      content: content,
      styles: {
        tablaEncabezado: {
          margin: [0, 5, 0, 15],
        },
      },
    };

    pdfMake.createPdf(documentDefinition).open();
  }

}
