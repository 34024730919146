import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,

} from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'kt-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.scss']
})
export class DistributorsComponent implements OnInit {
  modo = 'simplificado';
  localizaciones = [];
  distribuidores = [];
  localizacionesid = [];
  distribuidoresFiltro = [];
  filtroForm = new FormGroup({
    tipo2: new FormControl(),
    tipo3: new FormControl()
  });
  localizaciones3Filtrado = [];
  idioma: string;
  cargado = false;
  resultados = [];
  relacionLc = [];
  relacionDs = [];
  relacionDist = [];
  tituloResultado = '';

  constructor(private api: ApiService,
              public translate: TranslateService,
              private cdRef: ChangeDetectorRef,
              private activatedRoute: ActivatedRoute) {
    this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;

      this.localizaciones.sort((a, b) => {
        return a['nombre_' + this.idioma].localeCompare(b['nombre_' + this.idioma]);  
      });
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(v => this.modo = v.modo);
    this.api.readData('localizaciones').subscribe((res: any) => {
      this.localizaciones = res.data;
      for (const lc of res.data) {
        this.relacionLc[lc.id] = [];
        this.relacionDs[lc.id] = [];
      }

      for (const lc of res.data) {
        if (lc.padre !== '') {
          const parsePadre = JSON.parse(lc.padre);
          if (parsePadre.selected.length > 0 ) {
            for (const idLcPadre of parsePadre.selected) {
              this.relacionLc[idLcPadre].push(lc.id);
            }
          }
        }
      }

      this.localizaciones.sort( (a, b) => {
        return a['nombre_' + this.idioma].localeCompare(b['nombre_' + this.idioma]);
      });


      this.api.readData('distribuidores').subscribe((res: any) => {
        this.distribuidores = res.data;
        for (const ds of res.data) {
          if (ds.localizacion !== '') {
            const parseLc = JSON.parse(ds.localizacion);
            if (parseLc.selected.length > 0) {
              for (const idDsLc of parseLc.selected) {
                if (this.relacionDs[ds.id] !== undefined) {
                  this.relacionDs[idDsLc].push(ds.id);
                }
              }
            }
          }
        }
      });

    });



  }

  listar(idLc) {
    const localizacion = this.localizaciones.find((el) => el.id == idLc);
    this.tituloResultado = localizacion['nombre_' + this.idioma];
    this.distribuidoresFiltro = this.distribuidores.filter((el) => this.relacionDs[idLc].includes(el.id));
    if (this.distribuidoresFiltro.length === 0) {
      this.distribuidoresFiltro = this.distribuidores.filter((el) => this.relacionDs[this.filtroForm.controls.tipo2.value].includes(el.id));
    }
  }

  filtrarTipo(tipo, id) {
    this.distribuidoresFiltro = [];
    this.tituloResultado = '';
    if (tipo === 2) {
      if (this.relacionLc[id].length > 0 ) {
        this.localizaciones3Filtrado = this.localizaciones.filter((el) => this.relacionLc[id].includes(el.id));
      } else {
        this.localizaciones3Filtrado = [];
        this.listar(id);
      }
    } else if (tipo === 3) {
      this.listar(id);
    }
  }

  localizacionTipo(tipo) {
    return this.localizaciones.filter((el) => el.tipo == tipo);
  }

}
