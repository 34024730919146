// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Localizacion } from '../../../core/auth/_models/localizacion.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DeleteLocalizacionComponent } from './localizacion/delete-localizacion/delete-localizacion.component';

@Component({
  selector: 'kt-localizaciones',
  templateUrl: './localizaciones.component.html',
  styleUrls: ['./localizaciones.component.scss']
})
export class LocalizacionesComponent implements OnInit, OnDestroy {

  localizaciones: Localizacion[] = [];
  dataSource;
  contadorImagenes = [];
  tmpLocalizacionesForm = new FormGroup({
    selected: new FormControl()
  });


  displayedColumns: string[] = ['select', 'id', 'nombre_es', 'opciones'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Localizacion>(true, []);

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private matDialog: MatDialog,
    private header: HeaderService,
    private footer: FooterService) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {

    this.subheaderService.setTitle('Sub Familias');

    this.api.readData('localizaciones').subscribe((res: any) => {
      for (const o of res.data) {
        this.localizaciones.push(o);

      }
      this.dataSource = new MatTableDataSource(this.localizaciones);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.localizaciones.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.localizaciones.length) {
      this.selection.clear();
    } else {
      this.localizaciones.forEach(row => this.selection.select(row));
    }
  }

  fetchLocalizaciones() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.nombre_es}`,
        id: elem.id.toString(),
        status: elem.nombre_es
      });
    });
    console.log(messages);

  }

  editLocalizacion(id: number) {
    this.router.navigate(['../localizaciones/', id], { relativeTo: this.activatedRoute });
  }

  deleteLocalizacion(id: number) {
    const localizacion = this.localizaciones.find(i => i.id === id);
    const dialogConfig: MatDialogConfig = {
      autoFocus: true,
      maxWidth: '400px',
      data: {
        id: localizacion.id,
        nombre_es: localizacion.nombre_es
      }
    };
    const dialogRef = this.matDialog.open(DeleteLocalizacionComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.api.deleteData('localizaciones', res).subscribe((res2: any) => {
          if (res2) {
            this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
          }
        });
      }
    });
  }


  ngOnDestroy(): void {
    this.header.toggle();
  }
}
