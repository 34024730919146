
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-disenador',
  templateUrl: './delete-disenador.component.html',
  styleUrls: ['./delete-disenador.component.scss']
})
export class DeleteDisenadorComponent implements OnInit {

  id: number;
  nombre: string;

  constructor(public dialogRef: MatDialogRef<DeleteDisenadorComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.nombre = data.nombre;
  }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }

}
