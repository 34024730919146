import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { CategoriaProyecto } from '../../../../core/auth/_models/categoria-proyecto.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import {DomSanitizer} from '@angular/platform-browser';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { TranslationService } from '../../../../core/_base/layout';
@Component({
    selector: 'kt-categoria',
    templateUrl: './categoria-proyecto.component.html',
    styleUrls: ['./categoria-proyecto.component.scss']
})
export class CategoriaProyectoComponent implements OnInit, OnDestroy {

    categoriaProyecto: CategoriaProyecto;
    categoriaId: Observable<number>;
    categoriaForm: FormGroup;
    subcategoriaForm: FormGroup;
    subcategorias = [];
    hasFormErrors = false;
    selectedTab = 0;
    loadingFile = false;
    subcatNombre = '';
    subcatNombre_es = '';
    idCategoria = 0;

    constructor(
        // tslint:disable-next-line:variable-name
        public _DomSanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private subheaderService: SubheaderService,
        private api: ApiService,
        private header: HeaderService,
        private footer: FooterService,
        private cdRef: ChangeDetectorRef,
        public ts: TranslationService) {
        this.header.hide();
        this.footer.hide();
    }



    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.idCategoria = params.id;
            this.categoriaProyecto = new CategoriaProyecto();
            this.categoriaProyecto.clear();

            if (this.idCategoria && this.idCategoria > 0) {
                this.api.readData('categoriasproyectos', '', 'id = ' + this.idCategoria).subscribe((res: any) => {
                    this.categoriaProyecto = Object.assign(this.categoriaProyecto, res.data[0]);
                    this.initCategoria();

                });
            } else {
                this.initCategoria();
            }
        });

        this.cdRef.detectChanges();

    }

    initCategoria() {
        this.createForm();
        if (!this.categoriaProyecto.id) {
            this.subheaderService.setTitle('Crear categoria');
            this.subheaderService.setBreadcrumbs([
                { title: 'Categorias', page: `categorias` },
                { title: 'Crear categoria', page: `categorias/new` }
            ]);
            return;
        }
        this.subheaderService.setTitle('Editar Familia');
        this.subheaderService.setBreadcrumbs([
            { title: 'Categorias', page: `categorias proyectos` },
            { title: 'Editar categoria', page: `categorias proyectos`, queryParams: { id: this.categoriaProyecto.id } }
        ]);
    }

    createForm() {
        this.categoriaForm = this.fb.group({
            nombre_en: [this.categoriaProyecto.nombre_en],
            nombre_es: [this.categoriaProyecto.nombre_es],
            nombre_de: [this.categoriaProyecto.nombre_de],
            nombre_fr: [this.categoriaProyecto.nombre_fr]
        });

        console.log(this.categoriaForm);
    }

    getComponentTitle() {
        let result = 'Crear categoria';
        if (!this.categoriaProyecto || !this.categoriaProyecto.id) {
            return result;
        }

        result = `Editar categoria - ${this.categoriaProyecto.nombre_es}`;
        return result;
    }

    saveCategoria() {
        const newCategoria = Object.assign(this.categoriaProyecto, this.categoriaForm.value);
        if (!newCategoria.id) {
            this.api.createData('categoriasproyectos', newCategoria).subscribe((res: any) => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/catProyectos']);
                }

            });
        } else {
            this.api.updateData('categoriasproyectos', newCategoria).subscribe((res: any) => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/catProyectos']);
                }

            });
        }


    }


    ngOnDestroy(): void {
        this.header.toggle();
        this.footer.toggle();
    }

}
