import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ApiService } from "../../services/api/api.service";
import { ActivatedRoute } from '@angular/router';
import { Client } from "../../core/auth/_models/client.model";
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TranslationService } from "../../core/_base/layout";

@Component({
  selector: "kt-recuperacion",
  templateUrl: "./recuperacion.component.html",
  styleUrls: ["./recuperacion.component.scss"],
})
export class RecuperacionComponent implements OnInit {
  cliente: Client;
  passwordForm: FormGroup;
  hasFormErrors: boolean = false;
  errorValidacion: boolean = false;
  passwordRecuperada: boolean = false;
  textoError = '';
  token: string;
  idioma: string;


  constructor(
    private translationService: TranslationService,
    private fb: FormBuilder,
    private api: ApiService,
    private rutaActiva: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
    });
  }

  ngOnInit() {
    this.cliente = new Client();
    this.cliente.clear();

    this.token = this.rutaActiva.snapshot.params.token;
    this.initRecuperacion();

    this.cdRef.detectChanges();
  }

  initRecuperacion() {
    this.createForm();
  }

  createForm() {
    this.passwordForm = this.fb.group({
      password: ["", [Validators.required]],
      passwordConf: ["", [Validators.required]]
    });
  }

  guardarPassword() {

    if (this.passwordForm.valid) {
      if (this.passwordForm.value.password == this.passwordForm.value.passwordConf) {
        var existe = false;
        this.api
          .readData("clientes", '', "accessToken = '" + this.token + "'")
          .subscribe((res: any) => {
            if (res.data !== '') {
              this.api.restorePass(this.token, this.passwordForm.value.password).subscribe((res2: any) => {
                this.passwordRecuperada = true;
                this.cdRef.detectChanges();
              });
            }
            else {
              this.errorValidacion = true;
              this.textoError = this.translate.instant("RESTORE.RESEND");
              return false;
            }
          });

      } else {

        this.errorValidacion = true;
        this.textoError = this.translate.instant("RESTORE.PASSMATCH");
        return false;
      }

    }
  }
}
