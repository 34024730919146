// Angular
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup} from '@angular/forms';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { EstadisticasDescargas } from '../../../core/auth/_models/estadisticas-descargas';
import { Client } from '../../../core/auth/_models/client.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog} from '@angular/material/dialog';
/* Export to CSV */
import { ExportToCsv } from 'export-to-csv';
/* moment */
import * as moment from 'moment';
@Component({
  selector: 'kt-estadisticas-descargas',
  templateUrl: './estadisticas-descargas.component.html',
  styleUrls: ['./estadisticas-descargas.component.scss']
})

export class EstadisticasDescargasComponent implements OnInit {

  estadisticas: EstadisticasDescargas[] = [];
  clientes: Client[] = [];
  subfamilias = [];

  clientesSelect = [];
  subfamiliasSelect = [];
  postsControl: FormControl = new FormControl();
  filtroForm = new FormGroup({
    fechaDesde: new FormControl(),
    fechaHasta: new FormControl(),
    clientes: new FormControl(),
    subfamilias: new FormControl(),
    
  });

  dataSource;
  displayedColumns: string[] = ['select', 'id', 'fecha', 'nombreCliente', 'coleccion', 'tipo','descargas'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<EstadisticasDescargas>(true, []);
  estadisticasFiltrado: EstadisticasDescargas[];
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private matDialog: MatDialog,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef) {
    this.header.hide();
    this.footer.hide();
    this.estadisticasFiltrado = [];
  }

  ngOnInit() {

    this.clientesSelect.push({
      display: '-- TODOS --',
      value: '0',
    });
    this.subfamiliasSelect.push({
      display: '-- TODOS --',
      value: '',
    });
    this.subfamiliasSelect.push({
      display: 'Descarga general',
      value: '0',
    });

    this.subheaderService.setTitle('EstadisticasDescargass');

    this.api.readData('clientes', '', '', 'nombre ASC').subscribe((res: any) => {
      this.clientes = res.data;
      for (const o of res.data) {
        this.clientesSelect.push({
          display: o.nombre.toUpperCase() + ' ( ' + o.email.toUpperCase() + ' ) ',
          value: o.id,
        });
      }
      this.api.readData('`subfamilias`').subscribe((res2: any) => {
        this.subfamilias = res2.data;
        for (const o2 of res2.data) {
          this.subfamiliasSelect.push({
            display: o2.nombre_es.toUpperCase(),
            value: o2.id,
          });
        }

        this.api.readData('`estadisticas-descargas`').subscribe((res3: any) => {
          for (const o3 of res3.data) {
            o3.nombreCliente = this.getNombreCliente(o3.idCliente);
            o3.nombreSubfamilia = this.getNombreSubfamilia(o3.idSubfamilia);
            this.estadisticas.push(o3);
          }
          this.dataSource = new MatTableDataSource(this.estadisticas);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
    
        this.estadisticasFiltrado = this.estadisticas;
      });
      
    });

    
  }

  getNombreCliente(id) {

    const cliente = this.clientes.find(o => o.id === id);
    if (cliente !== undefined) {
      return cliente.nombre.toUpperCase();
    } else {
      return '';
    }
  }

  getNombreSubfamilia(id) {

    const subfamilia = this.subfamilias.find(o => o.id === id);
    if (subfamilia) {
      return subfamilia.nombre_es.toUpperCase();
    } else {
      return 'DESCARGA GENERAL';
    }
  }


  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.estadisticas.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.estadisticas.length) {
      this.selection.clear();
    } else {
      this.estadisticas.forEach(row => this.selection.select(row));
    }
  }

  fetchEstadisticasDescargass() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.idCliente}`,
        id: elem.id.toString(),
        status: elem.idCliente
      });
    });
    console.log(messages);

  }


  filtrar() {
    const fDesde = this.filtroForm.get('fechaDesde').value;
    const fHasta = this.filtroForm.get('fechaHasta').value;
    const cliente = this.filtroForm.get('clientes').value;
    const subfamilia = this.filtroForm.get('subfamilias').value;
    if (fHasta != null) {
      fHasta.setHours(23, 59, 59);
    }
    if (fDesde != null || fHasta != null || cliente != 0 || subfamilia != '') {
      this.estadisticasFiltrado = this.estadisticas.filter(
        o =>
          (fDesde != null ? new Date(o.fecha) >= new Date(fDesde) : true) &&
          (fHasta != null ? new Date(o.fecha) <= new Date(fHasta) : true) &&
          (cliente != 0 ? o.idCliente == cliente : o.id != 0) &&
          (subfamilia != '' ? o.idSubfamilia == subfamilia : true)
      );
      this.dataSource = new MatTableDataSource(this.estadisticasFiltrado);

    } else {
      this.dataSource = new MatTableDataSource(this.estadisticas);
    }

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.cdRef.detectChanges();
  }

  exportarCSV() {
    let nombre = 'estadisticas';
    if (this.filtroForm.get('fechaDesde').value !== null || this.filtroForm.get('fechaHasta').value !== null) {
      nombre += ' ' + moment(this.filtroForm.get('fechaDesde').value).format('DD/MM/YYYY')
      + ' - ' + moment(this.filtroForm.get('fechaHasta').value).format('DD/MM/YYYY');
    }
    const options = {
      filename: nombre,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Estadísticas [' + moment(this.filtroForm.get('fechaDesde').value).format('DD/MM/YYYY')
      + ' - ' + moment(this.filtroForm.get('fechaHasta').value).format('DD/MM/YYYY') + ']',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    const data: any[] = [];
    this.estadisticasFiltrado.forEach(element => {

      data.push({
        ID: element.idCliente,
        Fecha: element.fecha,
        Usuario: this.getNombreCliente(element.idCliente)
      });
    });
    csvExporter.generateCsv(data);
  }

}
