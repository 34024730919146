// Angular
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Client } from '../../../core/auth/_models/client.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteClienteComponent } from './cliente/delete-cliente/delete-cliente.component';
/* Export to CSV */
import { ExportToCsv } from 'export-to-csv';
/* moment */
import * as moment from 'moment';

@Component({
  selector: 'kt-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {

  clientes: Client[] = [];
  inputSearch = '';
  dataSource;
  displayedColumns: string[] = ['select', 'id', 'nombre', 'razonSocial', 'email', 'tipo','pais', 'opciones'];
  paises = [];
  paisSelect = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<Client>(true, []);

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private matDialog: MatDialog,
    private header: HeaderService,
    private snackBar: MatSnackBar,
    private footer: FooterService) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {

    this.subheaderService.setTitle('Clientes');
    this.api.readData('paises').subscribe((res: any) => {
      for (const o of res.data) {
        this.paises[o.id] = o;
      }
    })
    ;
    this.api.readData('clientes').subscribe((res: any) => {
      for (const o of res.data) {
        this.clientes.push(o);
      }
      this.dataSource = new MatTableDataSource(this.clientes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  filtrar() {
    const value = this.paisSelect;
    
    if (value == 0){
      this.dataSource.data = this.clientes;
    }else{
       this.inputSearch='';
       this.dataSource.data = this.clientes.filter(cliente =>cliente.pais == value);
    }
 
 }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.clientes.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.clientes.length) {
      this.selection.clear();
    } else {
      this.clientes.forEach(row => this.selection.select(row));
    }
  }

  fetchClients() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.nombre}, ${elem.email}`,
        id: elem.id.toString(),
        status: elem.nombre
      });
    });
    console.log(messages);

  }

  editClient(id: number) {
    this.router.navigate(['../clientes/', id], { relativeTo: this.activatedRoute });
  }

  deleteClient(id: number) {
    let client = this.clientes.find(i => i.id === id);
    const dialogConfig: MatDialogConfig = {
      autoFocus: true,
      maxWidth: '400px',
      data: {
        id: client.id,
        name: client.nombre
      }
    }
    const dialogRef = this.matDialog.open(DeleteClienteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.api.deleteData('clientes', res).subscribe((res: any) => {
          if (res) {
            this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
          }
        });
      }
    });
  }

  recargarClientes() {
    this.api.readData('clientes').subscribe((res: any) => {
      this.clientes = [];
      for (const o of res.data) {
        this.clientes.push(o);
      }
      this.dataSource = new MatTableDataSource(this.clientes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  exportarCSV() {
    if (this.selection.selected.length > 0) {
      var nombre = "clientes";
      const options = {
        filename: nombre,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        title: 'clientes ' + moment().format('DD/MM/YYYY'),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
      const csvExporter = new ExportToCsv(options);
      const data: any[] = [];
      this.selection.selected.forEach(element => {
        data.push({
          "ID": element.id,
          "Nombre": element.nombre,
          "Razón": element.razonSocial,
          "Email": element.email,
          "Tipo": element.tipo == 1 ? "Nacional" : "Exportación"
        });
      });
      csvExporter.generateCsv(data);
    } else {
      this.snackBar.open('Primero elija los clientes', 'Aceptar', {
        duration: 5_000,
        panelClass: ['error-snackbar']
      });
      return false;
    }
  }
}
