import { BaseModel } from '../../_base/crud';

export class Home extends BaseModel {
    id: number;
    tituloPrincipal_en: string;
    tituloPrincipal_es: string;
    tituloPrincipal_de: string;
    tituloPrincipal_fr: string;
    descripcionPrincipal_en: string;
    descripcionPrincipal_es: string;
    descripcionPrincipal_de: string;
    descripcionPrincipal_fr: string;
    linkVideoPrincipal: string;
    imgPrincipal: string;
    tituloDetalle1_en: string;
    tituloDetalle1_es: string;
    tituloDetalle1_de: string;
    tituloDetalle1_fr: string;
    descripcionDetalle1_en: string;
    descripcionDetalle1_es: string;
    descripcionDetalle1_de: string;
    descripcionDetalle1_fr: string;
    linkVideoDetalle1: string;
    imgDetalle1: string;
    tituloDetalle2_en: string;
    tituloDetalle2_es: string;
    tituloDetalle2_de: string;
    tituloDetalle2_fr: string;
    descripcionDetalle2_en: string;
    descripcionDetalle2_es: string;
    descripcionDetalle2_de: string;
    descripcionDetalle2_fr: string;
    linkVideoDetalle2: string;
    imgDetalle2: string;
    validezPrecios_en : string;
    validezPrecios_es : string;
    validezPrecios_de : string;
    validezPrecios_fr : string;
    imagenes: string;


    clear(): void {
        this.id = 0;
        this.tituloPrincipal_en = '';
        this.tituloPrincipal_es = '';
        this.tituloPrincipal_de = '';
        this.tituloPrincipal_fr = '';
        this.descripcionPrincipal_en = '';
        this.descripcionPrincipal_es = '';
        this.descripcionPrincipal_de = '';
        this.descripcionPrincipal_fr = '';
        this.linkVideoPrincipal = '';
        this.imgPrincipal = '';
        this.tituloDetalle1_en = '';
        this.tituloDetalle1_es = '';
        this.tituloDetalle1_de = '';
        this.tituloDetalle1_fr = '';
        this.descripcionDetalle1_en = '';
        this.descripcionDetalle1_es = '';
        this.descripcionDetalle1_de = '';
        this.descripcionDetalle1_fr = '';
        this.linkVideoDetalle1 = '';
        this.imgDetalle1 = '';
        this.tituloDetalle2_en = '';
        this.tituloDetalle2_es = '';
        this.tituloDetalle2_de = '';
        this.tituloDetalle2_fr = '';
        this.descripcionDetalle2_en = '';
        this.descripcionDetalle2_es = '';
        this.descripcionDetalle2_de = '';
        this.descripcionDetalle2_fr = '';
        this.linkVideoDetalle2 = '';
        this.imgDetalle2 = '';
        this.imagenes = '';
        this.validezPrecios_en = '';
        this.validezPrecios_es = '';
        this.validezPrecios_de = '';
        this.validezPrecios_fr = '';
    }
}
