// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Familia } from '../../../core/auth/_models/familia.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteFamiliaComponent } from './familia/delete-familia/delete-familia.component';

@Component({
	selector: 'kt-familias',
	templateUrl: './familias.component.html',
	styleUrls: ['./familias.component.scss']
})
export class FamiliasComponent implements OnInit, OnDestroy {

	familias: Familia[] = [];
	dataSource;
	displayedColumns: string[] = ['select', 'id', 'nombre', 'codigo','opciones'];

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	selection = new SelectionModel<Familia>(true, []);

	constructor(
		private api: ApiService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private subheaderService: SubheaderService,
		private matDialog: MatDialog,
		private header: HeaderService,
		private footer: FooterService) {
		this.header.hide();
		this.footer.hide();
	}

	ngOnInit() {

		this.subheaderService.setTitle('Familias');

		this.api.readData('familias').subscribe((res: any) => {
			for (const o of res.data) {
				this.familias.push(o);
			}
			this.dataSource = new MatTableDataSource(this.familias);
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;

		});
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.familias.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.selection.selected.length === this.familias.length) {
			this.selection.clear();
		} else {
			this.familias.forEach(row => this.selection.select(row));
		}
	}

	fetchFamilias() {
		const messages = [];
		this.selection.selected.forEach(elem => {
			messages.push({
				text: `${elem.nombre}, ${elem.codigo}`,
				id: elem.id.toString(),
				status: elem.nombre
			});
		});
		console.log(messages);

	}

	editFamilia(id: number) {
		this.router.navigate(['../familias/', id], { relativeTo: this.activatedRoute });
	}

	deleteFamilia(id: number) {
		let familia = this.familias.find(i => i.id === id);
		const dialogConfig: MatDialogConfig = {
			autoFocus: true,
			maxWidth: '400px',
			data: {
				id: familia.id
			}
		}
		const dialogRef = this.matDialog.open(DeleteFamiliaComponent, dialogConfig);
		dialogRef.afterClosed().subscribe((res: any) => {
			if (res) {
				this.api.deleteData('familias', res).subscribe((res: any) => {
					if (res) {
						this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
					}
				});
			}
		});
	}
  
	ngOnDestroy(): void {
		this.header.toggle();
	}
}
