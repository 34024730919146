import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Familia } from '../../../../core/auth/_models/familia.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
    selector: 'kt-familia',
    templateUrl: './familia.component.html',
    styleUrls: ['./familia.component.scss']
})
export class FamiliaComponent implements OnInit, OnDestroy {

    familia: Familia;
    familiaId: Observable<number>;
    familiaForm: FormGroup;
    hasFormErrors: boolean = false;
    selectedTab = 0;
    imagenesFamilia = [];

    constructor(
        public _DomSanitizer: DomSanitizer,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private subheaderService: SubheaderService,
        private api: ApiService,
        private header: HeaderService,
        private footer: FooterService,
        private cdRef: ChangeDetectorRef) {
        this.header.hide();
        this.footer.hide();
    }



    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            this.familia = new Familia();
            this.familia.clear();
            if (id && id > 0) {
                this.api.readData('familias','', 'id = ' + id).subscribe((res: any) => {
                    this.familia = Object.assign(this.familia, res.data[0]);
                    // console.log(this.familia);
                    this.initFamilia();
                    if (this.familia.imagenes !== ''){
                        const parseImagenes = JSON.parse(this.familia.imagenes);
                        for (const i in parseImagenes) {
                            // console.log(parseImagenes[i]);
                            this.imagenesFamilia.push([parseImagenes[i],this.api.getUrlBase() + "/api/assets/images/familias/" + this.familia.codigo + '/' + parseImagenes[i] ]);
                        }
                    }
                    console.log(this.imagenesFamilia);
                })
            } else {
                this.initFamilia();
            }
        });
    }

    initFamilia() {
        this.createForm();
        if (!this.familia.id) {
            this.subheaderService.setTitle('Crear familia');
            this.subheaderService.setBreadcrumbs([
                { title: 'Familias', page: `familias` },
                { title: 'Crear familia', page: `familias/new` }
            ]);
            return;
        }
        this.subheaderService.setTitle('Editar Familia');
        this.subheaderService.setBreadcrumbs([
            { title: 'Familias', page: `familias` },
            { title: 'Editar familia', page: `familias`, queryParams: { id: this.familia.id } }
        ]);
    }

    createForm() {
        this.familiaForm = this.fb.group({
            nombre: [this.familia.nombre, Validators.required],
            codigo: [this.familia.codigo, Validators.required],
            imagenes: [this.familia.imagenes]
        });

    }

    getComponentTitle() {
        let result = 'Crear familia';
        if (!this.familia || !this.familia.id) {
            return result;
        }

        result = `Editar familia - ${this.familia.nombre} ${this.familia.codigo}`;
        return result;
    }

    saveFamilia() {
        const newFamilia = Object.assign(this.familia, this.familiaForm.value);
        newFamilia["imagenes"] = this.imagenesFamilia;
        if (!newFamilia.id){
            this.api.createData('familias', newFamilia).subscribe((res: any) => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/familias']);
                }
            });
        }else{
            this.api.updateData('familias', newFamilia).subscribe((res: any) => {
                console.log(res);
                if (res) {
                    this.router.navigate(['/panel/familias']);
                }
            });
        }
        /*
            
        */
    }

    borrarImagen(imagen) {
        this.imagenesFamilia = this.imagenesFamilia.filter(obj => obj !== imagen);
        this.cdRef.detectChanges();
    }
    preview(files) {

        if (files.length === 0) {
            return;
        }
        // console.log(files);

        for (var _i = 0; _i < files.length; _i++) {
            this.cargarImagenes(files[_i]);
        }
    }

    cargarImagenes(imagen) {
        var mimeType = imagen.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }
        // console.log(imagen);
        var reader = new FileReader();
        //this.referenciaForm['pic'] = files;
        reader.readAsDataURL(imagen);
        reader.onload = (_event) => {
            var resultado =reader.result;
            var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
            this.imagenesFamilia.push([name,this._DomSanitizer.bypassSecurityTrustUrl(resultado.toString())]);
            this.cdRef.detectChanges();
        }

    }

    updateUrl(event) {
        //event.target.src = '../../../../../assets/img/users/default.jpg';
    }

    ngOnDestroy(): void {
        this.header.toggle();
        this.footer.toggle();
    }

}
