import { Component, OnInit, OnDestroy, ChangeDetectorRef, ɵConsole, Renderer2, ViewChild, ElementRef,} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl,} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Subfamilia } from '../../../../core/auth/_models/subfamilia.model';
import { SubheaderService } from '../../../../core/_base/layout';
import { Disenador } from '../../../../core/auth/_models/disenador.model';

import { TranslationService } from "../../../../core/_base/layout";
@Component({
  selector: 'kt-subfamilia',
  templateUrl: './subfamilia.component.html',
  styleUrls: ['./subfamilia.component.scss'],
})
export class SubfamiliaComponent implements OnInit, OnDestroy {
  @ViewChild('imgDestacada', { static: true }) imgDestacada: ElementRef;
  @ViewChild('imgDestacadaSVG', { static: false }) imgDestacadaSVG: ElementRef;
  subfamilia: Subfamilia;
  disenadores: Disenador[] = [];
  subfamiliaId: Observable<number>;
  subfamiliaForm: FormGroup;
  hasFormErrors = false;
  selectedTab = 0;
  loadingFile = false;
  imagenesSubfamilia = [];
  imagenesPrinSubfamilia = [];
  imagenDestacada = [];
  imagenPrincipal = [];
  acabados = [];
  preciosEspeciales = [];
  referenciaEspecial = 0;
  acabadoEspecial = 0;
  importeNacEspecial = 0;
  importeExpEspecial = 0;
  submenu = 1;
  orden = 0;
  tiposReferencias = [];
  acabadosSelect = [];
  referenciasSelect = [];
  referencias = [];
  referenciasID = [];
  refSecundariasOpcionales = [];
  refSecundariasPrecio = [];
  referenciasRelSelect = [];
  referenciasRel = [];
  subcategorias = [];
  categorias = [];
  grupos = [];

  descargas = {
    docRevit: 'Revit',
    doc3DFiles: 'Archivos 3D',
    doc2DFiles: 'Archivos 2D',
    docFicha: 'Ficha',
    docFotosHI: 'Fotos HI',
    docFotosLO: 'Fotos LO',
    certificados: 'Certificados',
    garantias: 'Garantías',
    fondo_blanco: 'Fondo blanco',
    hoja_montaje: 'Hoja montaje',
    medidas_interiores: 'Medidas interiores',
    video: 'Video',
    presentacion: 'Presentación de producto',
    imagen_detalle: 'Imagenes detalle'

  };
  descargasLogin = {
    docRevit: true,
    doc3DFiles: true,
    doc2DFiles: true,
    docFicha: true,
    docFotosHI: true,
    docFotosLO: true,
    certificados: true,
    garantias: true,
    fondo_blanco: true,
    hoja_montaje: true,
    medidas_interiores: true,
    video: true,
    presentacion: true,
    imagen_detalle: true
  };

  referenciasForm = new FormGroup({
    selected: new FormControl(),
  });

  acabadosForm = new FormGroup({
    selected: new FormControl(),
  });

  acabadosForm2 = new FormGroup({
    selected: new FormControl(),
  });

  acabadosForm3 = new FormGroup({
    selected: new FormControl(),
  });

  referenciasRelForm = new FormGroup({
    selected: new FormControl(),
  });

  relacionRef = [];

  selectedOptionsReferencias = [];
  selectedOptionsReferenciasRel = [];

  idSubfamilia = 0;
  idCopiarSubfamilia = 0;

  videoDestacado: any = {
    nombre: '',
    src: 'assets/img/mock/video.mp4'
  };



  constructor(
    public domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private api: ApiService,
    private header: HeaderService,
    private footer: FooterService,
    private render: Renderer2,
    private cdRef: ChangeDetectorRef,
    public ts: TranslationService
  ) {
    this.header.hide();
    this.footer.hide();
    this.imagenDestacada[1] = '../../../../../assets/img/thumbnail-600x350.png';
    this.imagenPrincipal[1] = '../../../../../assets/img/thumbnail-600x350.png';
    this.imagenDestacada[2] = false;
    this.imagenDestacada[3] = false;
    this.imagenDestacada[4] = false;
    this.imagenPrincipal[2] = false;
    this.imagenPrincipal[3] = false;
  }

  ngOnInit() {
    this.api.readData('acabados', '*', '1', 'grupo ASC').subscribe((res: any) => {
      // console.log(res.data);
      res.data = res.data.sort((a, b) => a.id - b.id);
      for (const o of res.data) {
        this.acabados.push({
          display: o.grupo + ' - ' + o.descripcion_es,
          value: o.id,
        });
      }
    });

    this.api.readData('subcategorias').subscribe((res: any) => {
      for (const o of res.data) {
        this.subcategorias.push(o);
      }
    });

    this.api.readData('tiposreferencias').subscribe((res: any) => {
      for (const o of res.data) {
        this.tiposReferencias.push(o);
      }
    });

    this.api.readData('categorias').subscribe((res: any) => {
      for (const o of res.data) {
        this.categorias[o.id] = o;
      }
    });

    this.api.readData('grupos').subscribe((res: any) => {
      console.log(this.grupos);
      if (res.data){
        
        res.data.sort((a, b) => {
          if (a.nombre_es.toUpperCase() < b.nombre_es.toUpperCase()) {return -1;}else{return 1;}
        });

        this.grupos = res.data;
      }

      
    });

    this.api.readData('disenadores').subscribe((res: any) => {
      for (const o of res.data) {
        this.disenadores.push(o);
      }
    });

    this.api.readData('referencias', '*', '1').subscribe((res: any) => {
      for (const o of res.data) {
        this.referenciasSelect.push({
          display: o.codigo,
          value: o.id,
        });

        this.referencias.push(o);
        this.referenciasID[o.id] = o;
      }
      this.cdRef.detectChanges();
    });

    this.api.readData('referencias', '*', '1').subscribe((res: any) => {
      for (const o of res.data) {
        this.referenciasRelSelect.push({
          display: o.codigo,
          value: o.id,
        });

        this.referenciasRel.push(o);
      }

      this.cdRef.detectChanges();
    });


    this.activatedRoute.params.subscribe((params) => {
      this.idSubfamilia = params.id;
      if (params.idDup != 'empty' && params.idDup != 'edit' && params.idDup != 0) {
        this.idCopiarSubfamilia = params.idDup;
        this.idSubfamilia = params.idDup;
      }

      this.subfamilia = new Subfamilia();
      this.subfamilia.clear();
      if (this.idSubfamilia && this.idSubfamilia > 0) {
        this.api
          .readData('subfamilias', '', 'id = ' + this.idSubfamilia)
          .subscribe((res: any) => {
            this.subfamilia = Object.assign(this.subfamilia, res.data[0]);
            if (this.idCopiarSubfamilia != 0){
              this.subfamilia.id = 0;
              this.subfamilia.codigo = '';
              this.subfamilia.img_destacada = '';
              this.subfamilia.imagenesPrincipales = '';
              this.subfamilia.imagenes = '';
              this.subfamilia.video_destacado = '';

            }
            this.initSubfamilia();

            if (this.subfamilia.imagenes !== '') {
              const parseImagenes = JSON.parse(this.subfamilia.imagenes);
              for (const i in parseImagenes) {
                if (parseImagenes[i]) {
                  this.imagenesSubfamilia.push([parseImagenes[i], this.api.getUrlBase() +
                    '/api/assets/images/subfamilias/' + this.subfamilia.codigo + '/' + parseImagenes[i]]);
                }
              }
            }

            if (this.subfamilia.imagenesPrincipales !== '') {
              const parseImagenes = JSON.parse(this.subfamilia.imagenesPrincipales);
              for (const i in parseImagenes) {
                if (parseImagenes[i]) {
                  this.imagenesPrinSubfamilia.push([parseImagenes[i], this.api.getUrlBase() +
                    '/api/assets/images/subfamilias/' + this.subfamilia.codigo + '/' + parseImagenes[i]]);
                }
              }
            }

            if (this.subfamilia.video_destacado !== '') {
              this.videoDestacado.src = this.api.getUrlBase() +
                '/api/assets/videos/subfamilias/' + this.subfamilia.codigo + '/' + this.subfamilia.video_destacado;
            }
            if (this.subfamilia.img_destacada !== '') {
              this.imagenDestacada[4] = true;
              const ext = this.subfamilia.img_destacada.split('.').pop();
              if (ext === 'svg') {
                this.imagenDestacada[2] = true;
                this.getImage('/api/assets/images/subfamilias/' + this.subfamilia.codigo + '/' + this.subfamilia.img_destacada)
                  .then((imagen) => {
                    // console.log(imagen)
                    this.imagenDestacada[1] =
                      this.domSanitizer.bypassSecurityTrustHtml(imagen.replace('<svg', '<svg style="width:100%; height: auto;"'));
                  });
              } else {
                this.imagenDestacada[2] = true;
                this.imagenDestacada[1] = this.api.getUrlBase()
                  + '/api/assets/images/subfamilias/' + this.subfamilia.codigo + '/' + this.subfamilia.img_destacada;
              }

              this.imagenDestacada[0] = this.subfamilia.img_destacada;
            }


            if (this.subfamilia.referencias && this.subfamilia.referencias !== '') {
              const jsonReferencias = JSON.parse(this.subfamilia.referencias);
              // console.log(jsonAcabados.selected);
              this.referenciasForm.setValue({ selected: jsonReferencias.selected });
            }
            if (this.subfamilia.acabados && this.subfamilia.acabados !== '') {
              const jsonAcabados = JSON.parse(this.subfamilia.acabados);
              if (jsonAcabados.selected != null){
                this.acabadosForm.setValue({ selected: jsonAcabados.selected });
              
                for (const acabado of this.acabadosForm.value.selected) {
                  this.acabadosSelect[acabado] = acabado;
                }
              }
            }

            if (this.subfamilia.acabados2 && this.subfamilia.acabados2 !== '') {
              const jsonAcabados2 = JSON.parse(this.subfamilia.acabados2);
              // console.log(jsonAcabados.selected);
              if (jsonAcabados2.selected != null){
                this.acabadosForm2.setValue({
                  selected: jsonAcabados2.selected,
                });
              
                for (const acabado of this.acabadosForm2.value.selected) {
                  this.acabadosSelect[acabado] = acabado;
                }
              }
            }

            if (this.subfamilia.acabados3 && this.subfamilia.acabados3 !== '') {
              const jsonAcabados3 = JSON.parse(this.subfamilia.acabados3);
              // console.log(jsonAcabados.selected);
              if (jsonAcabados3.selected != null){
                this.acabadosForm3.setValue({
                  selected: jsonAcabados3.selected,
                });

                for (const acabado of this.acabadosForm3.value.selected) {
                  this.acabadosSelect[acabado] = acabado;
                }
              }
            }

            if (
              this.subfamilia.referenciasRel && this.subfamilia.referenciasRel !== ''
            ) {
              const jsonReferenciasRel = JSON.parse(
                this.subfamilia.referenciasRel
              );
              if (jsonReferenciasRel != null){
                // console.log(jsonReferenciasRel.selected);
                this.referenciasRelForm.setValue({
                  selected: jsonReferenciasRel.selected,
                });
              }
            }

            if (
              this.subfamilia.relacionEntreRef && this.subfamilia.relacionEntreRef !== ''
            ) {
              // console.log(this.subfamilia.relacionEntreRef);
              const jsonRelacionRef = JSON.parse(
                this.subfamilia.relacionEntreRef
              );
              if (jsonRelacionRef != null){
                this.relacionRef = jsonRelacionRef;
              }
            }

            if (
              this.subfamilia.refRelOpcionales && this.subfamilia.refRelOpcionales !== ''
            ) {
              const jsonRefRelOpcionales = JSON.parse(
                this.subfamilia.refRelOpcionales
              );
              this.refSecundariasOpcionales = jsonRefRelOpcionales;
            }

            if (this.subfamilia.refRelPrecio && this.subfamilia.refRelPrecio !== '') {
              const jsonRefRelPrecio = JSON.parse(this.subfamilia.refRelPrecio);
              this.refSecundariasPrecio = jsonRefRelPrecio;
            }

            if (this.subfamilia.preciosEspeciales && this.subfamilia.preciosEspeciales !== '') {
              this.preciosEspeciales = JSON.parse(this.subfamilia.preciosEspeciales);
            }
            if (this.subfamilia.descargasLogin && this.subfamilia.descargasLogin !== '') {
              this.descargasLogin = JSON.parse(this.subfamilia.descargasLogin);
            }
          });


        /*
        this.api.readData('referencias', '*', 'subFamilia = ' + this.idSubfamilia).subscribe((res: any) => {
          var seleccionados = [];
          for (const o of res.data) {
            seleccionados.push(o.id);
          }
          // console.log(seleccionados);
          this.referenciasForm.setValue({
            selected: seleccionados,
          });
          this.cdRef.detectChanges();
        });
        */
      } else {
        this.api.readData('subfamilias', '').subscribe((res: any) => {
          this.orden = res.data.length;
          this.initSubfamilia();
      });
        
      }
    });

    // console.log(this.imagenDestacada);
    this.cdRef.detectChanges();
  }
  async getImage(url) {
    return await this.api.readSVG(url);
  }
  initSubfamilia() {
    this.createForm();
    if (!this.subfamilia.id) {
      this.subheaderService.setTitle('Crear colección');
      this.subheaderService.setBreadcrumbs([
        { title: 'Sub Familias', page: `subfamilias` },
        { title: 'Crear colección', page: `subfamilias/new` },
      ]);
      return;
    }
    this.subheaderService.setTitle('Editar Familia');
    this.subheaderService.setBreadcrumbs([
      { title: 'Sub Familias', page: `subfamilias` },
      {
        title: 'Editar colección',
        page: `subfamilias`,
        queryParams: { id: this.subfamilia.id },
      },
    ]);
  }

  createForm() {
    this.subfamiliaForm = this.fb.group({
      nombre_en: [this.subfamilia.nombre_en],
      nombre_es: [this.subfamilia.nombre_es],
      nombre_de: [this.subfamilia.nombre_de],
      nombre_fr: [this.subfamilia.nombre_fr],
      codigo: [this.subfamilia.codigo, Validators.required],
      descripcion_es: [this.subfamilia.descripcion_es],
      descripcion_en: [this.subfamilia.descripcion_en],
      descripcion_de: [this.subfamilia.descripcion_de],
      descripcion_fr: [this.subfamilia.descripcion_fr],
      referencias: [this.subfamilia.referencias],
      acabados: [this.subfamilia.acabados],
      acabados2: [this.subfamilia.acabados2],
      acabados3: [this.subfamilia.acabados3],
      referenciasRel: [this.subfamilia.referenciasRel],
      nombreAcabado_en: [this.subfamilia.nombreAcabado_en],
      nombreAcabado_es: [this.subfamilia.nombreAcabado_es],
      nombreAcabado_de: [this.subfamilia.nombreAcabado_de],
      nombreAcabado_fr: [this.subfamilia.nombreAcabado_fr],
      nombreAcabado2_en: [this.subfamilia.nombreAcabado2_en],
      nombreAcabado2_es: [this.subfamilia.nombreAcabado2_es],
      nombreAcabado2_de: [this.subfamilia.nombreAcabado2_de],
      nombreAcabado2_fr: [this.subfamilia.nombreAcabado2_fr],
      nombreAcabado3_en: [this.subfamilia.nombreAcabado3_en],
      nombreAcabado3_es: [this.subfamilia.nombreAcabado3_es],
      nombreAcabado3_de: [this.subfamilia.nombreAcabado3_de],
      nombreAcabado3_fr: [this.subfamilia.nombreAcabado3_fr],
      multiSelectPrin: this.subfamilia.multiSelectPrin == 1 ? true : false,
      multiSelectRel: this.subfamilia.multiSelectRel == 1 ? true : false,
      relacionEntreRef: [this.subfamilia.relacionEntreRef],
      preciosEspeciales: [this.subfamilia.preciosEspeciales],
      acabado1Precio: this.subfamilia.acabado1Precio == 1 ? true : false,
      acabado2Precio: this.subfamilia.acabado2Precio == 1 ? true : false,
      acabado3Precio: this.subfamilia.acabado3Precio == 1 ? true : false,
      subcategoria: [this.subfamilia.subcategoria],
      grupo: [this.subfamilia.grupo],
      docRevit: [this.subfamilia.docRevit],
      doc3DFiles: [this.subfamilia.doc3DFiles],
      doc2DFiles: [this.subfamilia.doc2DFiles],
      docFicha: [this.subfamilia.docFicha],
      docFotosHI: [this.subfamilia.docFotosHI],
      docFotosLO: [this.subfamilia.docFotosLO],
      img_destacada: [this.subfamilia.img_destacada],
      id_disenador: [this.subfamilia.id_disenador, Validators.required],
      video_destacado: [this.subfamilia.video_destacado],
      certificados: [this.subfamilia.certificados],
      garantias: [this.subfamilia.garantias],
      fondo_blanco: [this.subfamilia.fondo_blanco],
      hoja_montaje: [this.subfamilia.hoja_montaje],
      medidas_interiores: [this.subfamilia.medidas_interiores],
      presentacion: [this.subfamilia.presentacion],
      imagen_detalle: [this.subfamilia.imagen_detalle],
      video: [this.subfamilia.video],
    });

    // console.log(this.subfamiliaForm);
  }

  getComponentTitle() {
    let result = 'Crear colección';
    if (!this.subfamilia || !this.subfamilia.id) {
      return result;
    }

    result = `Editar  colección - ${this.subfamilia.nombre_es} ${this.subfamilia.codigo}`;
    return result;
  }

  saveSubfamilia() {
    const newSubfamilia = Object.assign(this.subfamilia, this.subfamiliaForm.value);
    newSubfamilia.refRelOpcionales = JSON.stringify(this.refSecundariasOpcionales);
    newSubfamilia.refRelPrecio = JSON.stringify(this.refSecundariasPrecio);
    newSubfamilia.relacionEntreRef = JSON.stringify(this.relacionRef);
    newSubfamilia.preciosEspeciales = JSON.stringify(this.preciosEspeciales);
    newSubfamilia.referencias = JSON.stringify(this.referenciasForm.value);
    newSubfamilia.acabados = JSON.stringify(this.acabadosForm.value);
    newSubfamilia.acabados2 = JSON.stringify(this.acabadosForm2.value);
    newSubfamilia.acabados3 = JSON.stringify(this.acabadosForm3.value);
    newSubfamilia.referenciasRel = JSON.stringify(
      this.referenciasRelForm.value
    );
    newSubfamilia.descargasLogin = JSON.stringify(this.descargasLogin);
    newSubfamilia.acabado1Precio = this.subfamiliaForm.controls.acabado1Precio.value === true ? 1 : 0;
    newSubfamilia.multiSelectRel = this.subfamiliaForm.controls.multiSelectRel.value === true ? 1 : 0;
    newSubfamilia.acabado2Precio = this.subfamiliaForm.controls.acabado2Precio.value === true ? 1 : 0;
    newSubfamilia.acabado3Precio = this.subfamiliaForm.controls.acabado3Precio.value === true ? 1 : 0;
    newSubfamilia.multiSelectPrin = this.subfamiliaForm.controls.multiSelectPrin.value === true ? 1 : 0;
    newSubfamilia.referenciasRel = JSON.stringify(this.referenciasRelForm.value);
    newSubfamilia.imagenes = this.imagenesSubfamilia;
    newSubfamilia.imagenesPrincipales = this.imagenesPrinSubfamilia;
    newSubfamilia.img_destacada = this.imagenDestacada[4]? this.imagenDestacada : '';


    newSubfamilia.video_destacado = this.videoDestacado.nombre !== ''
      ? this.videoDestacado
      : this.subfamiliaForm.controls.video_destacado.value;

    if (!newSubfamilia.id) {
      newSubfamilia.orden = this.orden;
      this.api.createData('subfamilias', newSubfamilia).subscribe((res: any) => {
        if (res) {
          this.router.navigate(['/panel/subfamilias']);
        }
      });
    } else {
      this.api.updateData('subfamilias', newSubfamilia).subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.router.navigate(['/panel/subfamilias']);
        }
      });
    }
  }

  devolverAcabado(id) {
    if (this.acabados.find((element) => element.value === id)) {
      return this.acabados.find((element) => element.value === id).display;
    }
  }

  devolverReferencias(id) {
    if (this.referenciasSelect.find((element) => element.value === id)) {
      return this.referenciasSelect.find((element) => element.value === id).display;
    }
  }

  devolverReferenciasRel(id) {
    if (this.referenciasRelSelect.find((element) => element.value === id)) {
      return this.referenciasRelSelect.find((element) => element.value === id).display;
    }
  }

  quitarItemAcabado(id) {
    const posicion: number = this.acabadosForm.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.acabadosForm.value.selected.splice(posicion, 1);
      const selecActual = this.acabadosForm.value.selected;
      this.acabadosForm = new FormGroup({
        selected: new FormControl(selecActual),
      });
    }
  }

  quitarItemAcabado2(id) {
    const posicion: number = this.acabadosForm2.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.acabadosForm2.value.selected.splice(posicion, 1);
      const selecActual2 = this.acabadosForm2.value.selected;
      this.acabadosForm2 = new FormGroup({
        selected: new FormControl(selecActual2),
      });
    }
  }

  quitarItemAcabado3(id) {
    const posicion: number = this.acabadosForm3.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.acabadosForm3.value.selected.splice(posicion, 1);
      const selecActual3 = this.acabadosForm3.value.selected;
      this.acabadosForm3 = new FormGroup({
        selected: new FormControl(selecActual3),
      });
    }
  }

  quitarReferenciaRel(id) {
    const posicion: number = this.referenciasRelForm.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.referenciasRelForm.value.selected.splice(posicion, 1);
      const selecActual2 = this.referenciasRelForm.value.selected;
      this.referenciasRelForm = new FormGroup({
        selected: new FormControl(selecActual2),
      });
    }
  }

  quitarItemReferencia(id) {
    // console.log(id);
    this.api.readData('referencias', '*', 'id = ' + id).subscribe((res: any) => {
      for (const o of res.data) {
        const referenciaModif = o;
        referenciaModif.subfamilia = 0;
        const imagenesReferencia = [];
        if (referenciaModif.imagenes !== '') {
          const parseImagenes = JSON.parse(referenciaModif.imagenes);
          for (const i in parseImagenes) {
            if (parseImagenes[i]) {
              imagenesReferencia.push([parseImagenes[i], this.api.getUrlBase() +
                '/api/assets/images/referencias/' + referenciaModif.codigo + '/' + parseImagenes[i]]);
            }
          }
        }
        referenciaModif.imagenes = imagenesReferencia;

        this.api
          .updateData('referencias', referenciaModif)
          .subscribe((res2: any) => { });
      }
    });

    const posicion: number = this.referenciasForm.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.referenciasForm.value.selected.splice(posicion, 1);
      const selecActual2 = this.referenciasForm.value.selected;
      this.referenciasForm = new FormGroup({ selected: new FormControl(selecActual2), });
    }

    this.cdRef.detectChanges();
  }

  ordenar(valor, id, tipo) {
    console.log('id:' + id);
    console.log('Valor:' + valor);

    if (tipo === 1) {
      const posicion: number = this.referenciasForm.value.selected.indexOf(id);

      if (posicion !== -1) {
        // this.referenciasForm.value.selected.move(posicion, eval(posicion + valor));
        this.referenciasForm.value.selected.splice(posicion + valor, 0, this.referenciasForm.value.selected.splice(posicion, 1)[0]);
        const selecActual2 = this.referenciasForm.value.selected;
        this.referenciasForm = new FormGroup({
          selected: new FormControl(selecActual2),
        });
      }
    } else if (tipo === 2) {
      const posicion: number = this.imagenesSubfamilia.indexOf(id);
      if (posicion !== -1) {
        this.imagenesSubfamilia.splice(posicion + valor, 0, this.imagenesSubfamilia.splice(posicion, 1)[0]);
        this.cdRef.detectChanges();
      }

    } else if (tipo === 3) {
      const posicion: number = this.referenciasRelForm.value.selected.indexOf(id);
      if (posicion !== -1) {

        this.referenciasRelForm.value.selected.splice(posicion + valor,
          0, this.referenciasRelForm.value.selected.splice(posicion, 1)[0]);
        const selecActual2 = this.referenciasRelForm.value.selected;
        this.referenciasRelForm = new FormGroup({
          selected: new FormControl(selecActual2),
        });
      }
    } else if (tipo === 4) {
      const posicion: number = this.imagenesPrinSubfamilia.indexOf(id);
      if (posicion !== -1) {
        this.imagenesPrinSubfamilia.splice(posicion + valor, 0, this.imagenesPrinSubfamilia.splice(posicion, 1)[0]);
        this.cdRef.detectChanges();
      }

    }
  }

  quitarItemRelacionado(id) {
    let posicion: number = this.referenciasRelForm.value.selected.indexOf(id);
    if (posicion !== -1) {
      this.referenciasRelForm.value.selected.splice(posicion, 1);
      const selecActual = this.referenciasRelForm.value.selected;
      this.referenciasRelForm = new FormGroup({
        selected: new FormControl(selecActual),
      });
    }

    posicion = this.refSecundariasPrecio.indexOf(id);
    if (posicion !== -1) {
      this.refSecundariasPrecio.splice(posicion, 1);
    }
  }

  quitarItemPrecioEsp(referencia, acabado) {
    const preciosEsp = this.preciosEspeciales;
    this.preciosEspeciales = [];

    for (const precioEsp of preciosEsp) {
      if (precioEsp.referencia !== referencia) {
        this.preciosEspeciales.push(precioEsp);
      } else {
        if (precioEsp.acabado !== acabado) {
          this.preciosEspeciales.push(precioEsp);
        }
      }
    }

    // console.log(this.preciosEspeciales);
  }

  borrarImagen(imagen) {
    this.imagenesSubfamilia = this.imagenesSubfamilia.filter(
      (obj) => obj !== imagen
    );
    this.cdRef.detectChanges();
  }

  borrarImagenPrinc(imagen) {
    this.imagenesPrinSubfamilia = this.imagenesPrinSubfamilia.filter(
      (obj) => obj !== imagen
    );
    this.cdRef.detectChanges();
  }

  borrarImagenDestacada() {
    this.imagenDestacada[1] = '../../../../../assets/img/thumbnail-600x350.png';
    this.imagenDestacada[2] = false;
    this.imagenDestacada[3] = false;
    this.cdRef.detectChanges();
  }

  borrarVideoDestacado() {
    this.videoDestacado = {
      nombre: '',
      src: 'assets/img/mock/video.mp4'
    };

  }
  preview(files) {
    if (files.length === 0) {
      return;
    }
    // console.log(files);
    for (const file of files) {
      this.cargarImagenes(file, 'configurador');
    }
  }
  preview2(files) {
    if (files.length === 0) {
      return;
    }
    // console.log(files);
    for (const file of files) {
      this.cargarImagenes(file, 'coleccion');
    }
  }

  cargarImagenes(imagen, tipo) {
    const mimeType = imagen.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // console.log(imagen);
    const reader = new FileReader();
    // this.referenciaForm['pic'] = files;
    reader.readAsDataURL(imagen);
    reader.onload = () => {
      const resultado = reader.result;
      var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
      if (tipo === 'coleccion') {
        this.imagenesPrinSubfamilia.push([
          name,
          this.domSanitizer.bypassSecurityTrustUrl(resultado.toString()),
        ]);
      } else if ( tipo === 'configurador'){
        this.imagenesSubfamilia.push([
          name,
          this.domSanitizer.bypassSecurityTrustUrl(resultado.toString()),
        ]);
      }

      this.cdRef.detectChanges();
    };
  }

  cargarImagenDestacada(img) {
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(img[0]);
    const ext = img[0].name.split('.').pop();
    if (ext === 'svg') {
      this.imagenDestacada[2] = true;
    } else {
      this.imagenDestacada[2] = false;
    }
    this.imagenDestacada[4] = true;
    myReader.onloadend = () => {
      this.imagenDestacada = [img[0].name, this.domSanitizer.bypassSecurityTrustUrl(myReader.result.toString())];
      this.imagenDestacada[4] = true;
      this.cdRef.detectChanges();
    };
    this.imagenDestacada[3] = true;
    
    this.cdRef.detectChanges();
  }

  cargarDoc(files, nombre) {
    // console.log(files);
    for (const file of files) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const resultado = reader.result;
        this.subfamilia[nombre] = resultado.toString();
        this.cdRef.detectChanges();
      };
    }
  }

  cargarVideoDestacado(e: any, videoDestacado: HTMLElement) {
    const video = e.target.files[0];
    const myReader: FileReader = new FileReader();
    myReader.readAsDataURL(video);
    myReader.onloadend = () => {
      this.render.setAttribute(videoDestacado, 'src', myReader.result as string);
      this.videoDestacado.nombre = video.name;
      this.videoDestacado.src = this.domSanitizer.bypassSecurityTrustUrl(myReader.result.toString());
    };
  }

  descargarDoc(datos, tipo) {
    const linkSource = datos;
    const downloadLink = document.createElement('a');
    const fileName = tipo + '.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  quitarDocumento(nombre) {
    this.subfamilia[nombre] = '';
  }

  marcarRelacion(relPrincipal: number, relSecundaria: number) {
    // console.log("Principal:" + relPrincipal);
    // console.log("Secundaria:" + relSecundaria);

    if (!this.relacionRef.hasOwnProperty(relPrincipal) || this.relacionRef[relPrincipal] == null) {
      this.relacionRef[relPrincipal] = {};

      this.relacionRef[relPrincipal][relSecundaria] = 1;
    } else {
      if (!this.relacionRef[relPrincipal].hasOwnProperty(relSecundaria)) {
        this.relacionRef[relPrincipal][relSecundaria] = 1;
      } else {
        delete this.relacionRef[relPrincipal][relSecundaria];
      }
    }
    // console.log(this.relacionRef);
  }

  revisarRelacion(relPrincipal: number, relSecundaria: number) {
    return true;
  }

  cambiosPrecioEsp(tipo, valor) {
    // console.log(tipo);
    // console.log(valor);

    switch (tipo) {
      case 1:
        this.referenciaEspecial = valor;
        break;
      case 2:
        this.acabadoEspecial = valor;
        break;
      case 3:
        this.importeNacEspecial = valor;
        break;
      case 4:
        this.importeExpEspecial = valor;
        break;
    }
  }

  savePrecioEsp() {
    this.preciosEspeciales.push({
      referencia: this.referenciaEspecial,
      acabado: this.acabadoEspecial,
      importeNac: this.importeNacEspecial,
      importeExp: this.importeExpEspecial,
    });
    this.referenciaEspecial = 0;
    this.acabadoEspecial = 0;
    this.importeNacEspecial = 0;
    this.importeExpEspecial = 0;
  }

  marcarRefSecundariaOpcional(tipoReferencia) {

    if (this.refSecundariasOpcionales.includes(tipoReferencia)) {
      const index = this.refSecundariasOpcionales.indexOf(tipoReferencia);
      if (index > -1) {
        this.refSecundariasOpcionales.splice(index, 1);
      }

    } else {
      this.refSecundariasOpcionales.push(tipoReferencia);
    }

  }

  marcarRefSecundariaPrecio(id) {

    if (this.refSecundariasPrecio.includes(id)) {
      const index = this.refSecundariasPrecio.indexOf(id);
      if (index > -1) {
        this.refSecundariasPrecio.splice(index, 1);
      }

    } else {
      this.refSecundariasPrecio.push(id);
    }

  }
  marcarDescargaLogin(key) {
    this.descargasLogin[key] = !this.descargasLogin[key];
  }

  existeTipoRefSec(tipo) {

    let existe = false;
    if (this.referenciasRelForm && this.referenciasRelForm.value && this.referenciasRelForm.value.selected != null){
      for (const ref of this.referenciasRelForm.value.selected) {
        const referencia = this.referencias.find((element) => element.id === ref);
        if (referencia && referencia.tipo === tipo) {
          existe = true;
        }
      }
    }
    
    return existe;

  }

  ngOnDestroy(): void {
    this.header.toggle();
    this.footer.toggle();
  }
}
