// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Subfamilia } from '../../../core/auth/_models/subfamilia.model';
import { Familia } from '../../../core/auth/_models/familia.model';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeleteSubfamiliaComponent } from './subfamilia/delete-subfamilia/delete-subfamilia.component';
import { TranslationService } from '../../../core/_base/layout';
/* Export to CSV */
import { ExportToCsv } from 'export-to-csv';
/* moment */
import * as moment from 'moment';
import { ChangeDetectorRef } from '@angular/core';
import { Subcategoria } from '../../../core/auth/_models/subcategoria.model';
import { fileURLToPath } from 'url';
@Component({
   selector: 'kt-subfamilias',
   templateUrl: './subfamilias.component.html',
   styleUrls: ['./subfamilias.component.scss']
})
export class SubfamiliasComponent implements OnInit, OnDestroy {

   inputSearch = '';
   subfamilias: Subfamilia[] = [];
   subfamilasFilter: Subfamilia[] = [];
   subfamliaSelect = 0;
   familias: Familia[] = [];
   dataSource;
   disenadores = [];
   categoriasSubfamilia = [];
   categoriaSubfamilia = "0";
   contador = {
       acabados : [],
       acabados2: [],
       acabados3: [],
       imagenes: [],
       referencias : [],
       referenciasRel : []
   };
   displayedColumns: string[] =
      [ 'order','nombre', 'codigo','disenador', 'categoria', 'opciones'];

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;

   selection = new SelectionModel<Subfamilia>(true, []);

   constructor(
      private api: ApiService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private subheaderService: SubheaderService,
      private matDialog: MatDialog,
      private header: HeaderService,
      private footer: FooterService,
      public ts: TranslationService,
      private cdRef: ChangeDetectorRef) {
      this.header.hide();
      this.footer.hide();
   }

   ngOnInit() {

      this.subheaderService.setTitle('Colecciones');
      this.api.readData('subcategorias').subscribe((res: any) => {
         for (const o of res.data) {
            this.categoriasSubfamilia[o.id] = o;
         }
      });
      this.api.readData('disenadores').subscribe((res: any) => {
         for (const d of res.data) {
            this.disenadores[d.id] = d;
         }
      });
      this.api.readData('subfamilias').subscribe((res: any) => {

         for (const o of res.data) {
            this.subfamilias.push(o);

            this.contador.acabados[o.id] = '-';
            this.contador.acabados2[o.id] = '-';
            this.contador.acabados3[o.id] = '-';
            this.contador.imagenes[o.id] = '-';
            this.contador.referencias[o.id] = '-';
            this.contador.referenciasRel[o.id] = '-';


            if (o.acabados && o.acabados !== '') {
               const jsonAcabados = JSON.parse(o.acabados);
               if (jsonAcabados.selected !== null && jsonAcabados.selected.length !== 0) {
                  this.contador.acabados[o.id] = jsonAcabados.selected.length;
               }

            }

            if (o.acabados2 && o.acabados2 !== '') {
               const jsonAcabados2 = JSON.parse(o.acabados2);

               if (jsonAcabados2.selected !== null && jsonAcabados2.selected.length !== 0) {
                  this.contador.acabados2[o.id] = jsonAcabados2.selected.length;
               }
            }
            if (o.acabados3 && o.acabados3 !== '') {
               const jsonAcabados3 = JSON.parse(o.acabados3);

               if (jsonAcabados3.selected !== null && jsonAcabados3.selected.length !== 0) {
                  this.contador.acabados3[o.id] = jsonAcabados3.selected.length;
               }
            }


            if (o.imagenes && o.imagenes !== '' && o.imagenes !== '[]') {
                const jsonImagenes = JSON.parse(o.imagenes);

                if (jsonImagenes && jsonImagenes.length !== 0 ) {
                    this.contador.imagenes[o.id] = jsonImagenes.length;
                }
            }

            if (o.referencias && o.referencias !== '') {
               const jsonReferencias = JSON.parse(o.referencias);
               if (jsonReferencias.selected !== null && jsonReferencias.selected.length !== 0) {
                  this.contador.referencias[o.id] = jsonReferencias.selected.length;
               }
            }

            if (o.referenciasRel && o.referenciasRel !== '') {
               const jsonReferenciasRel = JSON.parse(o.referenciasRel);
               if (jsonReferenciasRel.selected !== null && jsonReferenciasRel.selected.length !== 0) {
                  this.contador.referenciasRel[o.id] = jsonReferenciasRel.selected.length;
               }

            }
            /*
            this.api.readData('referencias', '*', 'subFamilia = ' + o.id).subscribe(res => {
               var seleccionados = [];
               for (const o of res['data']) {
                  seleccionados.push(o.id);
               }
               if (seleccionados.length !== 0){
                  this.contador.referencias[o.id] = seleccionados.length ;
               }
            });
            */

         }
         this.subfamilias = this.subfamilias.sort((a, b) => a.orden - b.orden);
         this.dataSource = new MatTableDataSource(this.subfamilias);
         this.dataSource.paginator = this.paginator;
         //this.dataSource.sort = this.sort;



      });
      this.api.readData('familias').subscribe((res: any) => {
         for (const o of res.data) {
            this.familias[o.id] = o.nombre;
         }
      });
   }
   
   applyFilter(event: Event) {
      this.dataSource = new MatTableDataSource(this.subfamilias);
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
      }
   }
   
   filtrar() {
      const value = this.subfamliaSelect;
      if (value == 0){
         this.dataSource = new MatTableDataSource(this.subfamilias);
      }else{
         this.inputSearch='';
         this.dataSource.data = this.subfamilias.filter(subfam =>subfam.subcategoria == value);
      }
   
   }

   ordenar(valor, subfamilia){

      const posicion: number =this.dataSource.data.indexOf(subfamilia);
      let ordenSubfam = subfamilia.orden;
      if (posicion !== -1) {
          
          this.dataSource.data.map((element:any) =>{
              let ordenOriginal = element.orden;
              if (ordenOriginal == ordenSubfam ){
                  element.orden = parseInt(element.orden) + valor;
                  let customElement:any = {id: element.id, orden:element.orden};
                  this.api.updateData('subfamilias', customElement).subscribe((res: any) => {
                  });
              };

              if (ordenOriginal == parseInt(ordenSubfam) + valor ){
                  element.orden = ordenSubfam;
                  let customElement:any = {id: element.id, orden:element.orden};
                  this.api.updateData('subfamilias', customElement).subscribe((res: any) => {
                  });
              };
          });
          
          this.dataSource.data = this.dataSource.data.sort((a, b) => a.orden - b.orden);
          //this.dataSource.splice(posicion + valor, 0, this.dataSource.splice(posicion, 1)[0]);
          
      }
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      //this.filtrar();
      this.cdRef.detectChanges();


  }

   isAllSelected(): boolean {
      const numSelected = this.selection.selected.length;
      const numRows = this.subfamilias.length;
      return numSelected === numRows;
   }

   masterToggle() {
      if (this.selection.selected.length === this.subfamilias.length) {
         this.selection.clear();
      } else {
         this.subfamilias.forEach(row => this.selection.select(row));
      }
   }

   fetchSubfamilias() {
      const messages = [];
      this.selection.selected.forEach(elem => {
         messages.push({
            text: `${elem.nombre_es}, ${elem.codigo}`,
            id: elem.id.toString(),
            status: elem.nombre_es
         });
      });
      console.log(messages);

   }

   editSubfamilia(id: number) {
      this.router.navigate(['../subfamilias/'+ id + '/', 'edit'], { relativeTo: this.activatedRoute });
   }
   duplicateSubfamilia(id: number) {
      this.router.navigate(['../subfamilias/new/', id], { relativeTo: this.activatedRoute });
   }

   deleteSubfamilia(id: number) {
      const subfamilia = this.subfamilias.find(i => i.id === id);
      const dialogConfig: MatDialogConfig = {
         autoFocus: true,
         maxWidth: '400px',
         data: {
            id: subfamilia.id,
            nombre: subfamilia.nombre_es,
            codigo: subfamilia.codigo
         }
      };
      const dialogRef = this.matDialog.open(DeleteSubfamiliaComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(res => {
         if (res) {
            this.api.deleteData('subfamilias', res).subscribe(res2 => {
               if (res2) {
                  this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
               }
            });
         }
      });
   }

   exportarCSV() {
      const nombre = 'coleccion';
      const options = {
         filename: nombre,
         fieldSeparator: ',',
         quoteStrings: '"',
         decimalSeparator: '.',
         showLabels: true,
         showTitle: false,
         title: 'coleccion ' + moment().format('DD/MM/YYYY'),
         useTextFile: false,
         useBom: true,
         useKeysAsHeaders: true,
         // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
      };
      const data: any[] = [];
      this.subfamilias.forEach(element => {
         data.push({
            Nombre: element.nombre_es,
            'Acabado 1': element.nombreAcabado_es,
            'Acabado 2': element.nombreAcabado2_es,
            'Acabado 3': element.nombreAcabado3_es
         });
      });

      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(data);
   }

   ngOnDestroy(): void {
      this.header.toggle();
   }
}
