import { BaseModel } from '../../_base/crud';

export class Comercial extends BaseModel {
    id: number;
    nombre: string;
    apellido1: string;
    apellido2: string;
    email: string;
    paises: string;
    
    clear(): void {
        this.id = undefined;
        this.nombre = '';
        this.apellido1 = '';
        this.apellido2 = '';
        this.email = '';
        this.paises = '';
    }
}
