import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-delete-distribuidor',
  templateUrl: './delete-distribuidor.component.html',
  styleUrls: ['./delete-distribuidor.component.scss']
})
export class DeleteDistribuidorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
