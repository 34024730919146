import { BaseModel } from '../../_base/crud';

export class Localizacion extends BaseModel {

    id: number;
    nombre_en: string;
    nombre_es: string;
    nombre_de: string;
    nombre_fr: string;
    padre: string;
    lat: number;
    lng: number;
    tipo: number;

    clear(): void {
        this.id = undefined;
        this.nombre_en = '';
        this.nombre_es = '';
        this.nombre_de = '';
        this.nombre_fr = '';
        this.padre = '';
        this.lat = 0;
        this.lng = 0;
        this.tipo = 0;
    }
}
