// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Disenador } from '../../../core/auth/_models/disenador.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DeleteDisenadorComponent } from './disenador/delete-disenador/delete-disenador.component';
import { ChangeDetectorRef } from '@angular/core';
@Component({
   selector: 'kt-disenadores',
   templateUrl: './disenadores.component.html',
   styleUrls: ['./disenadores.component.scss']
})
export class DisenadoresComponent implements OnInit, OnDestroy {

   disenadores: Disenador[] = [];
   dataSource;
   contadorImagenes = [];
   imagenesDisenador = [];
   tmpDisenadoresForm = new FormGroup({
      selected: new FormControl()
   });


   displayedColumns: string[] = [ 'order', 'nombre', 'opciones'];

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   @ViewChild(MatSort, { static: true }) sort: MatSort;

   selection = new SelectionModel<Disenador>(true, []);

   constructor(
      private api: ApiService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private subheaderService: SubheaderService,
      private matDialog: MatDialog,
      private header: HeaderService,
      private footer: FooterService,
      private cdRef: ChangeDetectorRef) {
      this.header.hide();
      this.footer.hide();
   }

   ngOnInit() {

      this.subheaderService.setTitle('Sub Familias');

      this.api.readData('disenadores').subscribe((res: any) => {
         for (const o of res.data) {
            this.disenadores.push(o);
            this.contadorImagenes[o.id] = '-';
         }
         this.disenadores = this.disenadores.sort((a, b) => a.orden - b.orden);

         this.dataSource = new MatTableDataSource(this.disenadores);
         this.dataSource.paginator = this.paginator;
         this.dataSource.sort = this.sort;

      });

   }

   ordenar(valor, disenador){

      const posicion: number =this.dataSource.data.indexOf(disenador);
      let ordenDisenador = disenador.orden;
      if (posicion !== -1) {
          
          this.dataSource.data.map((element:any) =>{
              let ordenOriginal = element.orden;
              if (ordenOriginal == ordenDisenador ){
                  element.orden = parseInt(element.orden) + valor;
                  let customElement:any = {id: element.id, orden:element.orden};
                  this.api.updateData('disenadores', customElement).subscribe((res: any) => {
                  });
              };

              if (ordenOriginal == parseInt(ordenDisenador) + valor ){
                  element.orden = ordenDisenador;
                  let customElement:any = {id: element.id, orden:element.orden};
                  this.api.updateData('disenadores', customElement).subscribe((res: any) => {
                  });
              };
          });
          this.dataSource.data = this.dataSource.data.sort((a, b) => a.orden - b.orden);
          //this.dataSource.data.splice(posicion + valor, 0, this.dataSource.data.splice(posicion, 1)[0]);
      }
      this.dataSource = new MatTableDataSource(this.dataSource.data);

      this.cdRef.detectChanges();


  }

   applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
         this.dataSource.paginator.firstPage();
      }
   }

   isAllSelected(): boolean {
      const numSelected = this.selection.selected.length;
      const numRows = this.disenadores.length;
      return numSelected === numRows;
   }

   masterToggle() {
      if (this.selection.selected.length === this.disenadores.length) {
         this.selection.clear();
      } else {
         this.disenadores.forEach(row => this.selection.select(row));
      }
   }

   fetchDisenadores() {
      const messages = [];
      this.selection.selected.forEach(elem => {
         messages.push({
            text: `${elem.descripcion_es}`,
            id: elem.id.toString(),
            status: elem.descripcion_es
         });
      });
      console.log(messages);

   }

   editDisenador(id: number) {
      this.router.navigate(['../disenadores/', id], { relativeTo: this.activatedRoute });
   }


   deleteDisenador(id: number) {

      const disenador = this.disenadores.find(i => i.id === id);
      const dialogConfig: MatDialogConfig = {
         autoFocus: true,
         maxWidth: '400px',
         data: {
            id: disenador.id,
            nombre: disenador.nombre
         }
      };

      const dialogRef = this.matDialog.open(DeleteDisenadorComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
               this.api.deleteData('disenadores', res).subscribe((res3: any) => {
                  if (res3) {
                     this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
                  }
               });
            }
      });

   }


   ngOnDestroy(): void {
      this.header.toggle();
   }
}
