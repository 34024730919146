import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { AboutUs } from '../../../core/auth/_models/about-us.model';
import { SubheaderService } from '../../../core/_base/layout';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslationService } from "../../../core/_base/layout";
@Component({
  selector: 'kt-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, OnDestroy {

  aboutUs: AboutUs;
  aboutUsForm: FormGroup;
  hasFormErrors = false;
  selectedTab = 0;
  loadingFile = false;
  imagenesAbout = [];
  valueImagenes = [];
  guardado = false;
  imagenesSlider = [];

  constructor(
    public _DomSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private subheaderService: SubheaderService,
    private api: ApiService,
    private header: HeaderService,
    private footer: FooterService,
    private cdRef: ChangeDetectorRef,
    public ts: TranslationService) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {

      this.aboutUs = new AboutUs();
      this.aboutUs.clear();
      this.api.readData('aboutus', '', 'id = 1').subscribe((res: any) => {
        this.aboutUs = Object.assign(this.aboutUs, res.data[0]);
        console.log(this.aboutUs);

        if (this.aboutUs.imagenes !== '') {
          const parseImagenes = JSON.parse(this.aboutUs.imagenes);
          for (const image of parseImagenes) {

            const extension = image.split('.').pop();
            this.imagenesAbout.push([image, this.api.getUrlBase() +
                '/api/assets/images/aboutus/' + this.aboutUs.id + '/' + image, extension]);

            if (this.aboutUs.imgBloque1 === image) {
              this.valueImagenes[image] = '1';
            } else if (this.aboutUs.imgBloque2 === image) {
              this.valueImagenes[image] = '2';
            } else if (this.aboutUs.imgBloque3 === image) {
              this.valueImagenes[image] = '3';
            }

            this.cdRef.detectChanges();
          }
        }
        if (this.aboutUs.imagenesSlider !== '') {
          const parseImagenes = JSON.parse(this.aboutUs.imagenesSlider);
          for (const i in parseImagenes) {
            if (parseImagenes[i]) {
              this.imagenesSlider.push([parseImagenes[i], this.api.getUrlBase() +
                '/api/assets/images/aboutus/' + this.aboutUs.id + '/' + parseImagenes[i]]);
            }
          }
        }
        this.createForm();
      });
    });

  }

  createForm() {
    this.aboutUsForm = this.fb.group({
      id: [this.aboutUs.id],
      tituloBloque1_en: [this.aboutUs.tituloBloque1_en],
      tituloBloque1_es: [this.aboutUs.tituloBloque1_es],
      tituloBloque1_de: [this.aboutUs.tituloBloque1_de],
      tituloBloque1_fr: [this.aboutUs.tituloBloque1_fr],
      descripcionBloque1_en: [this.aboutUs.descripcionBloque1_en],
      descripcionBloque1_es: [this.aboutUs.descripcionBloque1_es],
      descripcionBloque1_de: [this.aboutUs.descripcionBloque1_de],
      descripcionBloque1_fr: [this.aboutUs.descripcionBloque1_fr],
      imgBloque1: [this.aboutUs.imgBloque1],
      tituloBloque2_en: [this.aboutUs.tituloBloque2_en],
      tituloBloque2_es: [this.aboutUs.tituloBloque2_es],
      tituloBloque2_de: [this.aboutUs.tituloBloque2_de],
      tituloBloque2_fr: [this.aboutUs.tituloBloque2_fr],
      descripcionBloque2_en: [this.aboutUs.descripcionBloque2_en],
      descripcionBloque2_es: [this.aboutUs.descripcionBloque2_es],
      descripcionBloque2_de: [this.aboutUs.descripcionBloque2_de],
      descripcionBloque2_fr: [this.aboutUs.descripcionBloque2_fr],
      imgBloque2: [this.aboutUs.imgBloque2],
      tituloBloque3_en: [this.aboutUs.tituloBloque3_en],
      tituloBloque3_es: [this.aboutUs.tituloBloque3_es],
      tituloBloque3_de: [this.aboutUs.tituloBloque3_de],
      tituloBloque3_fr: [this.aboutUs.tituloBloque3_fr],
      descripcionBloque3_en: [this.aboutUs.descripcionBloque3_en],
      descripcionBloque3_es: [this.aboutUs.descripcionBloque3_es],
      descripcionBloque3_de: [this.aboutUs.descripcionBloque3_de],
      descripcionBloque3_fr: [this.aboutUs.descripcionBloque3_fr],
      imgBloque3: [this.aboutUs.imgBloque3],
      imagenes: [this.aboutUs.imagenes],
      imagenesSlider: [this.aboutUs.imagenesSlider]

    });
    this.cdRef.detectChanges();

    this.aboutUsForm.valueChanges.subscribe(x => {
      console.log('entra en cambio');
      this.guardado = false;
    });
  }

  getComponentTitle() {
    const result = `Editar Nosotros`;
    return result;
  }

  saveAbout() {
    const newAbout = Object.assign(this.aboutUs, this.aboutUsForm.value);
    newAbout.imagenes = this.imagenesAbout;
    //newAbout.imagenesSlider = this.imagenesSlider;
    
    for (const key of Object.keys(this.valueImagenes)) {
      if (this.valueImagenes[key] === '1') {
        newAbout.imgBloque1 = key;
      }
      if (this.valueImagenes[key] === '2') {
        newAbout.imgBloque2 = key;
      }
      if (this.valueImagenes[key] === '3') {
        newAbout.imgBloque3 = key;
      }
    }

    this.api.updateData('aboutus', newAbout).subscribe((res: any) => {
      console.log(res);
      if (res) {
        this.guardado = true;
        this.cdRef.detectChanges();

        setTimeout(() => {
          this.guardado = false;
          this.cdRef.detectChanges();
        }, 3000);
      }
    });

  }

  actualizaImgSelect(event, imagen) {
    this.guardado = true;

    for (const key of Object.keys(this.valueImagenes)) {
      if (this.valueImagenes[key] === event.value) {
        this.valueImagenes[key] = '';
      }
    }

    this.valueImagenes[imagen] = event.value;
    this.cdRef.detectChanges();
  }

  borrarImagen(imagen) {
    this.imagenesAbout = this.imagenesAbout.filter(obj => obj !== imagen);
    this.cdRef.detectChanges();
  }
  preview(files) {

    if (files.length === 0) {
      return;
    }
    // console.log(files);

    for (var _i = 0; _i < files.length; _i++) {
      this.cargarImagenes(files[_i]);
    }
  }

  ordenar(valor, id, tipo) {
    /*
      const posicion: number = this.imagenesPrinSubfamilia.indexOf(id);
      if (posicion !== -1) {
        this.imagenesPrinSubfamilia.splice(posicion + valor, 0, this.imagenesPrinSubfamilia.splice(posicion, 1)[0]);
        this.cdRef.detectChanges();
      }
    */
  }
  cargarImagenes(imagen) {

    const extension = imagen.name.split('.').pop();
    const reader = new FileReader();
    // this.referenciaForm['pic'] = files;
    reader.readAsDataURL(imagen);
    reader.onload = (_event) => {
      var name = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g,'') + '.' + imagen.name.split('.').pop();
      const resultado = reader.result;
      this.imagenesAbout.push([name, this._DomSanitizer.bypassSecurityTrustUrl(resultado.toString()), extension]);
      this.cdRef.detectChanges();
    };

  }

  updateUrl(event) {
    // event.target.src = '../../../../../assets/img/users/default.jpg';
  }

  ngOnDestroy(): void {
    this.header.toggle();
    this.footer.toggle();
  }

}
