import { Injectable, OnInit } from '@angular/core';
import { ApiService } from '../api/api.service';
import { ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit {

  cliente:any;

  logged = false;
  cesta = [];
  idPedido = null;
  mostrarLogin = false;
  mostrarCookies = true;
  mostrarNewsletter = true;
  currency = '';
  loggedChange: Subject<boolean> = new Subject<boolean>();
  idioma = 'es';
  paises = [];
  
  constructor(private api: ApiService, private translate: TranslateService) {
    this.idioma = this.translate.currentLang;

    this.loggedChange.subscribe((value) => {
      this.logged = value;
    });
  }
  ngOnInit() {
  }
  getCountries() {
    this.api.readData('paises').subscribe((res: any) => {
      for (const o of res.data) {
         this.paises[o.id] = o;
      }
   });
  }

  returnAnotationPrice(){
    return this.paises[this.cliente.pais].anotacionPrecio;
  }
  checkLogin() {
    
    if (localStorage.getItem('userToken')) {
      
      this.api
        .loginCliente()
        .subscribe((res: any) => {

          if (res.result.logged) {
            const usuario = res.data.userData;
            switch (usuario.tipo) {
              case '4':
                usuario.currency = 'CAD';
                break;
              case '6':
                usuario.currency = '$';
                break;
              default:
                usuario.currency = '€';
                break;
            }
            this.logged = true;
            this.cliente = usuario;
            this.checkCart().then(() => {});

          } else {
            this.cliente = null;
          }
          //this.cdRef.detectChanges();
        });

    } else {
     this.cliente = null;
     //this.cdRef.detectChanges();
    }
    

  }

  checkCart(): Promise<void> {
   return new Promise((resolve, reject) => {
      if (this.logged) {
          this.api.readData('pedidos', '*', 'estado = 1 and version = 2', 'fecha DESC').subscribe(
          (resPedido: any) => {
            if (resPedido.data.length > 0) {
              this.cesta = JSON.parse(resPedido.data[0].productos);
              this.idPedido = resPedido.data[0].id;
            }
            resolve();
          });
      } else {
        resolve();
      }
    });
  }

  abrirLogin() {
    this.mostrarLogin = true;
  }
  cerrarLogin() {
    this.checkLogin();
    this.mostrarLogin = false;
  }

  abrirNewsletter() {
    this.mostrarNewsletter = true;
  }

  cerrarNewsletter() {
    this.mostrarNewsletter = false;
    localStorage.setItem('newsletter', '1');
  }

  checkNewsletter() {
    if (localStorage.getItem('newsletter') !== undefined ) {
      this.mostrarNewsletter = false;
    }
  }
  checkCookies() {
    if (localStorage.getItem('cookies') ||sessionStorage.getItem('cookies') ) {
      this.mostrarCookies = false;
    }
  }


  cerrarCookies() {
    this.mostrarCookies = false;
  }

  aceptarCookies() {
    this.mostrarCookies = false;
    localStorage.setItem('cookies', '1');
  }
  rechazarCookies() {
    this.mostrarCookies = false;
    localStorage.setItem('cookies', '2');
  }

  aceptarEstaCookies() {
    this.mostrarCookies = false;
    sessionStorage.setItem('cookies', '1');
  }

  formatCurrency(importe: number) {
    let value = '';
    if (this.logged && this.paises[this.cliente.pais] && this.paises[this.cliente.pais].precios == 1 || this.logged && this.cliente.permitirPrecios == 1 ){
      if (this.idioma == "es"){
        value = importe.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      }else{
        value = importe.toLocaleString('en-IN', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
      }
    }else{
      return '-';
    }
    return (this.currency == '$' ? ' $ ':'') + value + (this.currency != '$' ? ' '+ this.currency:'');
  }
}
