import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { AboutUs } from '../../core/auth/_models/about-us.model';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'kt-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  aboutUs: AboutUs;
  imagenesAbout = { imgBloque1: '', imgBloque2: '', imgBloque3: ''};
  aboutUsCharged = false;
  idioma: string;
  slideConfigSimpl = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  imagenes: any[] = [
    { img: '../../assets/img/pages/about/cuna.png', ano: '1980', titulo: 'Cuna', autor: 'Lola Castelló, Daniel Nebot, Vicent Martínez', info: 'Se funda la empresa/company founded' },
    { img: '../../assets/img/pages/about/literatura.png', ano: '1985', titulo: 'Literatura', autor: 'Vicente martínez' },
    { img: '../../assets/img/pages/about/river.png', ano: '1989', titulo: 'River', autor: 'Terence Woodgate' },
    { img: '../../assets/img/pages/about/elx.png', ano: '1997', titulo: 'Elx', autor: 'Vicente Martínez', info: 'Premio nacional de diseño/National Desegn Award' },
    { img: '../../assets/img/pages/about/temps.png', ano: '1997', titulo: 'Temps', autor: 'Jorge Pensi' },
    { img: '../../assets/img/pages/about/premio.png', ano: '1997', titulo: 'Premio Nacional de Diseño', autor: 'Vicente Martínez, Jorge Pensi' },
    { img: '../../assets/img/pages/about/sussex.png', ano: '2000', titulo: 'Sussex', autor: 'Terence Woodgate' },
    { img: '../../assets/img/pages/about/tactile.png', ano: '2006', titulo: 'Tactile', autor: 'Terence Woodgate' },
    { img: '../../assets/img/pages/about/sillon_panticosa.png', ano: '2006', titulo: 'Panticosa', autor: 'Rafael Moneo' },
    { img: '../../assets/img/pages/about/dot 2.png', ano: '2010', titulo: 'Dot', autor: 'Terence Woodgate' },
    { img: '../../assets/img/pages/about/aparador_mario.png', ano: '2012', titulo: 'Stockholm', autor: 'Mario Ruiz' },
    { img: '../../assets/img/pages/about/open.png', ano: '2014', titulo: 'Literatura Open', autor: 'Vicente Martínez' }, 
    { img: '../../assets/img/pages/about/ACO.png', ano: '2016', titulo: 'Amor Cortese', autor: 'Monica Armani' },
    { img: '../../assets/img/pages/about/hug.png', ano: '2018', titulo: 'Hug', autor: 'Estudi Manel Molina' },
    { img: '../../assets/img/pages/about/mlm.png', ano: '2018', titulo: 'Malmö', autor: 'Mario Ruiz' },
    { img: '../../assets/img/pages/about/CHC.png', ano: '2020', titulo: 'Chicago', autor: 'Norm Architects' }
  ];
    slideConfig = {  
      "slidesToShow": 4,  
      "slidesToScroll": 4,  
      "infinite": true,
      "responsive": [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]  
    };  
  constructor(
    private api: ApiService,
    public translate: TranslateService,
    private cdRef: ChangeDetectorRef
    ) {
      this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
    }); 
  }

  ngOnInit() {

    this.api.readData('aboutus', '*', 'id = 1').subscribe((resAbout: any) => {
      this.aboutUs = resAbout.data[0];
      const carpetaImgAbout = this.api.getUrlBase() + '/api/assets/images/aboutus/1/';

      if (this.aboutUs.imgBloque1 !== '') {
        this.imagenesAbout.imgBloque1 = carpetaImgAbout + this.aboutUs.imgBloque1;
      }
      if (this.aboutUs.imgBloque2 !== '') {
        this.imagenesAbout.imgBloque2 = carpetaImgAbout + this.aboutUs.imgBloque2;
      }
      if (this.aboutUs.imgBloque3 !== '') {
        this.imagenesAbout.imgBloque3 = carpetaImgAbout + this.aboutUs.imgBloque3;
      }

      this.aboutUsCharged = true;
      this.cdRef.detectChanges();
    });
  }


  imgFileType(img) {
    if (img.includes('.mp4') || img.includes('.avi') || img.includes('.ogg') || img.includes('.webm')  ) {
      return 'video';
    } else {
      return 'image';
    }
  }

}
