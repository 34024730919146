import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import { CategoriaProyecto } from '../../../../src/app/core/auth/_models/categoria-proyecto.model';
import { Pais } from '../../../../src/app/core/auth/_models/pais.model';
import { Proyecto } from '../../../../src/app/core/auth/_models/proyecto.model';
import { ApiService } from '../../../../src/app/services/api/api.service';

@Component({
  selector: 'kt-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  categorias: CategoriaProyecto[] = [];
  proyectosTodos: Proyecto[] = [];
  proyectosFiltrados: Proyecto[] = [];
  paises: Pais[] = [];
  posicionCategoriaActiva: number = 0;
  idioma: string;
  slideConfigSimpl = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  constructor(private api: ApiService, public translate: TranslateService, private cdRef: ChangeDetectorRef) {
    this.idioma = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.idioma = event.lang;
    });
  }

  async ngOnInit() {
    await this.api.readData('categoriasproyectos').pipe(
      map((res: any) => res.data.map(cat => {
        this.categorias.push(cat)
      }))
    ).toPromise();


    await this.api.readData('paises').pipe(
      map((res: any) => res.data.map(pais => {
        this.paises.push(pais)
      }))
    ).toPromise();

    await this.api.readData('proyectos').pipe(
      map((res: any) => res.data.map(proy => {
        const arrImgs = JSON.parse(proy.imagenes).map((element: any) => `${this.api.getUrlBase()}/api/assets/images/proyectos/${proy.id}/${element}`);

        proy.imagenes = proy.imagenes == '[]' ? ['assets/img/mock/nuestros-arquitectos.png'] : arrImgs;

        this.proyectosTodos.push(proy);
        //console.log(this.proyectosTodos)
      }))
    ).toPromise();
    this.proyectosFiltrados = this.proyectosTodos.filter(proy => proy.id_categoria == this.categorias[this.posicionCategoriaActiva].id);
    this.cdRef.detectChanges();
  }


  filtrarPorCategoria(cat: CategoriaProyecto): void {
    this.proyectosFiltrados = this.proyectosTodos.filter(proy => proy.id_categoria == cat.id);

    this.posicionCategoriaActiva = this.categorias.indexOf(cat);

    this.cdRef.detectChanges();
  }

  nombrePais(id_pais: number) {
    const pais = this.paises.filter(pais => pais.id == id_pais)[0];
    return this.idioma === 'es' ? pais.nombre : pais.name;
  }

}
