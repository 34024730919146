// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort, MatDialogConfig } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Descarga } from '../../../core/auth/_models/descarga.model';
import { DeleteDescargasComponent } from './descarga/delete-descargas/delete-descargas.component';
import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { TranslationService } from '../../../core/_base/layout';
@Component({
    selector: 'kt-descargas',
    templateUrl: './descargas-panel.component.html',
    styleUrls: ['./descargas-panel.component.scss']
})
export class DescargasPanelComponent implements OnInit, OnDestroy {

    descargas: Descarga[] = [];
    dataSource;
    contadorImagenes = [];
    imagenesDescarga = [];
    tmpDescargasForm = new FormGroup({
        selected: new FormControl()
    });


    displayedColumns: string[] = ['orden', 'nombre', 'opciones'];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    selection = new SelectionModel<Descarga>(true, []);

    constructor(
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private subheaderService: SubheaderService,
        private matDialog: MatDialog,
        private header: HeaderService,
        private footer: FooterService,
        private cdRef: ChangeDetectorRef,
        public ts:TranslationService) {
        this.header.hide();
        this.footer.hide();
    }

    ngOnInit() {

        this.subheaderService.setTitle('Descargas');

        this.api.readData('descargas').subscribe((res: any) => {
            for (const o of res.data) {
                this.descargas.push(o);
            }
            this.descargas = this.descargas.sort((a, b) => a.orden - b.orden);
            this.dataSource = new MatTableDataSource(this.descargas);
            this.dataSource.paginator = this.paginator;
            //this.dataSource.sort = this.sort;

        });

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    ordenar(valor, descarga){

        const posicion: number =this.dataSource.data.indexOf(descarga);
        let ordenDescarga = descarga.orden;
        if (posicion !== -1) {
            
            this.dataSource.data.map((element:any) =>{
                let ordenOriginal = element.orden;
                if (ordenOriginal == ordenDescarga ){
                    element.orden = parseInt(element.orden) + valor;
                    let customElement:any = {id: element.id, orden:element.orden};
                    this.api.updateData('descargas', customElement).subscribe((res: any) => {
                    });
                };

                if (ordenOriginal == parseInt(ordenDescarga) + valor ){
                    element.orden = ordenDescarga;
                    let customElement:any = {id: element.id, orden:element.orden};
                    this.api.updateData('descargas', customElement).subscribe((res: any) => {
                    });
                };
            });
            this.dataSource.data = this.dataSource.data.sort((a, b) => a.orden - b.orden);
            //this.dataSource.data.splice(posicion + valor, 0, this.dataSource.data.splice(posicion, 1)[0]);
        }
        this.dataSource = new MatTableDataSource(this.dataSource.data);

        this.cdRef.detectChanges();


    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.descargas.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.selection.selected.length === this.descargas.length) {
            this.selection.clear();
        } else {
            this.descargas.forEach(row => this.selection.select(row));
        }
    }

    fetchDescargas() {
        const messages = [];
        this.selection.selected.forEach(elem => {
            messages.push({
                text: `${elem.nombre_es}`,
                id: elem.id.toString(),
                status: elem.nombre_es
            });
        });
        console.log(messages);

    }

    editDescarga(id: number) {
        this.router.navigate(['../descargasPanel/', id], { relativeTo: this.activatedRoute });
    }

    deleteDescarga(id: number) {
        
        let descarga = this.descargas.find(i => i.id === id);
        const dialogConfig: MatDialogConfig = {
            autoFocus: true,
            maxWidth: '400px',
            data: {
        id: descarga.id,
        nombre: descarga.nombre_es,
            }
        }
        const dialogRef = this.matDialog.open(DeleteDescargasComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
                this.api.deleteData('descargas', res).subscribe((res: any) => {
                    if (res) {
                        this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
                    }
                });
            }
        });

    }


    ngOnDestroy(): void {
        this.header.toggle();
    }
}
