// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { TipoReferencia } from '../../../core/auth/_models/tipo-referencia.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DeleteTipoReferenciaComponent } from './tipo-referencia/delete-tipo-referencia/delete-tipo-referencia.component';
import { TranslationService } from '../../../core/_base/layout';
@Component({
  selector: 'kt-tipos-referencias',
  templateUrl: './tipos-referencias.component.html',
  styleUrls: ['./tipos-referencias.component.scss']
})
export class TiposReferenciasComponent implements OnInit, OnDestroy {

  tiposReferencias: TipoReferencia[] = [];
  dataSource;
  contadorImagenes = [];
  imagenesTipoReferencia = [];
  tmpTipoReferenciasForm = new FormGroup({
    selected: new FormControl()
  });


  displayedColumns: string[] = ['select', 'id', 'nombre', 'orden', 'opciones'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  selection = new SelectionModel<TipoReferencia>(true, []);

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private subheaderService: SubheaderService,
    private matDialog: MatDialog,
    private header: HeaderService,
    private footer: FooterService,
    public ts: TranslationService) {
    this.header.hide();
    this.footer.hide();
  }

  ngOnInit() {

    this.subheaderService.setTitle('Sub Familias');

    this.api.readData('tiposreferencias').subscribe((res: any) => {
      for (const o of res.data) {
        this.tiposReferencias.push(o);
      }
      this.dataSource = new MatTableDataSource(this.tiposReferencias);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.tiposReferencias.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.tiposReferencias.length) {
      this.selection.clear();
    } else {
      this.tiposReferencias.forEach(row => this.selection.select(row));
    }
  }

  fetchTipoReferencias() {
    const messages = [];
    this.selection.selected.forEach(elem => {
      messages.push({
        text: `${elem.nombre_es}`,
        id: elem.id.toString(),
        status: elem.nombre_es
      });
    });
    console.log(messages);

  }

  editTipoReferencia(id: number) {
    this.router.navigate(['../tiposReferencias/', id], { relativeTo: this.activatedRoute });
  }


  deleteTipoReferencia(id: number) {

    let tipoReferencia = this.tiposReferencias.find(i => i.id === id);
    const dialogConfig: MatDialogConfig = {
      autoFocus: true,
      maxWidth: '400px',
      data: {
        id: tipoReferencia.id,
        nombre: tipoReferencia.nombre_es
      }
    }
    const dialogRef = this.matDialog.open(DeleteTipoReferenciaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res: any) => {
      this.api.deleteData('tiposreferencias', res).subscribe((res: any) => {
        if (res) {
          this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
        }
      });
    });



  }


  ngOnDestroy(): void {
    this.header.toggle();
  }
}
