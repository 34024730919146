import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';

import { Disenador } from '../../../../src/app/core/auth/_models/disenador.model';
import { ApiService } from '../../../../src/app/services/api/api.service';

@Component({
  selector: 'kt-designers',
  templateUrl: './designers.component.html',
  styleUrls: ['./designers.component.scss']
})
export class DesignersComponent implements OnInit {

  designers: Disenador[] = [];
  constructor(private api: ApiService, private cdRef: ChangeDetectorRef) { }

  async ngOnInit() {
    await this.api.readData('disenadores').pipe(
      map((res: any) => res.data.map(design => {
        design.imagenes = design.imagenes != '[]' ? this.api.URLBase + '/api/assets/images/disenadores/' + design.id + '/' + JSON.parse(design.imagenes)[0] : 'assets/img/mock/nuestros-arquitectos.png';
        this.designers.push(design);
      }))
    ).toPromise();
    
    this.designers = this.designers.sort((a, b) => a.orden - b.orden);

    this.cdRef.detectChanges();

  }

}
