import { BaseModel } from '../../_base/crud';

export class Familia extends BaseModel {
    id: number;
    nombre: string;
    codigo: string;
    imagenes: string;
    clear(): void {
        this.id = undefined;
        this.nombre = '';
        this.codigo = '';
        this.imagenes = '';
    }
}
