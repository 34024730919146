import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-acabado',
  templateUrl: './delete-categoria-proyecto.component.html',
  styleUrls: ['./delete-categoria-proyecto.component.scss']
})
export class DeleteCategoriaProyectoComponent implements OnInit {

  id: number;
  nombre_es: string;

  constructor(public dialogRef: MatDialogRef<DeleteCategoriaProyectoComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.nombre_es = data.nombre_es;
  }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }

}
