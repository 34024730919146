// Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
import { ErrorPageComponent } from './views/theme/content/error-page/error-page.component';
// Auth
import { AuthGuard } from './core/auth';
import { MainComponent } from './pages/main/main.component';

import { UsuariosComponent } from './views/pages/usuarios/usuarios.component';
import { ClientesComponent } from './views/pages/clientes/clientes.component';
import { ReferenciasComponent } from './views/pages/referencias/referencias.component';

import { PedidosComponent } from './views/pages/pedidos/pedidos.component';

import { UsuarioComponent } from './views/pages/usuarios/usuario/usuario.component';
import { ClienteComponent } from './views/pages/clientes/cliente/cliente.component';
import { ReferenciaComponent } from './views/pages/referencias/referencia/referencia.component';
import { FamiliasComponent } from './views/pages/familias/familias.component';
import { FamiliaComponent } from './views/pages/familias/familia/familia.component';
import { AcabadosComponent } from './views/pages/acabados/acabados.component';
import { DescargasPanelComponent } from './views/pages/descargas-panel/descargas-panel.component';
import { DescargaComponent } from './views/pages/descargas-panel/descarga/descarga.component';
import { AcabadoComponent } from './views/pages/acabados/acabado/acabado.component';
import { CategoriasComponent } from './views/pages/categorias/categorias.component';
import { CategoriaComponent } from './views/pages/categorias/categoria/categoria.component';
import { SubfamiliasComponent } from './views/pages/subfamilias/subfamilias.component';
import { SubfamiliaComponent } from './views/pages/subfamilias/subfamilia/subfamilia.component';
import { ComercialesComponent } from './views/pages/comerciales/comerciales.component';
import { ComercialComponent } from './views/pages/comerciales/comercial/comercial.component';
import { PedidoComponent } from './views/pages/pedidos/pedido/pedido.component';
import { PreciosReferenciasComponent } from './views/pages/precios-referencias/precios-referencias.component';
import { EstadisticasComponent } from './views/pages/estadisticas/estadisticas.component';
import { RegistroComponent } from './pages/registro/registro.component';
import { EstadisticasDescargasComponent } from './views/pages/estadisticas-descargas/estadisticas-descargas.component';
import { LoginComponent } from './pages/login/login.component';
import { ColeccionesComponent } from './pages/colecciones/colecciones.component';
import { DisenadoresComponent } from './views/pages/disenadores/disenadores.component';
import { DisenadorComponent } from './views/pages/disenadores/disenador/disenador.component';

import { CartComponent } from './pages/cart/cart.component';
import { RecuperacionComponent } from './pages/recuperacion/recuperacion.component';
import { TiposReferenciasComponent } from './views/pages/tipos-referencias/tipos-referencias.component';
import { TipoReferenciaComponent } from './views/pages/tipos-referencias/tipo-referencia/tipo-referencia.component';

import { HomeComponent } from './pages/home/home.component';
import { AboutUsComponent } from './views/pages/about-us/about-us.component';
import { ProductsComponent } from './pages/products/products.component';
import { ProductComponent } from './pages/products/product/product.component';
import { DesignersComponent } from './pages/designers/designers.component';
import { DesignerComponent } from './pages/designers/designer/designer.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProyectoComponent } from './views/pages/proyectos/proyecto/proyecto.component';
import { ProyectosComponent } from './views/pages/proyectos/proyectos.component';
import { CategoriaProyectoComponent } from './views/pages/categorias-proyectos/categoria-proyecto/categoria-proyecto.component';
import { CategoriasProyectosComponent } from './views/pages/categorias-proyectos/categorias-proyectos.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { HomePanelComponent } from './views/pages/home-panel/home-panel.component';
import { LocalizacionesComponent } from './views/pages/localizaciones/localizaciones.component';
import { LocalizacionComponent } from './views/pages/localizaciones/localizacion/localizacion.component';
import { DistribuidoresComponent } from './views/pages/distribuidores/distribuidores.component';
import { DistribuidorComponent } from './views/pages/distribuidores/distribuidor/distribuidor.component';
import { DistributorsComponent } from './pages/distributors/distributors.component';
import { SostenibilidadComponent } from './pages/sostenibilidad/sostenibilidad.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { ColeccionesProductoAcabadoComponent } from './pages/colecciones-producto-acabado/colecciones-producto-acabado.component';
import { GruposComponent } from './views/pages/grupos/grupos.component';
import { GrupoComponent } from './views/pages/grupos/grupo/grupo.component';

const routes: Routes = [
  { path: 'main', component: MainComponent },
  { path: 'login', component: LoginComponent },
  { path: 'registro/:tipo', component: RegistroComponent },
  { path: 'signup', component: RegistroComponent },
  { path: 'recuperacion/:token', component: RecuperacionComponent },
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: 'colecciones', component: ColeccionesComponent },
  { path: 'colecciones/:descargas', component: ColeccionesComponent },

  { path: 'home', component: HomeComponent },
  { path: 'products', component: ProductsComponent, data:{link:'products'}  },
  { path: 'products-config', component: ProductsComponent, data:{link:'products-config'} },
  { path: 'products-finished', component: ProductsComponent, data:{link:'products-finished'} },
  { path: 'products-finished/:id/:name', component: ProductComponent, data:{modo:'product-finished'}} ,
  { path: 'downloads-punt', component: ProductsComponent },
  { path: 'products/:name', component: ProductComponent, data:{modo:'extendido'} },
  { path: 'products-config/:name', component: ProductComponent, data:{modo:'simplificado'} },
  { path: 'designers', component: DesignersComponent },
  { path: 'designers/:name', component: DesignerComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'about', component: AboutComponent },
  { path: 'distributors', component: DistributorsComponent, data:{modo:'extendido'} },
  { path: 'sostenibilidad', component: SostenibilidadComponent },
  { path: 'cookies', component: CookiesComponent },

  { path: 'cart', component: CartComponent },
  { path: 'cart/:idpedido', component: CartComponent },
  { path: 'colecciones-producto-acabado', component: ColeccionesProductoAcabadoComponent },
  {
    path: 'panel',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      { path: 'variables-globales', component: HomePanelComponent },
      { path: 'nosotros', component: AboutUsComponent },
      { path: 'acabados', component: AcabadosComponent },
      { path: 'acabados/:id', component: AcabadoComponent },
      { path: 'categorias', component: CategoriasComponent },
      { path: 'categorias/:id', component: CategoriaComponent },
      { path: 'grupos', component: GruposComponent },
      { path: 'grupos/:id', component: GrupoComponent },
      { path: 'clientes', component: ClientesComponent },
      { path: 'clientes/:id', component: ClienteComponent },
      { path: 'comerciales', component: ComercialesComponent },
      { path: 'comerciales/:id', component: ComercialComponent },
      { path: 'descargasPanel', component: DescargasPanelComponent },
      { path: 'descargasPanel/:id', component: DescargaComponent },
      { path: 'disenadores', component: DisenadoresComponent },
      { path: 'disenadores/:id', component: DisenadorComponent },
      { path: 'estadisticas', component: EstadisticasComponent },
      { path: 'estadisticasDescargas', component: EstadisticasDescargasComponent },
      { path: 'familias', component: FamiliasComponent },
      { path: 'familias/:id', component: FamiliaComponent },
      { path: 'pedidos', component: PedidosComponent },
      { path: 'pedidos/:id', component: PedidoComponent },
      { path: 'precios', component: PreciosReferenciasComponent },
      { path: 'proyectos', component: ProyectosComponent },
      { path: 'proyectos/:id', component: ProyectoComponent },
      { path: 'catProyectos', component: CategoriasProyectosComponent },
      { path: 'catProyectos/:id', component: CategoriaProyectoComponent },
      { path: 'referencias', component: ReferenciasComponent },
      { path: 'referencias/:id', component: ReferenciaComponent },
      { path: 'subfamilias', component: SubfamiliasComponent },
      { path: 'subfamilias/:id/:idDup', component: SubfamiliaComponent },
      { path: 'tiposReferencias', component: TiposReferenciasComponent },
      { path: 'tiposReferencias/:id', component: TipoReferenciaComponent },
      { path: 'usuarios', component: UsuariosComponent },
      { path: 'usuarios/:id', component: UsuarioComponent },
      { path: 'distribuidores', component: DistribuidoresComponent },
      { path: 'distribuidores/:id', component: DistribuidorComponent },
      { path: 'localizaciones', component: LocalizacionesComponent },
      { path: 'localizaciones/:id', component: LocalizacionComponent },
      {
        path: 'error/403',
        component: ErrorPageComponent,
        data: {
          type: 'error-v6',
          code: 403,
          title: '403... Access forbidden',
          desc:
            'Looks like you don\'t have permission to access for requested page.<br> Please, contact administrator',
        },
      },
      { path: 'error/:type', component: ErrorPageComponent },
      { path: '', redirectTo: 'subfamilias', pathMatch: 'full' },
      { path: '**', redirectTo: 'subfamilias', pathMatch: 'full' },
    ],
  },

  { path: '**', redirectTo: 'home', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
