import { BaseModel } from '../../_base/crud';

export class Acabado extends BaseModel {
    id: number;
    grupo: string;
    codigoColor: string;
    descripcion_es: string;
    descripcion_en: string;
    descripcion_de: string;
    descripcion_fr: string;
    orden: number;
    imagenes: string;
    activo: number;

    clear(): void {
        this.id = undefined;
        this.grupo = '';
        this.codigoColor = '';
        this.descripcion_es = '';
        this.descripcion_en = '';
        this.descripcion_de = '';
        this.descripcion_fr = '';
        this.orden = 0;
        this.imagenes = '';
        this.activo = 1;
    }
}
