import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Pedido } from '../../../../core/auth/_models/pedido.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';

@Component({
	selector: 'kt-pedido',
	templateUrl: './pedido.component.html',
	styleUrls: ['./pedido.component.scss']
})
export class PedidoComponent implements OnInit, OnDestroy {

	pedido: Pedido;
	pedidoId: Observable<number>;
	pedidoForm: FormGroup;
	hasFormErrors: boolean = false;
	selectedTab = 0;
	imagenesPedido = [];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private subheaderService: SubheaderService,
		private api: ApiService,
		private header: HeaderService,
		private footer: FooterService,
		private cdRef: ChangeDetectorRef) {
		this.header.hide();
		this.footer.hide();
	}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			const id = params.id;
			this.pedido = new Pedido();
			this.pedido.clear();
			if (id && id > 0) {
				this.api.readData('pedidos','', 'id = ' + id).subscribe((res: any) => {
					this.pedido = Object.assign(this.pedido, res.data[0]);

					this.initPedido();
				})
			} else {
				this.initPedido();
			}
		});
	}

	initPedido() {

		if (!this.pedido.id) {
			this.subheaderService.setTitle('Crear pedido');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Pedidos', page: `pedidos` },
				{ title: 'Crear pedido', page: `pedidos/new` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar Pedido');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Pedidos', page: `pedidos` },
			{ title: 'Editar pedido', page: `pedidos`, queryParams: { id: this.pedido.id } }
		]);
	}


	getComponentTitle() {
		let result = 'Crear pedido';
		if (!this.pedido || !this.pedido.id) {
			return result;
		}

		result = `Visualizando pedido - ${this.pedido.id} - ${this.pedido.idCliente} - ${this.pedido.fecha}`;
		return result;
	}



	updateUrl(event) {
		//event.target.src = '../../../../../assets/img/users/default.jpg';
	}

	ngOnDestroy(): void {
		this.header.toggle();
		this.footer.toggle();
	}

}
