import { BaseModel } from '../../_base/crud';

export class AboutUs extends BaseModel {


id:number;
tituloBloque1_en: string;
tituloBloque1_es: string;
tituloBloque1_de: string;
tituloBloque1_fr: string;
descripcionBloque1_en: string;
descripcionBloque1_es: string;
descripcionBloque1_de: string;
descripcionBloque1_fr: string;
imgBloque1: string;
tituloBloque2_en: string;
tituloBloque2_es: string;
tituloBloque2_de: string;
tituloBloque2_fr: string;
descripcionBloque2_en: string;
descripcionBloque2_es: string;
descripcionBloque2_de: string;
descripcionBloque2_fr: string;
imgBloque2: string;
tituloBloque3_en: string;
tituloBloque3_es: string;
tituloBloque3_de: string;
tituloBloque3_fr: string;
descripcionBloque3_en: string;
descripcionBloque3_es: string;
descripcionBloque3_de: string;
descripcionBloque3_fr: string;
imgBloque3: string;
imagenes: string;
imagenesSlider: string;


    clear(): void {

        this.id = 0;
        this.tituloBloque1_en = '';
        this.tituloBloque1_es = '';
        this.tituloBloque1_de = '';
        this.tituloBloque1_fr = '';
        this.descripcionBloque1_en = '';
        this.descripcionBloque1_es = '';
        this.descripcionBloque1_de = '';
        this.descripcionBloque1_fr = '';
        this.imgBloque1 = '';
        this.tituloBloque2_en = '';
        this.tituloBloque2_es = '';
        this.tituloBloque2_de = '';
        this.tituloBloque2_fr = '';
        this.descripcionBloque2_en = '';
        this.descripcionBloque2_es = '';
        this.descripcionBloque2_de = '';
        this.descripcionBloque2_fr = '';
        this.imgBloque2 = '';
        this.tituloBloque3_en = '';
        this.tituloBloque3_es = '';
        this.tituloBloque3_de = '';
        this.tituloBloque3_fr = '';
        this.descripcionBloque3_en = '';
        this.descripcionBloque3_es = '';
        this.descripcionBloque3_de = '';
        this.descripcionBloque3_fr = '';
        this.imgBloque3 = '';
        this.imagenes = '';
        this.imagenesSlider = '';
        
    }
}
