import { Component, OnInit } from '@angular/core';
import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

@Component({
  selector: 'kt-custom-url-serializer',
  templateUrl: './custom-url-serializer.component.html',
  styleUrls: ['./custom-url-serializer.component.scss']
})
export class CustomUrlSerializerComponent implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    // Encode "+" to "%2B"
    url = url.replace('%20', '-');
    // Use the default serializer.
    return this._defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    return this._defaultUrlSerializer.serialize(tree).replace('%20', '-');
  }

}
