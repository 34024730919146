import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
// Services
import { ApiService } from '../../../../services/api/api.service';
import { HeaderService } from '../../../../services/header/header.service';
import { FooterService } from '../../../../services/footer/footer.service';
// Models
import { Grupo } from '../../../../core/auth/_models/grupo.model';
import { Observable } from 'rxjs';
import { SubheaderService } from '../../../../core/_base/layout';
import {DomSanitizer} from '@angular/platform-browser';
import { TranslationService } from "../../../../core/_base/layout";
@Component({
	selector: 'kt-grupo',
	templateUrl: './grupo.component.html',
	styleUrls: ['./grupo.component.scss']
})
export class GrupoComponent implements OnInit, OnDestroy {

	grupo: Grupo;
	grupoId: Observable<number>;
	grupoForm: FormGroup;
	hasFormErrors: boolean = false;
	selectedTab = 0;
	loadingFile: boolean = false;
	subcatNombre = '';
	subcatNombre_es = '';
	idGrupo = 0;

	constructor(
		public _DomSanitizer: DomSanitizer,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private subheaderService: SubheaderService,
		private api: ApiService,
		private header: HeaderService,
		private footer: FooterService,
		private cdRef: ChangeDetectorRef,
		public ts:TranslationService) {
		this.header.hide();
		this.footer.hide();
	}



	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.idGrupo = params.id;
			this.grupo = new Grupo();
			this.grupo.clear();

			if (this.idGrupo && this.idGrupo > 0) {
				this.api.readData('grupos', '', 'id = ' + this.idGrupo).subscribe((res: any) => {
					this.grupo = Object.assign(this.grupo, res.data[0]);
					this.initGrupo();

				})
			} else {
				this.initGrupo();
			}
		});

		this.cdRef.detectChanges();

	}

	initGrupo() {
		this.createForm();
		if (!this.grupo.id) {
			this.subheaderService.setTitle('Crear grupo');
			this.subheaderService.setBreadcrumbs([
				{ title: 'Grupos', page: `grupos` },
				{ title: 'Crear grupo', page: `grupos/new` }
			]);
			return;
		}
		this.subheaderService.setTitle('Editar Familia');
		this.subheaderService.setBreadcrumbs([
			{ title: 'Grupos', page: `grupos` },
			{ title: 'Editar grupo', page: `grupos`, queryParams: { id: this.grupo.id } }
		]);
	}


	createForm() {
		this.grupoForm = this.fb.group({
     		nombre_en: [this.grupo.nombre_en, Validators.required],
      		nombre_es: [this.grupo.nombre_es],
			nombre_de: [this.grupo.nombre_de],
			nombre_fr: [this.grupo.nombre_fr]
		});

		console.log(this.grupoForm);
	}

	getComponentTitle() {
		let result = 'Crear grupo';
		if (!this.grupo || !this.grupo.id) {
			return result;
		}

		result = `Editar grupo - ${this.grupo.nombre_es}`;
		return result;
	}

	saveGrupo() {
		const newGrupo = Object.assign(this.grupo, this.grupoForm.value);
		if (!newGrupo.id) {
			this.api.createData('grupos', newGrupo).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/grupos']);
				}
				
			});
		} else {
			this.api.updateData('grupos', newGrupo).subscribe((res: any) => {
				console.log(res);
				if (res) {
					this.router.navigate(['/panel/grupos']);
				}

			});
		}
		

	}

	


	ngOnDestroy(): void {
		this.header.toggle();
		this.footer.toggle();
	}

}
