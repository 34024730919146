// Angular
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
// Services
import { ApiService } from '../../../services/api/api.service';
import { HeaderService } from '../../../services/header/header.service';
import { FooterService } from '../../../services/footer/footer.service';
// Models
import { Comercial } from '../../../core/auth/_models/comercial.model';

import { SubheaderService } from '../../../core/_base/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DeleteComercialComponent } from './comercial/delete-comercial/delete-comercial.component';

@Component({
    selector: 'kt-comerciales',
    templateUrl: './comerciales.component.html',
    styleUrls: ['./comerciales.component.scss']
})
export class ComercialesComponent implements OnInit, OnDestroy {

    comerciales: Comercial[] = [];
    dataSource;
    contadorImagenes = [];
    tmpComercialesForm = new FormGroup({
        selected: new FormControl()
    });


    displayedColumns: string[] = ['select', 'id', 'nombre', 'email', 'opciones'];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    selection = new SelectionModel<Comercial>(true, []);

    constructor(
        private api: ApiService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private subheaderService: SubheaderService,
        private matDialog: MatDialog,
        private header: HeaderService,
        private footer: FooterService) {
        this.header.hide();
        this.footer.hide();
    }

    ngOnInit() {

        this.subheaderService.setTitle('Sub Familias');

        this.api.readData('comerciales').subscribe((res: any) => {
            for (const o of res.data) {
                this.comerciales.push(o);

            }
            this.dataSource = new MatTableDataSource(this.comerciales);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

        });

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.comerciales.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.selection.selected.length === this.comerciales.length) {
            this.selection.clear();
        } else {
            this.comerciales.forEach(row => this.selection.select(row));
        }
    }

    fetchComerciales() {
        const messages = [];
        this.selection.selected.forEach(elem => {
            messages.push({
                text: `${elem.nombre}`,
                id: elem.id.toString(),
                status: elem.nombre
            });
        });
        console.log(messages);

    }

    editComercial(id: number) {
        this.router.navigate(['../comerciales/', id], { relativeTo: this.activatedRoute });
    }

    deleteComercial(id: number) {
        const comercial = this.comerciales.find(i => i.id === id);
        const dialogConfig: MatDialogConfig = {
            autoFocus: true,
            maxWidth: '400px',
            data: {
                id: comercial.id
            }
        };
        const dialogRef = this.matDialog.open(DeleteComercialComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
                this.api.deleteData('comerciales', res).subscribe((res2: any) => {
                    if (res2) {
                        this.dataSource.data = this.dataSource.data.filter(obj => obj.id !== id);
                    }
                });
            }
        });
    }


    ngOnDestroy(): void {
        this.header.toggle();
    }
}
