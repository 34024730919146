import { BaseModel } from '../../_base/crud';

export class Proyecto extends BaseModel {
    id: number;
    titulo_es: string;
    titulo_en: string;
    titulo_de: string;
    titulo_fr: string;
    pais: number;
    imagenes: string[];
    id_categoria: number;
    clear(): void {
        this.id = undefined;
        this.titulo_es = '';
        this.titulo_en = '';
        this.titulo_de = '';
        this.titulo_fr = '';
        this.pais = 0;
        this.imagenes = [];
        this.id_categoria = 0;
    }
}
