// Spain
export const locale = {
  lang: 'es',
  data: {
    HOME: {
      NEWCATALOGUES: 'CONSULTA NUESTROS NUEVOS CATÁLOGOS ONLINE',
    },
    COOKIES: {
      THISTIME: 'Aceptar esta vez',
      ACCEPT: 'Aceptar',
      REJECT: 'Rechazar',
      PRIVACY: 'Valoramos tu privacidad',
      TEXT: 'Esta página utiliza cookies para mejorar tu experiencia de usuario. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      LINK: 'Nuestra política de cookies',
      CONTENT: `<h3>Modelos, acabados y colores</h3>

      <p>Modelos: En PUNT trabajamos continuamente en la mejora de los productos por lo que nos reservamos el derecho a rectificar dimensiones, materiales, colores, etc, de los mismos con el fin de mejorar la calidad, prestación, solidez o precio. Si los productos que has solicitado incorporan algún cambio respecto al catálogo o tarifa vigente, te lo comunicaremos previamente para que nos confirmes que sigues interesado en los mismos.</p>

      <p>Acabados: Todos los acabados que te ofrecemos, ya sean maderas naturales, teñidas o lacadas; metales lacados o anodizados, aún protegidos, pueden ir corrigiendo su color original con el paso del tiempo y la exposición a la luz solar. Las maderas, por su origen natural, presentan mallas y tonos no homogéneos de un tablero a otro y a una pieza maciza. En PUNT seleccionamos para ti cada pieza para que esas diferencias, dentro de un mismo mueble, sean agradables.</p>

      <p>Colores: Puede que nuestros bonitos colores una vez los aprecies en la realidad, no sean exactamente iguales a los que puedas haber visto en una pantalla o en un catálogo.</p>

      <h3>Pedidos, transporte y plazo de entrega</h3>

      <p>Pedidos: Para evitar confusiones, deberás formalizar las órdenes de pedido por escrito. Una vez recibamos tu pedido completamente definido te enviaremos un e-mail confirmándolo y empezaremos a prepararlo. A partir de dicho momento cualquier cancelación por tu parte podrá conllevar el abono de los costes y gastos incurridos por PUNT en la confección de tu pedido.</p> 

      <p>Transporte: Te indicaremos en la confirmación de pedido el servicio de transporte pactado y si llevara algún coste asociado.</p>

      <p>Plazo de Entrega: PUNT indicará en cada pedido el plazo de entrega estimado en función de los productos adquiridos y el destino de envío. Trabajamos duro para mejorar dichas estimaciones. Esperamos que nos disculpes si por causas imprevistas no lo conseguimos. Cuando esté listo tu pedido lo entregaremos en el destino de envío indicado en la orden de pedido donde deberás firmar el albarán de entrega.</p>

      <h3>Garantía</h3>

      <p>Si eres un cliente final, te garantizamos durante 2 años la calidad de la pieza por lo que debes guardarte el comprobante de la compra. Si tienes una incidencia, no nos mandes el producto de vuelta. Mándanos una foto y la explicación para que podamos entender el problema y darte solución. No cubrimos los defectos si haces un uso inadecuado de la pieza así que, con cada producto, te entregaremos el manual de instalación y las condiciones de uso y mantenimiento. Estamos a tu disposición en info@puntmobles.com para cualquier duda que tengas.</p>

      <p>Sólo la compañía de transporte cubre los defectos ocasionados en el mismo por lo que revisa inmediatamente el producto en la recepción y anota cualquier sospecha en el documento de transporte o no se harán cargo. Nuestro embalaje es muy bueno pero no soporta cualquier trato. Asegúrate que lo manipulas con cuidado antes de retirarlo y no lo hagas hasta que el mueble llegue al lugar donde se quede definitivamente.</p>

      <h3>Mantenimiento y limpieza de muebles</h3>

      <p>Para una correcta conservación del mueble debes mantenerlo en un espacio no húmedo. Debes retirar cualquier vertido o mancha de forma inmediata para que no atraviese o dañe la capa de barniz protectora. Para cualquiera de los materiales del mueble debes utilizar paños no abrasivos ligeramente humedecidos o con productos de limpieza que no sean ácidos ni disolventes de pinturas como la acetona. En las maderas acabadas al agua nunca utilices productos de limpieza con contenido en alcohol. Tras limpiar la superficie sécala con un paño seco.</p>

      <h3>Web, contenidos y diseños</h3>

      <p>PUNT MOBLES XXI, S.L. (“PUNT”) es la propietaria de este dominio, y de los contenidos existentes en el mismo. Si quieres reproducirlos total o parcialmente pídenoslo antes. Asimismo, PUNT tiene la comercialización en exclusiva de los diseños ofrecidos.</p>

      <h3>Protegemos tus datos</h3>

      <p>Los datos personales que envías a través de la página web son almacenados en los servidores de PUNT. Sólo registramos aquellos datos que necesitamos para enviarte la información que nos pides, las novedades de PUNT y poder tramitar tus pedidos. Si eres un distribuidor trasladamos tus datos al agente comercial de tu país y al proveedor de servicios de transporte que te lleva los productos y si eres un cliente final, los enviamos al distribuidor más adecuado para darte el servicio, pues nosotros no vendemos online. Todos ellos se comprometen a mantener la confidencialidad de los mismos y a hacer un uso exclusivo para el fin con el que nos los entregaste. En ningún caso trasladamos tus datos a empresas de tratamientos de datos por lo que no empezarás a recibir publicidad indeseada que no corresponda a PUNT. En todo momento podrás pedirnos la información sobre los datos almacenados y sobre su utilización. Podrás actualizarlos, modificarlos o eliminarlos para que no volvamos a utilizarlos. Es suficiente con enviarnos un correo electrónico a <a href="mailto:info@puntmobles.com">info@puntmobles.com</a>.</p>

      <h3>Usamos Cookies</h3>

      <p>Una cookie es un pequeño archivo que se genera y se almacena en el ordenador del usuario cuando se conecta a nuestra página web. Estos archivos contienen una serie de informaciones sobre la configuración de nuestro portal web e información de costumbres de navegación. La información obtenida a través de las cookies es totalmente anónima, y en ningún caso puede ser asociada a un usuario concreto e identificado. Esta información nos permite conocer las pautas y estadísticas de uso para mejorar la experiencia de navegación del usuario. En cualquier momento el uso de cookies puede ser bloqueado en las opciones del navegador que uses.</p>

      <h3>POLITICA DE CALIDAD. DECLARACIÓN DE LA DIRECCIÓN.</h3>
      <p>El alcance de la actividad de la empresa es el diseño, producción y comercialización de mobiliario de hogar y contract.</p>
      
      <p>Punt Mobles XXI asume que la satisfacción de los requisitos del cliente, expresados o implícitos, y el cumplimiento de los requisitos legales y reglamentarios que afecten a nuestros productos, son básicos para asegurar nuestra continuidad como empresa. Por ello desde la Dirección se apoyará cualquier iniciativa que tienda a comprender, definir, controlar o cumplir estos requisitos.</p>
      <p>La política enunciada seguidamente, debe tomarse como una orientación para todas las personas que trabajamos en la empresa. Igualmente, sirve como referencia para nuestros clientes, expresando todo aquello que pueden esperar de Punt Mobles XXI sin que en ningún momento tengan que sentirse defraudados.</p>
      <p>La base de nuestra política es ofrecer, a todos nuestros clientes y de forma constante, el producto demandado garantizándoles un buen servicio durante toda la relación comercial. </p>
      <p>Para conseguir este objetivo, se articulan las siguientes políticas:</p>
      <ul>
          <li>La dirección de Punt apoya decididamente la evolución de su identidad corporativa, integrando en esta el concepto de calidad.</li>
          <li>La dirección de Punt participa activamente en la mejora de la calidad, estableciendo las medidas de relación y apoyo necesarias con sus proveedores.</li>
          <li>Punt asume como pilar básico de su política, la atención y satisfacción del cliente.</li>
          <li>La dirección de Punt considera imprescindible el compromiso y la participación de todo el colectivo de su organización, en la obtención de la máxima calidad del producto, servicio al cliente y mejora del medio ambiente.</li>
          <li>Punt se compromete a mantener el esfuerzo permanente en la formación y reciclaje de todo el personal de su organización para garantizar el progreso en la mejora de la calidad.</li>
          <li>El principio de nuestra política de calidad, es la garantía de nuestra supervivencia como empresa, adoptando la mejora continua en calidad, competitividad y medio ambiente, como herramienta orientada a la consecución y revisión de nuestros objetivos.”</li>
      </ul>
      
      <i>“La empresa puntmoblesXXI. S.L ha participado en la Jornada Técnica del 16 de Octubre en Ciudad de México y ha contado con el apoyo de ICEX, así como con la cofinanciación de fondos europeos FEDER, habiendo contribuido, según la medida de los mismos, al crecimiento económico de esta empresa, su región y de España en su conjunto”.</i>`
    },
    NAVBAR: {
      PRODUCTSFINISHED: 'Productos acabados',
      PRODUCTS: 'Productos',
      PROJECTS: 'Proyectos',
      DESIGNERS: 'Diseñadores',
      DOWNLOADS: 'Descargas',
      ABOUT: 'Nosotros',
      CONTACT: 'Contacto',
      LANGUAGE: 'Idioma',
      SIGNUP: 'Registro',
    },
    TRANSLATOR: {
      SELECT: 'Elige tu idioma',
      SPANISH: 'Español',
      ENGLISH: 'English',
    },
    RESTORE: {
      RESTORE: 'RECUPERA TU CONTRASEÑA',
      SUCCESS: 'Tu contraseña ha sido actualizada.',
      UPDATE: 'Actualizar',
      PASSMATCH: 'Las contraseñas no coinciden',
      RESEND: 'No ha sido posible recuperar la contraseña. Vuelve a solicitar el email de recuperación.'
    },
    SESION: {
      EMAIL: 'email',
      PASSWORD: 'Contraseña',
      MYDATA: 'Mis datos',
      SIGNOFF: 'Log out',
      LOGIN: 'Log in',
      FORGOTPASS: '¿Has olvidado la contraseña? Pincha aquí.',
      ERRORDATOS: 'Tienes que rellenar todos los campos',
      ERRORMAIL: 'Tienes que rellenar el campo del email',
      ERRORUSER: 'No existe ningún usuario con los datos introducidos',
      SENTMAIL: 'Te hemos enviado un email para recuperar tu contraseña. Revisa tu bandeja de correo.',
      PROBLEMS: 'Si tienes problemas para acceder contacta con',
      PROBLEMS2: '¿Tienes contraseña? Pincha aquí',
      REMEMBERPASS: 'Recordar contraseña',
      ERRORPASS: 'Contraseña incorrecta. Por favor, vuelve a intentarlo',
    },
    DISTRIBUTORS: {
      NAME: 'Nombre',
      EMAIL: 'Email',
      PHONE: 'Teléfono',
      LOCATION: 'LOCALIZACIÓN',
      PROVINCESTATE: 'Estado / provincia',
      COUNTRY: 'País',
      TITLERESULTS: 'Distribuidores para la localización ',
      NORESULTS: 'No existen resultados para esta localización',
      AGENT: 'Agente',
      AGENTMAIL: 'Email Agente',
      CONTACT: 'Contacta con nosotros, encuentra tu distribuidor'

    },
    NEWSLETTER: {
      TITLE: 'SÉ EL/LA PRIMERO/A EN ENTERARTE',
      DESCRIPTION1: 'Sé el/la primero/a en recibir nuevas ofertas, ediciones limitadas de productos, ' +
      'eventos e inspiración directamente en tu correo',
      DESCRIPTION2: 'Introduciondo tu dirección de correo aceptas nuestra',
      PRIVACY: 'política de privacidad.'
    },
    CONFIGURADOR: {

      COLLECTIONS: 'Colecciones',
      BACK_BUTTON: 'Anterior',
      NEXT: 'Siguiente',
      COMPOSITION: 'COMPOSICIÓN',
      FINISH: 'Acabado',
      SUMMARY: 'Resumen',
      ADDCART: 'Añadir al carrito',
      ADDMORE: 'Añadir más elementos',
      GOCART: 'Ir al carrito',
      CONTINUEBUY: 'Volver a las colecciones',
      DOWNLOADS: 'Descargas',
      REFERENCE: 'Referencia',
      QTY: 'Cantidad',
      PRIZE: 'Precio ( IVA excluido )',
      LOGINREQUIRED: 'Para poder ver los precios',
      LOGIN: 'inicia sesión',
      PRIZEPENINSULA: 'Precio para península.',
      REQUESTPRICE: 'Solicitar precio',
      PRIZEADVICE: 'Tenga en cuenta que es un precio sin IVA, el IVA se pagará en el país de importación. No se incluyen otros gastos, como los de envío, los aranceles y los gastos de declaración.'
    },
    COLLECTIONS: {
      IMAGENDETALLE: 'IMÁGENES DE DETALLE',
      PRESENTATION: 'PRESENTACIÓN DE PRODUCTO',
      VIDEO: 'VIDEO',
      REVIT: 'REVIT',
      SHEET: 'INFORMACIÓN TÉCNICA',
      THREEDFILES: 'ARCHIVOS 3D',
      TWODFILES: 'ARCHIVOS 2D',
      PHOTOSHI: 'FOTOS HI',
      PHOTOSLO: 'FOTOS LO',
      COLLECTIONS: 'Colecciones',
      FILTER: 'Filtro',
      SEARCH: 'Buscar',
      TYPE: 'Tipología',
      ALL: 'Todas',
      DESIGNEDBY: 'diseñado por',
      DISCOVER: 'Descubre toda la colección',
      PRODUCTSBY: 'PRODUCTOS POR',
      CERTIFICATES: 'CERTIFICADOS',
      GUARANTIES: 'CERTIFICADO DE CALIDAD Y GARANTÍA',
      BACKGROUNDWHITE: 'FONDO BLANCO',
      HOJAMONTAJE: 'MONTAJE',
      MEDIDASINTERIORES: 'MEDIDAS INTERIORES',
      NOTFOUND: 'No products were found with the selected filters',
      SEARCHINFO: "Puedes encontrar los archivos del producto que necesites con nuestro buscador"
    },
    PROJECTS: {
      PROJECTS: 'PROYECTOS'
    },
    DOWNLOADS: {
      DOWNLOADS: 'Descargas',
      FILTER: 'Filtrar',
      SEARCH: 'Buscar...',
      TECHNICALINFO: 'INFORMACIÓN TÉCNICA',
      CERTIFICATES: 'CERTIFICADOS',
      GUARANTY: 'GARANTÍA',
      INSTRUCTIONS: 'HOJAS DE MONTAJE',
      PHOTOHIGHTRESOLUTION: 'FOTOS ALTA RESOLUCIÓN',
      PHOTOLOWRESOLUTION: 'FOTOS BAJA RESOLUCIÓN',
      WHITEBACKGROUNDPHOTOS: 'FOTOS FONDO BLANCO',
      VIDEO: 'VIDEO'
    },
    ORDER: {
      COLLECTIONS: 'Colecciones',
      ORDER: 'Presupuesto',
      FILTER: 'Filtro',
      PRIZEVALID: 'Precios válidos durante 7 días',
      REFERENCE: 'Referencia',
      QTY: 'Cantidad',
      PRIZE: 'Precio',
      PRIZEUNIT: 'Precio unitario',
      REMOVE: 'Borrar',
      MKORDER: 'Finalizar',
      FINISHED: '¡Gracias!',
      DOWNPDF: 'Descargar PDF',
      TOTCOMPOSITION: 'TOTAL COMPOSICIÓN',
      TOTALAMOUNT: 'TOTAL PRESUPUESTO',
      IVANO: 'IVA no incluido',
      COMMENTS: 'OBSERVACIONES',
      MOREINFO: 'Gracias por utilizar nuestra tarifa online. Este proceso no ha generado' +
      ' ningún pedido en Punt de forma automática. Si desea contactar con nosotros, mande un mail a su persona de contacto.',
      VALIDITY: 'VALIDEZ DEL PRESUPUESTO',
      VATNOTINCLUDE: 'IVA no incluido',
      OBSERVATIONS: 'OBSERVACIONES: '

    },
    SIGNUP: {
      SIGNUP: 'REGISTRO',
      BRANDNAME: 'Nombre y apellido (apellido solo 1)',
      BUSSNAME: 'Razón Social',
      CONTACTPERS: 'Persona de Contacto',
      VATID: 'NIF / CIF',
      ADDRESS: 'Dirección',
      POSTALCODE: 'Código Postal',
      CITY: 'Ciudad',
      STATE: 'Provincia',
      PHONE: 'Teléfono',
      EMAIL: 'Email',
      WEBSITE: 'Web',
      PASSWORD: 'Contraseña',
      CONFPASSWORD: 'Confirma la Contraseña',
      SIGNUPI: 'Registrar',
      SIGNUPMESSAGE: '¡Registro realizado con éxito!',
      GOCOLLECTIONS: 'Ir a colecciones',
      COUNTRY: 'País',
      USEREXISTS: 'Ya existe un usuario con ese email. Por favor, inicia sesión.'
    },
    MENU: {
      NEW: 'nuevo',
      ACTIONS: 'Comportamiento',
      CREATE_POST: 'Crear nueva publicación',
      PAGES: 'Pages',
      FEATURES: 'Caracteristicas',
      APPS: 'Aplicaciones',
      DASHBOARD: 'Tablero',
      USERS: 'Usuarios',
      CLIENTS: 'Clientes',
      FAMILIES: 'Famílias',
      PROJECTS: 'Proyectos',
      CATPROJECTS: 'Categorías proyectos',
      SUBFAMILIES: 'Sub Famílias',
      COLECTION: 'Colecciones',
      FINISHES: 'Acabados',
      PRICES: 'Precios',
      REFERENCES: 'referencias',
      MODULES: 'Módulos',
      PLINTHS: 'Zócalos',
      COVERS: 'Cubiertas',
      ORDERS: 'Listado presupuestos',
      UNFINISHED: 'Presupuestos inacabados',
      DESIGNERS: 'Diseñadores',
      DOWNLOADS: 'Descargas',
      DISTRIBUTORS: 'Distribuidores',
      ABOUT: 'Nosotros',
      CONTACT: 'Contacto',
    },
    FOOTER: {
      ABOUT: 'Acerca de',
      PROFESSIONAL: 'Profesional',
      ABOUTUS: 'Sobre nosotros',
      DESIGNERS: 'Diseñadores',
      VIRTUALSHOWROOM: 'Virtual showroom',
      LEGALMAINTENANCE: 'Legal & mantenimiento',
      SUSTAINABILITY: 'Sostenibilidad',
      DOWNLOADS: 'Descargas',
      DISTRIBUTIONS: 'Distribuidores',
      CONTACT: 'Contacto',
      PRESSKIT: 'Presskit',
      NEWSLETTER: 'Newsletter',
      FOLLOWUS: 'Síguenos en',
    },
    AUTH: {
      GENERAL: {
        OR: 'O',
        SUBMIT_BUTTON: 'Enviar',
        NO_ACCOUNT: 'No tienes una cuenta?',
        SIGNUP_BUTTON: 'Regístrate',
        FORGOT_BUTTON: 'Se te olvidó tu contraseña',
        BACK_BUTTON: 'Volver',
        PRIVACY: 'Intimidad',
        LEGAL: 'Legal',
        CONTACT: 'Contacto',
        TITLE: 'Iniciar sesión',
      },
      LOGIN: {
        TITLE: 'Crear una cuenta',
        BUTTON: 'Iniciar Sesión',
      },
      FORGOT: {
        TITLE: 'Contraseña olvidada?',
        DESC: 'Ingrese su correo electrónico para restablecer su contraseña',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
        REGISTER: 'REGISTRO',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Usuario',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
    },
    SUTAINABILITY: {
      T1 : '¡Protejamos el medioambiente!',
      D11 : 'Somos conscientes de que el medioambiente es una tarea de todos y trabajamos con el mejor nivel de calidad y diseño para garantizar un uso duradero de todos los productos reduciendo la huella de carbono y el efecto invernadero. Trabajamos principalmente con materiales reciclados y/o reciclables y nos apoyamos en proveedores con procesos certificados en mejora continua y respeto ambiental.',
      D12 : 'Toda la energía eléctrica consumida en nuestra factoría se nutre de fuentes de energía de origen renovable.',
      T2 : 'Reducción de la huella de carbono',
      D2 : 'Los árboles, en su proceso de crecimiento, captan el Co2 liberado por la quema de combustibles fósiles. Producir madera que se transforma en mueble es reducir el Co2 ambiental mitigando así el efecto invernadero. En Punt vamos más allá. Toda la energía eléctrica consumida en el proceso de fabricación proviene de fuentes renovables por lo que reducimos a 0% las emisiones de carbono en nuestra fábrica!',
      T3 : 'Materiales reciclados',
      D3 : 'Somos especialistas en tablero chapado en maderas naturales. Con este material te ofrecemos acabados de primerísima calidad con interiores 100% reciclados.',
      T4 : 'Materiales reciclables',
      D4 : 'Nuestros productos se componen casi al 100% de forma exclusiva de maderas, aceros, aluminios y vidrios. Estos materiales se identifican en las fichas de producto que se entregan a los clientes en cada pedido y son 100% reciclables.',
      T5 : 'FSC',
      T6 : 'Respetemos nuestros bosques',
      D61 : 'Proteger nuestros bosques y reducir el dióxido de carbono ambiental está en nuestras manos. Mediante el control del origen de la madera se puede garantizar la reforestación de los bosques utilizados para la obtención de la materia prima. FSC es una organización global, sin ánimo de lucro, dedicada a promover la gestión forestal responsable en todo el mundo. La certificación FSC garantiza que los productos tienen su origen en bosques bien gestionados que proporcionan beneficios ambientales, sociales y económicos. En particular, La certificación de Cadena de Custodia se aplica a fabricantes, rematantes y distribuidores de productos forestales certificados FSC. Este tipo de certificación verifica que los productos que se venden con etiqueta FSC realmente contienen materiales certificados FSC y fuentes controladas a lo largo de su cadena de producción.',
      D62 : 'La Madera Controlada se ha definido para evitar que las empresas u organizaciones incluyan en sus materiales fuentes de madera que no se pueden aceptar. La Madera Controlada FSC solo puede mezclarse con madera certificada FSC en productos etiquetados como FSC Fuentes Mixtas.',
      D63 : 'En Punt trabajamos con proveedores de tableros certificados FSC en la Cadena de Custodia (FSC-STD-40- 004) y en la Madera Controlada (FCD-STD-40-005).',
      T7 : 'Garantía de Calidad, respeto al medio ambiente y mejora continua',
      D71 : 'Punt está certificada con la ISO 9001.',
      D72 : 'Seleccionamos cuidadosamente a nuestro proveedores y trabajamos unidos para garantizar la mejor calidad de nuestros productos. Por ello, nuestros proveedores de tableros, barnices, espumas, telas, piezas metálicas y herrajes están certificados con la ISO 9001:2008 y los de barnices y telas, además, en la ISO 14001:2004.',
    },
    
    CONTACT: {
      TGENERAL: "T. Departamento comercial SPAIN / ESPAÑA",
      TCOMERCIAL: "T. Departamento comercial OTHER COUNTRIES",
      TBUYS: "T. Departamento de compras"
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Buscar',
        IN_ALL_FIELDS: 'en todos los campos',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        }
      },
    },
  },
};
