import { BaseModel } from '../../_base/crud';

export class Subfamilia extends BaseModel {
    id: number;
    nombre_en: string;
    nombre_es: string;
    nombre_de: string;
    nombre_fr: string;
    codigo: string;
    nombreAcabado_en: string;
    nombreAcabado_es: string;
    nombreAcabado_de: string;
    nombreAcabado_fr: string;
    referencias: string;
    acabados: string;
    nombreAcabado2_en: string;
    nombreAcabado2_es: string;
    nombreAcabado2_de: string;
    nombreAcabado2_fr: string;
    nombreAcabado3_en: string;
    nombreAcabado3_es: string;
    nombreAcabado3_de: string;
    nombreAcabado3_fr: string;
    acabados2: string;
    acabados3: string;
    referenciasRel: string;
    relacionEntreRef: string;
    acabado1Precio: number;
    acabado2Precio: number;
    acabado3Precio: number;
    preciosEspeciales: string;
    docRevit: string;
    doc3DFiles: string;
    doc2DFiles: string;
    docFicha: string;
    docFotosHI: string;
    docFotosLO: string;
    descargasLogin: string;
    subcategoria: number;
    grupo: number;
    multiSelectPrin: number;
    multiSelectRel: number;
    refRelOpcionales: string;
    refRelPrecio: string;
    imagenes: string;
    imagenesPrincipales: string;
    img_destacada: string;
    id_disenador: number;
    descripcion_es: string;
    descripcion_en: string;
    descripcion_de: string;
    descripcion_fr: string;
    video_destacado: string;
    certificados: string;
    garantias: string;
    fondo_blanco: string;
    hoja_montaje: string;
    medidas_interiores: string;
    presentacion: string;
    imagen_detalle: string;
    video:string;
    orden: number;

    clear(): void {
        this.id = undefined;
        this.nombre_en = '';
        this.nombre_es = '';
        this.nombre_de = '';
        this.nombre_fr = '';
        this.codigo = '';
        this.referencias = '';
        this.nombreAcabado_en = '';
        this.nombreAcabado_es = '';
        this.nombreAcabado_de = '';
        this.nombreAcabado_fr = '';
        this.acabados2 = '';
        this.nombreAcabado2_en = '';
        this.nombreAcabado2_es = '';
        this.nombreAcabado2_de = '';
        this.nombreAcabado2_fr = '';
        this.acabados3 = '';
        this.nombreAcabado3_en = '';
        this.nombreAcabado3_es = '';
        this.nombreAcabado3_de = '';
        this.nombreAcabado3_fr = '';
        this.acabados = '';
        this.referenciasRel = '';
        this.relacionEntreRef = '';
        this.acabado1Precio = 0;
        this.acabado2Precio = 0;
        this.acabado3Precio = 0;
        this.preciosEspeciales = '';
        this.subcategoria = 0;
        this.grupo = 0;
        this.multiSelectPrin = 0;
        this.multiSelectRel = 0;
        this.refRelOpcionales = '';
        this.refRelPrecio = '';
        this.imagenes = '';
        this.img_destacada = '';
        this.id_disenador = 0;
        this.descripcion_es = '';
        this.descripcion_en = '';
        this.descripcion_de = '';
        this.descripcion_fr = '';
        this.video_destacado = '';
        this.docFicha = '';
        this.docFotosHI = '';
        this.docFotosLO = '';
        this.docRevit = '';
        this.doc3DFiles = '';
        this.doc2DFiles = '';
        this.certificados = '';
        this.garantias = '';
        this.fondo_blanco = '';
        this.hoja_montaje = '';
        this.medidas_interiores = '';
        this.presentacion = '';
        this.video = '';
        this.imagen_detalle = '';
        this.orden = 0;
    }
}
