import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kt-delete-familia',
  templateUrl: './delete-subfamilia.component.html',
  styleUrls: ['./delete-subfamilia.component.scss']
})
export class DeleteSubfamiliaComponent implements OnInit {

  id: number;
  nombre: string;

  constructor(public dialogRef: MatDialogRef<DeleteSubfamiliaComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.id = data.id;
    this.nombre = data.nombre;
   }

  ngOnInit() {
  }

  accept() {
    this.dialogRef.close(this.id);
  }

  cancel() {
    this.dialogRef.close();
  }

}
